import React from 'react';
import {
    Row, Col, Form, FormFeedback, FormGroup
} from 'reactstrap';
import {clearSignUpTeacher, signUpTeacher} from "../../store/user/actions";
import {connect} from "react-redux";
import MyForm from "../Common/MyForm";
import {CancelButton, LoaderButton} from "../Common/MyButtons";
import {
    checkIfNull, clickCloseChildButton,
    getAreaAndPhoneFromContact,
    getInstituteOptionsForTeacherAndStudents,
    validateIsEmpty
} from "../../helpers/helper";
import AsyncSelect from 'react-select/lib/Async';
import language from "../../helpers/language";
import '../Pages/Reports/reports.css';

class SignUpTeacherForOtherInstitute extends MyForm {
    componentDidMount() {
        this.props.clearSignUpTeacher();
        this.setInitialState();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.signUpTeacherSuccessMsg !== '') {
            this.resetState();
            clickCloseChildButton();
        }
    }

    setInitialState() {
        const {user} = this.props;
        let contactNumber = getAreaAndPhoneFromContact(user.contactNumber);
        this.setState({
            fields: {
                instituteId: '',
                firstName: checkIfNull(user.firstName),
                lastName: checkIfNull(user.lastName),
                email: checkIfNull(user.email),
                country: checkIfNull(user.country),
                area_code: contactNumber['area_code'],
                phone_number: contactNumber['phone_number'],
            },
            validationErrors: {
                instituteId: '',
            },
            notRequiredFields: ["country", "area_code", "phone_number"]
        });
    }

    submitFormUsingProps() {
        const {fields} = this.state;
        const joinOther = 'Join Other Institutes';
        this.props.signUpTeacher(fields, joinOther);
    }

    customValidation(fieldName, value) {
        const {validationErrors} = this.state;
        switch (fieldName) {
            default:
                if (!validateIsEmpty(value)) {
                    validationErrors[fieldName] = '';
                }
                break;
        }
    }

    render() {
        const {fields, validationErrors, isFormValid, isValidationRun, isFormChanged} = this.state;
        const {isSigningUpTeacher, canBrowserShowSuggestions} = this.props;
        return (
            <React.Fragment>
                <Row>
                    <Col className="text-center" sm={{size: 10, offset: 1}}>
                        <h1 className="text-uppercase">{language.TITLE_JOIN_AS_TEACHER}</h1>
                        <br/>

                        <Form className="text-center" onSubmit={this.handleSubmit}
                              autoComplete={canBrowserShowSuggestions ? 'on' : 'off'}>
                            <div className="form-title">
                                INSTITUTE INFO
                            </div>
                            <FormGroup className="attendance_body white">
                                <AsyncSelect cacheOptions defaultOptions className="text-left"
                                             name="instituteId"
                                             clearValue={(value) => this.handleChangeReactSelect('instituteId', value)}
                                             onChange={(value) => this.handleChangeReactSelect('instituteId', value)}
                                             value={fields.instituteId}
                                             loadOptions={getInstituteOptionsForTeacherAndStudents}
                                             noOptionsMessage={(inputValue) => {
                                                 return 'No Institutes Available'
                                             }}
                                             blurInputOnSelect={true}
                                             placeholder="Select Institute"
                                             classNamePrefix="react-select"
                                             instanceId="12345678"
                                />
                                <FormFeedback className="d-block">{validationErrors.instituteId}</FormFeedback>
                            </FormGroup>
                            <br/>

                            <LoaderButton btnLabel="Join" isRequest={isSigningUpTeacher} isFormValid={isFormValid}
                                          isValidationRun={isValidationRun} isFormChanged={isFormChanged}/>
                            <CancelButton btnLabel="Cancel" onClick={clickCloseChildButton}
                                          isFormChanged={isFormChanged}/>
                            <br/>
                            <br/>
                        </Form>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    signUpTeacher,
    clearSignUpTeacher
};

function mapStateToProps(state) {
    return {
        isSigningUpTeacher: state.user.isSigningUpTeacher,
        signUpTeacherSuccessMsg: state.user.signUpTeacherSuccessMsg,
        signUpTeacherErrorMsg: state.user.signUpTeacherErrorMsg
    };
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(SignUpTeacherForOtherInstitute);
