import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    isloggingIn: false,
    isLoggedIn: false,
    errorMsg: '',
    user: {},
    otherAccounts: [],
    instituteInfo: {},

    isTokenValidating: false,

    isRecurringTokenValidating: false,
    isInstituteBlocked: false,
    instituteBlockedErrorMsg: false,
});

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return state.merge({
                isloggingIn: true,
                isLoggedIn: false,
                errorMsg: '',
                user: {},
                otherAccounts: [],
                instituteInfo: []
            });
        case types.LOGIN_SUCCESS:
            return state.merge({
                isloggingIn: false,
                isLoggedIn: true,
                errorMsg: '',
                user: action.user.userData,
            });
        case types.LOGIN_FAILURE:
            return state.merge({
                isloggingIn: false,
                isLoggedIn: false,
                errorMsg: action.errorMsg
            });
        case types.TOKEN_VALIDATING:
            return state.merge({
                isTokenValidating: true,
            });
        case types.TOKEN_VALIDATE:
            return state.merge({
                isTokenValidating: false,
                isloggingIn: false,
                isLoggedIn: action.isLoggedIn,
                errorMsg: action.errorMsg,
                user: action.user,
                otherAccounts: action.otherAccounts,
                instituteInfo: action.instituteInfo,
            });
        case types.RECURRING_TOKEN_VALIDATING:
            return state.merge({
                isRecurringTokenValidating: true,
            });
        case types.RECURRING_TOKEN_VALIDATE:
            return state.merge({
                isRecurringTokenValidating: false,
                isInstituteBlocked: action.isInstituteBlocked,
                instituteBlockedErrorMsg: action.instituteBlockedErrorMsg,
            });
        case types.LOGOUT_REQUEST:
            return state.merge({
                isloggingIn: false,
                isLoggedIn: false,
                errorMsg: '',
                user: {},
                otherAccounts: [],
                instituteInfo: {},
            });
        default:
            return state;
    }
}