import React from 'react';
import Loadable from "react-loadable";

const Loading = () => (
    <div className="text-center route-loader-style">
        <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw"/>
        <span className="sr-only">Loading...</span>
    </div>
    );

export const NotFound = Loadable({
    loader: () => import('../Pages/NotFound/NotFound'),
    loading: Loading,
});

export const Home = Loadable({
    loader: () => import('../Pages/Home/Home'),
    loading: Loading,
});

export const TermsOfUse = Loadable({
    loader: () => import('../Pages/TermsOfUse/TermsOfUse'),
    loading: Loading,
});

export const PrivacyPolicy = Loadable({
    loader: () => import('../Pages/PrivacyPolicy/PrivacyPolicy'),
    loading: Loading,
});

export const Support = Loadable({
    loader: () => import('../Pages/Support/Support'),
    loading: Loading,
});

export const ContactUs = Loadable({
    loader: () => import('../Pages/ContactUs/ContactUs'),
    loading: Loading,
});

export const Login = Loadable({
    loader: () => import('../Pages/Login/Login'),
    loading: Loading,
});

export const ForgotPassword = Loadable({
    loader: () => import('../Pages/ForgotPassword/ForgotPassword'),
    loading: Loading,
});

export const ResetPassword = Loadable({
    loader: () => import('../Pages/ResetPassword/ResetPassword'),
    loading: Loading,
});

export const SignupInstitute = Loadable({
    loader: () => import('../Pages/Institute/SignupInstitute'),
    loading: Loading,
});

export const InviteInstitute = Loadable({
    loader: () => import('../Pages/Institute/InviteInstitute'),
    loading: Loading,
});

export const AddInstitute = Loadable({
    loader: () => import('../Pages/Institute/AddInstitute'),
    loading: Loading,
});

export const EditInstitute = Loadable({
    loader: () => import('../Pages/Institute/EditInstitute'),
    loading: Loading,
});

export const AcceptInstituteInvite = Loadable({
    loader: () => import('../Pages/Institute/AcceptInstituteInvite'),
    loading: Loading,
});

export const RejectInstituteInvite = Loadable({
    loader: () => import('../Pages/Institute/RejectInstituteInvite'),
    loading: Loading,
});

export const ExpiredInstituteInvite = Loadable({
    loader: () => import('../Pages/Institute/ExpiredInstituteInvite'),
    loading: Loading,
});

export const Institutes = Loadable({
    loader: () => import('../Pages/Institute/Listing/Institutes'),
    loading: Loading,
});

export const InstituteDetail = Loadable({
    loader: () => import('../Pages/Institute/InstituteDetail'),
    loading: Loading,
});

export const JoinInstitute = Loadable({
    loader: () => import('../Pages/Institute/JoinInstitute'),
    loading: Loading,
});

// Institute Admin
export const Dashboard = Loadable({
    loader: () => import('../Pages/Dashboard/Dashboard'),
    loading: Loading,
});

// Institute Admin Settings Detail
export const InstituteSettingsDetail = Loadable({
    loader: () => import('../Pages/Settings/InstituteAdmin/SettingsDetail'),
    loading: Loading,
});

// Institute Admin Settings
export const InstituteSettings = Loadable({
    loader: () => import('../Pages/Settings/InstituteAdmin/EditSettings'),
    loading: Loading,
});

export const AddTeacher = Loadable({
    loader: () => import('../Pages/Teacher/AddTeacher'),
    loading: Loading,
});

export const EditTeacher = Loadable({
    loader: () => import('../Pages/Teacher/EditTeacher'),
    loading: Loading,
});

export const TeacherDetail = Loadable({
    loader: () => import('../Pages/Teacher/Detail/TeacherDetail'),
    loading: Loading,
});

export const Teachers = Loadable({
    loader: () => import('../Pages/Teacher/Listing/Teachers'),
    loading: Loading,
});

export const AddStudent = Loadable({
    loader: () => import('../Pages/Student/AddStudent'),
    loading: Loading,
});

export const EditStudent = Loadable({
    loader: () => import('../Pages/Student/EditStudent'),
    loading: Loading,
});

export const StudentDetail = Loadable({
    loader: () => import('../Pages/Student/Detail/StudentDetail'),
    loading: Loading,
});

export const Students = Loadable({
    loader: () => import('../Pages/Student/Listing/Students'),
    loading: Loading,
});

export const AddClass = Loadable({
    loader: () => import('../Pages/Class/AddClass'),
    loading: Loading,
});

export const EditClass = Loadable({
    loader: () => import('../Pages/Class/EditClass'),
    loading: Loading,
});

export const Classes = Loadable({
    loader: () => import('../Pages/Class/Listing/Classes'),
    loading: Loading,
});

export const ClassDetail = Loadable({
    loader: () => import('../Pages/Class/Detail/ClassDetail'),
    loading: Loading,
});

export const SessionDetail = Loadable({
    loader: () => import('../Pages/Class/Session/Detail/SessionDetail'),
    loading: Loading,
});

export const ReportsNavigator = Loadable({
    loader: () => import('../Pages/Reports/ReportsNavigator'),
    loading: Loading,
});

export const OverallSummaryDetail = Loadable({
    loader: () => import('../Pages/Reports/Common/OverallSummary/OverallSummaryDetail'),
    loading: Loading,
});

export const ClassReportDetail = Loadable({
    loader: () => import('../Pages/Reports/Common/ClassReport/ClassReportDetail'),
    loading: Loading,
});

export const SessionReportDetail = Loadable({
    loader: () => import('../Pages/Reports/Common/SessionReport/SessionReportDetail'),
    loading: Loading,
});

export const TeacherReportDetail = Loadable({
    loader: () => import('../Pages/Reports/Common/TeacherReport/TeacherReportDetail'),
    loading: Loading,
});

export const StudentReportDetail = Loadable({
    loader: () => import('../Pages/Reports/Common/StudentReport/StudentReportDetail'),
    loading: Loading,
});

export const AcceptUserInvite = Loadable({
    loader: () => import('../Common/AcceptUserInvite'),
    loading: Loading,
});

export const RejectUserInvite = Loadable({
    loader: () => import('../Common/RejectUserInvite'),
    loading: Loading,
});

export const AcceptClassInvite = Loadable({
    loader: () => import('../Pages/Class/AcceptClassInvite'),
    loading: Loading,
});

export const RejectClassInvite = Loadable({
    loader: () => import('../Pages/Class/RejectClassInvite'),
    loading: Loading,
});

export const Profile = Loadable({
    loader: () => import('../Pages/Profile/Profile'),
    loading: Loading,
});

export const EditProfile = Loadable({
    loader: () => import('../Pages/Profile/EditProfile'),
    loading: Loading,
});

export const SignUpTeacher = Loadable({
    loader: () => import('../Pages/Teacher/SignUpTeacher'),
    loading: Loading,
});

export const SignUpStudent = Loadable({
    loader: () => import('../Pages/Student/SignUpStudent'),
    loading: Loading,
});

export const AcceptSignUpInvite = Loadable({
    loader: () => import('../Common/AcceptSignUpInvite'),
    loading: Loading,
});

export const RejectSignUpInvite = Loadable({
    loader: () => import('../Common/RejectSignUpInvite'),
    loading: Loading,
});