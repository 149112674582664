import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    isFrontEnd: true,
    isHideMenu: false,

    isSupportRequested: false,
    supportSuccessMsg: '',
    supportErrorMsg: '',

    isContactAdminRequested: false,
    contactAdminSuccessMsg: '',
    contactAdminErrorMsg: '',
});

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.ROUTE_CHANGED:
            return state.merge({
                isFrontEnd: action.isFrontEnd,
                isHideMenu: action.isHideMenu,
            });
        // Support Form
        case types.SUPPORT_REQUEST:
            return state.merge({
                isSupportRequested: true,
                supportSuccessMsg: '',
                supportErrorMsg: '',
            });
        case types.SUPPORT_SUCCESS:
            return state.merge({
                isSupportRequested: false,
                supportSuccessMsg: action.supportSuccessMsg,
                supportErrorMsg: '',
            });
        case types.SUPPORT_FAILURE:
            return state.merge({
                isSupportRequested: false,
                supportSuccessMsg: '',
                supportErrorMsg: action.supportErrorMsg,
            });
        case types.SUPPORT_CLEAR:
            return state.merge({
                isSupportRequested: false,
                supportSuccessMsg: '',
                supportErrorMsg: '',
            });

        // Contact Admin Form
        case types.CONTACT_ADMIN_REQUEST:
            return state.merge({
                isContactAdminRequested: true,
                contactAdminSuccessMsg: '',
                contactAdminErrorMsg: '',
            });
        case types.CONTACT_ADMIN_SUCCESS:
            return state.merge({
                isContactAdminRequested: false,
                contactAdminSuccessMsg: action.contactAdminSuccessMsg,
                contactAdminErrorMsg: '',
            });
        case types.CONTACT_ADMIN_FAILURE:
            return state.merge({
                isContactAdminRequested: false,
                contactAdminSuccessMsg: '',
                contactAdminErrorMsg: action.contactAdminErrorMsg,
            });
        case types.CONTACT_ADMIN_CLEAR:
            return state.merge({
                isContactAdminRequested: false,
                contactAdminSuccessMsg: '',
                contactAdminErrorMsg: '',
            });
        default:
            return state;
    }
}