import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import enums from "../../helpers/enums";
import config from "../../config";
import {checkIfUndefined} from "../../helpers/helper";
const {status} = enums;

const initialState = Immutable({
    isCreatingInstitute: false,
    createInstituteSuccessMsg: '',
    createInstituteErrorMsg: '',

    isEditingInstitute: false,
    editInstituteSuccessMsg: '',
    editInstituteErrorMsg: '',

    isAddingInstitute: false,
    addInstituteSuccessMsg: '',
    addInstituteErrorMsg: '',

    isJoiningInstitute: false,
    joinInstituteSuccessMsg: '',
    joinInstituteErrorMsg: '',

    isInviteRequested: false,
    inviteSuccessMsg: '',
    inviteErrorMsg: '',

    isRequestedInstitutes: false,
    institutes: [],
    totalCountsInstitute: {},
    isSuccessInstitutes: false,
    isFailureInstitutes: false,
    isRefreshInstitute: false,
    instituteSearchText: '',
    instituteFilterStatus: '',
    instituteSortByName: config.SORT_BY_NAME,
    instituteSortByOrder: config.SORT_BY_ORDER,
    instituteLimitNRows: config.LIMIT_N_ROWS,
    instituteActiveTab: '1',
    instituteTabs: [
        {title: "All", tab:"1", filter: "", countKey: "all"},
        {title: "Active", tab: "2", filter: status.ACTIVE, countKey: "active"},
        {title: "Pending", tab: "3", filter: status.PENDING, countKey: "pending"},
        {title: "Blocked / Inactive", tab: "4", filter: status.BLOCKED, countKey: "blocked"},
    ],

    isSingleInstituteRequested: false,
    singleInstitute: {},
    isSuccessSingleInstitute: false,
    isFailureSingleInstitute: false,

    isDeleteInstituteRequested: false,
    deleteInstituteSuccessMsg: '',
    deleteInstituteErrorMsg: '',

    isBlockInstituteRequested: false,
    blockInstituteSuccessMsg: '',
    blockInstituteErrorMsg: '',

    isUnblockInstituteRequested: false,
    unblockInstituteSuccessMsg: '',
    unblockInstituteErrorMsg: '',

    isDeleteInviteRequested: false,
    deleteInviteSuccessMsg: '',
    deleteInviteErrorMsg: '',

    isAcceptInviteRequested: false,
    acceptInviteSuccessMsg: '',
    acceptInviteErrorMsg: '',

    isRejectInviteRequested: false,
    rejectInviteSuccessMsg: '',
    rejectInviteErrorMsg: '',

    isReInviteRequested: false,
    reInviteSuccessMsg: '',
    reInviteErrorMsg: '',

    isSettingInstitute: false,
    settingInstituteSuccessMsg: '',
    settingInstituteErrorMsg: '',

    isSingleClassReportRequested: false,
    singleClassReport: {},
    isSuccessSingleClassReport: false,
    isFailureSingleClassReport: false,
    isRefreshSingleClassReport: false,
    searchTextSessionsClassReport: '',
    sortByNameSessionsClassReport: config.SORT_BY_NAME,
    sortByOrderSessionsClassReport: config.SORT_BY_ORDER,
    limitNRowsSessionsClassReport: config.LIMIT_N_ROWS,

    isSingleSessionReportRequested: false,
    singleSessionReport: {},
    isSuccessSingleSessionReport: false,
    isFailureSingleSessionReport: false,
    isRefreshSingleSessionReport: false,
    searchTextStudentsSessionReport: '',
    sortByNameStudentsSessionReport: config.SORT_BY_NAME,
    sortByOrderStudentsSessionReport: config.SORT_BY_ORDER,
    limitNRowsStudentsSessionReport: config.LIMIT_N_ROWS,

    isSingleStudentReportRequested: false,
    singleStudentReport: {},
    isSuccessSingleStudentReport: false,
    isFailureSingleStudentReport: false,
    isRefreshSingleStudentReport: false,
    searchTextSessionsStudentReport: '',
    sortByNameSessionsStudentReport: config.SORT_BY_NAME,
    sortByOrderSessionsStudentReport: config.SORT_BY_ORDER,
    limitNRowsSessionsStudentReport: config.LIMIT_N_ROWS,
});

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        // register Institute
        case types.CREATE_INSTITUTE_REQUEST:
            return state.merge({
                isCreatingInstitute: true,
                createInstituteSuccessMsg: '',
                createInstituteErrorMsg: '',
            });
        case types.CREATE_INSTITUTE_SUCCESS:
            return state.merge({
                isCreatingInstitute: false,
                createInstituteSuccessMsg: action.createInstituteSuccessMsg,
                createInstituteErrorMsg: '',
            });
        case types.CREATE_INSTITUTE_FAILURE:
            return state.merge({
                isCreatingInstitute: false,
                createInstituteErrorMsg: action.createInstituteErrorMsg,
                createInstituteSuccessMsg: ''
            });
        case types.CREATE_INSTITUTE_CLEAR:
            return state.merge({
                isCreatingInstitute: false,
                createInstituteSuccessMsg: '',
                createInstituteErrorMsg: '',
            });

        // Add Institute
        case types.ADD_INSTITUTE_REQUEST:
            return state.merge({
                isAddingInstitute: true,
                addInstituteSuccessMsg: '',
                addInstituteErrorMsg: '',
            });
        case types.ADD_INSTITUTE_SUCCESS:
            return state.merge({
                isAddingInstitute: false,
                addInstituteSuccessMsg: action.addInstituteSuccessMsg,
                addInstituteErrorMsg: '',
            });
        case types.ADD_INSTITUTE_FAILURE:
            return state.merge({
                isAddingInstitute: false,
                addInstituteSuccessMsg: '',
                addInstituteErrorMsg: action.addInstituteErrorMsg,
            });
        case types.ADD_INSTITUTE_CLEAR:
            return state.merge({
                isAddingInstitute: false,
                addInstituteSuccessMsg: '',
                addInstituteErrorMsg: '',
            });

        // Edit Institute
        case types.EDIT_INSTITUTE_REQUEST:
            return state.merge({
                isEditingInstitute: true,
                editInstituteSuccessMsg: '',
                editInstituteErrorMsg: '',
            });
        case types.EDIT_INSTITUTE_SUCCESS:
            return state.merge({
                isEditingInstitute: false,
                editInstituteSuccessMsg: action.editInstituteSuccessMsg,
                editInstituteErrorMsg: '',
            });
        case types.EDIT_INSTITUTE_FAILURE:
            return state.merge({
                isEditingInstitute: false,
                addInstituteSuccessMsg: '',
                editInstituteErrorMsg: action.editInstituteErrorMsg,
            });
        case types.EDIT_INSTITUTE_CLEAR:
            return state.merge({
                isEditingInstitute: false,
                editInstituteSuccessMsg: '',
                editInstituteErrorMsg: '',
            });

        // Join Institute
        case types.JOIN_INSTITUTE_REQUEST:
            return state.merge({
                isJoiningInstitute: true,
                joinInstituteSuccessMsg: '',
                joinInstituteErrorMsg: '',
            });
        case types.JOIN_INSTITUTE_SUCCESS:
            return state.merge({
                isJoiningInstitute: false,
                joinInstituteSuccessMsg: action.joinInstituteSuccessMsg,
                joinInstituteErrorMsg: '',
            });
        case types.JOIN_INSTITUTE_FAILURE:
            return state.merge({
                isJoiningInstitute: false,
                joinInstituteSuccessMsg: '',
                joinInstituteErrorMsg: action.joinInstituteErrorMsg,
            });
        case types.JOIN_INSTITUTE_CLEAR:
            return state.merge({
                isJoiningInstitute: false,
                joinInstituteSuccessMsg: '',
                joinInstituteErrorMsg: '',
            });

        // Invite Institute
        case types.INVITE_INSTITUTE_REQUEST:
            return state.merge({
                isInviteRequested: true,
                inviteSuccessMsg: '',
                inviteErrorMsg: '',
            });
        case types.INVITE_INSTITUTE_SUCCESS:
            return state.merge({
                isInviteRequested: false,
                inviteSuccessMsg: action.inviteSuccessMsg,
                inviteErrorMsg: '',
            });
        case types.INVITE_INSTITUTE_FAILURE:
            return state.merge({
                isInviteRequested: false,
                inviteErrorMsg: action.inviteErrorMsg,
                inviteSuccessMsg: '',
            });
        case types.INVITE_INSTITUTE_CLEAR:
            return state.merge({
                isInviteRequested: false,
                inviteErrorMsg: '',
                inviteSuccessMsg: '',
            });

        // Get Institutes
        case types.LIST_INSTITUTES_REQUEST:
            return state.merge({
                isRequestedInstitutes: true,
                isSuccessInstitutes: false,
                isFailureInstitutes: false,
                isRefreshInstitute: false,
            });

        case types.LIST_INSTITUTES_SUCCESS:
            return state.merge({
                isRequestedInstitutes: false,
                institutes: action.institutes,
                totalCountsInstitute: action.totalCountsInstitute,
                isSuccessInstitutes: true,
                isFailureInstitutes: false
            });

        case types.LIST_INSTITUTES_FAILURE:
            return state.merge({
                isRequestedInstitutes: false,
                isSuccessInstitutes: false,
                isFailureInstitutes: true
            });

        case types.UPDATE_INSTITUTE_LISTING_PARAMS:
            return state.merge({
                instituteSearchText: checkIfUndefined(action.instituteSearchText) ? state.instituteSearchText : action.instituteSearchText,
                instituteActiveTab: checkIfUndefined(action.instituteActiveTab) ? state.instituteActiveTab : action.instituteActiveTab,
                instituteFilterStatus: checkIfUndefined(action.instituteFilterStatus) ? state.instituteFilterStatus : action.instituteFilterStatus,
                instituteSortByName: checkIfUndefined(action.instituteSortByName) ? state.instituteSortByName : action.instituteSortByName,
                instituteSortByOrder: checkIfUndefined(action.instituteSortByOrder) ? state.instituteSortByOrder : action.instituteSortByOrder,
                instituteLimitNRows: checkIfUndefined(action.instituteLimitNRows) ? state.instituteLimitNRows : action.instituteLimitNRows,
            });

        // Get Single Institute
        case types.SINGLE_INSTITUTE_REQUEST:
            return state.merge({
                isSingleInstituteRequested: true,
                singleInstitute: {},
                isSuccessSingleInstitute: false,
                isFailureSingleInstitute: false,
            });

        case types.SINGLE_INSTITUTE_SUCCESS:
            return state.merge({
                isSingleInstituteRequested: false,
                singleInstitute: action.singleInstitute,
                isSuccessSingleInstitute: true,
                isFailureSingleInstitute: false
            });

        case types.SINGLE_INSTITUTE_FAILURE:
            return state.merge({
                isSingleInstituteRequested: false,
                isSuccessSingleInstitute: false,
                isFailureSingleInstitute: true
            });

        // delete Institute
        case types.DELETE_INSTITUTE_REQUEST:
            return state.merge({
                isDeleteInstituteRequested: true,
                deleteInstituteSuccessMsg: '',
                deleteInstituteErrorMsg: '',
            });
        case types.DELETE_INSTITUTE_SUCCESS:
            return state.merge({
                isDeleteInstituteRequested: false,
                deleteInstituteSuccessMsg: action.deleteInstituteSuccessMsg,
                deleteInstituteErrorMsg: '',
                isRefreshInstitute: true,
            });
        case types.DELETE_INSTITUTE_FAILURE:
            return state.merge({
                isDeleteInstituteRequested: false,
                deleteInstituteSuccessMsg: '',
                deleteInstituteErrorMsg: action.deleteInstituteErrorMsg,
            });

        // block Institute
        case types.BLOCK_INSTITUTE_REQUEST:
            return state.merge({
                isBlockInstituteRequested: true,
                blockInstituteSuccessMsg: '',
                blockInstituteErrorMsg: '',
            });
        case types.BLOCK_INSTITUTE_SUCCESS:
            return state.merge({
                isBlockInstituteRequested: false,
                blockInstituteSuccessMsg: action.blockInstituteSuccessMsg,
                blockInstituteErrorMsg: '',
                isRefreshInstitute: true,
            });
        case types.BLOCK_INSTITUTE_FAILURE:
            return state.merge({
                isBlockInstituteRequested: false,
                blockInstituteSuccessMsg: '',
                blockInstituteErrorMsg: action.blockInstituteErrorMsg,
            });

        // unblock Institute
        case types.UNBLOCK_INSTITUTE_REQUEST:
            return state.merge({
                isUnblockInstituteRequested: true,
                unblockInstituteSuccessMsg: '',
                unblockInstituteErrorMsg: '',
            });
        case types.UNBLOCK_INSTITUTE_SUCCESS:
            return state.merge({
                isUnblockInstituteRequested: false,
                unblockInstituteSuccessMsg: action.unblockInstituteSuccessMsg,
                unblockInstituteErrorMsg: '',
                isRefreshInstitute: true,
            });
        case types.UNBLOCK_INSTITUTE_FAILURE:
            return state.merge({
                isUnblockInstituteRequested: false,
                unblockInstituteSuccessMsg: '',
                unblockInstituteErrorMsg: action.unblockInstituteErrorMsg,
            });

        // accept invite
        case types.ACCEPT_INVITE_REQUEST:
            return state.merge({
                isAcceptInviteRequested: true,
                acceptInviteSuccessMsg: '',
                acceptInviteErrorMsg: '',
            });
        case types.ACCEPT_INVITE_SUCCESS:
            return state.merge({
                isAcceptInviteRequested: false,
                acceptInviteSuccessMsg: action.acceptInviteSuccessMsg,
                acceptInviteErrorMsg: '',
                isRefreshInstitute: true,
            });
        case types.ACCEPT_INVITE_FAILURE:
            return state.merge({
                isAcceptInviteRequested: false,
                acceptInviteSuccessMsg: '',
                acceptInviteErrorMsg: action.acceptInviteErrorMsg,
            });

        // reject invite
        case types.REJECT_INVITE_REQUEST:
            return state.merge({
                isRejectInviteRequested: true,
                rejectInviteSuccessMsg: '',
                rejectInviteErrorMsg: '',
            });
        case types.REJECT_INVITE_SUCCESS:
            return state.merge({
                isRejectInviteRequested: false,
                rejectInviteSuccessMsg: action.rejectInviteSuccessMsg,
                rejectInviteErrorMsg: '',
                isRefreshInstitute: true,
            });
        case types.REJECT_INVITE_FAILURE:
            return state.merge({
                isRejectInviteRequested: false,
                rejectInviteSuccessMsg: '',
                rejectInviteErrorMsg: action.rejectInviteErrorMsg,
            });

        // delete invite
        case types.DELETE_INVITE_REQUEST:
            return state.merge({
                isDeleteInviteRequested: true,
                deleteInviteSuccessMsg: '',
                deleteInviteErrorMsg: '',
            });
        case types.DELETE_INVITE_SUCCESS:
            return state.merge({
                isDeleteInviteRequested: false,
                deleteInviteSuccessMsg: action.deleteInviteSuccessMsg,
                deleteInviteErrorMsg: '',
                isRefreshInstitute: true,
            });
        case types.DELETE_INVITE_FAILURE:
            return state.merge({
                isDeleteInviteRequested: false,
                deleteInviteSuccessMsg: '',
                deleteInviteErrorMsg: action.deleteInviteErrorMsg,
            });

        // resend invite
        case types.RESEND_INVITE_REQUEST:
            return state.merge({
                isReInviteRequested: true,
                reInviteSuccessMsg: '',
                reInviteErrorMsg: '',
            });
        case types.RESEND_INVITE_SUCCESS:
            return state.merge({
                isReInviteRequested: false,
                reInviteSuccessMsg: action.reInviteSuccessMsg,
                reInviteErrorMsg: '',
                isRefreshInstitute: true,
            });
        case types.RESEND_INVITE_FAILURE:
            return state.merge({
                isReInviteRequested: false,
                reInviteSuccessMsg: '',
                reInviteErrorMsg: action.reInviteErrorMsg,
            });

        // setting Institute
        case types.SETTING_INSTITUTE_REQUEST:
            return state.merge({
                isSettingInstitute: true,
                settingInstituteSuccessMsg: '',
                settingInstituteErrorMsg: '',
            });
        case types.SETTING_INSTITUTE_SUCCESS:
            return state.merge({
                isSettingInstitute: false,
                settingInstituteSuccessMsg: action.settingInstituteSuccessMsg,
                settingInstituteErrorMsg: '',
            });
        case types.SETTING_INSTITUTE_FAILURE:
            return state.merge({
                isSettingInstitute: false,
                settingInstituteErrorMsg: action.settingInstituteErrorMsg,
                settingInstituteSuccessMsg: ''
            });
        case types.SETTING_INSTITUTE_CLEAR:
            return state.merge({
                isSettingInstitute: false,
                settingInstituteSuccessMsg: '',
                settingInstituteErrorMsg: '',
            });

        // Get Single Class Report
        case types.SINGLE_CLASS_REPORT_REQUEST:
            return state.merge({
                isSingleClassReportRequested: true,
                isSuccessSingleClassReport: false,
                isFailureSingleClassReport: false,
            });

        case types.SINGLE_CLASS_REPORT_SUCCESS:
            return state.merge({
                isSingleClassReportRequested: false,
                singleClassReport: action.singleClassReport,
                isSuccessSingleClassReport: true,
                isFailureSingleClassReport: false
            });

        case types.SINGLE_CLASS_REPORT_FAILURE:
            return state.merge({
                isSingleClassReportRequested: false,
                isSuccessSingleClassReport: false,
                isFailureSingleClassReport: true
            });

        case types.SINGLE_CLASS_REPORT_CLEAR:
            return state.merge({
                isSingleClassReportRequested: false,
                singleClassReport: {},
                isSuccessSingleClassReport: false,
                isFailureSingleClassReport: false,
                isRefreshSingleClassReport: false,
                searchTextSessionsClassReport: '',
                sortByNameSessionsClassReport: config.SORT_BY_NAME,
                sortByOrderSessionsClassReport: config.SORT_BY_ORDER,
                limitNRowsSessionsClassReport: config.LIMIT_N_ROWS,
            });

        case types.UPDATE_SINGLE_CLASS_REPORT_LISTING_PARAMS:
            return state.merge({
                searchTextSessionsClassReport: checkIfUndefined(action.searchTextSessionsClassReport) ? state.searchTextSessionsClassReport : action.searchTextSessionsClassReport,
                sortByNameSessionsClassReport: checkIfUndefined(action.sortByNameSessionsClassReport) ? state.sortByNameSessionsClassReport : action.sortByNameSessionsClassReport,
                sortByOrderSessionsClassReport: checkIfUndefined(action.sortByOrderSessionsClassReport) ? state.sortByOrderSessionsClassReport : action.sortByOrderSessionsClassReport,
                limitNRowsSessionsClassReport: checkIfUndefined(action.limitNRowsSessionsClassReport) ? state.limitNRowsSessionsClassReport : action.limitNRowsSessionsClassReport,
                });

        // Get Single Session Report
        case types.SINGLE_SESSION_REPORT_REQUEST:
            return state.merge({
                isSingleSessionReportRequested: true,
                isSuccessSingleSessionReport: false,
                isFailureSingleSessionReport: false,
            });

        case types.SINGLE_SESSION_REPORT_SUCCESS:
            return state.merge({
                isSingleSessionReportRequested: false,
                singleSessionReport: action.singleSessionReport,
                isSuccessSingleSessionReport: true,
                isFailureSingleSessionReport: false
            });

        case types.SINGLE_SESSION_REPORT_FAILURE:
            return state.merge({
                isSingleSessionReportRequested: false,
                isSuccessSingleSessionReport: false,
                isFailureSingleSessionReport: true
            });

        case types.SINGLE_SESSION_REPORT_CLEAR:
            return state.merge({
                isSingleSessionReportRequested: false,
                singleSessionReport: {},
                isSuccessSingleSessionReport: false,
                isFailureSingleSessionReport: false,
                isRefreshSingleSessionReport: false,
                searchTextStudentsSessionReport: '',
                sortByNameStudentsSessionReport: config.SORT_BY_NAME,
                sortByOrderStudentsSessionReport: config.SORT_BY_ORDER,
                limitNRowsStudentsSessionReport: config.LIMIT_N_ROWS,
            });

        case types.UPDATE_SINGLE_SESSION_REPORT_LISTING_PARAMS:
            return state.merge({
                searchTextStudentsSessionReport: checkIfUndefined(action.searchTextStudentsSessionReport) ? state.searchTextStudentsSessionReport : action.searchTextStudentsSessionReport,
                sortByNameStudentsSessionReport: checkIfUndefined(action.sortByNameStudentsSessionReport) ? state.sortByNameStudentsSessionReport : action.sortByNameStudentsSessionReport,
                sortByOrderStudentsSessionReport: checkIfUndefined(action.sortByOrderStudentsSessionReport) ? state.sortByOrderStudentsSessionReport : action.sortByOrderStudentsSessionReport,
                limitNRowsStudentsSessionReport: checkIfUndefined(action.limitNRowsStudentsSessionReport) ? state.limitNRowsStudentsSessionReport : action.limitNRowsStudentsSessionReport,
                });

        // Get Single Student Report
        case types.SINGLE_STUDENT_CLASS_REPORT_REQUEST:
            return state.merge({
                isSingleStudentReportRequested: true,
                isSuccessSingleStudentReport: false,
                isFailureSingleStudentReport: false,
            });

        case types.SINGLE_STUDENT_CLASS_REPORT_SUCCESS:
            return state.merge({
                isSingleStudentReportRequested: false,
                singleStudentReport: action.singleStudentReport,
                isSuccessSingleStudentReport: true,
                isFailureSingleStudentReport: false
            });

        case types.SINGLE_STUDENT_CLASS_REPORT_FAILURE:
            return state.merge({
                isSingleStudentReportRequested: false,
                isSuccessSingleStudentReport: false,
                isFailureSingleStudentReport: true
            });

        case types.SINGLE_STUDENT_CLASS_REPORT_CLEAR:
            return state.merge({
                isSingleStudentReportRequested: false,
                singleStudentReport: {},
                isSuccessSingleStudentReport: false,
                isFailureSingleStudentReport: false,
                isRefreshSingleStudentReport: false,
                searchTextSessionsStudentReport: '',
                sortByNameSessionsStudentReport: config.SORT_BY_NAME,
                sortByOrderSessionsStudentReport: config.SORT_BY_ORDER,
                limitNRowsSessionsStudentReport: config.LIMIT_N_ROWS,
            });

        case types.UPDATE_SINGLE_STUDENT_CLASS_REPORT_LISTING_PARAMS:
            return state.merge({
                searchTextSessionsStudentReport: checkIfUndefined(action.searchTextSessionsStudentReport) ? state.searchTextSessionsStudentReport : action.searchTextSessionsStudentReport,
                sortByNameSessionsStudentReport: checkIfUndefined(action.sortByNameSessionsStudentReport) ? state.sortByNameSessionsStudentReport : action.sortByNameSessionsStudentReport,
                sortByOrderSessionsStudentReport: checkIfUndefined(action.sortByOrderSessionsStudentReport) ? state.sortByOrderSessionsStudentReport : action.sortByOrderSessionsStudentReport,
                limitNRowsSessionsStudentReport: checkIfUndefined(action.limitNRowsSessionsStudentReport) ? state.limitNRowsSessionsStudentReport : action.limitNRowsSessionsStudentReport,
                });
        default:
            return state;
    }
}
