import React from 'react';
import {Button} from 'reactstrap';
import history from "../../helpers/history";
import language from "../../helpers/language";
import confirm from "../../thirdparty/reactstrap-confirm";
import {getConfirmAlertMessage} from "../../helpers/helper";

export function LoaderButton(props) {
    const {btnType, isRequest, isFormValid, isValidationRun, btnLabel, block, isFormChanged, btnColor} = props;
    let btnTypeClass = btnType ? btnType : 'btn-account';
    let classNameBtn = btnTypeClass + ' mr-sm-3 mb-2 btn-block-sm';
    classNameBtn += isRequest ? ' btn-with-spinner' : '';
    let forceDisable = false;
    if (isFormChanged !== undefined && isFormChanged === false) {
        forceDisable = true;
    }
    return (
        <React.Fragment>
            <Button type="submit" color={btnColor ? btnColor : ""} className={classNameBtn} block={block}
                    disabled={isRequest || (!isFormValid && isValidationRun) || forceDisable}>
                {
                    isRequest
                    &&
                    (
                        <i className="fa fa-circle-o-notch fa-spin fa-fw btn-spinner"/>
                    )
                }
                &nbsp;{btnLabel}
            </Button>
        </React.Fragment>
    )
}

export function CancelButton(props) {
    const {btnLabel, btnType, isFormChanged} = props;
    let btnTypeClass = btnType ? btnType : 'btn-grey-gradient';
    let classNameBtn = btnTypeClass + ' mb-2 btn-block-sm';
    let onClick;
    if (isFormChanged !== undefined) {
        if (props.onClick) {
            onClick = confirmCancel(isFormChanged, props.onClick);
        } else {
            onClick = confirmCancel(isFormChanged);
        }
    } else {
        onClick = props.onClick ? props.onClick : goBack();
    }
    return (
        <React.Fragment>
            <Button onClick={onClick} color="" className={classNameBtn}>{btnLabel}</Button>
        </React.Fragment>
    )
}

export function goBack() {
    history.goBack();
}

export function confirmCancel(isFormChanged, func = undefined) {
    return async () => {
        if (isFormChanged) {
            let modal = await confirm(
                getConfirmAlertMessage('Confirm', language.CONFIRM_CHANGES_REMOVED)
            );
            if (modal) {
                if (func !== undefined) {
                    func();
                } else {
                    goBack();
                }
            }
        } else {
            if (func !== undefined) {
                func();
            } else {
                goBack();
            }
        }
    }
}