import React, {Component} from 'react';
import {
    Row, Col, ModalBody, Modal, Button
} from 'reactstrap';
import {connect} from "react-redux";
import OtherAccounts from "../Common/OtherAccounts";
import {logout} from "../../store/auth/actions";
import language from "../../helpers/language";
import {getConfirmAlertMessage} from "../../helpers/helper";
import confirm from "../../thirdparty/reactstrap-confirm";
import ContactAdmin from "./ContactAdmin";
import FormModal from "../Common/FormModal";

class FullScreenInstituteBlocked extends Component {
    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
    }

    async logout() {
        let result = await confirm(
            getConfirmAlertMessage('Confirm', language.CONFIRM_LOGOUT)
        );
        if (result) {
            this.props.logout();
        }
    }

    isOtherAccounts() {
        const {otherAccounts} = this.props;
        return !!(otherAccounts && otherAccounts.length > 0);
    }

    render() {
        const {otherAccounts} = this.props;
        let classes = "modal-large-blocked";
        if (this.isOtherAccounts()) {
            classes = "modal-large-blocked-switch-account";
        }
        return (
            <Modal isOpen={true} toggle={false} className={classes}>
                <ModalBody>
                    <div className="text-center mt-4 font-family-lato">
                        <h1 className="mb-4">Account Blocked</h1>
                        <i className="fa fa-exclamation warning-icon-modal"/>
                        <div className="mt-4 font-family-lato px-4">
                            <p className="mb-0">{this.props.instituteBlockedErrorMsg}</p>
                        </div>
                        <div className="my-4">
                            <FormModal color="account" classes="mr-sm-2 mb-2 btn-block-sm"
                                       modalContent={<ContactAdmin/>}>
                                Contact
                            </FormModal>
                            <Button color="grey-gradient" className="mb-2 btn-block-sm" onClick={this.logout}>Sign
                                out</Button>
                        </div>
                        {
                            this.isOtherAccounts()
                            &&
                            (
                                <React.Fragment>
                                    <Row>
                                        <Col lg={{size: 10, offset: 1}}>
                                            <h5 className="text-left font-weight-light mt-3">
                                                You can switch your account.
                                            </h5>
                                            {
                                                (otherAccounts && otherAccounts.length > 0)
                                                &&
                                                (
                                                    <ul className="list-group switch-account-list-group nice-scroll">
                                                        <OtherAccounts/>
                                                    </ul>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )
                        }
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    logout
};

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        otherAccounts: state.auth.otherAccounts,
        isInstituteBlocked: state.auth.isInstituteBlocked,
        instituteBlockedErrorMsg: state.auth.instituteBlockedErrorMsg,
    };
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(FullScreenInstituteBlocked);