import React, {Component} from 'react';
import {getActiveSessions, markAttendanceStudent} from "../../../store/user/actions";
import {connect} from "react-redux";
import {
    Button,
    Col,
    Row
} from "reactstrap";
import FormModal from "../FormModal";
import MarkAttendanceModal from "../../Modals/MarkAttendanceModal";
import config from "../../../config";

class MarkAttendance extends Component {
    constructor(props) {
        super(props);
        this.intervalRefresh = null;
        this.state = {
            dropdownOpen: false
        };

        this.toggle = this.toggle.bind(this);
        this.markAttendance = this.markAttendance.bind(this);
    }

    componentDidMount() {
        // Initial Load for the Active Sessions
        this.props.getActiveSessions();

        // Interval Loading for the Active Sessions
        this.intervalRefresh = setInterval(() => {
            this.props.getActiveSessions();
        }, config.POLLING_INTERVAL_ATTENDANCE);
    }

    componentWillUnmount() {
        clearInterval(this.intervalRefresh);
    }

    markAttendance(sessionId) {
        this.props.markAttendanceStudent(sessionId);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        const {activeSessions} = this.props;
        const {dropdownOpen} = this.state;
        if (activeSessions !== undefined && activeSessions.length > 0) {
            return (
                <div className="sa-header-mark-attendance sa-header-user">
                    <div>
                        <Button color="account" onClick={this.toggle} className="btn-md" block>
                            {
                                !dropdownOpen
                                &&
                                (
                                    <React.Fragment>
                                        Mark Attendance<i className="fa fa-check-square-o ml-2"/>
                                    </React.Fragment>
                                )
                            }
                            {
                                dropdownOpen
                                &&
                                (
                                    <React.Fragment>
                                        Close<i className="fa fa-remove ml-2"/>
                                    </React.Fragment>
                                )
                            }
                        </Button>
                        {
                            dropdownOpen
                            &&
                            (
                                <ul className="list-active-classes">
                                    {
                                        activeSessions.map((item, index) => {
                                            let remainingTime = item.remainingSeconds > 0 ? parseInt(item.remainingSeconds) : 0;
                                            if (remainingTime > 120) {
                                                remainingTime = `${parseInt(remainingTime / 60)} mins remaining`;
                                            } else if (remainingTime === 60) {
                                                remainingTime = `${remainingTime} min remaining`;
                                            } else if (remainingTime < 60) {
                                                remainingTime = `${remainingTime} secs remaining`;
                                            } else if (remainingTime <= 0) {
                                                remainingTime = `---`;
                                            }
                                            return (
                                                <React.Fragment key={index}>
                                                    <li>
                                                        <Row>
                                                            <Col xs="6" className="text-center">
                                                                <span
                                                                    className="text-capitalize text-dark-blue overflow-full-width"
                                                                    title={item.class ? item.class.name : ''}>
                                                                    {item.class ? item.class.name : '---'}
                                                                    </span>
                                                                <span className="font-0-8rem text-lowercase text-dark">
                                                            {remainingTime}
                                                        </span>
                                                            </Col>
                                                            <Col xs="6">
                                                                {
                                                                    (item.remainingSeconds > 0 && item.canMark === true)
                                                                    &&
                                                                    (
                                                                        <FormModal className="modal-large"
                                                                                   classes="btn-sm font-family-oswald w-100 mt-1"
                                                                                   color="blue"
                                                                                   modalContent={<MarkAttendanceModal
                                                                                       ref={(comp) => {
                                                                                           window.markAttendanceComponent = comp
                                                                                       }} data={item}/>}>
                                                                            Mark Now
                                                                        </FormModal>
                                                                    )
                                                                }
                                                                {
                                                                    item.canMark !== true
                                                                    &&
                                                                    (
                                                                        <Button color="blue"
                                                                                className="btn-sm font-family-oswald"
                                                                                block disabled>
                                                                            Mark Now
                                                                        </Button>
                                                                    )
                                                                }
                                                                {
                                                                    item.remainingSeconds <= 0
                                                                    &&
                                                                    (
                                                                        <Button color="blue"
                                                                                className="btn-sm font-family-oswald"
                                                                                block disabled>
                                                                            Expired
                                                                        </Button>
                                                                    )
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </li>
                                                </React.Fragment>

                                            )
                                        })

                                    }
                                </ul>
                            )
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <React.Fragment>

                </React.Fragment>
            );
        }
    }

}

function mapStateToProps(state) {
    return {
        isActiveSessionsRequested: state.user.isActiveSessionsRequested,
        activeSessions: state.user.activeSessions,
        isSuccessActiveSessions: state.user.isSuccessActiveSessions,
        isFailureActiveSessions: state.user.isFailureActiveSessions,

        isMarkAttendanceRequested: state.user.isMarkAttendanceRequested,
        markAttendanceSuccessMsg: state.user.markAttendanceSuccessMsg,
        markAttendanceErrorMsg: state.user.markAttendanceErrorMsg,
    };
}

const mapDispatchToProps = {
    getActiveSessions,
    markAttendanceStudent
};

export default connect(mapStateToProps, mapDispatchToProps)(MarkAttendance);
