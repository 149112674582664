import language from "./language";
import validator from "validator";
import enums from "./enums";

export function getCountryCodeValidationMessage(value) {
    let msg = '';
    let regExp = /^\+[0-9].*$/;
    if (!validator.isNumeric(value) && !(value.indexOf('+') > -1)) {
        msg = language.MSG_ALLOWED_NUMBERS;
    } else if (!validator.isLength(value, {min: 2, max: 4})) {
        msg = language.MSG_MIN_2_4_CHARACTER_ALLOWED;
    } else if (!regExp.test(value)) {
        msg = language.MSG_PLUS_CHARACTER_MANDATORY;
    } else {
        msg = '';
    }

    return msg;
}

export function getAreaCodeValidationMessage(value) {
    let msg = '';

    if (!validator.isNumeric(value)) {
        msg = language.MSG_ALLOWED_NUMBERS;
    } else if (!validator.isLength(value, {min: 3, max: 3})) {
        msg = language.MIN_3_CHARACTER_ALLOWED;
    } else {
        msg = '';
    }

    return msg;
}

export function getPhoneNumberValidationMessage(value) {
    let msg = '';

    if (!validator.isNumeric(value)) {
        msg = language.MSG_ALLOWED_NUMBERS;
    } else if (!validator.isLength(value, {min: 5, max: 12})) {
        msg = language.MSG_MIN_5_12_CHARACTER_ALLOWED;
    } else {
        msg = '';
    }

    return msg;
}

export function getQuotaValidationMessage(value, paymentCategoryId) {
    let msg = '';

    if (value <= 0) {
        if (paymentCategoryId === enums.paymentCategories.PERSTUDENT.toString())
            msg = 'Max Students cannot be 0.';
        else if (paymentCategoryId === enums.paymentCategories.PERTEACHER.toString())
            msg = 'Max Teachers cannot be 0.';
        else if (paymentCategoryId === enums.paymentCategories.PERCLASS.toString())
            msg = 'Max Classes cannot be 0.';
    } else {
        msg = '';
    }

    return msg;
}

export function getEmailValidationMessage(value) {
    let msg = '';

    if (!validator.isEmail(value)) {
        msg = language.MSG_VALID_EMAIL;
    } else {
        msg = '';
    }

    return msg;
}

export function getUsernameOrEmailValidationMessage(value) {
    let msg = '';
    if (value.length <= 3) {
        return language.MSG_VALID_LENGTH_EMAIL_USERNAME;
    }
    if (validator.isNumeric(value)){
        return language.MSG_INVALID_USERNAME;
    }
    if (!validator.isEmail(value)) {
        let invalidUsernameRegex = /[\[\]\^\$\|\?\*\(\)\\~`\!@#%&\={}'""<>:;, ]{1,}/g;
        if (invalidUsernameRegex.test(value)) {
            msg = language.MSG_VALID_USERNAME_OR_EMAIL;
        }
    } else {
        msg = '';
    }

    return msg;
}

export function getUsernameValidationMessage(value) {
    let msg = '';
    let invalidUsernameRegex = /[\[\]\^\$\|\?\*\(\)\\~`\!@#%&\={}'""<>:;, ]{1,}/g;
    if (invalidUsernameRegex.test(value)) {
        msg = language.MSG_VALID_USERNAME;
    }
    return msg;
}

export function getPasswordValidationMessage(value) {
    let msg = '';
    if (value.length < 6) {
        msg = language.MSG_VALID_PASSWORD;
    } else {
        msg = '';
    }

    return msg;
}
