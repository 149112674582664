const language = {
    /* Confirm Message */
    CONFIRM_LOGOUT: 'You want to sign out.',
    CONFIRM_SCREEN_ALREADY_PUBLISHED: 'Your screen will be unpublished from another class. Do you want to continue?',
    CONFIRM_INSTITUTE_DELETE: 'You want to delete this institute? All classes, sessions, students and teachers will be removed. This action cannot be undone.',
    CONFIRM_INSTITUTE_BLOCK: 'You want to block this institute.',
    CONFIRM_INSTITUTE_DELETE_INVITE: 'You want to delete the institute invite.',
    CONFIRM_INSTITUTE_UPDATE_SETTINGS: 'You want to update Institute Settings.',
    CONFIRM_TEACHER_DELETE: 'You want to delete this teacher.',
    CONFIRM_TEACHER_BLOCK: 'You want to block this teacher.',
    CONFIRM_TEACHER_DELETE_INVITE: 'You want to delete the teacher invite.',
    CONFIRM_TEACHER_RESET_PASSWORD_SELECTED: 'You want to reset password for selected teacher.',
    CONFIRM_TEACHER_ASSIGN_UNASSIGN_SELECTED: 'You want to assign/unassign selected teacher.',
    CONFIRM_STUDENT_DELETE: 'You want to delete this student.',
    CONFIRM_STUDENT_BLOCK: 'You want to block this student.',
    CONFIRM_STUDENT_DELETE_INVITE: 'You want to delete the student invite.',
    CONFIRM_STUDENT_RESET_PASSWORD_SELECTED: 'You want to reset password for selected student.',
    CONFIRM_STUDENT_ASSIGN_UNASSIGN_SELECTED: 'You want to assign/unassign selected students.',
    CONFIRM_CLASS_DELETE: 'You want to delete this class.',
    CONFIRM_CLASS_COMPLETE: 'You want to complete this class.',
    CONFIRM_CLASS_COMPLETE_ACTIVE: 'You want to complete this class. This class has an active session.',
    CONFIRM_CLASS_DROP: 'You want to drop the class.',
    CONFIRM_CLASS_DELETE_DROP_INVITE: 'You want to delete the class drop invite.',
    CONFIRM_CLASS_DELETE_INVITE: 'You want to delete the class invite.',
    CONFIRM_CLASS_ACCEPT_INVITE: 'You want to accept the class invite.',
    CONFIRM_CLASS_REJECT_INVITE: 'You want to reject the class invite.',
    CONFIRM_CLASS_ACCEPT_ALL_DROP_REQUESTS: 'You want to accept all students drop requests for this class.',
    CONFIRM_CLASS_REJECT_ALL_DROP_REQUESTS: 'You want to reject all students drop requests for this class.',
    CONFIRM_CLASS_ASSIGN_UNASSIGN_SELECTED: 'You want to assign/unassign selected classes.',
    CONFIRM_CLASS_UNASSIGN: 'You want to unassign this class.',
    CONFIRM_CLASS_SESSION_DELETE: 'You want to Delete Session for this class.',
    CONFIRM_CLASS_REGENERATE_QR_AND_PASS_CODE: 'You want to Re-generate QR Code and Pass Code for this class.',
    CONFIRM_CLASS_SESSION_STOP: 'You want to Stop Session for this class.',
    CONFIRM_CLASS_UNASSIGN_TEACHER: 'You want to unassign this teacher from this class.',
    CONFIRM_CLASS_UNASSIGN_STUDENT: 'You want to unassign this student from this class.',
    CONFIRM_CLASS_ACCEPT_INVITE_FROM_STUDENT: 'You want to accept this student invite to join this class.',
    CONFIRM_CLASS_REJECT_INVITE_FROM_STUDENT: 'You want to reject this student invite to join this class.',
    CONFIRM_CLASS_DELETE_INVITE_FROM_STUDENT: 'You want to delete the class invite for this student.',
    CONFIRM_CLASS_ACCEPT_DROP_REQUEST_FROM_STUDENT: 'You want to accept this student drop request for this class.',
    CONFIRM_CLASS_REJECT_DROP_REQUEST_FROM_STUDENT: 'You want to reject this student drop request for this class.',
    CONFIRM_CLASS_DELETE_INVITE_FROM_TEACHER: 'You want to delete the class invite for this teacher.',
    CONFIRM_CLASS_DELETE_CORRECTION_REQUEST_FROM_STUDENT: 'You want to delete the correction request.',
    CONFIRM_CHANGES_REMOVED: 'Your changes will be lost, If you leave before saving.',
    CONFIRM_RESET_PASSWORD: 'You want to reset password.',

    /* Main Titles */
    TITLE_SELF_ATTENDANCE: 'OneScreen Attend',
    TITLE_404_NOT_FOUND: 'PAGE NOT FOUND',
    TITLE_LOGIN: 'Sign in',
    TITLE_FORGOT_PASSWORD: 'Forgot Password',
    TITLE_SIGNUP_TEACHER: 'Sign Up Teacher',
    TITLE_JOIN_AS_TEACHER: 'Join As A Teacher',
    TITLE_SIGNUP_STUDENT: 'Sign Up Student',
    TITLE_JOIN_AS_STUDENT: 'Join As A Student',
    TITLE_RESET_PASSWORD: 'Reset Password',
    TITLE_CHANGE_PASSWORD: 'Change Password',
    TITLE_SIGNUP_METHOD: 'Sign up',
    TITLE_JOIN_METHOD: 'Join',
    TITLE_SUPPORT: 'Support',
    TITLE_CONTACT_US: 'Contact Us',
    TITLE_CONTACT_ADMIN: 'Contact Admin',
    TITLE_INSTITUTES: 'Institutes',
    TITLE_ADD_INSTITUTE: 'Add Institute',
    TITLE_EDIT_INSTITUTE: 'Edit Institute',
    TITLE_INVITE_INSTITUTE: 'Invite Institute',
    TITLE_JOIN_INSTITUTE: 'Join Institute',
    TITLE_SIGN_UP_INSTITUTE: 'Join Institute',
    TITLE_ACCEPT_INVITATION: 'INVITATION ACCEPTED',
    TITLE_REJECT_INVITATION: 'INVITATION REJECTED',
    TITLE_INSTITUTE_INVITATION: 'Institute Invitation',
    TITLE_INSTITUTE_DETAIL: 'Institute Details',
    TITLE_EDIT_SETTINGS: 'Edit Settings',
    TITLE_EDIT_PROFILE: 'Edit Profile',
    TITLE_CLASSES: 'Classes',
    TITLE_ADD_CLASS: 'Add Class',
    TITLE_IMPORT_CLASSES: 'Import Classes',
    TITLE_EDIT_CLASS: 'Edit Class',
    TITLE_TEACHERS: 'Teachers',
    TITLE_ADD_TEACHER: 'Add Teacher',
    TITLE_IMPORT_TEACHERS: 'Import Teachers',
    TITLE_EDIT_TEACHER: 'Edit Teacher',
    TITLE_TEACHER_PROFILE: 'Teacher Profile',
    TITLE_STUDENTS: 'Students',
    TITLE_ADD_STUDENT: 'Add Student',
    TITLE_EDIT_STUDENT: 'Edit Student',
    TITLE_STUDENT_PROFILE: 'Student Profile',
    TITLE_REPORT_CLASS: 'Class Report',
    TITLE_REPORT_SESSIONS: 'Sessions Report',
    TITLE_REPORT_OVERALL_SUMMARY: 'Overall Summary',
    TITLE_REPORT_STUDENT: 'Student Report',
    TITLE_REPORT_TEACHER: 'Teacher Report',
    TITLE_ASSIGN_CLASSES: 'Assign Classes',
    TITLE_ASSIGN_STUDENTS: 'Assign Students',
    TITLE_ASSIGN_TEACHER: 'Assign Teacher',
    TITLE_ENROLL_CLASSES: 'Enroll Classes',
    TITLE_TEACH_CLASSES: 'Teach Classes',
    TITLE_IMPORT_STUDENTS: 'Import Students',
    TITLE_IMPORT_USERS_CLASSES: 'Import Users To Classes',
    TITLE_IMPORT_TO_CLASSES: 'Import To Classes',
    TITLE_EDIT_ATTENDANCE: 'Edit Attendance',
    TITLE_START_NEW_SESSION: 'Start New Session',
    TITLE_SESSION_CODE: 'Session Code',
    TITLE_SCAN_QR_CODE: 'Scan QR Code',
    TITLE_MARK_ATTENDANCE: 'Mark Attendance',
    TITLE_ASK_FOR_CORRECTION: 'Ask for Correction',
    TITLE_EDIT_CORRECTION: 'Edit Correction',
    TITLE_DOWNLOAD: 'Download',
    TITLE_PRIVACY_POLICY: 'Privacy Policy',
    TITLE_TERMS_AND_CONDITIONS: 'Terms and Conditions',
    TITLE_CSV_HELP: 'CSV Help',

    /* General Messages */
    MSG_SERVER_ERROR: 'Unable to connect with server! Please try again.',
    MSG_CONNECTION_PROBLEM: 'Connection Problem! Please check your internet connection.',
    MSG_LOST_CONNECTION: 'Your device lost its internet connection. Please connect it with the internet.',
    MSG_LOGGED_OUT: 'You have been logged out.',
    MSG_PLEASE_WAIT: 'Please wait...',
    MSG_PAYMENT_DEDUCTION_FROM_STUDENTS: 'Charges will be deducted on per Student for each Enrollment of Class',

    /* Validation Messages */
    MSG_VALID_EMAIL: 'Please enter a valid email address.',
    MSG_VALID_USERNAME_OR_EMAIL: 'Please enter a valid username or email.',
    MSG_VALID_USERNAME: 'Please enter a valid username.',
    MSG_INVALID_USERNAME: 'Username cannot contain only numbers.',
    MSG_VALID_LENGTH_EMAIL_USERNAME: 'Please enter more than 3 characters.',
    MSG_VALID_PASSWORD: 'Passwords must be at least 6 characters long.',
    MSG_FORGOT_PASSWORD_NOT_MATCHED: 'Password reset link has expired',
    MSG_ALLOWED_NUMBERS: 'Please enter numbers only.',
    MSG_MAX_QUOTA: 'Max value cannot be 0.',
    MSG_MIN_2_4_CHARACTER_ALLOWED: 'Please enter 1 to 3 digits with leading +.',
    MSG_MIN_5_12_CHARACTER_ALLOWED: 'Please enter 5 to 12 digits.',
    MSG_PLUS_CHARACTER_MANDATORY: 'Please start with + e.g (+1 234 4545678).',
    MSG_INVITATION_EXPIRED: 'Invitation has expired.',
    MSG_NO_CLASS_SELECTED: 'Please select at-least one class to proceed.',
    MSG_NO_TEACHER_SELECTED: 'Please select at-least one teacher to proceed.',
    MSG_NO_STUDENT_SELECTED: 'Please select at-least one student to proceed.',
    MSG_NO_IMAGE_EXIST: 'Please select a valid image, we are unable to find the image you have selected.',
};
export default language;
