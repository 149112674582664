import React, {Component} from 'react';
import history from '../../helpers/history';

class Anchor extends Component {
    constructor(props) {
        super(props);

        this.toLink = this.toLink.bind(this);
        this.goBack = this.goBack.bind(this);
    }
    toLink(e) {
        e.preventDefault();
        history.push(this.props.href);
    }

    goBack(e) {
        e.preventDefault();
        history.goBack();
    }

    render() {
        const {href, classes, isActualBack, withOnClick, onClick} = this.props;
        return (
            <a href={href} className={classes}
               onClick={withOnClick ? onClick : (isActualBack ? this.goBack : this.toLink)}>
                {this.props.children}
            </a>
        );
    }
}

export default Anchor;
