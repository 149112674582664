import * as types from './actionTypes';
import {do_login, validate_token, do_logout} from '../../services/User';
import history from '../../helpers/history';
import {
    getDashboardLinkByRole, getDeviceTypeEnum,
    getLoginUrl,
    getRoleIdFromUser,
    redirectIfLogin,
    showAlertMessage, showAuthorizationAlert,
    getPersistentLocalStorageKeys
} from '../../helpers/helper';
import language from "../../helpers/language";
import enums from "../../helpers/enums";
import {registerEvent} from "../../helpers/googleAnalytics";

export function login(email, password) {
    return (dispatch) => {
        const alertOption = {
            heading: language.TITLE_LOGIN,
            type: "error"
        };
        dispatch({type: types.LOGIN_REQUEST});

        do_login(email, password, (response) => {
            if (response.code === 0) {

                let role = '';
                if (response.data.userData.roleId === enums.role.INSTITUTE_ADMIN){
                    role = 'Institute Admin';
                } else if (response.data.userData.roleId === enums.role.TEACHER){
                    role = 'Teacher';
                } else if (response.data.userData.roleId === enums.role.STUDENT){
                    role = 'Student';
                }
                // Fire Analytics Event
                registerEvent(
                    'Authentication',
                    'Signin',
                    role,
                    1,
                    window.location.hostname,
                    '',
                    response.data.userData.instituteId,
                    response.data.userData.email,
                    '',
                    '');

                dispatch({type: types.LOGIN_SUCCESS, user: response.data});
                history.replace(getDashboardLinkByRole(getRoleIdFromUser(response.data.userData)));
            } else {
                dispatch({type: types.LOGIN_FAILURE, errorMsg: response.message});

                // Showing Alert to User
                showAlertMessage(alertOption.type, alertOption.heading, response.message);
            }
        });
    };
}

export function logout() {
    return (dispatch) => {
        do_logout((response) => {
            Object.keys(localStorage).forEach((key) => {
                if (getPersistentLocalStorageKeys().indexOf(key) < 0) {
                    console.log('Clearing ' + key);
                    localStorage.removeItem(key);
                }
            });

            dispatch({type: types.LOGOUT_REQUEST, isLoggedIn: false, errorMsg: ''});
            history.replace(getLoginUrl());
        });
    };
}

export function validateToken() {
    return (dispatch) => {
        dispatch({type: types.TOKEN_VALIDATING});
        let currentLocation = history.location.pathname;
        let isLoggedIn = false;
        let user = {};
        let otherAccounts = [];
        let instituteInfo = {};
        let errorMsg = '';
        const token = localStorage.getItem('token');
        if (token) {
            validate_token((response) => {
                if (response.code === 0 || response.code === 18) {
                    let userData = response.data;
                    user = userData.user;
                    otherAccounts = userData.otherAccounts;
                    instituteInfo = userData.instituteInfo;
                    isLoggedIn = true;
                    if (redirectIfLogin.indexOf(currentLocation) >= 0) {
                        history.push(getDashboardLinkByRole(getRoleIdFromUser(user)));
                    }
                } else {
                    isLoggedIn = false;
                    if (response.code === 2 || response.code === 8) {
                        localStorage.removeItem('token');
                    }
                    showAuthorizationAlert(response.message);
                }
                dispatch({
                    type: types.TOKEN_VALIDATE,
                    isLoggedIn: isLoggedIn,
                    errorMsg: errorMsg,
                    user: user,
                    otherAccounts: otherAccounts,
                    instituteInfo: instituteInfo,
                });
            });
        } else {
            isLoggedIn = false;
            dispatch({type: types.TOKEN_VALIDATE, isLoggedIn: isLoggedIn, errorMsg: errorMsg, user: user});
        }
    };
}

export function recurring_validate_token() {
    return (dispatch) => {
        dispatch({type: types.RECURRING_TOKEN_VALIDATING});
        let isInstituteBlocked = false;
        let instituteBlockedErrorMsg = '';
        const token = localStorage.getItem('token');
        if (token) {
            validate_token((response) => {
                if (response.code === 18) {
                    isInstituteBlocked = true;
                    instituteBlockedErrorMsg = response.message;
                } else {
                    isInstituteBlocked = false;
                }
                dispatch({
                    type: types.RECURRING_TOKEN_VALIDATE,
                    isInstituteBlocked: isInstituteBlocked,
                    instituteBlockedErrorMsg: instituteBlockedErrorMsg,
                });
            });
        } else {
            dispatch({
                type: types.RECURRING_TOKEN_VALIDATE,
                isInstituteBlocked: isInstituteBlocked,
                instituteBlockedErrorMsg: instituteBlockedErrorMsg
            });
        }
    };
}
