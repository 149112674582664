import validator from "validator";
import enums from './enums';
import React from 'react';
import confirm from "../thirdparty/reactstrap-confirm";
import moment from "moment";
import "moment-timezone";
import {validate_token} from "../services/User";
import {Alert} from "reactstrap";
import history from "./history";
import language from "./language";
import config from "../config";
import axios from 'axios';
import md5 from 'crypto-js/md5';
import {get_institutes_offline} from "../services/Institute";
import swapControlReceivedImageSrc from "../assets/images/control-received.svg";
import swapViewReceivedImageSrc from "../assets/images/view-received.svg";
import swapNoAccessReceivedImageSrc from "../assets/images/noaccess-received.svg";
import swapControlGivenImageSrc from "../assets/images/control-given.svg";
import swapViewGivenImageSrc from "../assets/images/view-given.svg";
import swapNoAccessGivenImageSrc from "../assets/images/noaccess-given.svg";

export const frontendRoutes = [
    '/',
    '/terms-of-use',
    '/terms-and-conditions',
    '/privacy-policy',
    '/support',
    '/contact-us',
    '/signup-teacher',
    '/signup-student',
];

export const hiddenMenuRoutes = [
    '/support',
];

export const redirectIfLogin = [
    '/login',
    '/register',
    '/join-us',
];

export function checkLinkForActive(links) {
    let location = history.location.pathname;
    let hash = history.location.hash;
    for (let index in links) {
        if (links.hasOwnProperty(index)) {
            switch (links[index]) {
                case 'home':
                    return (location === '/' && hash === '');
                case 'why-us':
                    return (location === '/' && hash === '#why-us');
                case 'how-it-works':
                    return (location === '/' && hash === '#how-it-works');
                case 'whats-new':
                    return (location === '/' && hash === '#whats-new');
                case 'pricing':
                    return (location === '/' && hash === '#pricing');
                case 'help':
                    return (location === '/' && hash === '#help');
                case 'dashboard':
                    return (location === '/dashboard');
                case 'classes':
                    return (location === '/classes' || location.indexOf('class/') > -1 || location.indexOf('session/') > -1);
                case 'teachers':
                    return (location === '/teachers' || location.indexOf('teacher/') > -1);
                case 'students':
                    return (location === '/students' || location.indexOf('student/') > -1);
                case 'reports':
                    return (location === '/reports' || location.indexOf('report/') > -1);
                case 'profile':
                    return (location === '/profile');
                case 'settings':
                    return (location === '/settings');
                default:
                    return false;
            }
        }
    }
}

export function toCapitalizedWords(name) {
    let words = name.match(/[A-Za-z][a-z]*/g) || [];
    let indexId = inArray("Id", words);
    if (indexId !== false) {
        delete words[indexId];
    }

    return words.map(capitalize).join(" ");
}

export function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
}

export function inArray(needle, haystack) {
    let length = haystack.length;
    for (let i = 0; i < length; i++) {
        if (haystack[i] === needle) return i;
    }
    return false;
}

export function humanize(str) {
    let frags = str.split('_');
    for (let i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
    }
    return frags.join(' ');
}

export function showConnectionErrorAlert(response) {
    if (response.code === 408) {
        showServerConnectionAlert(response.message);
    }
}

export function validateApiResponse(response) {
    const customResponse = {
        code: -1, // Means Nothing,
        message: language.MSG_SERVER_ERROR,
        data: ''
    };

    if (response !== undefined && response) {
        if (response.code !== undefined) {
            if (response.code === 0) {
                return response;
            } else {
                if (response.message !== undefined) {
                    if (response.message) {
                        return response;
                    } else {
                        // TODO: Log this response
                        console.log("message is empty in this API!", response);
                        return response;
                    }
                } else {
                    // TODO: Log this response
                    console.log("No message assigned to this API!", response);
                    return response;
                }
            }
        } else {

            // TODO: Create Custom Code and Log it
            console.log("No code assigned to this API!", response);

            if (response.message !== undefined) {
                if (response.message) {
                } else {
                    console.log("No code assigned and message is empty in this API!", response);
                }
            } else {
                console.log("No code assigned and message is empty in this API!", response);
            }
        }
    } else {
        console.log("No response found!", response);
    }

    return customResponse;
}

var apiRecalled304 = 0;

export function fetchApi(apiOptions, callback) {
    let options = {
        url: config.API_ENDPOINT_V1 + apiOptions.apiName,
        method: apiOptions.method,
        headers: apiOptions.headers,
        data: apiOptions.data
    };
    let isDifferentUrl = false;
    if (apiOptions.data.differentServerUrl) {
        isDifferentUrl = true;
        options.url = apiOptions.data.differentServerUrl + apiOptions.apiName;
        delete options.headers['x-access-token'];
        delete options.headers['Time-Zone'];
        delete apiOptions.data.differentServerUrl;
        //options.headers['Content-Length'] = options.data.length;
    }

    if (apiOptions.data.length !== 0) {
        options['body'] = JSON.stringify(apiOptions.data);
    }

    if (apiOptions.signal !== null) {
        options['signal'] = apiOptions.signal;
    }

    if (isInternetConnectionAvailable()) {
        if (isDifferentUrl) {
            let xmlhttp = new XMLHttpRequest();
            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
                    if (xmlhttp.status == 200) {
                        let response = JSON.parse(xmlhttp.responseText);
                        callback(response);
                    } else {
                        callback({code: 408, message: language.MSG_SERVER_ERROR, data: ''})
                    }
                }
            };

            xmlhttp.open(apiOptions.method, options.url, true);
            xmlhttp.send(options['body'] ? options['body'] : null);
        } else {
            axios(options)
                .then(response => {
                    apiRecalled304 = 0;
                    callback(response.data);
                })
                .catch(error => {
                    if (apiRecalled304 < 3 && error.response !== undefined && error.response.status === 304) {
                        apiOptions['headers']['ETag'] = "NEW_" + moment().unix();
                        fetchApi(apiOptions, (response) => {
                            callback(response);
                        });
                        apiRecalled304++;
                    } else {
                        if (axios.isCancel(error)) {
                            console.log('Fetch aborted', error);
                        } else {
                            console.log('request failed', error);

                            /*let message = error ? error.toString() : language.MSG_SERVER_ERROR;
                            if (isNetworkError(message)) {
                                message = language.MSG_SERVER_ERROR;
                            }*/
                            callback({code: 408, message: language.MSG_SERVER_ERROR, data: ''})
                        }
                    }
                });
        }
    } else {
        callback({code: -1, message: language.MSG_CONNECTION_PROBLEM, data: ''});
    }
}

export function isNetworkError(error) {
    return !!(error && error.indexOf('Network Error') > -1);

}

export function requestApiToServerWithAbort(apiName, method, data, callback) {

    // TODO: Cancel Previous Requests in Axios

    let apiOptions = {
        apiName: apiName,
        method: method,
        data: data,
        headers: getHeaders()
    };

    fetchApi(apiOptions, (response) => {
        callback(response);
    });
}

export function requestApiToServer(apiName, method, data, callback) {
    let apiOptions = {
        apiName: apiName,
        method: method,
        data: data,
        headers: getHeaders()
    };

    fetchApi(apiOptions, (response) => {
        callback(response);
    });
}

export function requestFileApiToServer(apiName, method, data, callback) {
    let apiOptions = {
        apiName: apiName,
        method: method,
        data: data,
        headers: getFileHeaders()
    };

    fetchApi(apiOptions, (response) => {
        callback(response);
    });
}

export function requestReportApiToServer(apiName, fileName, data, callback) {
    let options = {
        method: 'GET',
        headers: getHeaders()
    };

    if (detectIE() !== false) {
        options['url'] = config.API_ENDPOINT_V1 + apiName;
        options['responseType'] = 'blob';
    }

    if (isInternetConnectionAvailable()) {
        if (detectIE() === false) {
            fetch(config.API_ENDPOINT_V1 + apiName, options)
                .then(handleResponse => {
                    return getResponseType(handleResponse);
                })
                .then(response => {
                    if (response.code !== undefined) {
                        callback(response);
                    } else {
                        downloadFile(response, data, fileName);
                        callback(true);
                    }
                })
                .catch(error => {
                    console.log('request failed', error);
                    callback({code: 408, message: language.MSG_SERVER_ERROR, data: ''});
                });
        } else {
            axios(options)
                .then(response => {
                    if (response.data.code !== undefined) {
                        callback(response.data);
                    } else {
                        downloadFile(response.data, data, fileName);
                        callback(true);
                    }
                })
                .catch(error => {
                    console.log('request failed', error);
                    callback({code: 408, message: language.MSG_SERVER_ERROR, data: ''});
                });
        }
    } else {
        callback({code: -1, message: language.MSG_CONNECTION_PROBLEM, data: ''});
    }
}

export function handleResponse(response) {
    let data = response.json();
    if (response.status === 403 && response.url.indexOf('user/validateAuthToken') === -1) {
        validate_token((responseValidateToken) => {
            if (responseValidateToken === false) {
                window.location.reload();
            }
        });
    }

    return data;
}

export function getHeaders() {
    let timezone = moment.tz.guess();
    let header = {'Content-Type': 'application/json', 'Time-Zone': timezone};
    if (localStorage.getItem('token')) {
        header['x-access-token'] = localStorage.getItem('token');
    }
    return header;
}

export function getFileHeaders() {
    let timezone = moment.tz.guess();
    let header = {'Accept': 'application/json', 'Time-Zone': timezone};
    if (localStorage.getItem('token')) {
        header['x-access-token'] = localStorage.getItem('token');
    }
    return header;
}

export function checkIsFormValid(validationErrors, callback) {
    let isFormValid = true;

    for (let fieldName in validationErrors) {
        if (validationErrors.hasOwnProperty(fieldName)) {
            if (validationErrors[fieldName] !== '') {
                document.getElementsByName(fieldName)[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
                isFormValid = false;
                break;
            }
        }
    }

    if (isFormValid) {
        callback(true);
    } else {
        callback(false);
    }
}

export function checkIsFieldsHasData(fields, callback) {
    let isEmpty = false;

    for (let fieldName in fields) {
        if (fields.hasOwnProperty(fieldName)) {
            if (validator.isEmpty(fields[fieldName])) {
                isEmpty = true;
                break;
            }
        }
    }

    if (isEmpty) {
        callback(false);
    } else {
        callback(true);
    }
}

export function validateIsEmpty(value) {
    if (typeof value === "string") {
        if (!validator.isEmpty(value)) {
            return false;
        }
    } else if (typeof value === "object") {
        if (value) {
            return false;
        }
    } else if(typeof value === "boolean") {
        return false;
    }

    return true;
}

export function getStatusString(id) {
    let status = enums.status;

    if (status !== undefined) {
        for (let item in status) {
            if (status.hasOwnProperty(item) && status[item] === id) {
                return humanize(item);
            }
        }
    }
}

export function getPaymentCategoryActualString(id) {
    let paymentCategories = enums.paymentCategories;
    let idInt = parseInt(id);

    let paymentCategoriesText = "";
    switch (idInt) {
        case paymentCategories.PERSTUDENT:
            paymentCategoriesText = "Per Student";
            break;
        case paymentCategories.PERTEACHER:
            paymentCategoriesText = "Per Teacher";
            break;
        case paymentCategories.PERCLASS:
            paymentCategoriesText = "Per Class";
            break;
        case paymentCategories.LUMPSUM:
            paymentCategoriesText = "Lump Sum";
            break;
        default:
            paymentCategoriesText = "";
    }

    return paymentCategoriesText;
}

export function daysRemaining(date) {
    if (date) {
        let eventDate = moment(date);
        let todayDate = moment();
        console.log('Days: '+eventDate.diff(todayDate, 'days'));
        return eventDate.diff(todayDate, 'days');
    }
    return ``;
}

export function hoursRemaining(date) {
    if (date) {
        let eventDate = moment(date);
        let todayDate = moment();
        console.log('Hours: '+eventDate.diff(todayDate, 'hours'));
        return eventDate.diff(todayDate, 'hours');
    }
    return ``;
}

export function getPaymentCategoryString(id) {
    let paymentCategories = enums.paymentCategories;
    let idInt = parseInt(id);

    let paymentCategoriesText = "";
    switch (idInt) {
        case paymentCategories.PERSTUDENT:
            paymentCategoriesText = "students";
            break;
        case paymentCategories.PERTEACHER:
            paymentCategoriesText = "teachers";
            break;
        case paymentCategories.PERCLASS:
            paymentCategoriesText = "classes";
            break;
        case paymentCategories.LUMPSUM:
            paymentCategoriesText = "lump sum";
            break;
        default:
            paymentCategoriesText = "";
    }

    return paymentCategoriesText;
}

export function getPaymentTermsString(id) {
    let paymentTerms = enums.paymentTerms;
    let idInt = parseInt(id);

    let paymentTermsText = '';
    switch (idInt) {
        case paymentTerms.MONTHLY:
            paymentTermsText = "month";
            break;
        case paymentTerms.QUARTERLY:
            paymentTermsText = "quarter";
            break;
        case paymentTerms.HALFYEARLY:
            paymentTermsText = "half-year";
            break;
        case paymentTerms.YEARLY:
            paymentTermsText = "year";
            break;
        default:
            paymentTermsText = "month";
    }

    return paymentTermsText;
}

export function getMonthsFromPaymentTerms(id) {
    let paymentTerms = enums.paymentTerms;
    let idInt = parseInt(id);

    let month = 1;
    switch (idInt) {
        case paymentTerms.MONTHLY:
            month = 1;
            break;
        case paymentTerms.QUARTERLY:
            month = 3;
            break;
        case paymentTerms.HALFYEARLY:
            month = 6;
            break;
        case paymentTerms.YEARLY:
            month = 12;
            break;
        default:
            month = 1;
    }

    return month;
}

export function getStatusColor(id) {
    let status = enums.status;
    let color = '';
    switch (id) {
        case status.ACTIVE:
            color = "text-success";
            break;
        case status.PENDING:
            color = "text-warning";
            break;
        case status.INVITE_RECEIVED:
            color = "text-warning";
            break;
        case status.INVITE_SENT:
            color = "text-warning";
            break;
        case status.BLOCKED:
            color = "text-danger";
            break;
        case status.PRESENT:
            color = "text-success";
            break;
        case status.ABSENT:
            color = "text-danger";
            break;
        default:
            color = "text-muted";
    }

    return color;
}

export function getPlanName(plan) {
    if (plan !== null) {
        let {paymentCategoryId} = plan;
        // TODO: Replace "Standard" with Actual Plan name
        if (paymentCategoryId === enums.paymentCategories.LUMPSUM) {
            return "Standard - " + getPaymentCategoryString(paymentCategoryId);
        } else {
            let {paymentCategoryQuota} = plan;
            return "Standard - " + paymentCategoryQuota + " " + getPaymentCategoryString(paymentCategoryId);
        }
    }

    return ""
}

export function getAccessStateImage(accessState, isViewer) {
    // if (accessState === 1) {
    //     return isViewer ? swapControlReceivedImageSrc : swapControlGivenImageSrc;
    // } else if (accessState === 0) {
    //     return isViewer ? swapViewReceivedImageSrc : swapViewGivenImageSrc;
    // } else {
    //     return isViewer ? swapNoAccessReceivedImageSrc : swapNoAccessGivenImageSrc;
    // }
}

export function getAccessStateText(accessState) {
    if (accessState === 1) {
        return 'Control';
    } else if (accessState === 0) {
        return 'View';
    } else {
        return 'No Access';
    }
}

export function getRoleString(id) {
    let roles = enums.role;
    if (roles !== undefined) {
        for (let item in roles) {
            if (roles.hasOwnProperty(item) && roles[item] === id) {
                return humanize(item);
            }
        }
    }
}

export function checkIfNull(str) {
    if (str === "null" || str === null || str === undefined || str === "undefined") {
        return "";
    }
    return str;
}

export function checkIfNullProfilePicture(str) {
    if (!str || str === "null" || str === null || str === undefined || str === "undefined") {
        return "";
    }
    return str;
}

export function checkIfUndefined(str) {
    return str === undefined;

}

export function checkIfNeedToCallView(target) {
    return target === "DIV" || target === "SPAN" || target === "SMALL";

}

export function replaceNewLineToBr(str) {
    if (checkIfNull(str)) {
        return str.replace(/\n/g, "<br />");
    }

    return "";
}

export function getAreaAndPhoneFromContact(contactNumber) {
    let numberArr = [];
    if (checkIfNull(contactNumber)) {
        let contact = contactNumber.split(" ");
        numberArr['area_code'] = contact[0] !== undefined ? contact[0] : '';
        numberArr['phone_number'] = contact[1] !== undefined ? contact[1] : '';
    } else {
        numberArr['area_code'] = '';
        numberArr['phone_number'] = '';
    }
    return numberArr;
}

export function getContactNumberUsingAreaAndPhone(data) {
    return data['area_code'] + " " + data['phone_number'];
}

export function getParentContactNumberUsingAreaAndPhone(data) {
    return data['parent_area_code'] + " " + data['parent_phone_number'];
}

export function getFullContactNumberWithCountry(country, contactNumber, isDashes) {
    if (country && contactNumber) {
        return country + " " + contactNumber;
    }

    if (isDashes !== undefined) {
        return '';
    }

    return '---';
}

export function getFullAddress(data) {
    let address = '';

    if (data !== undefined) {
        address += checkIfNull(data.address) ? data.address + " " : '';
        address += checkIfNull(data.city) ? data.city + " " : '';
        address += checkIfNull(data.state) ? data.state + " " : '';
        address += checkIfNull(data.zipCode) ? data.zipCode + " " : '';
    }

    return address;
}

export function getFirstNameFromFullName(name) {
    let firstName = '*No Name*';
    let limitChar = 15;
    if (checkIfNull(name)) {
        let nameArr = name.split(" ");
        firstName = nameArr[0] !== undefined ? nameArr[0] : '*No Name*';
        firstName = firstName.length > limitChar ? firstName.slice(0, limitChar) : firstName;
    }
    return firstName;
}

export function getFullnameFromFirstAndLastNames(firstName, lastName) {
    let fullname = '---';
    if (firstName && firstName.length > 0)
        fullname = firstName;
    if (lastName && lastName.length > 0)
        fullname += ' ' + lastName;
    return fullname;
}

export function getMomentDateFromMysql(date) {
    let newDate = moment(date);
    if (newDate.isValid()) {
        return newDate;
    }

    return '';
}

export function getMomentPlusMonthDateFromMysql(integer) {
    let newDate;
    if (integer === 0) {
        newDate = moment();
    } else if (integer < 0) {
        integer = Math.abs(integer);
        newDate = moment().subtract(integer, 'months');
    } else {
        newDate = moment().add(integer, 'months');
    }
    if (newDate.isValid()) {
        return newDate;
    }

    return '';
}

export function convertMomentDateToMySql(date) {
    let mySqlDate = '';
    if (typeof date === "object") {
        mySqlDate = moment(moment(date).format('YYYY/MM/DD HH:mm:ss')).format("YYYY-MM-DD HH:mm:ss");
    } else if (date) {
        let newDate = moment(date);
        if (newDate.isValid()) {
            mySqlDate = moment(newDate.format('YYYY/MM/DD HH:mm:ss')).format("YYYY-MM-DD HH:mm:ss");
        }
    }

    return mySqlDate;
}

export function convertMomentDateToOnlyDateMySql(date) {
    let mySqlDate = '';
    if (typeof date === "object") {
        mySqlDate = moment(moment(date).format('YYYY/MM/DD HH:mm:ss')).format("YYYY-MM-DD");
    } else if (date) {
        let newDate = moment(date);
        if (newDate.isValid()) {
            mySqlDate = moment(newDate.format('YYYY/MM/DD HH:mm:ss')).format("YYYY-MM-DD");
        }
    }

    return mySqlDate;
}

export function getFromAndTillDateFromDateRangeText(text) {
    const dates = {
        fromDate: '',
        tillDate: '',
    };
    switch (text) {
        case 'week':
            dates.fromDate = convertMomentDateToMySql(moment().add(-1, 'week'));
            dates.tillDate = convertMomentDateToMySql(moment());
            break;
        case 'month':
            dates.fromDate = convertMomentDateToMySql(moment().add(-1, 'month'));
            dates.tillDate = convertMomentDateToMySql(moment());
            break;
        case '6_month':
            dates.fromDate = convertMomentDateToMySql(moment().add(-6, 'month'));
            dates.tillDate = convertMomentDateToMySql(moment());
            break;
        case 'year':
            dates.fromDate = convertMomentDateToMySql(moment().add(-1, 'year'));
            dates.tillDate = convertMomentDateToMySql(moment());
            break;
        default:
            break;
    }
    return dates;
}

export function convertMySqlToHumanReadable(date) {
    let dateStr = '';

    if (date && date !== undefined && date !== "NaN") {
        dateStr = moment(date).format('DD MMM YYYY');
    }else if (date === "NaN"){
        dateStr = "Unlimited";
    }

    return dateStr;
}

export function convertMySqlToHumanReadableTime(date) {
    let dateStr = '';

    if (date && date !== undefined) {
        dateStr = moment(date).format('h:mma');
    }

    return dateStr;
}

export function getHoursMinutesAndMeridiemFromMySqlDate(date) {
    let timeObj = {
        hours: '1',
        minutes: '1',
        meridiem: 'am',
    };

    if (date && date !== undefined) {
        timeObj['hours'] = moment(date).format('h');
        timeObj['minutes'] = moment(date).format('mm');
        timeObj['meridiem'] = moment(date).format('a');
    }

    return timeObj;
}

export function getTimeFromHoursMinutesAndMeridiem(data) {
    let hours = data['hours'].toString();
    let minutes = data['minutes'].toString();
    hours = hours.length === 1 ? "0" + hours : hours;
    minutes = minutes.length === 1 ? "0" + minutes : minutes;
    return `${hours}:${minutes} ${data['meridiem']}`;
    // To Send Date
    // let hours = data['meridiem'] === 'pm' ? parseInt(data['hours']) + 12 : data['hours'];
    // return moment({hour:hours, minute:data['minutes']}).format("YYYY-MM-DD HH:mm:ss");
}

export function getTitleFromChildren(children) {
    if (children !== undefined) {
        return children.toString().replace(",false", "");
    }
}

export function hideExtraContent(string, limit) {
    let limitChar = limit !== undefined ? limit : 35;
    if (string !== undefined && string !== null && string.length > limitChar) {
        return string.slice(0, limitChar) + "...";
    }

    return string;
}

export function isInternetConnectionAvailable() {
    return (navigator !== undefined && navigator.onLine);
}

export function showSettingsAlert() {
    let options = {
        message: (
            <React.Fragment>
                <div className="text-center" id="connectionAlertId">
                    <h1 className="text-uppercase mb-4">Default Institute Settings</h1>
                    <div className="mt-4 font-family-lato px-4">
                        <p className="text-muted mb-2 font-0-9rem text-left">Please see the default settings below, You
                            can change these options from the <a href="/settings">settings</a>:</p>
                        <ul className="font-0-9rem text-left mb-0">
                            <li>Teacher can create class without approval.</li>
                            <li>Admin or teacher can assign class to student without approval.</li>
                            <li>Student can enroll in class without approval.</li>
                            <li>Teacher can assign class to self without approval.</li>
                            <li>Admin can assign class to teacher without approval.</li>
                            <li>Teacher cannot use the account after signup without approval.</li>
                            <li>Student cannot use the account after signup without approval.</li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        ),
        confirmText: "OK",
        confirmColor: "account"
    };
    // Showing Alert by calling confirm func
    confirm(options);
}

export function showServerConnectionAlert(message) {
    let connectionAlertId = document.getElementById('connectionAlertId');
    let authAlertId = document.getElementById('authAlertId');
    let title = "Connection Error";
    if (isNetworkError(message)) {
        title = "Network Error";
        message = language.MSG_SERVER_ERROR;
    }
    if (authAlertId === null && connectionAlertId === null) {
        let options = {
            message: (
                <React.Fragment>
                    <div className="text-center" id="connectionAlertId">
                        <h1 className="text-uppercase mb-4">{title}</h1>
                        <i className="fa fa-remove error-icon-modal"/>
                        <div className="mt-4 font-family-lato px-4">
                            <p className="text-muted mb-0">{message}</p>
                        </div>
                    </div>
                </React.Fragment>
            ),
            confirmText: "OK",
            confirmColor: "account"
        };
        // Showing Alert by calling confirm func
        confirm(options);
    }
}

export function showAuthorizationAlert(message) {
    let authAlertId = document.getElementById('authAlertId');
    let connectionAlertId = document.getElementById('connectionAlertId');
    let title = "Authorization";
    if (isNetworkError(message)) {
        title = "Network Error";
        message = language.MSG_SERVER_ERROR;
    }
    if (authAlertId === null && connectionAlertId === null) {
        let options = {
            message: (
                <React.Fragment>
                    <div className="text-center" id="authAlertId">
                        <h1 className="text-uppercase mb-4">{title}</h1>
                        <i className="fa fa-remove error-icon-modal"/>
                        <div className="mt-4 font-family-lato px-4">
                            <p className="text-muted mb-0">{message}</p>
                        </div>
                    </div>
                </React.Fragment>
            ),
            confirmText: "OK",
            confirmColor: "account"
        };
        // Showing Alert by calling confirm func
        confirm(options);
    }
}

export function showAlertMessage(type, title, message, isWarning = undefined) {
    if (type !== 'hidden') {
        if (!isInternetConnectionAvailable()) {
            if (type === "error" && message === language.MSG_SERVER_ERROR) {
                message = language.MSG_CONNECTION_PROBLEM;
            }
        }
        let options = {
            message: (
                <React.Fragment>
                    <div className="text-center">
                        <h1 className="text-uppercase mb-4">{title}</h1>
                        {
                            type === "success"
                            &&
                            <i className="fa fa-check success-icon-modal"/>
                        }
                        {
                            type === "warning"
                            &&
                            <i className="fa fa-exclamation warning-icon-modal"/>
                        }
                        {
                            type === "error"
                            &&
                            <i className="fa fa-remove error-icon-modal"/>
                        }
                        <div className="mt-4 font-family-lato px-4">
                            <p className="text-muted mb-0">
                                <span dangerouslySetInnerHTML={{__html: message}}/>
                            </p>
                        </div>
                        {
                            type === "success" && isWarning !== undefined
                            &&
                            <Alert color="warning" className="mt-3 mb-0 text-center">
                                Your institute is using FREE package. You will be charged for enrolling a class.
                            </Alert>
                        }
                    </div>
                </React.Fragment>
            ),
            confirmText: "OK",
            confirmColor: "account"
        };
        // Showing Alert by calling confirm func
        confirm(options);
    }
}

export function getConfirmAlertMessage(title, message, subMessage = null, showPayment = false) {
    subMessage = subMessage === null ? "Are you Sure?" : subMessage;

    return {
        message: (
            <React.Fragment>
                <div className="text-center">
                    <h1 className="text-uppercase mb-4">{title}</h1>
                    <i className="fa fa-exclamation warning-icon-modal"/>
                    <div className="mt-4 font-family-lato px-4">
                        <p className="mb-0">{subMessage}</p>
                        <p className="text-muted mb-0">{message}</p>
                    </div>
                    {
                        showPayment
                        &&
                        <Alert color="warning" className="mt-3 mb-0 text-center">
                            Your institute is using FREE package. In future you will be charged for enrolling a class.
                        </Alert>
                    }
                </div>
            </React.Fragment>
        ),
        confirmText: "Yes",
        cancelText: "No",
        confirmColor: "account",
        cancelColor: "grey-gradient"
    };
}

export function getConfirmModalOptions(content, yesButtonText, noButtonText) {
    return {
        message: content,
        confirmText: null,
        cancelText: null,
        confirmColor: "account",
        cancelColor: "grey-gradient"
    };
}

export function getDashboardLinkByRole(role) {
    let link = '/';
    switch (role) {
        case enums.role.SUPER_ADMIN:
            link = '/institutes';
            break;
        case enums.role.INSTITUTE_ADMIN:
        case enums.role.TEACHER:
        case enums.role.STUDENT:
            link = '/dashboard';
            break;

        default:
            link = '/#home';
    }

    return link;
}

export function timeDiff(date) {
    if (date) {
        return moment(date).diff(moment(), 'months', true);
    } else {
        return '';
    }
}

export function isMobile() {
    return !!(navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i));
}

export function isWindows() {
    return navigator.platform === 'Win32';
}

export function isMobileByWidth() {
    return window.innerWidth <= 767;
}

export function detectIE() {
    let ua = window.navigator.userAgent;

    let msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    let trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        let rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    // other browser
    return false;
}

export function getLoginUrl() {
    if (isMobile()) {
        return "/#login";
    } else {
        return "/#login";
    }
}

export function getIdFromURL(pathname) {
    let paths = pathname.split("/");
    if (paths[2] !== undefined) {
        return parseInt(paths[2], 10);
    }
    return false;
}

export function getDateRangeTextFromURL(pathname) {
    let paths = pathname.split("/");
    if (paths[2] !== undefined) {
        return paths[2];
    }
    return false;
}

// To make role id same for all service's
export function getRoleIdFromUser(user) {
    if (user !== undefined && user) {
        return user.roleId;
    }

    return '';
}

export function downloadFileOld(response, data, name) {
    let ext = '';
    if (data['downloadAs'] === "PDF") {
        ext = 'pdf';
    } else if (data['downloadAs'] === "EXCEL") {
        ext = 'xlsx';
    } else if (data['downloadAs'] === "CSV") {
        ext = 'csv';
    }

    let fileName = name + "." + ext;

    if (detectIE() !== false) {
        window.navigator.msSaveBlob(response, fileName);
    } else {
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        //Create a DOMString representing the blob
        //and point the link element towards it
        let url = window.URL.createObjectURL(response);
        a.href = url;
        a.download = fileName;
        //programatically click the link to trigger the download
        a.click();
        //release the reference to the file by revoking the Object URL
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
        }, 100);
    }
}

export function baseName(str) {
    let base = String(str).substring(str.lastIndexOf('/') + 1);
    if (base.lastIndexOf(".") !== -1)
        base = base.substring(0, base.lastIndexOf("."));
    return base;
}

export function downloadCSVFile(event, fileUrl) {
    event.preventDefault();

    let completeFileUrl = config.FRONTEND_URL + fileUrl;

    // Getting CSV File into a variable
    axios({
        url: completeFileUrl,
        method: 'GET'
    }).then(response => {
        if (response.status === 200) {
            let CSV = response.data;
            let fileName = baseName(completeFileUrl);
            downloadFile(CSV, {downloadAs: "CSV"}, fileName);
        } else {
            showAlertMessage("error", "Download File", "Unable to download file. Please try again.");
            console.log('request status not 200', response);
        }
    }).catch(error => {
        showAlertMessage("error", "Download File", "Unable to download file. Please try again.");
        console.log('request failed', error);
    });
}

export function downloadFile(response, data, name) {
    let ext = '', type;
    if (data['downloadAs'] === "PDF") {
        ext = 'pdf';
        type = "application/pdf";
    } else if (data['downloadAs'] === "EXCEL") {
        ext = 'xlsx';
        type = "application/vnd.openxmlformats";
    } else if (data['downloadAs'] === "CSV") {
        ext = 'csv';
        type = "text/csv";
    }

    let fileName = name + "." + ext;

    let newBlob = new Blob([response], {type: type});
    if (isIOSMobileApp() || isDroidMobileApp()) {
        blobToBase64(newBlob, (base64Blob) => {
            sendBlobToApp(base64Blob, fileName);
        });
    } else {
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.runDownloadReportEvent("success");
            window.navigator.msSaveOrOpenBlob(newBlob, fileName);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataBlob = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = dataBlob;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            window.runDownloadReportEvent("success");
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(dataBlob);
        }, 100);
    }
}

export function blobToBase64(blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
        var dataUrl = reader.result;
        var base64 = dataUrl.split(',')[1];
        callback(base64);
    };
    reader.readAsDataURL(blob);
}

export function sendBlobToApp(blobString, filename) {
    let data = JSON.stringify({
        blob: blobString,
        filename: filename
    });

    if (isIOSMobileApp()) {
        window.webkit.messageHandlers.save.postMessage(data);
    }

    if (isDroidMobileApp()) {
        window.native.saveBlobDroid(data);
    }
}

export function getResponseType(response) {
    const contentType = response.headers.get("content-type");
    if ((contentType && contentType.indexOf("application/json") !== -1) ||
        (contentType && contentType.indexOf("text/html") !== -1)) {
        return response.json();
    } else {
        return response.blob();
    }
}

export function getUniqueNumber() {
    return moment().valueOf();
}

export function clickCloseButton(e) {
    if (e !== undefined) {
        e.preventDefault();
    }
    let modalCloseElement = document.getElementById('modalClose');
    if (modalCloseElement !== null) {
        modalCloseElement.click();
    }
}

export function clickCloseChildButton(e) {
    if (e !== undefined) {
        e.preventDefault();
    }
    let modalCloseElement = document.getElementById('childModalClose');
    if (modalCloseElement !== null) {
        modalCloseElement.click();
    }
}

export function calculateDifferenceInMinutes(endDate) {
    if (endDate) {
        let start_date = moment(moment().utc().utcOffset(0).format('YYYY-MM-DD HH:mm:ss'));
        let end_date = moment(moment(endDate).format('YYYY-MM-DD HH:mm:ss'));
        let duration = moment.duration(end_date.diff(start_date));
        let seconds = duration.seconds();
        let minutes = parseInt(duration.minutes());
        if (minutes < 0 || minutes === 59) {
            return `0 secs`;
        } else if (minutes === 0) {
            return `${seconds} secs`;
        } else {
            return `${minutes} mins ${seconds} secs`;
        }
    }
    return ``;
}

export function calculateDifferenceInSeconds(endDate) {
    if (endDate) {
        let start_date = moment(moment().utc().utcOffset(0).format('YYYY-MM-DD HH:mm:ss'));
        let end_date = moment(moment(endDate).format('YYYY-MM-DD HH:mm:ss'));
        let duration = moment.duration(end_date.diff(start_date));
        let seconds = duration.seconds();
        let minutes = parseInt(duration.minutes());

        if (minutes < 0 || minutes === 59) {
            return 0;
        } else if (minutes === 0) {
            return seconds;
        } else {
            return minutes;
        }
    }
    return ``;
}

export function getObjFromArrayWithOneElement(arr) {
    let obj = {};

    if (arr !== undefined) {
        if (arr && arr.length > 0 && arr.hasOwnProperty(0)) {
            obj = arr.hasOwnProperty(0) ? arr[0] : [];
        }
    }

    return obj;
}

export function getTotalRecordsFromTabKey(totalCounts, tabs, activeTab) {
    let activeTabNewIndex = activeTab - 1;
    if (tabs.hasOwnProperty(activeTabNewIndex)) {
        if (tabs[activeTabNewIndex].hasOwnProperty('countKey')) {
            return totalCounts[tabs[activeTabNewIndex]['countKey']];
        }
    }

    return 0;
}

export function checkIsFileCorrect(file, callback) {
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        callback('This file type is not supported');
    } else {
        callback(true);
    }
}

export function isEmptyObj(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function serialize(obj) {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export function checkIsFreePlan(instituteInfo, user) {
    if (
        instituteInfo.plan !== undefined &&
        getRoleIdFromUser(user) === enums.role.STUDENT
    ) {

        if (instituteInfo.plan.cost === null || parseInt(instituteInfo.plan.cost, 10) === 0) {
            return true;
        }
    }

    return false;
}

export function getInstituteOptionsForTeacherAndStudents(inputValue = '', callback) {
    const data = {
        searchText: inputValue,
        limitNRows: config.LIMIT_N_ROWS_DROPDOWN,
    };

    get_institutes_offline(data, (response) => {
        if (response.code === 0) {
            const options = [];
            response = response.data;
            if (response !== undefined) {
                for (let singleInstitute in response) {
                    if (response.hasOwnProperty(singleInstitute)) {
                        let id = response[singleInstitute].id;
                        let isParentInfoRequired = response[singleInstitute].isParentInfoRequired;
                        if (response[singleInstitute].name) {
                            options.push(
                                {
                                    value: id,
                                    isParentInfoRequired: isParentInfoRequired,
                                    label: <span
                                        className="leftvalue">{hideExtraContent(response[singleInstitute].name, 40)}</span>,
                                }
                            );
                        }
                    }
                }
                callback(options);
            }
        }
    });
}

export function showZeroIfNotFound(number) {
    return (number !== undefined && number) ? number : 0;
}

export function isIOSMobileApp() {
    return window.webkit !== undefined;
}

export function openIOSNativeCamera() {
    if (window.webkit !== undefined) {
        window.webkit.messageHandlers.scanQR.postMessage("Scan QR Code");
    } else {
        showAlertMessage("warning", "Camera Access Failed", "Please check your camera permissions/settings and try again.");
    }
}

export function openIOSNativeMediaChooser(showFor) {
    if (window.webkit !== undefined) {
        window.webkit.messageHandlers.openMediaChooser.postMessage(showFor);
    } else {
        //showAlertMessage("warning", "Camera Access Failed", "Please check your camera permissions/settings and try again.");
    }
}

export function isDroidMobileApp() {
    return window.native !== undefined;
}

export function openDroidNativeCamera() {
    if (window.native !== undefined && window.native.scanQR !== undefined) {
        console.log("Calling Native scan QR");
        window.native.scanQR();
    } else {
        showAlertMessage("warning", "Camera Access Failed", "Please check your camera permissions/settings and try again.");
    }
}

export function getDeviceTypeEnum(deviceType) {
    switch (deviceType) {
        case 'android':
            return enums.deviceType.DROID;
        case 'ios':
            return enums.deviceType.IOS;
        case 'web':
            return enums.deviceType.WEB;
        default:
            return enums.deviceType.WEB;
    }
}

export function getDeviceType() {
    let deviceType = '';
    if (isIOSMobileApp()){
        deviceType = 'IOS';
    } else if (isDroidMobileApp()){
        deviceType = 'ANDROID';
    } else {
        deviceType = 'WEB';
    }
    return deviceType;
}

export function getPersistentLocalStorageKeys() {
    return ['FCMToken', 'DeviceId', 'DeviceType'];
}

/**
 * IOS Native App is Calling This function to submit Mark Attendance
 */
window.qrScanComplete = (code) => {
    if (
        window.markAttendanceComponent !== undefined &&
        window.markAttendanceComponent._reactInternalFiber !== undefined &&
        window.markAttendanceComponent._reactInternalFiber.child !== undefined &&
        window.markAttendanceComponent._reactInternalFiber.child.stateNode !== undefined
    ) {
        window.markAttendanceComponent._reactInternalFiber.child.stateNode.getWrappedInstance().QRCodeAction(code);
    } else {
        showAlertMessage("error", "QR Scan Failed", "Failed to scan QR.");
    }
};

/**
 * IOS Native App is Calling This function to submit media
 */
window.onIOSMediaChosen = (base64EncodedStringFile, filename, mimeType) => {
    base64EncodedStringFile = 'data:' + mimeType + ';base64,' + base64EncodedStringFile;
    fetch(base64EncodedStringFile)
        .then(res => res.blob())
        .then(blob => {
            let fileTarget = {
                target: {
                    files: [blob],
                    name: filename
                }
            };

            if (
                window.editProfileComponent &&
                window.editProfileComponent !== null &&
                window.editProfileComponent._reactInternalFiber !== null &&
                window.editProfileComponent._reactInternalFiber.child !== null &&
                window.editProfileComponent._reactInternalFiber.child.stateNode !== null
            ) {
                window.editProfileComponent._reactInternalFiber.child.stateNode.handleChangeFile(fileTarget);
            } else {
                //showAlertMessage("error", "QR Scan Failed", "Failed to scan QR.");
            }

            if (
                window.importCsvModalComponent &&
                window.importCsvModalComponent !== null &&
                window.importCsvModalComponent._reactInternalFiber !== null &&
                window.importCsvModalComponent._reactInternalFiber.child !== null &&
                window.importCsvModalComponent._reactInternalFiber.child.stateNode !== null
            ) {
                window.importCsvModalComponent._reactInternalFiber.child.stateNode.handleChangeFile(fileTarget);
            } else {
                //showAlertMessage("error", "QR Scan Failed", "Failed to scan QR.");
            }
        });
};

/**
 * Android Native App is Calling This function to save user's data
 */
window.saveFireBaseTokenAndDeviceId = (FCMToken, deviceId, deviceType) => {
    localStorage.setItem('FCMToken', FCMToken);
    localStorage.setItem('DeviceId', deviceId);
    localStorage.setItem('DeviceType', getDeviceTypeEnum(deviceType));
};

/**
 * Android Native App is Calling This function to save user's data
 */
window.sendDeviceInfoForMarkingAttendance = (address) => {
    localStorage.setItem('Address', address);
};

window.showGlobalAlert = (type, title, description) => {
    showAlertMessage(type, title, description);
};
/**
 * Android and IOS Native Apps is Calling This function to send response of downloaded report
 */
window.runDownloadReportEvent = (type) => {
    // Close Download Modal
    clickCloseButton();
    setTimeout(() => {
        if (type === "error") {
            showAlertMessage("error", "Download File", "Unable to download file. Please try again.");
        } else if (type === "notsupported") {
            showAlertMessage("error", "Download File", "Unable to view file, please install an app that can view this type of file.");
        }
    }, 500);
};

/**
 * Android Native App is Calling This function to simulate click on change Profile picture when permissions result has been received
 */
window.clickChangeProfilePicture = () => {
    if (
        window.editProfileComponent !== undefined &&
        window.editProfileComponent._reactInternalFiber !== undefined &&
        window.editProfileComponent._reactInternalFiber.child !== undefined &&
        window.editProfileComponent._reactInternalFiber.child.stateNode !== undefined
    ) {
        window.editProfileComponent._reactInternalFiber.child.stateNode.clickInputFile();
    } else {
        // showAlertMessage("error", "QR Scan Failed", "Failed to scan QR.");
    }
};

export function createHash(data){
    return md5(data).toString();
}

export function getBaseUrl(url){
    return url.replace(/\d/g, '');
}
