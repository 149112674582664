import React from 'react';
import {
    Button, NavItem, NavLink, Nav, TabPane, TabContent,
    Row, Col, Input, Form, CustomInput
} from 'reactstrap';
import classnames from 'classnames';
import {
    checkIfNullProfilePicture,
    convertMySqlToHumanReadable,
    getStatusColor,
    getStatusString,
    isIOSMobileApp,
    isDroidMobileApp
} from "../../helpers/helper";
import config from "../../config";
import userPlaceholder from '../../assets/images/user-placeholder.jpg';
import enums from "../../helpers/enums";

export function ListingHeader(props) {
    return (
        <div className="sa-listing-header font-family-oswald">
            <Row>
                <Col xs="5" md="4">
                    <h4 className="m-0 mt-2">{props.title}</h4>
                </Col>
                <Col xs="7" md="8">
                    <div className="float-right">
                        {props.children}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export function SmallListingHeader(props) {
    return (
        <div className="form-title black py-2 mb-0">
            {props.children}
        </div>
    );
}

export function SmallListingButton(props) {
    return (
        <React.Fragment>
            <i className={props.icon + " icon-circle-small mr-1"}/>
            <span style={{fontSize: "0.9rem"}} className="text-uppercase">{props.text}</span>
        </React.Fragment>
    );
}

export function ListingNav(props) {
    return (
        <div className="sa-listing-nav">
            {props.children}
        </div>
    );
}

export function SaListing(props) {
    return (
        <div className="sa-listing font-family-lato">
            {props.children}
        </div>
    );
}

export function SaSmallListing(props) {
    return (
        <div className="sa-listing sa-listing-small font-family-lato">
            {props.children}
        </div>
    );
}

export function ListingBody(props) {
    return (
        <div className="sa-listing-body">
            {props.children}
        </div>
    );
}

export function ListingNavTabs(props) {
    let {tabs, activeTab, toggle, totalCounts, isResetPasswordFor, handleChangeAllResetPassword} = props;
    let navItems = '';
    if (tabs && tabs.length > 0) {
        navItems = tabs.map((item, index) => {
            return (
                <NavItem key={index}>
                    <NavLink
                        className={classnames({active: activeTab === item.tab})}
                        onClick={() => {
                            toggle(item.tab, item.filter);
                        }}
                    >
                        {item.title}({totalCounts[item.countKey]})
                    </NavLink>
                </NavItem>
            );
        });
    }

    return (
        <Nav tabs>
            {
                isResetPasswordFor
                &&
                (
                    <span className="reset-password-checkbox-all">
                        <CustomInput value="all" type="checkbox" label="" id="resetPasswordFillAll"
                                     inline onChange={handleChangeAllResetPassword}/>
                    </span>
                )
            }
            {navItems}
        </Nav>
    )
}

export function NavTabsWithSearch(props) {
    let {tabs, activeTab, toggle, totalCounts} = props;
    let {handleSearch, searchText, clearSearch} = props;
    return (
        <div className="sa-listing-nav">
            <Row>
                <Col lg="8">
                    <ListingNavTabs tabs={tabs} activeTab={activeTab} toggle={toggle} totalCounts={totalCounts}/>
                </Col>
                <Col lg="4">
                    <NavSearchForm handleSearch={handleSearch} searchText={searchText} clearSearch={clearSearch}/>
                </Col>
            </Row>
        </div>
    );
}

export function NavSearchForm(props) {
    let {className, handleSearch, searchText, clearSearch, noMarginInput, canBrowserShowSuggestions} = props;
    return (
        <Form className={className + " sa-search-form"} autoComplete={canBrowserShowSuggestions ? 'on' : 'off'}>
            <Input type="text" name="search" id="search" placeholder="Search"
                   onChange={handleSearch} value={searchText} className={noMarginInput ? "" : "my-1"}/>
            {
                (searchText && searchText.length > 0)
                &&
                (
                    <button className="close-icon" type="reset" onClick={clearSearch}>
                        <i className="fa fa-remove"/>
                    </button>
                )
            }
        </Form>
    );
}

export function ListingTabsContent(props) {
    const {activeTab} = props;
    return (
        <React.Fragment>
            <TabContent activeTab={activeTab} className="mb-3">
                <TabPane tabId="1">
                    {props.children}
                </TabPane>
                <TabPane tabId="2">
                    {props.children}
                </TabPane>
                <TabPane tabId="3">
                    {props.children}
                </TabPane>
                <TabPane tabId="4">
                    {props.children}
                </TabPane>
            </TabContent>
        </React.Fragment>
    );
}

export function ListingLoader(props) {
    return (
        <div className={`${props.className} listing-loader`}>
            <div className="mt-5">
                <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw"/>
            </div>
        </div>
    );
}

export function ViewItem(props) {
    return (
        <div className="view-item"
             onClick={() => props.doAction(props.actionName ? props.actionName : 'view', props.id)}>
            <i className="fa fa-chevron-right"/>
        </div>
    );
}

export function LoadMoreButton(props) {
    if (
        props.data && props.data.length > 0 &&
        (props.data.length % config.LIMIT_N_ROWS === 0) &&
        (props.totalRecords === undefined || props.totalRecords > props.data.length)
    ) {
        return (
            <div className="text-center">
                <Button color=""
                        className={props.small ? "btn-sm btn-blue-bordered my-2" : "btn-blue-bordered my-2 mb-4"}
                        onClick={props.loadMore}
                        disabled={props.isRequested}>
                    {
                        props.isRequested
                        &&
                        (
                            <i className="fa fa-circle-o-notch fa-spin fa-fw"/>
                        )
                    }
                    &nbsp;Load More
                </Button>
            </div>
        );
    } else {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export function ListingHeaderColumn(props) {
    if (props.sortTitle !== undefined) {
        return (
            <Col lg={props.lg} md={props.md} sm={props.sm} xs={props.xs}
                 className={classnames({active: props.sortByName === props.sortTitle}) + " listing-column-header"}
                 data-sort={props.sortTitle}
                 data-sort-order={props.sortByName === props.sortTitle ? props.sortByOrder : "DESC"}
                 onClick={props.sortData}>
                {props.title}
                <span className="sorting">&nbsp;</span>
            </Col>
        );
    } else {
        return (
            <Col lg={props.lg} md={props.md} sm={props.sm} xs={props.xs} className="listing-column-header">
                {props.title}
            </Col>
        );
    }

}

export function ListingBodySingleColumn(props) {
    return (
        <Col lg={props.size} md={props.md} sm={props.sm} xs={props.xs} className="listing-column">
            <div>
                <span className="overflow-full-width mt-2 d-block" title={!props.info ? props.firstElement : ''}>
                    {props.firstElement ? props.firstElement : '---'}
                    <span
                        className="ml-2 text-warning font-0-8rem">{props.inlineWithFirstElement ? props.inlineWithFirstElement : ''}</span>
                    {
                        (props.info && props.firstElement)
                        &&
                        (
                            <div className="info-block-wrapper">
                                <i className="fa fa-info info-block-icon" onMouseEnter={(e) => checkHeight(e)}/>
                                {props.info}
                            </div>
                        )
                    }
                </span>
            </div>
        </Col>
    );
}

export function ListingBodyDoubleColumn(props) {
    let classFirst = 'overflow-full-width';
    classFirst += props.noSecondElement ? ' mt-2' : '';
    if (!props.firstElement && !props.secondElement && props.optionalSecond) {
        return (
            <Col lg={props.size} className="listing-column">
                <div>
                <span className={classFirst + 'overflow-full-width mt-2 d-block'}>
                    ---
                </span>
                </div>
            </Col>
        );
    } else {
        return (
            <Col lg={props.size} className="listing-column">
                <div>
                <span className={classFirst + ' d-block'} title={!props.info ? props.firstElement : ''}>
                    {props.firstElement ? props.firstElement : '---'}
                    {
                        (props.info && props.firstElement)
                        &&
                        (
                            <div className="info-block-wrapper">
                                <i className="fa fa-info info-block-icon" onMouseEnter={(e) => checkHeight(e)}/>
                                {props.info}
                            </div>
                        )
                    }
                </span>
                </div>
                {
                    (!props.noSecondElement)
                    &&
                    (
                        <div>
                            {
                                <small className="text-muted overflow-full-width" title={props.secondElement}>
                                    {props.secondElement ? props.secondElement : '---'}
                                </small>
                            }
                        </div>
                    )
                }
            </Col>
        );
    }
}

export function ListingBodyDoubleColumnWithImage(props) {
    let classFirst = props.firstElement ? 'overflow-full-width' : 'overflow-full-width';
    let onImageClick = props.onImageClick;
    let showSquared = props.showSquared;
    let colClassNames = props.colClassNames ? 'listing-column ' + props.colClassNames : 'listing-column';
    let imageClassNames = (!showSquared ? 'user-placeholder-listing ' : 'user-placeholder-listing-squared ') + (props.imageClassNames ? props.imageClassNames : '');
    classFirst += props.secondElement ? '' : ' mt-2';
    let accessState = props.accessState;
    let viewerUriScheme = null;
    if (accessState === 1 || accessState === 0)
        viewerUriScheme = props.viewerUriScheme;
    let isMobile = isIOSMobileApp() || isDroidMobileApp();
    return (
        <Col lg={props.size} className={colClassNames}>
            <div className="clearfix mt-3 mt-lg-0">
                <div className="text-center float-left" style={{width: "40px"}}>
                    {
                        checkIfNullProfilePicture(props.imageSrc)
                        &&
                        (
                            <React.Fragment>
                                {
                                    (!viewerUriScheme)
                                    &&
                                    (
                                        <img className={imageClassNames} src={checkIfNullProfilePicture(props.imageSrc)}
                                             alt="P" onClick={onImageClick}/>
                                    )
                                }
                                {
                                    (viewerUriScheme)
                                    &&
                                    (
                                        <a href={viewerUriScheme} target={!isMobile ? '_blank' : ''}><img
                                            className={imageClassNames}
                                            src={checkIfNullProfilePicture(props.imageSrc)}
                                            alt="P" onClick={onImageClick}/></a>
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                    {
                        ((props.firstElement && props.firstElement !== '---') && !checkIfNullProfilePicture(props.imageSrc))
                        &&
                        (
                            <img className={imageClassNames} src={userPlaceholder} alt="P"
                                 onClick={onImageClick}/>
                        )
                    }

                </div>
                <div className="float-left" style={{width: "calc(100% - 40px)"}}>
                    <div>
                        <span className={classFirst + ' d-block'} title={props.firstElement}>
                            {props.firstElement ? props.firstElement : '---'}
                            {
                                (props.info && props.firstElement)
                                &&
                                (
                                    <div className="info-block-wrapper">
                                        <i className="fa fa-info info-block-icon" onMouseEnter={(e) => checkHeight(e)}/>
                                        {props.info}
                                    </div>
                                )
                            }
                        </span>
                    </div>
                    {
                        props.secondElement
                        &&
                        (
                            <div>
                                <small className="text-muted overflow-full-width" title={props.secondElement}>
                                    {
                                        props.secondElementColor
                                        &&
                                        (
                                            <span className={props.secondElementColor}>
                                                {props.secondElement ? props.secondElement : '---'}
                                            </span>
                                        )
                                    }
                                    {
                                        !props.secondElementColor
                                        &&
                                        (props.secondElement ? props.secondElement : '---')
                                    }
                                </small>
                            </div>
                        )
                    }
                </div>
            </div>
        </Col>
    );
}

export function ListingBodyDoubleClassColumn(props) {
    const {assignedClasses} = props;
    let infoData = [], firstClass = false, firstClassObj = false;
    if (assignedClasses !== undefined && assignedClasses.length > 0) {
        assignedClasses.forEach((classItem) => {
            if (firstClass === false) {
                firstClass = classItem.name;
                firstClassObj = classItem;
            } else {
                infoData.push({description: classItem.name, statusId: classItem.userClassStatusId});
            }
        });
    }

    if (!firstClass) {
        firstClass = (<small className="text-muted">No Class Assigned</small>)
    }
    return (
        <Col lg={props.size} className="listing-column">
            <div>
                <span className="mt-2 d-block" title={typeof firstClass === "string" ? firstClass : ""}>
                    {
                        (firstClassObj !== false)
                        &&
                        (
                            <i className={getStatusColor(firstClassObj.statusId) + " fa fa-circle mr-2"}
                               title={getStatusString(firstClassObj.statusId)}/>
                        )
                    }
                    {firstClass ? firstClass : '---'}
                    {
                        (infoData && firstClass)
                        &&
                        (
                            <div className="info-block-wrapper info-block-text-wrapper">
                                <i className="info-block-text info-block-icon" onMouseEnter={(e) => checkHeight(e)}>{
                                    ((assignedClasses && assignedClasses.length > 1) ? "+" + (assignedClasses.length - 1) : '')
                                }</i>
                                <InfoBlock data={infoData}/>
                            </div>
                        )
                    }
                </span>
            </div>
        </Col>
    );
}

function checkHeight(e) {
    if (e.target.nextSibling !== null) {
        let infoBlock = e.target.nextSibling;
        let heightInfoBlock = infoBlock.offsetHeight;
        if (!elementInViewport(infoBlock)) {
            let futureHeightInfoBlock;
            if (infoBlock.className.match(/\binfo-block-small\b/)) {
                futureHeightInfoBlock = heightInfoBlock + 30;
            } else {
                futureHeightInfoBlock = heightInfoBlock + 45;
            }
            infoBlock.style.transform = `translate3d(0, -${futureHeightInfoBlock}px, 0px)`;
            infoBlock.classList.add('info-block-opposite');
        } else {
            infoBlock.style.transform = `translate3d(0, 0, 0)`;
            infoBlock.classList.remove('info-block-opposite');
        }
    }
}

function elementInViewport(el) {
    if (el !== null) {
        let top = el.offsetTop;
        let left = el.offsetLeft;
        let width = el.offsetWidth;
        let height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top >= window.pageYOffset &&
            left >= window.pageXOffset &&
            (top + height) <= (window.pageYOffset + window.innerHeight) &&
            (left + width) <= (window.pageXOffset + window.innerWidth)
        );
    }
}

export function ListingBodyDoubleDateColumn(props) {
    return (
        <Col lg={props.size} className="listing-column">
            <div className="small-line-height">
                <small className="text-muted mr-2">Created On</small>
                <small>{props.createdAt ? convertMySqlToHumanReadable(props.createdAt) : '---'}</small>
            </div>
            <div className="small-line-height">
                <small className="text-muted mr-2">Expires On</small>
                <small>&nbsp;{props.expiresOn ? convertMySqlToHumanReadable(props.expiresOn) : '---'}</small>
            </div>
        </Col>
    );
}

export function ListingBodySingleDateColumn(props) {
    return (
        <Col lg={props.size} className="listing-column">
            <div className="small-line-height">
                <small className="text-muted mr-2">Created</small>
                <small>{props.createdAt ? convertMySqlToHumanReadable(props.createdAt) : '---'}</small>
            </div>
        </Col>
    );
}

export function ListingBodyDoubleStatusColumn(props) {
    let statusClass = getStatusColor(props.firstElement) + ' overflow-full-width d-inline-block';
    statusClass += props.secondElement ? '' : ' mt-2';
    return (
        <Col lg={props.size} className="listing-column">
            <div>
                <span className={statusClass} title={getStatusString(props.firstElement)}>
                    {getStatusString(props.firstElement)}
                </span>
            </div>
            <div>
                {
                    props.secondElement
                    &&
                    (
                        <small className="text-warning overflow-full-width" title={props.secondElement}>
                            {props.secondElement}
                        </small>
                    )
                }
            </div>
        </Col>
    );
}

export function ListingBodyTwoStatusColumn(props) {
    let {firstElement, secondElement} = props;

    let statusClass = getStatusColor(firstElement) + ' overflow-full-width d-inline-block';
    if (
        (firstElement === secondElement) ||
        (firstElement === enums.status.COMPLETED && secondElement === enums.status.ACTIVE)
    ) {
        secondElement = '';
        statusClass += ' mt-2';
    }

    let statusClassSecond = getStatusColor(secondElement) + ' overflow-full-width';
    return (
        <Col lg={props.size} className="listing-column">
            <div>
                <span className={statusClass} title={getStatusString(firstElement)}>
                    {getStatusString(firstElement)}
                </span>
            </div>
            <div>
                {
                    secondElement
                    &&
                    (
                        <small className={statusClassSecond} title={getStatusString(secondElement)}>
                            {getStatusString(secondElement)}
                        </small>
                    )
                }
            </div>
        </Col>
    );
}

export function ListingBodyOtherStatusColumn(props) {
    let statusClass = getStatusColor(props.firstElement) + ' overflow-full-width d-inline-block';
    statusClass += props.secondElement ? '' : ' mt-2';
    return (
        <Col lg={props.size} className="listing-column">
            <div>
                <span className={statusClass} title={getStatusString(props.firstElement)}>
                    <i className="fa fa-circle mr-2"/>
                    <span className="text-dark">{getStatusString(props.firstElement)}</span>
                </span>
            </div>
            <div className="info-block-wrapper with-relative">
                {
                    props.secondElement
                    &&
                    (
                        <small className="text-warning overflow-full-width" title={props.secondElement}
                               style={{paddingLeft: "22px"}} onMouseEnter={(e) => checkHeight(e)}>
                            {props.secondElement}
                        </small>
                    )
                }
                {props.info}
            </div>
        </Col>
    );
}

export function InfoBlockWithHeading(props) {
    let {data} = props;
    let infoRows;
    if (data && data.length > 0) {
        infoRows = data.map((item, index) => {
            return (
                <div key={index} className="info-block-row">
                    <div className="info-block-heading-column">
                        {item.heading}
                    </div>
                    <div className="info-block-detail-column">
                        <div className="overflow-full-width">{item.description}</div>
                    </div>
                </div>
            );
        });
        return (
            <div className="info-block">
                {infoRows}
            </div>
        );
    } else {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export function ListingNoContent(props) {
    return (
        <Row className="listing-row row-no-content">
            <Col xs="12" className="listing-column-header text-center listing-no-content">{props.children}</Col>
        </Row>
    );
}

export function InfoBlock(props) {
    let {data} = props;
    let infoRows;
    if (data && data.length > 0) {
        infoRows = data.map((item, index) => {
            let statusIcon = '';
            if (item.statusId !== undefined) {
                statusIcon = <i className={getStatusColor(item.statusId) + " fa fa-circle mr-2"}
                                title={getStatusString(item.statusId)}/>
            }
            return (
                <div key={index} className="info-block-row">
                    <div className="info-block-detail-column" title={item.description}>
                        {statusIcon}
                        <span dangerouslySetInnerHTML={{__html: item.description}}/>
                    </div>
                </div>
            );
        });
        return (
            <div className="info-block info-block-small">
                <div className="info-block-handler">
                    {infoRows}
                </div>
            </div>
        );
    } else {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}
