////////////////////// General ///////////////////////////
export const FORGOT_PASSWORD_REQUEST = 'user.FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'user.FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'user.FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_CLEAR = 'user.FORGOT_PASSWORD_CLEAR';

export const RESET_PASSWORD_REQUEST = 'user.RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'user.RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'user.RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_CLEAR = 'user.RESET_PASSWORD_CLEAR';

export const FORGOT_PASSWORD_NOT_MATCHED = 'user.FORGOT_PASSWORD_NOT_MATCHED';

export const CHANGE_PASSWORD_REQUEST = 'user.CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'user.CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'user.CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_CLEAR = 'user.CHANGE_PASSWORD_CLEAR';

////////////////////// Teachers ///////////////////////////
export const ADD_TEACHER_REQUEST = 'user.ADD_TEACHER_REQUEST';
export const ADD_TEACHER_SUCCESS = 'user.ADD_TEACHER_SUCCESS';
export const ADD_TEACHER_FAILURE = 'user.ADD_TEACHER_FAILURE';
export const ADD_TEACHER_CLEAR = 'user.ADD_TEACHER_CLEAR';

export const EDIT_TEACHER_REQUEST = 'user.EDIT_TEACHER_REQUEST';
export const EDIT_TEACHER_SUCCESS = 'user.EDIT_TEACHER_SUCCESS';
export const EDIT_TEACHER_FAILURE = 'user.EDIT_TEACHER_FAILURE';
export const EDIT_TEACHER_CLEAR = 'user.EDIT_TEACHER_CLEAR';

export const LIST_TEACHER_REQUEST = 'user.LIST_TEACHER_REQUEST';
export const LIST_TEACHER_SUCCESS = 'user.LIST_TEACHER_SUCCESS';
export const LIST_TEACHER_FAILURE = 'user.LIST_TEACHER_FAILURE';
export const UPDATE_TEACHER_LISTING_PARAMS = 'user.UPDATE_TEACHER_LISTING_PARAMS';

export const SINGLE_TEACHER_REQUEST = 'user.SINGLE_TEACHER_REQUEST';
export const SINGLE_TEACHER_SUCCESS = 'user.SINGLE_TEACHER_SUCCESS';
export const SINGLE_TEACHER_FAILURE = 'user.SINGLE_TEACHER_FAILURE';
export const UPDATE_SINGLE_TEACHER_LISTING_PARAMS = 'user.UPDATE_SINGLE_TEACHER_LISTING_PARAMS';

export const DELETE_TEACHER_REQUEST = 'user.DELETE_TEACHER_REQUEST';
export const DELETE_TEACHER_SUCCESS = 'user.DELETE_TEACHER_SUCCESS';
export const DELETE_TEACHER_FAILURE = 'user.DELETE_TEACHER_FAILURE';

export const BLOCK_TEACHER_REQUEST = 'user.BLOCK_TEACHER_REQUEST';
export const BLOCK_TEACHER_SUCCESS = 'user.BLOCK_TEACHER_SUCCESS';
export const BLOCK_TEACHER_FAILURE = 'user.BLOCK_TEACHER_FAILURE';

export const UNBLOCK_TEACHER_REQUEST = 'user.UNBLOCK_TEACHER_REQUEST';
export const UNBLOCK_TEACHER_SUCCESS = 'user.UNBLOCK_TEACHER_SUCCESS';
export const UNBLOCK_TEACHER_FAILURE = 'user.UNBLOCK_TEACHER_FAILURE';

export const ACCEPT_DROP_REQUEST_TEACHER_REQUEST = 'user.ACCEPT_DROP_REQUEST_TEACHER_REQUEST';
export const ACCEPT_DROP_REQUEST_TEACHER_SUCCESS = 'user.ACCEPT_DROP_REQUEST_TEACHER_SUCCESS';
export const ACCEPT_DROP_REQUEST_TEACHER_FAILURE = 'user.ACCEPT_DROP_REQUEST_TEACHER_FAILURE';

export const REJECT_DROP_REQUEST_TEACHER_REQUEST = 'user.REJECT_DROP_REQUEST_TEACHER_REQUEST';
export const REJECT_DROP_REQUEST_TEACHER_SUCCESS = 'user.REJECT_DROP_REQUEST_TEACHER_SUCCESS';
export const REJECT_DROP_REQUEST_TEACHER_FAILURE = 'user.REJECT_DROP_REQUEST_TEACHER_FAILURE';

export const DELETE_INVITE_TEACHER_REQUEST = 'user.DELETE_INVITE_TEACHER_REQUEST';
export const DELETE_INVITE_TEACHER_SUCCESS = 'user.DELETE_INVITE_TEACHER_SUCCESS';
export const DELETE_INVITE_TEACHER_FAILURE = 'user.DELETE_INVITE_TEACHER_FAILURE';

export const RESEND_INVITE_TEACHER_REQUEST = 'user.RESEND_INVITE_TEACHER_REQUEST';
export const RESEND_INVITE_TEACHER_SUCCESS = 'user.RESEND_INVITE_TEACHER_SUCCESS';
export const RESEND_INVITE_TEACHER_FAILURE = 'user.RESEND_INVITE_TEACHER_FAILURE';

export const RESET_PASSWORD_TEACHER_REQUEST = 'user.RESET_PASSWORD_TEACHER_REQUEST';
export const RESET_PASSWORD_TEACHER_SUCCESS = 'user.RESET_PASSWORD_TEACHER_SUCCESS';
export const RESET_PASSWORD_TEACHER_FAILURE = 'user.RESET_PASSWORD_TEACHER_FAILURE';
export const RESET_PASSWORD_TEACHER_CLEAR_SUCCESS = 'user.RESET_PASSWORD_TEACHER_CLEAR_SUCCESS';

export const UNASSIGN_SINGLE_CLASS_TEACHER_REQUEST = 'user.UNASSIGN_SINGLE_CLASS_TEACHER_REQUEST';
export const UNASSIGN_SINGLE_CLASS_TEACHER_SUCCESS = 'user.UNASSIGN_SINGLE_CLASS_TEACHER_SUCCESS';
export const UNASSIGN_SINGLE_CLASS_TEACHER_FAILURE = 'user.UNASSIGN_SINGLE_CLASS_TEACHER_FAILURE';

export const ASSIGN_SINGLE_CLASS_TEACHER_REQUEST = 'user.ASSIGN_SINGLE_CLASS_TEACHER_REQUEST';
export const ASSIGN_SINGLE_CLASS_TEACHER_SUCCESS = 'user.ASSIGN_SINGLE_CLASS_TEACHER_SUCCESS';
export const ASSIGN_SINGLE_CLASS_TEACHER_FAILURE = 'user.ASSIGN_SINGLE_CLASS_TEACHER_FAILURE';
export const CLEAR_ASSIGN_SINGLE_CLASS_TEACHER = 'user.CLEAR_ASSIGN_SINGLE_CLASS_TEACHER';

export const LIST_CLASSES_WRT_TEACHER_REQUEST = 'user.LIST_CLASSES_WRT_TEACHER_REQUEST';
export const LIST_CLASSES_WRT_TEACHER_SUCCESS = 'user.LIST_CLASSES_WRT_TEACHER_SUCCESS';
export const LIST_CLASSES_WRT_TEACHER_FAILURE = 'user.LIST_CLASSES_WRT_TEACHER_FAILURE';

export const EDIT_PROFILE_TEACHER_REQUEST = 'user.EDIT_PROFILE_TEACHER_REQUEST';
export const EDIT_PROFILE_TEACHER_SUCCESS = 'user.EDIT_PROFILE_TEACHER_SUCCESS';
export const EDIT_PROFILE_TEACHER_FAILURE = 'user.EDIT_PROFILE_TEACHER_FAILURE';
export const EDIT_PROFILE_TEACHER_CLEAR = 'user.EDIT_PROFILE_TEACHER_CLEAR';

export const IMPORT_TEACHERS_REQUEST = 'class.IMPORT_TEACHERS_REQUEST';
export const IMPORT_TEACHERS_SUCCESS = 'class.IMPORT_TEACHERS_SUCCESS';
export const IMPORT_TEACHERS_FAILURE = 'class.IMPORT_TEACHERS_FAILURE';
export const IMPORT_TEACHERS_CLEAR = 'class.IMPORT_TEACHERS_CLEAR';

export const SIGNUP_TEACHER_REQUEST = 'user.SIGNUP_TEACHER_REQUEST';
export const SIGNUP_TEACHER_SUCCESS = 'user.SIGNUP_TEACHER_SUCCESS';
export const SIGNUP_TEACHER_FAILURE = 'user.SIGNUP_TEACHER_FAILURE';
export const SIGNUP_TEACHER_CLEAR = 'user.SIGNUP_TEACHER_CLEAR';

export const ACCEPT_SIGNUP_INVITE_TEACHER_REQUEST = 'user.ACCEPT_SIGNUP_INVITE_TEACHER_REQUEST';
export const ACCEPT_SIGNUP_INVITE_TEACHER_SUCCESS = 'user.ACCEPT_SIGNUP_INVITE_TEACHER_SUCCESS';
export const ACCEPT_SIGNUP_INVITE_TEACHER_FAILURE = 'user.ACCEPT_SIGNUP_INVITE_TEACHER_FAILURE';

export const REJECT_SIGNUP_INVITE_TEACHER_REQUEST = 'user.REJECT_SIGNUP_INVITE_TEACHER_REQUEST';
export const REJECT_SIGNUP_INVITE_TEACHER_SUCCESS = 'user.REJECT_SIGNUP_INVITE_TEACHER_SUCCESS';
export const REJECT_SIGNUP_INVITE_TEACHER_FAILURE = 'user.REJECT_SIGNUP_INVITE_TEACHER_FAILURE';

////////////////////// Students ///////////////////////////
export const ADD_STUDENT_REQUEST = 'user.ADD_STUDENT_REQUEST';
export const ADD_STUDENT_SUCCESS = 'user.ADD_STUDENT_SUCCESS';
export const ADD_STUDENT_FAILURE = 'user.ADD_STUDENT_FAILURE';
export const ADD_STUDENT_CLEAR = 'user.ADD_STUDENT_CLEAR';

export const EDIT_STUDENT_REQUEST = 'user.EDIT_STUDENT_REQUEST';
export const EDIT_STUDENT_SUCCESS = 'user.EDIT_STUDENT_SUCCESS';
export const EDIT_STUDENT_FAILURE = 'user.EDIT_STUDENT_FAILURE';
export const EDIT_STUDENT_CLEAR = 'user.EDIT_STUDENT_CLEAR';

export const SINGLE_STUDENT_REQUEST = 'user.SINGLE_STUDENT_REQUEST';
export const SINGLE_STUDENT_SUCCESS = 'user.SINGLE_STUDENT_SUCCESS';
export const SINGLE_STUDENT_FAILURE = 'user.SINGLE_STUDENT_FAILURE';
export const UPDATE_SINGLE_STUDENT_LISTING_PARAMS = 'user.UPDATE_SINGLE_STUDENT_LISTING_PARAMS';

export const LIST_STUDENT_REQUEST = 'user.LIST_STUDENT_REQUEST';
export const LIST_STUDENT_SUCCESS = 'user.LIST_STUDENT_SUCCESS';
export const LIST_STUDENT_FAILURE = 'user.LIST_STUDENT_FAILURE';
export const UPDATE_STUDENT_LISTING_PARAMS = 'user.UPDATE_STUDENT_LISTING_PARAMS';

export const DELETE_STUDENT_REQUEST = 'user.DELETE_STUDENT_REQUEST';
export const DELETE_STUDENT_SUCCESS = 'user.DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_FAILURE = 'user.DELETE_STUDENT_FAILURE';

export const BLOCK_STUDENT_REQUEST = 'user.BLOCK_STUDENT_REQUEST';
export const BLOCK_STUDENT_SUCCESS = 'user.BLOCK_STUDENT_SUCCESS';
export const BLOCK_STUDENT_FAILURE = 'user.BLOCK_STUDENT_FAILURE';

export const UNBLOCK_STUDENT_REQUEST = 'user.UNBLOCK_STUDENT_REQUEST';
export const UNBLOCK_STUDENT_SUCCESS = 'user.UNBLOCK_STUDENT_SUCCESS';
export const UNBLOCK_STUDENT_FAILURE = 'user.UNBLOCK_STUDENT_FAILURE';

export const ACCEPT_DROP_REQUEST_STUDENT_REQUEST = 'user.ACCEPT_DROP_REQUEST_STUDENT_REQUEST';
export const ACCEPT_DROP_REQUEST_STUDENT_SUCCESS = 'user.ACCEPT_DROP_REQUEST_STUDENT_SUCCESS';
export const ACCEPT_DROP_REQUEST_STUDENT_FAILURE = 'user.ACCEPT_DROP_REQUEST_STUDENT_FAILURE';

export const REJECT_DROP_REQUEST_STUDENT_REQUEST = 'user.REJECT_DROP_REQUEST_STUDENT_REQUEST';
export const REJECT_DROP_REQUEST_STUDENT_SUCCESS = 'user.REJECT_DROP_REQUEST_STUDENT_SUCCESS';
export const REJECT_DROP_REQUEST_STUDENT_FAILURE = 'user.REJECT_DROP_REQUEST_STUDENT_FAILURE';

export const DELETE_INVITE_STUDENT_REQUEST = 'user.DELETE_INVITE_STUDENT_REQUEST';
export const DELETE_INVITE_STUDENT_SUCCESS = 'user.DELETE_INVITE_STUDENT_SUCCESS';
export const DELETE_INVITE_STUDENT_FAILURE = 'user.DELETE_INVITE_STUDENT_FAILURE';

export const RESEND_INVITE_STUDENT_REQUEST = 'user.RESEND_INVITE_STUDENT_REQUEST';
export const RESEND_INVITE_STUDENT_SUCCESS = 'user.RESEND_INVITE_STUDENT_SUCCESS';
export const RESEND_INVITE_STUDENT_FAILURE = 'user.RESEND_INVITE_STUDENT_FAILURE';

export const RESET_PASSWORD_STUDENT_REQUEST = 'user.RESET_PASSWORD_STUDENT_REQUEST';
export const RESET_PASSWORD_STUDENT_SUCCESS = 'user.RESET_PASSWORD_STUDENT_SUCCESS';
export const RESET_PASSWORD_STUDENT_FAILURE = 'user.RESET_PASSWORD_STUDENT_FAILURE';
export const RESET_PASSWORD_STUDENT_CLEAR_SUCCESS = 'user.RESET_PASSWORD_STUDENT_CLEAR_SUCCESS';

export const CHANGE_PASSWORD_STUDENT_REQUEST = 'user.CHANGE_PASSWORD_STUDENT_REQUEST';
export const CHANGE_PASSWORD_STUDENT_SUCCESS = 'user.CHANGE_PASSWORD_STUDENT_SUCCESS';
export const CHANGE_PASSWORD_STUDENT_FAILURE = 'user.CHANGE_PASSWORD_STUDENT_FAILURE';
export const CHANGE_PASSWORD_STUDENT_CLEAR_SUCCESS = 'user.CHANGE_PASSWORD_STUDENT_CLEAR_SUCCESS';

export const UNASSIGN_SINGLE_CLASS_STUDENT_REQUEST = 'user.UNASSIGN_SINGLE_CLASS_STUDENT_REQUEST';
export const UNASSIGN_SINGLE_CLASS_STUDENT_SUCCESS = 'user.UNASSIGN_SINGLE_CLASS_STUDENT_SUCCESS';
export const UNASSIGN_SINGLE_CLASS_STUDENT_FAILURE = 'user.UNASSIGN_SINGLE_CLASS_STUDENT_FAILURE';

export const ASSIGN_SINGLE_CLASS_STUDENT_REQUEST = 'user.ASSIGN_SINGLE_CLASS_STUDENT_REQUEST';
export const ASSIGN_SINGLE_CLASS_STUDENT_SUCCESS = 'user.ASSIGN_SINGLE_CLASS_STUDENT_SUCCESS';
export const ASSIGN_SINGLE_CLASS_STUDENT_FAILURE = 'user.ASSIGN_SINGLE_CLASS_STUDENT_FAILURE';
export const CLEAR_ASSIGN_SINGLE_CLASS_STUDENT = 'user.CLEAR_ASSIGN_SINGLE_CLASS_STUDENT';

export const LIST_CLASSES_WRT_STUDENT_REQUEST = 'user.LIST_CLASSES_WRT_STUDENT_REQUEST';
export const LIST_CLASSES_WRT_STUDENT_SUCCESS = 'user.LIST_CLASSES_WRT_STUDENT_SUCCESS';
export const LIST_CLASSES_WRT_STUDENT_FAILURE = 'user.LIST_CLASSES_WRT_STUDENT_FAILURE';

export const ACTIVE_SESSIONS_REQUEST = 'user.ACTIVE_SESSIONS_REQUEST';
export const ACTIVE_SESSIONS_SUCCESS = 'user.ACTIVE_SESSIONS_SUCCESS';
export const ACTIVE_SESSIONS_FAILURE = 'user.ACTIVE_SESSIONS_FAILURE';

export const MARK_ATTENDANCE_REQUEST = 'user.MARK_ATTENDANCE_REQUEST';
export const MARK_ATTENDANCE_SUCCESS = 'user.MARK_ATTENDANCE_SUCCESS';
export const MARK_ATTENDANCE_FAILURE = 'user.MARK_ATTENDANCE_FAILURE';
export const MARK_ATTENDANCE_CLEAR = 'user.MARK_ATTENDANCE_CLEAR';

export const EDIT_PROFILE_STUDENT_REQUEST = 'user.EDIT_PROFILE_STUDENT_REQUEST';
export const EDIT_PROFILE_STUDENT_SUCCESS = 'user.EDIT_PROFILE_STUDENT_SUCCESS';
export const EDIT_PROFILE_STUDENT_FAILURE = 'user.EDIT_PROFILE_STUDENT_FAILURE';
export const EDIT_PROFILE_STUDENT_CLEAR = 'user.EDIT_PROFILE_STUDENT_CLEAR';

export const IMPORT_STUDENTS_REQUEST = 'class.IMPORT_STUDENTS_REQUEST';
export const IMPORT_STUDENTS_SUCCESS = 'class.IMPORT_STUDENTS_SUCCESS';
export const IMPORT_STUDENTS_FAILURE = 'class.IMPORT_STUDENTS_FAILURE';
export const IMPORT_STUDENTS_CLEAR = 'class.IMPORT_STUDENTS_CLEAR';

export const SIGNUP_STUDENT_REQUEST = 'user.SIGNUP_STUDENT_REQUEST';
export const SIGNUP_STUDENT_SUCCESS = 'user.SIGNUP_STUDENT_SUCCESS';
export const SIGNUP_STUDENT_FAILURE = 'user.SIGNUP_STUDENT_FAILURE';
export const SIGNUP_STUDENT_CLEAR = 'user.SIGNUP_STUDENT_CLEAR';

export const ACCEPT_SIGNUP_INVITE_STUDENT_REQUEST = 'user.ACCEPT_SIGNUP_INVITE_STUDENT_REQUEST';
export const ACCEPT_SIGNUP_INVITE_STUDENT_SUCCESS = 'user.ACCEPT_SIGNUP_INVITE_STUDENT_SUCCESS';
export const ACCEPT_SIGNUP_INVITE_STUDENT_FAILURE = 'user.ACCEPT_SIGNUP_INVITE_STUDENT_FAILURE';

export const REJECT_SIGNUP_INVITE_STUDENT_REQUEST = 'user.REJECT_SIGNUP_INVITE_STUDENT_REQUEST';
export const REJECT_SIGNUP_INVITE_STUDENT_SUCCESS = 'user.REJECT_SIGNUP_INVITE_STUDENT_SUCCESS';
export const REJECT_SIGNUP_INVITE_STUDENT_FAILURE = 'user.REJECT_SIGNUP_INVITE_STUDENT_FAILURE';