import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import enums from "../../helpers/enums";
import config from "../../config";
import {checkIfUndefined} from "../../helpers/helper";
const {status} = enums;

const initialState = Immutable({
    isForgotPasswordRequested: false,
    forgotPasswordSuccessMsg: '',
    forgotPasswordErrorMsg: '',

    isResetPasswordRequested: false,
    resetPasswordSuccessMsg: '',
    resetPasswordErrorMsg: '',

    forgotPasswordNotMatchedErrorMsg: '',

    isChangePasswordRequested: false,
    changePasswordSuccessMsg: '',
    changePasswordErrorMsg: '',

    ////// Teachers //////
    isAddingTeacher: false,
    addTeacherSuccessMsg: '',
    addTeacherErrorMsg: '',

    isEditingTeacher: false,
    editTeacherSuccessMsg: '',
    editTeacherErrorMsg: '',

    isRequestedTeachers: false,
    teachers: [],
    totalCountsTeacher: {},
    isSuccessTeachers: false,
    isFailureTeachers: false,
    isRefreshTeachers: false,
    teacherSearchText: '',
    teacherFilterStatus: '',
    teacherSortByName: config.SORT_BY_NAME,
    teacherSortByOrder: config.SORT_BY_ORDER,
    teacherLimitNRows: config.LIMIT_N_ROWS,
    teacherActiveTab: '1',
    teacherTabs: [
        {title: "All", tab:"1", filter: "", countKey: "all"},
        {title: "Active", tab: "2", filter: status.ACTIVE, countKey: "active"},
        {title: "Pending", tab: "3", filter: status.PENDING, countKey: "pending"},
        {title: "Blocked", tab: "4", filter: status.BLOCKED, countKey: "blocked"},
    ],

    isSingleTeacherRequested: false,
    singleTeacher: {},
    isSuccessSingleTeacher: false,
    isFailureSingleTeacher: false,
    isRefreshSingleTeacher: false,
    searchTextClassesTeacher: '',
    sortByNameClassesTeacher: config.SORT_BY_NAME,
    sortByOrderClassesTeacher: config.SORT_BY_ORDER,
    limitNRowsClassesTeacher: config.LIMIT_N_ROWS,

    isClassesWrtTeachersRequested: false,
    classesWrtTeachers: [],
    isSuccessClassesWrtTeachers: false,
    isFailureClassesWrtTeachers: false,
    isRefreshClassesWrtTeachers: false,

    isDeleteTeacherRequested: false,
    deleteTeacherSuccessMsg: '',
    deleteTeacherErrorMsg: '',

    isBlockTeacherRequested: false,
    blockTeacherSuccessMsg: '',
    blockTeacherErrorMsg: '',

    isUnblockTeacherRequested: false,
    unblockTeacherSuccessMsg: '',
    unblockTeacherErrorMsg: '',

    isDeleteInviteTeacherRequested: false,
    deleteInviteTeacherSuccessMsg: '',
    deleteInviteTeacherErrorMsg: '',

    isAcceptDropRequestTeacherRequested: false,
    acceptDropRequestTeacherSuccessMsg: '',
    acceptDropRequestTeacherErrorMsg: '',

    isRejectDropRequestTeacherRequested: false,
    rejectDropRequestTeacherSuccessMsg: '',
    rejectDropRequestTeacherErrorMsg: '',

    isReInviteTeacherRequested: false,
    reInviteTeacherSuccessMsg: '',
    reInviteTeacherErrorMsg: '',

    isResetPasswordTeacherRequested: false,
    resetPasswordTeacherSuccess: false,
    resetPasswordTeacherSuccessMsg: '',
    resetPasswordTeacherErrorMsg: '',

    unassignSingleClassTeacherRequested: false,
    unassignSingleClassTeacherSuccessMsg: '',
    unassignSingleClassTeacherErrorMsg: '',

    assignSingleClassTeacherRequested: false,
    assignSingleClassTeacherSuccessMsg: '',
    assignSingleClassTeacherErrorMsg: '',

    isEditingProfileTeacher: false,
    editProfileTeacherSuccessMsg: '',
    editProfileTeacherErrorMsg: '',

    importTeachersRequested: false,
    importTeachersSuccessMsg: '',
    importTeachersErrorMsg: '',

    isSigningUpTeacher: false,
    signUpTeacherSuccessMsg: '',
    signUpTeacherErrorMsg: '',

    isAcceptSignUpInviteTeacherRequested: false,
    acceptSignUpInviteTeacherSuccessMsg: '',
    acceptSignUpInviteTeacherErrorMsg: '',

    isRejectSignUpInviteTeacherRequested: false,
    rejectSignUpInviteTeacherSuccessMsg: '',
    rejectSignUpInviteTeacherErrorMsg: '',

    ////// Students //////
    isAddingStudent: false,
    addStudentSuccessMsg: '',
    addStudentErrorMsg: '',

    isEditingStudent: false,
    editStudentSuccessMsg: '',
    editStudentErrorMsg: '',

    isRequestedStudents: false,
    students: [],
    totalCountsStudent: {},
    isSuccessStudents: false,
    isFailureStudents: false,
    isRefreshStudents: false,
    studentSearchText: '',
    studentFilterStatus: '',
    studentSortByName: config.SORT_BY_NAME,
    studentSortByOrder: config.SORT_BY_ORDER,
    studentLimitNRows: config.LIMIT_N_ROWS,
    studentActiveTab: '1',
    studentTabs: [
        {title: "All", tab:"1", filter: "", countKey: "all"},
        {title: "Active", tab: "2", filter: status.ACTIVE, countKey: "active"},
        {title: "Pending", tab: "3", filter: status.PENDING, countKey: "pending"},
        {title: "Blocked", tab: "4", filter: status.BLOCKED, countKey: "blocked"},
    ],

    isSingleStudentRequested: false,
    singleStudent: {},
    isSuccessSingleStudent: false,
    isFailureSingleStudent: false,
    isRefreshSingleStudent: false,
    searchTextClassesStudent: '',
    sortByNameClassesStudent: config.SORT_BY_NAME,
    sortByOrderClassesStudent: config.SORT_BY_ORDER,
    limitNRowsClassesStudent: config.LIMIT_N_ROWS,

    isClassesWrtStudentsRequested: false,
    classesWrtStudents: [],
    isSuccessClassesWrtStudents: false,
    isFailureClassesWrtStudents: false,
    isRefreshClassesWrtStudents: false,

    isDeleteStudentRequested: false,
    deleteStudentSuccessMsg: '',
    deleteStudentErrorMsg: '',

    isBlockStudentRequested: false,
    blockStudentSuccessMsg: '',
    blockStudentErrorMsg: '',

    isUnblockStudentRequested: false,
    unblockStudentSuccessMsg: '',
    unblockStudentErrorMsg: '',

    isDeleteInviteStudentRequested: false,
    deleteInviteStudentSuccessMsg: '',
    deleteInviteStudentErrorMsg: '',

    isAcceptDropRequestStudentRequested: false,
    acceptDropRequestStudentSuccessMsg: '',
    acceptDropRequestStudentErrorMsg: '',

    isRejectDropRequestStudentRequested: false,
    rejectDropRequestStudentSuccessMsg: '',
    rejectDropRequestStudentErrorMsg: '',

    isReInviteStudentRequested: false,
    reInviteStudentSuccessMsg: '',
    reInviteStudentErrorMsg: '',

    isResetPasswordStudentRequested: false,
    resetPasswordStudentSuccess: false,
    resetPasswordStudentSuccessMsg: '',
    resetPasswordStudentErrorMsg: '',

    isChangePasswordStudentRequested: false,
    changePasswordStudentSuccess: false,
    changePasswordStudentSuccessMsg: '',
    changePasswordStudentErrorMsg: '',

    unassignSingleClassStudentRequested: false,
    unassignSingleClassStudentSuccessMsg: '',
    unassignSingleClassStudentErrorMsg: '',

    assignSingleClassStudentRequested: false,
    assignSingleClassStudentSuccessMsg: '',
    assignSingleClassStudentErrorMsg: '',

    isActiveSessionsRequested: false,
    activeSessions: [],
    isSuccessActiveSessions: false,
    isFailureActiveSessions: false,

    isMarkAttendanceRequested: false,
    markAttendanceSuccessMsg: '',
    markAttendanceErrorMsg: '',

    isEditingProfileStudent: false,
    editProfileStudentSuccessMsg: '',
    editProfileStudentErrorMsg: '',

    importStudentsRequested: false,
    importStudentsSuccessMsg: '',
    importStudentsErrorMsg: '',

    isSigningUpStudent: false,
    signUpStudentSuccessMsg: '',
    signUpStudentErrorMsg: '',

    isAcceptSignUpInviteStudentRequested: false,
    acceptSignUpInviteStudentSuccessMsg: '',
    acceptSignUpInviteStudentErrorMsg: '',

    isRejectSignUpInviteStudentRequested: false,
    rejectSignUpInviteStudentSuccessMsg: '',
    rejectSignUpInviteStudentErrorMsg: '',
});

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        // Forgot password
        case types.FORGOT_PASSWORD_REQUEST:
            return state.merge({
                isForgotPasswordRequested: true,
                forgotPasswordSuccessMsg: '',
                forgotPasswordErrorMsg: '',
            });
        case types.FORGOT_PASSWORD_SUCCESS:
            return state.merge({
                isForgotPasswordRequested: false,
                forgotPasswordSuccessMsg: action.forgotPasswordSuccessMsg,
                forgotPasswordErrorMsg: '',
            });
        case types.FORGOT_PASSWORD_FAILURE:
            return state.merge({
                isForgotPasswordRequested: false,
                forgotPasswordSuccessMsg: '',
                forgotPasswordErrorMsg: action.forgotPasswordErrorMsg,
            });
        case types.FORGOT_PASSWORD_CLEAR:
            return state.merge({
                isForgotPasswordRequested: false,
                forgotPasswordSuccessMsg: '',
                forgotPasswordErrorMsg: '',
            });
        // Reset Password
        case types.RESET_PASSWORD_REQUEST:
            return state.merge({
                isResetPasswordRequested: true,
                resetPasswordSuccessMsg: '',
                resetPasswordErrorMsg: '',
            });
        case types.RESET_PASSWORD_SUCCESS:
            return state.merge({
                isResetPasswordRequested: false,
                resetPasswordSuccessMsg: action.resetPasswordSuccessMsg,
                resetPasswordErrorMsg: '',
            });
        case types.RESET_PASSWORD_FAILURE:
            return state.merge({
                isResetPasswordRequested: false,
                resetPasswordSuccessMsg: '',
                resetPasswordErrorMsg: action.resetPasswordErrorMsg,
            });
        case types.RESET_PASSWORD_CLEAR:
            return state.merge({
                isResetPasswordRequested: false,
                resetPasswordSuccessMsg: '',
                resetPasswordErrorMsg: '',
            });
        case types.FORGOT_PASSWORD_NOT_MATCHED:
            return state.merge({
                forgotPasswordNotMatchedErrorMsg: action.forgotPasswordNotMatchedErrorMsg
            });
        // Change Password
        case types.CHANGE_PASSWORD_REQUEST:
            return state.merge({
                isChangePasswordRequested: true,
                changePasswordSuccessMsg: '',
                changePasswordErrorMsg: '',
            });
        case types.CHANGE_PASSWORD_SUCCESS:
            return state.merge({
                isChangePasswordRequested: false,
                changePasswordSuccessMsg: action.changePasswordSuccessMsg,
                changePasswordErrorMsg: '',
            });
        case types.CHANGE_PASSWORD_FAILURE:
            return state.merge({
                isChangePasswordRequested: false,
                changePasswordSuccessMsg: '',
                changePasswordErrorMsg: action.changePasswordErrorMsg,
            });
        case types.CHANGE_PASSWORD_CLEAR:
            return state.merge({
                isChangePasswordRequested: false,
                changePasswordSuccessMsg: '',
                changePasswordErrorMsg: '',
            });

        // Add Teacher
        case types.ADD_TEACHER_REQUEST:
            return state.merge({
                isAddingTeacher: true,
                addTeacherSuccessMsg: '',
                addTeacherErrorMsg: '',
            });
        case types.ADD_TEACHER_SUCCESS:
            return state.merge({
                isAddingTeacher: false,
                addTeacherSuccessMsg: action.addTeacherSuccessMsg,
                addTeacherErrorMsg: '',
            });
        case types.ADD_TEACHER_FAILURE:
            return state.merge({
                isAddingTeacher: false,
                addTeacherSuccessMsg: '',
                addTeacherErrorMsg: action.addTeacherErrorMsg,
            });
        case types.ADD_TEACHER_CLEAR:
            return state.merge({
                isAddingTeacher: false,
                addTeacherSuccessMsg: '',
                addTeacherErrorMsg: '',
            });

        // Edit Teacher
        case types.EDIT_TEACHER_REQUEST:
            return state.merge({
                isEditingTeacher: true,
                editTeacherSuccessMsg: '',
                editTeacherErrorMsg: '',
            });
        case types.EDIT_TEACHER_SUCCESS:
            return state.merge({
                isEditingTeacher: false,
                editTeacherSuccessMsg: action.editTeacherSuccessMsg,
                editTeacherErrorMsg: '',
            });
        case types.EDIT_TEACHER_FAILURE:
            return state.merge({
                isEditingTeacher: false,
                editTeacherSuccessMsg: '',
                editTeacherErrorMsg: action.editTeacherErrorMsg,
            });
        case types.EDIT_TEACHER_CLEAR:
            return state.merge({
                isEditingTeacher: false,
                editTeacherSuccessMsg: '',
                editTeacherErrorMsg: '',
            });

        // List Teachers
        case types.LIST_TEACHER_REQUEST:
            return state.merge({
                isRequestedTeachers: true,
                isSuccessTeachers: false,
                isFailureTeachers: false,
                isRefreshTeachers: false,
            });

        case types.LIST_TEACHER_SUCCESS:
            return state.merge({
                isRequestedTeachers: false,
                teachers: action.teachers,
                totalCountsTeacher: action.totalCountsTeacher,
                isSuccessTeachers: true,
                isFailureTeachers: false
            });

        case types.LIST_TEACHER_FAILURE:
            return state.merge({
                isRequestedTeachers: false,
                isSuccessTeachers: false,
                isFailureTeachers: true
            });

        case types.UPDATE_TEACHER_LISTING_PARAMS:
            return state.merge({
                teacherSearchText: checkIfUndefined(action.teacherSearchText) ? state.teacherSearchText : action.teacherSearchText,
                teacherActiveTab: checkIfUndefined(action.teacherActiveTab) ? state.teacherActiveTab : action.teacherActiveTab,
                teacherFilterStatus: checkIfUndefined(action.teacherFilterStatus) ? state.teacherFilterStatus : action.teacherFilterStatus,
                teacherSortByName: checkIfUndefined(action.teacherSortByName) ? state.teacherSortByName : action.teacherSortByName,
                teacherSortByOrder: checkIfUndefined(action.teacherSortByOrder) ? state.teacherSortByOrder : action.teacherSortByOrder,
                teacherLimitNRows: checkIfUndefined(action.teacherLimitNRows) ? state.teacherLimitNRows : action.teacherLimitNRows,
            });

        // Get Single Teacher
        case types.SINGLE_TEACHER_REQUEST:
            return state.merge({
                isSingleTeacherRequested: true,
                // singleTeacher: {},
                isSuccessSingleTeacher: false,
                isFailureSingleTeacher: false,
                isRefreshSingleTeacher: false,
            });

        case types.SINGLE_TEACHER_SUCCESS:
            return state.merge({
                isSingleTeacherRequested: false,
                singleTeacher: action.singleTeacher,
                isSuccessSingleTeacher: true,
                isFailureSingleTeacher: false
            });

        case types.SINGLE_TEACHER_FAILURE:
            return state.merge({
                isSingleTeacherRequested: false,
                isSuccessSingleTeacher: false,
                isFailureSingleTeacher: true
            });

        case types.UPDATE_SINGLE_TEACHER_LISTING_PARAMS:
            return state.merge({
                searchTextClassesTeacher: checkIfUndefined(action.searchTextClassesTeacher) ? state.searchTextClassesTeacher : action.searchTextClassesTeacher,
                sortByNameClassesTeacher: checkIfUndefined(action.sortByNameClassesTeacher) ? state.sortByNameClassesTeacher : action.sortByNameClassesTeacher,
                sortByOrderClassesTeacher: checkIfUndefined(action.sortByOrderClassesTeacher) ? state.sortByOrderClassesTeacher : action.sortByOrderClassesTeacher,
                limitNRowsClassesTeacher: checkIfUndefined(action.limitNRowsClassesTeacher) ? state.limitNRowsClassesTeacher : action.limitNRowsClassesTeacher,
            });

        // delete Teacher
        case types.DELETE_TEACHER_REQUEST:
            return state.merge({
                isDeleteTeacherRequested: true,
                deleteTeacherSuccessMsg: '',
                deleteTeacherErrorMsg: '',
            });
        case types.DELETE_TEACHER_SUCCESS:
            return state.merge({
                isDeleteTeacherRequested: false,
                deleteTeacherSuccessMsg: action.deleteTeacherSuccessMsg,
                deleteTeacherErrorMsg: '',
                isRefreshTeachers: true,
            });
        case types.DELETE_TEACHER_FAILURE:
            return state.merge({
                isDeleteTeacherRequested: false,
                deleteTeacherSuccessMsg: '',
                deleteTeacherErrorMsg: action.deleteTeacherErrorMsg,
            });

        // block Teacher
        case types.BLOCK_TEACHER_REQUEST:
            return state.merge({
                isBlockTeacherRequested: true,
                blockTeacherSuccessMsg: '',
                blockTeacherErrorMsg: '',
            });
        case types.BLOCK_TEACHER_SUCCESS:
            return state.merge({
                isBlockTeacherRequested: false,
                blockTeacherSuccessMsg: action.blockTeacherSuccessMsg,
                blockTeacherErrorMsg: '',
                isRefreshTeachers: true,
            });
        case types.BLOCK_TEACHER_FAILURE:
            return state.merge({
                isBlockTeacherRequested: false,
                blockTeacherSuccessMsg: '',
                blockTeacherErrorMsg: action.blockTeacherErrorMsg,
            });

        // unblock Teacher
        case types.UNBLOCK_TEACHER_REQUEST:
            return state.merge({
                isUnblockTeacherRequested: true,
                unblockTeacherSuccessMsg: '',
                unblockTeacherErrorMsg: '',
            });
        case types.UNBLOCK_TEACHER_SUCCESS:
            return state.merge({
                isUnblockTeacherRequested: false,
                unblockTeacherSuccessMsg: action.unblockTeacherSuccessMsg,
                unblockTeacherErrorMsg: '',
                isRefreshTeachers: true,
            });
        case types.UNBLOCK_TEACHER_FAILURE:
            return state.merge({
                isUnblockTeacherRequested: false,
                unblockTeacherSuccessMsg: '',
                unblockTeacherErrorMsg: action.unblockTeacherErrorMsg,
            });

        // accept drop request
        case types.ACCEPT_DROP_REQUEST_TEACHER_REQUEST:
            return state.merge({
                isAcceptDropRequestRequested: true,
                acceptDropRequestTeacherSuccessMsg: '',
                acceptDropRequestTeacherErrorMsg: '',
            });
        case types.ACCEPT_DROP_REQUEST_TEACHER_SUCCESS:
            return state.merge({
                isAcceptDropRequestRequested: false,
                acceptDropRequestTeacherSuccessMsg: action.acceptDropRequestTeacherSuccessMsg,
                acceptDropRequestTeacherErrorMsg: '',
                isRefreshTeachers: true,
            });
        case types.ACCEPT_DROP_REQUEST_TEACHER_FAILURE:
            return state.merge({
                isAcceptDropRequestRequested: false,
                acceptDropRequestTeacherSuccessMsg: '',
                acceptDropRequestTeacherErrorMsg: action.acceptDropRequestTeacherErrorMsg,
            });

        // reject drop request
        case types.REJECT_DROP_REQUEST_TEACHER_REQUEST:
            return state.merge({
                isRejectDropRequestRequested: true,
                rejectDropRequestSuccessMsg: '',
                rejectDropRequestErrorMsg: '',
            });
        case types.REJECT_DROP_REQUEST_TEACHER_SUCCESS:
            return state.merge({
                isRejectDropRequestRequested: false,
                rejectDropRequestSuccessMsg: action.rejectDropRequestSuccessMsg,
                rejectDropRequestErrorMsg: '',
                isRefreshTeachers: true,
            });
        case types.REJECT_DROP_REQUEST_TEACHER_FAILURE:
            return state.merge({
                isRejectDropRequestRequested: false,
                rejectDropRequestSuccessMsg: '',
                rejectDropRequestErrorMsg: action.rejectDropRequestErrorMsg,
            });

        // delete invite
        case types.DELETE_INVITE_TEACHER_REQUEST:
            return state.merge({
                isDeleteInviteTeacherRequested: true,
                deleteInviteTeacherSuccessMsg: '',
                deleteInviteTeacherErrorMsg: '',
            });
        case types.DELETE_INVITE_TEACHER_SUCCESS:
            return state.merge({
                isDeleteInviteTeacherRequested: false,
                deleteInviteTeacherSuccessMsg: action.deleteInviteTeacherSuccessMsg,
                deleteInviteTeacherErrorMsg: '',
                isRefreshTeachers: true,
            });
        case types.DELETE_INVITE_TEACHER_FAILURE:
            return state.merge({
                isDeleteInviteTeacherRequested: false,
                deleteInviteTeacherSuccessMsg: '',
                deleteInviteTeacherErrorMsg: action.deleteInviteTeacherErrorMsg,
            });

        // resend invite Teacher
        case types.RESEND_INVITE_TEACHER_REQUEST:
            return state.merge({
                isReInviteTeacherRequested: true,
                reInviteTeacherSuccessMsg: '',
                reInviteTeacherErrorMsg: '',
            });
        case types.RESEND_INVITE_TEACHER_SUCCESS:
            return state.merge({
                isReInviteTeacherRequested: false,
                reInviteTeacherSuccessMsg: action.reInviteTeacherSuccessMsg,
                reInviteTeacherErrorMsg: '',
                isRefreshTeachers: true,
            });
        case types.RESEND_INVITE_TEACHER_FAILURE:
            return state.merge({
                isReInviteTeacherRequested: false,
                reInviteTeacherSuccessMsg: '',
                reInviteTeacherErrorMsg: action.reInviteTeacherErrorMsg,
            });

        // reset password Teacher
        case types.RESET_PASSWORD_TEACHER_REQUEST:
            return state.merge({
                isResetPasswordTeacherRequested: true,
                resetPasswordTeacherSuccess: false,
                resetPasswordTeacherSuccessMsg: '',
                resetPasswordTeacherErrorMsg: '',
            });
        case types.RESET_PASSWORD_TEACHER_SUCCESS:
            return state.merge({
                isResetPasswordTeacherRequested: false,
                resetPasswordTeacherSuccess: true,
                resetPasswordTeacherSuccessMsg: action.resetPasswordTeacherSuccessMsg,
                resetPasswordTeacherErrorMsg: '',
                isRefreshTeachers: true,
            });
        case types.RESET_PASSWORD_TEACHER_FAILURE:
            return state.merge({
                isResetPasswordTeacherRequested: false,
                resetPasswordTeacherSuccess: false,
                resetPasswordTeacherSuccessMsg: '',
                resetPasswordTeacherErrorMsg: action.resetPasswordTeacherErrorMsg,
            });
        case types.RESET_PASSWORD_TEACHER_CLEAR_SUCCESS:
            return state.merge({
                resetPasswordTeacherSuccess: false,
            });

        // Unassign Class Teacher
        case types.UNASSIGN_SINGLE_CLASS_TEACHER_REQUEST:
            return state.merge({
                unassignSingleClassTeacherRequested: true,
                unassignSingleClassTeacherSuccessMsg: '',
                unassignSingleClassTeacherErrorMsg: '',
            });
        case types.UNASSIGN_SINGLE_CLASS_TEACHER_SUCCESS:
            return state.merge({
                unassignSingleClassTeacherRequested: false,
                unassignSingleClassTeacherSuccessMsg: action.unassignSingleClassTeacherSuccessMsg,
                unassignSingleClassTeacherErrorMsg: '',
                isRefreshSingleTeacher: true,
            });
        case types.UNASSIGN_SINGLE_CLASS_TEACHER_FAILURE:
            return state.merge({
                unassignSingleClassTeacherRequested: false,
                unassignSingleClassTeacherSuccessMsg: '',
                unassignSingleClassTeacherErrorMsg: action.unassignSingleClassTeacherErrorMsg,
            });

        // Assign Class Teacher
        case types.ASSIGN_SINGLE_CLASS_TEACHER_REQUEST:
            return state.merge({
                assignSingleClassTeacherRequested: true,
                assignSingleClassTeacherSuccessMsg: '',
                assignSingleClassTeacherErrorMsg: '',
            });
        case types.ASSIGN_SINGLE_CLASS_TEACHER_SUCCESS:
            return state.merge({
                assignSingleClassTeacherRequested: false,
                assignSingleClassTeacherSuccessMsg: action.assignSingleClassTeacherSuccessMsg,
                assignSingleClassTeacherErrorMsg: '',
                isRefreshSingleTeacher: true,
            });
        case types.ASSIGN_SINGLE_CLASS_TEACHER_FAILURE:
            return state.merge({
                assignSingleClassTeacherRequested: false,
                assignSingleClassTeacherSuccessMsg: '',
                assignSingleClassTeacherErrorMsg: action.assignSingleClassTeacherErrorMsg,
            });
        case types.CLEAR_ASSIGN_SINGLE_CLASS_TEACHER:
            return state.merge({
                assignSingleClassTeacherRequested: false,
                assignSingleClassTeacherSuccessMsg: '',
                assignSingleClassTeacherErrorMsg: '',
            });

        // List Teachers
        case types.LIST_CLASSES_WRT_TEACHER_REQUEST:
            return state.merge({
                isClassesWrtTeachersRequested: true,
                classesWrtTeachers: [],
                isSuccessClassesWrtTeachers: false,
                isFailureClassesWrtTeachers: false,
                isRefreshClassesWrtTeachers: false,
            });

        case types.LIST_CLASSES_WRT_TEACHER_SUCCESS:
            return state.merge({
                isClassesWrtTeachersRequested: false,
                classesWrtTeachers: action.classesWrtTeachers,
                isSuccessClassesWrtTeachers: true,
                isFailureClassesWrtTeachers: false
            });

        case types.LIST_CLASSES_WRT_TEACHER_FAILURE:
            return state.merge({
                isClassesWrtTeachersRequested: false,
                isSuccessClassesWrtTeachers: false,
                isFailureClassesWrtTeachers: true
            });

        // Edit Profile Teacher
        case types.EDIT_PROFILE_TEACHER_REQUEST:
            return state.merge({
                isEditingProfileTeacher: true,
                editProfileTeacherSuccessMsg: '',
                editProfileTeacherErrorMsg: '',
            });
        case types.EDIT_PROFILE_TEACHER_SUCCESS:
            return state.merge({
                isEditingProfileTeacher: false,
                editProfileTeacherSuccessMsg: action.editProfileTeacherSuccessMsg,
                editProfileTeacherErrorMsg: '',
            });
        case types.EDIT_PROFILE_TEACHER_FAILURE:
            return state.merge({
                isEditingProfileTeacher: false,
                editProfileTeacherSuccessMsg: '',
                editProfileTeacherErrorMsg: action.editProfileTeacherErrorMsg,
            });
        case types.EDIT_PROFILE_TEACHER_CLEAR:
            return state.merge({
                isEditingProfileTeacher: false,
                editProfileTeacherSuccessMsg: '',
                editProfileTeacherErrorMsg: '',
            });

        // import teachers
        case types.IMPORT_TEACHERS_REQUEST:
            return state.merge({
                importTeachersRequested: true,
                importTeachersSuccessMsg: '',
                importTeachersErrorMsg: '',
            });
        case types.IMPORT_TEACHERS_SUCCESS:
            return state.merge({
                importTeachersRequested: false,
                importTeachersSuccessMsg: action.importTeachersSuccessMsg,
                importTeachersErrorMsg: '',
            });
        case types.IMPORT_TEACHERS_FAILURE:
            return state.merge({
                importTeachersRequested: false,
                importTeachersSuccessMsg: '',
                importTeachersErrorMsg: action.importTeachersErrorMsg,
            });

        // Signup Teacher
        case types.SIGNUP_TEACHER_REQUEST:
            return state.merge({
                isSigningUpTeacher: true,
                signUpTeacherSuccessMsg: '',
                signUpTeacherErrorMsg: '',
            });
        case types.SIGNUP_TEACHER_SUCCESS:
            return state.merge({
                isSigningUpTeacher: false,
                signUpTeacherSuccessMsg: action.signUpTeacherSuccessMsg,
                signUpTeacherErrorMsg: '',
            });
        case types.SIGNUP_TEACHER_FAILURE:
            return state.merge({
                isSigningUpTeacher: false,
                signUpTeacherSuccessMsg: '',
                signUpTeacherErrorMsg: action.signUpTeacherErrorMsg,
            });
        case types.SIGNUP_TEACHER_CLEAR:
            return state.merge({
                isSigningUpTeacher: false,
                signUpTeacherSuccessMsg: '',
                signUpTeacherErrorMsg: '',
            });

        // accept signup invite
        case types.ACCEPT_SIGNUP_INVITE_TEACHER_REQUEST:
            return state.merge({
                isAcceptSignUpInviteTeacherRequested: true,
                acceptSignUpInviteTeacherSuccessMsg: '',
                acceptSignUpInviteTeacherErrorMsg: '',
            });
        case types.ACCEPT_SIGNUP_INVITE_TEACHER_SUCCESS:
            return state.merge({
                isAcceptSignUpInviteTeacherRequested: false,
                acceptSignUpInviteTeacherSuccessMsg: action.acceptSignUpInviteTeacherSuccessMsg,
                acceptSignUpInviteTeacherErrorMsg: '',
                isRefreshTeachers: true,
            });
        case types.ACCEPT_SIGNUP_INVITE_TEACHER_FAILURE:
            return state.merge({
                isAcceptSignUpInviteTeacherRequested: false,
                acceptSignUpInviteTeacherSuccessMsg: '',
                acceptSignUpInviteTeacherErrorMsg: action.acceptSignUpInviteTeacherErrorMsg,
            });

        // reject signup invite
        case types.REJECT_SIGNUP_INVITE_TEACHER_REQUEST:
            return state.merge({
                isRejectSignUpInviteRequested: true,
                rejectSignUpInviteSuccessMsg: '',
                rejectSignUpInviteErrorMsg: '',
            });
        case types.REJECT_SIGNUP_INVITE_TEACHER_SUCCESS:
            return state.merge({
                isRejectSignUpInviteRequested: false,
                rejectSignUpInviteSuccessMsg: action.rejectSignUpInviteSuccessMsg,
                rejectSignUpInviteErrorMsg: '',
                isRefreshTeachers: true,
            });
        case types.REJECT_SIGNUP_INVITE_TEACHER_FAILURE:
            return state.merge({
                isRejectSignUpInviteRequested: false,
                rejectSignUpInviteSuccessMsg: '',
                rejectSignUpInviteErrorMsg: action.rejectSignUpInviteErrorMsg,
            });

        ///////////////////////////////////////////////////////////////

        // Add Student
        case types.ADD_STUDENT_REQUEST:
            return state.merge({
                isAddingStudent: true,
                addStudentSuccessMsg: '',
                addStudentErrorMsg: '',
            });
        case types.ADD_STUDENT_SUCCESS:
            return state.merge({
                isAddingStudent: false,
                addStudentSuccessMsg: action.addStudentSuccessMsg,
                addStudentErrorMsg: '',
            });
        case types.ADD_STUDENT_FAILURE:
            return state.merge({
                isAddingStudent: false,
                addStudentSuccessMsg: '',
                addStudentErrorMsg: action.addStudentErrorMsg,
            });
        case types.ADD_STUDENT_CLEAR:
            return state.merge({
                isAddingStudent: false,
                addStudentSuccessMsg: '',
                addStudentErrorMsg: '',
            });

        // Edit Student
        case types.EDIT_STUDENT_REQUEST:
            return state.merge({
                isEditingStudent: true,
                editStudentSuccessMsg: '',
                editStudentErrorMsg: '',
            });
        case types.EDIT_STUDENT_SUCCESS:
            return state.merge({
                isEditingStudent: false,
                editStudentSuccessMsg: action.editStudentSuccessMsg,
                editStudentErrorMsg: '',
            });
        case types.EDIT_STUDENT_FAILURE:
            return state.merge({
                isEditingStudent: false,
                editStudentSuccessMsg: '',
                editStudentErrorMsg: action.editStudentErrorMsg,
            });
        case types.EDIT_STUDENT_CLEAR:
            return state.merge({
                isEditingStudent: false,
                editStudentSuccessMsg: '',
                editStudentErrorMsg: '',
            });

        // List Students
        case types.LIST_STUDENT_REQUEST:
            return state.merge({
                isRequestedStudents: true,
                isSuccessStudents: false,
                isFailureStudents: false,
                isRefreshStudents: false,
            });

        case types.LIST_STUDENT_SUCCESS:
            return state.merge({
                isRequestedStudents: false,
                students: action.students,
                totalCountsStudent: action.totalCountsStudent,
                isSuccessStudents: true,
                isFailureStudents: false
            });

        case types.LIST_STUDENT_FAILURE:
            return state.merge({
                isRequestedStudents: false,
                isSuccessStudents: false,
                isFailureStudents: true
            });

        case types.UPDATE_STUDENT_LISTING_PARAMS:
            return state.merge({
                studentSearchText: checkIfUndefined(action.studentSearchText) ? state.studentSearchText : action.studentSearchText,
                studentActiveTab: checkIfUndefined(action.studentActiveTab) ? state.studentActiveTab : action.studentActiveTab,
                studentFilterStatus: checkIfUndefined(action.studentFilterStatus) ? state.studentFilterStatus : action.studentFilterStatus,
                studentSortByName: checkIfUndefined(action.studentSortByName) ? state.studentSortByName : action.studentSortByName,
                studentSortByOrder: checkIfUndefined(action.studentSortByOrder) ? state.studentSortByOrder : action.studentSortByOrder,
                studentLimitNRows: checkIfUndefined(action.studentLimitNRows) ? state.studentLimitNRows : action.studentLimitNRows,
            });

        // Get Single Student
        case types.SINGLE_STUDENT_REQUEST:
            return state.merge({
                isSingleStudentRequested: true,
                // singleStudent: {},
                isSuccessSingleStudent: false,
                isFailureSingleStudent: false,
                isRefreshSingleStudent: false,
            });

        case types.SINGLE_STUDENT_SUCCESS:
            return state.merge({
                isSingleStudentRequested: false,
                singleStudent: action.singleStudent,
                isSuccessSingleStudent: true,
                isFailureSingleStudent: false
            });

        case types.SINGLE_STUDENT_FAILURE:
            return state.merge({
                isSingleStudentRequested: false,
                isSuccessSingleStudent: false,
                isFailureSingleStudent: true
            });

        case types.UPDATE_SINGLE_STUDENT_LISTING_PARAMS:
            return state.merge({
                searchTextClassesStudent: checkIfUndefined(action.searchTextClassesStudent) ? state.searchTextClassesStudent : action.searchTextClassesStudent,
                sortByNameClassesStudent: checkIfUndefined(action.sortByNameClassesStudent) ? state.sortByNameClassesStudent : action.sortByNameClassesStudent,
                sortByOrderClassesStudent: checkIfUndefined(action.sortByOrderClassesStudent) ? state.sortByOrderClassesStudent : action.sortByOrderClassesStudent,
                limitNRowsClassesStudent: checkIfUndefined(action.limitNRowsClassesStudent) ? state.limitNRowsClassesStudent : action.limitNRowsClassesStudent,
            });

        // delete Student
        case types.DELETE_STUDENT_REQUEST:
            return state.merge({
                isDeleteStudentRequested: true,
                deleteStudentSuccessMsg: '',
                deleteStudentErrorMsg: '',
            });
        case types.DELETE_STUDENT_SUCCESS:
            return state.merge({
                isDeleteStudentRequested: false,
                deleteStudentSuccessMsg: action.deleteStudentSuccessMsg,
                deleteStudentErrorMsg: '',
                isRefreshStudents: true,
            });
        case types.DELETE_STUDENT_FAILURE:
            return state.merge({
                isDeleteStudentRequested: false,
                deleteStudentSuccessMsg: '',
                deleteStudentErrorMsg: action.deleteStudentErrorMsg,
            });

        // block Student
        case types.BLOCK_STUDENT_REQUEST:
            return state.merge({
                isBlockStudentRequested: true,
                blockStudentSuccessMsg: '',
                blockStudentErrorMsg: '',
            });
        case types.BLOCK_STUDENT_SUCCESS:
            return state.merge({
                isBlockStudentRequested: false,
                blockStudentSuccessMsg: action.blockStudentSuccessMsg,
                blockStudentErrorMsg: '',
                isRefreshStudents: true,
            });
        case types.BLOCK_STUDENT_FAILURE:
            return state.merge({
                isBlockStudentRequested: false,
                blockStudentSuccessMsg: '',
                blockStudentErrorMsg: action.blockStudentErrorMsg,
            });

        // unblock Student
        case types.UNBLOCK_STUDENT_REQUEST:
            return state.merge({
                isUnblockStudentRequested: true,
                unblockStudentSuccessMsg: '',
                unblockStudentErrorMsg: '',
            });
        case types.UNBLOCK_STUDENT_SUCCESS:
            return state.merge({
                isUnblockStudentRequested: false,
                unblockStudentSuccessMsg: action.unblockStudentSuccessMsg,
                unblockStudentErrorMsg: '',
                isRefreshStudents: true,
            });
        case types.UNBLOCK_STUDENT_FAILURE:
            return state.merge({
                isUnblockStudentRequested: false,
                unblockStudentSuccessMsg: '',
                unblockStudentErrorMsg: action.unblockStudentErrorMsg,
            });

        // accept drop request
        case types.ACCEPT_DROP_REQUEST_STUDENT_REQUEST:
            return state.merge({
                isAcceptDropRequestStudentRequested: true,
                acceptDropRequestStudentSuccessMsg: '',
                acceptDropRequestStudentErrorMsg: '',
            });
        case types.ACCEPT_DROP_REQUEST_STUDENT_SUCCESS:
            return state.merge({
                isAcceptDropRequestStudentRequested: false,
                acceptDropRequestStudentSuccessMsg: action.acceptDropRequestStudentSuccessMsg,
                acceptDropRequestStudentErrorMsg: '',
                isRefreshStudents: true,
            });
        case types.ACCEPT_DROP_REQUEST_STUDENT_FAILURE:
            return state.merge({
                isAcceptDropRequestStudentRequested: false,
                acceptDropRequestStudentSuccessMsg: '',
                acceptDropRequestStudentErrorMsg: action.acceptDropRequestStudentErrorMsg,
            });

        // reject drop request
        case types.REJECT_DROP_REQUEST_STUDENT_REQUEST:
            return state.merge({
                isRejectDropRequestRequested: true,
                rejectDropRequestSuccessMsg: '',
                rejectDropRequestErrorMsg: '',
            });
        case types.REJECT_DROP_REQUEST_STUDENT_SUCCESS:
            return state.merge({
                isRejectDropRequestRequested: false,
                rejectDropRequestSuccessMsg: action.rejectDropRequestSuccessMsg,
                rejectDropRequestErrorMsg: '',
                isRefreshStudents: true,
            });
        case types.REJECT_DROP_REQUEST_STUDENT_FAILURE:
            return state.merge({
                isRejectDropRequestRequested: false,
                rejectDropRequestSuccessMsg: '',
                rejectDropRequestErrorMsg: action.rejectDropRequestErrorMsg,
            });

        // delete invite
        case types.DELETE_INVITE_STUDENT_REQUEST:
            return state.merge({
                isDeleteInviteStudentRequested: true,
                deleteInviteStudentSuccessMsg: '',
                deleteInviteStudentErrorMsg: '',
            });
        case types.DELETE_INVITE_STUDENT_SUCCESS:
            return state.merge({
                isDeleteInviteStudentRequested: false,
                deleteInviteStudentSuccessMsg: action.deleteInviteStudentSuccessMsg,
                deleteInviteStudentErrorMsg: '',
                isRefreshStudents: true,
            });
        case types.DELETE_INVITE_STUDENT_FAILURE:
            return state.merge({
                isDeleteInviteStudentRequested: false,
                deleteInviteStudentSuccessMsg: '',
                deleteInviteStudentErrorMsg: action.deleteInviteStudentErrorMsg,
            });

        // resend invite Student
        case types.RESEND_INVITE_STUDENT_REQUEST:
            return state.merge({
                isReInviteStudentRequested: true,
                reInviteStudentSuccessMsg: '',
                reInviteStudentErrorMsg: '',
            });
        case types.RESEND_INVITE_STUDENT_SUCCESS:
            return state.merge({
                isReInviteStudentRequested: false,
                reInviteStudentSuccessMsg: action.reInviteStudentSuccessMsg,
                reInviteStudentErrorMsg: '',
                isRefreshStudents: true,
            });
        case types.RESEND_INVITE_STUDENT_FAILURE:
            return state.merge({
                isReInviteStudentRequested: false,
                reInviteStudentSuccessMsg: '',
                reInviteStudentErrorMsg: action.reInviteStudentErrorMsg,
            });

        // reset password Student
        case types.RESET_PASSWORD_STUDENT_REQUEST:
            return state.merge({
                isResetPasswordStudentRequested: true,
                resetPasswordStudentSuccess: false,
                resetPasswordStudentSuccessMsg: '',
                resetPasswordStudentErrorMsg: '',
            });
        case types.RESET_PASSWORD_STUDENT_SUCCESS:
            return state.merge({
                isResetPasswordStudentRequested: false,
                resetPasswordStudentSuccess: true,
                resetPasswordStudentSuccessMsg: action.resetPasswordStudentSuccessMsg,
                resetPasswordStudentErrorMsg: '',
                isRefreshStudents: true,
            });
        case types.RESET_PASSWORD_STUDENT_FAILURE:
            return state.merge({
                isResetPasswordStudentRequested: false,
                resetPasswordStudentSuccess: false,
                resetPasswordStudentSuccessMsg: '',
                resetPasswordStudentErrorMsg: action.resetPasswordStudentErrorMsg,
            });
        case types.RESET_PASSWORD_STUDENT_CLEAR_SUCCESS:
            return state.merge({
                resetPasswordStudentSuccess: false,
            });

        // change password Student
        case types.CHANGE_PASSWORD_STUDENT_REQUEST:
            return state.merge({
                isChangePasswordStudentRequested: true,
                changePasswordStudentSuccess: false,
                changePasswordStudentSuccessMsg: '',
                changePasswordStudentErrorMsg: '',
            });
        case types.CHANGE_PASSWORD_STUDENT_SUCCESS:
            return state.merge({
                isChangePasswordStudentRequested: false,
                changePasswordStudentSuccess: true,
                changePasswordStudentSuccessMsg: action.changePasswordStudentSuccessMsg,
                changePasswordStudentErrorMsg: '',
                isRefreshStudents: true,
            });
        case types.CHANGE_PASSWORD_STUDENT_FAILURE:
            return state.merge({
                isChangePasswordStudentRequested: false,
                changePasswordStudentSuccess: false,
                changePasswordStudentSuccessMsg: '',
                changePasswordStudentErrorMsg: action.changePasswordStudentErrorMsg,
            });
        case types.CHANGE_PASSWORD_STUDENT_CLEAR_SUCCESS:
            return state.merge({
                changePasswordStudentSuccess: false,
            });

        // Unassign Class Student
        case types.UNASSIGN_SINGLE_CLASS_STUDENT_REQUEST:
            return state.merge({
                unassignSingleClassStudentRequested: true,
                unassignSingleClassStudentSuccessMsg: '',
                unassignSingleClassStudentErrorMsg: '',
            });
        case types.UNASSIGN_SINGLE_CLASS_STUDENT_SUCCESS:
            return state.merge({
                unassignSingleClassStudentRequested: false,
                unassignSingleClassStudentSuccessMsg: action.unassignSingleClassStudentSuccessMsg,
                unassignSingleClassStudentErrorMsg: '',
                isRefreshSingleStudent: true,
            });
        case types.UNASSIGN_SINGLE_CLASS_STUDENT_FAILURE:
            return state.merge({
                unassignSingleClassStudentRequested: false,
                unassignSingleClassStudentSuccessMsg: '',
                unassignSingleClassStudentErrorMsg: action.unassignSingleClassStudentErrorMsg,
            });

        // Assign Class Student
        case types.ASSIGN_SINGLE_CLASS_STUDENT_REQUEST:
            return state.merge({
                assignSingleClassStudentRequested: true,
                assignSingleClassStudentSuccessMsg: '',
                assignSingleClassStudentErrorMsg: '',
            });
        case types.ASSIGN_SINGLE_CLASS_STUDENT_SUCCESS:
            return state.merge({
                assignSingleClassStudentRequested: false,
                assignSingleClassStudentSuccessMsg: action.assignSingleClassStudentSuccessMsg,
                assignSingleClassStudentErrorMsg: '',
                isRefreshSingleStudent: true,
            });
        case types.ASSIGN_SINGLE_CLASS_STUDENT_FAILURE:
            return state.merge({
                assignSingleClassStudentRequested: false,
                assignSingleClassStudentSuccessMsg: '',
                assignSingleClassStudentErrorMsg: action.assignSingleClassStudentErrorMsg,
            });
        case types.CLEAR_ASSIGN_SINGLE_CLASS_STUDENT:
            return state.merge({
                assignSingleClassStudentRequested: false,
                assignSingleClassStudentSuccessMsg: '',
                assignSingleClassStudentErrorMsg: '',
            });

        // List Students
        case types.LIST_CLASSES_WRT_STUDENT_REQUEST:
            return state.merge({
                isClassesWrtStudentsRequested: true,
                classesWrtStudents: [],
                isSuccessClassesWrtStudents: false,
                isFailureClassesWrtStudents: false,
                isRefreshClassesWrtStudents: false,
            });

        case types.LIST_CLASSES_WRT_STUDENT_SUCCESS:
            return state.merge({
                isClassesWrtStudentsRequested: false,
                classesWrtStudents: action.classesWrtStudents,
                isSuccessClassesWrtStudents: true,
                isFailureClassesWrtStudents: false
            });

        case types.LIST_CLASSES_WRT_STUDENT_FAILURE:
            return state.merge({
                isClassesWrtStudentsRequested: false,
                isSuccessClassesWrtStudents: false,
                isFailureClassesWrtStudents: true
            });

        // Active Session List
        case types.ACTIVE_SESSIONS_REQUEST:
            return state.merge({
                isActiveSessionsRequested: true,
                isSuccessActiveSessions: false,
                isFailureActiveSessions: false,
            });
        case types.ACTIVE_SESSIONS_SUCCESS:
            return state.merge({
                isActiveSessionsRequested: false,
                activeSessions: action.activeSessions,
                isSuccessActiveSessions: true,
                isFailureActiveSessions: false,
            });
        case types.ACTIVE_SESSIONS_FAILURE:
            return state.merge({
                isActiveSessionsRequested: false,
                isSuccessActiveSessions: false,
                isFailureActiveSessions: true,
            });

        // Mark Attendance
        case types.MARK_ATTENDANCE_REQUEST:
            return state.merge({
                isMarkAttendanceRequested: true,
                markAttendanceSuccessMsg: '',
                markAttendanceErrorMsg: '',
            });
        case types.MARK_ATTENDANCE_SUCCESS:
            return state.merge({
                isMarkAttendanceRequested: false,
                markAttendanceSuccessMsg: action.markAttendanceSuccessMsg,
                markAttendanceErrorMsg: '',
            });
        case types.MARK_ATTENDANCE_FAILURE:
            return state.merge({
                isMarkAttendanceRequested: false,
                markAttendanceSuccessMsg: '',
                markAttendanceErrorMsg: action.markAttendanceErrorMsg,
            });
        case types.MARK_ATTENDANCE_CLEAR:
            return state.merge({
                isMarkAttendanceRequested: false,
                markAttendanceSuccessMsg: '',
                markAttendanceErrorMsg: '',
            });

        // Edit Profile Student
        case types.EDIT_PROFILE_STUDENT_REQUEST:
            return state.merge({
                isEditingProfileStudent: true,
                editProfileStudentSuccessMsg: '',
                editProfileStudentErrorMsg: '',
            });
        case types.EDIT_PROFILE_STUDENT_SUCCESS:
            return state.merge({
                isEditingProfileStudent: false,
                editProfileStudentSuccessMsg: action.editProfileStudentSuccessMsg,
                editProfileStudentErrorMsg: '',
            });
        case types.EDIT_PROFILE_STUDENT_FAILURE:
            return state.merge({
                isEditingProfileStudent: false,
                editProfileStudentSuccessMsg: '',
                editProfileStudentErrorMsg: action.editProfileStudentErrorMsg,
            });
        case types.EDIT_PROFILE_STUDENT_CLEAR:
            return state.merge({
                isEditingProfileStudent: false,
                editProfileStudentSuccessMsg: '',
                editProfileStudentErrorMsg: '',
            });

        // import students
        case types.IMPORT_STUDENTS_REQUEST:
            return state.merge({
                importStudentsRequested: true,
                importStudentsSuccessMsg: '',
                importStudentsErrorMsg: '',
            });
        case types.IMPORT_STUDENTS_SUCCESS:
            return state.merge({
                importStudentsRequested: false,
                importStudentsSuccessMsg: action.importStudentsSuccessMsg,
                importStudentsErrorMsg: '',
            });
        case types.IMPORT_STUDENTS_FAILURE:
            return state.merge({
                importStudentsRequested: false,
                importStudentsSuccessMsg: '',
                importStudentsErrorMsg: action.importStudentsErrorMsg,
            });

        // Signup Student
        case types.SIGNUP_STUDENT_REQUEST:
            return state.merge({
                isSigningUpStudent: true,
                signUpStudentSuccessMsg: '',
                signUpStudentErrorMsg: '',
            });
        case types.SIGNUP_STUDENT_SUCCESS:
            return state.merge({
                isSigningUpStudent: false,
                signUpStudentSuccessMsg: action.signUpStudentSuccessMsg,
                signUpStudentErrorMsg: '',
            });
        case types.SIGNUP_STUDENT_FAILURE:
            return state.merge({
                isSigningUpStudent: false,
                signUpStudentSuccessMsg: '',
                signUpStudentErrorMsg: action.signUpStudentErrorMsg,
            });
        case types.SIGNUP_STUDENT_CLEAR:
            return state.merge({
                isSigningUpStudent: false,
                signUpStudentSuccessMsg: '',
                signUpStudentErrorMsg: '',
            });

        // accept signup invite
        case types.ACCEPT_SIGNUP_INVITE_STUDENT_REQUEST:
            return state.merge({
                isAcceptSignUpInviteStudentRequested: true,
                acceptSignUpInviteStudentSuccessMsg: '',
                acceptSignUpInviteStudentErrorMsg: '',
            });
        case types.ACCEPT_SIGNUP_INVITE_STUDENT_SUCCESS:
            return state.merge({
                isAcceptSignUpInviteStudentRequested: false,
                acceptSignUpInviteStudentSuccessMsg: action.acceptSignUpInviteStudentSuccessMsg,
                acceptSignUpInviteStudentErrorMsg: '',
                isRefreshStudents: true,
            });
        case types.ACCEPT_SIGNUP_INVITE_STUDENT_FAILURE:
            return state.merge({
                isAcceptSignUpInviteStudentRequested: false,
                acceptSignUpInviteStudentSuccessMsg: '',
                acceptSignUpInviteStudentErrorMsg: action.acceptSignUpInviteStudentErrorMsg,
            });

        // reject signup invite
        case types.REJECT_SIGNUP_INVITE_STUDENT_REQUEST:
            return state.merge({
                isRejectSignUpInviteRequested: true,
                rejectSignUpInviteSuccessMsg: '',
                rejectSignUpInviteErrorMsg: '',
            });
        case types.REJECT_SIGNUP_INVITE_STUDENT_SUCCESS:
            return state.merge({
                isRejectSignUpInviteRequested: false,
                rejectSignUpInviteSuccessMsg: action.rejectSignUpInviteSuccessMsg,
                rejectSignUpInviteErrorMsg: '',
                isRefreshStudents: true,
            });
        case types.REJECT_SIGNUP_INVITE_STUDENT_FAILURE:
            return state.merge({
                isRejectSignUpInviteRequested: false,
                rejectSignUpInviteSuccessMsg: '',
                rejectSignUpInviteErrorMsg: action.rejectSignUpInviteErrorMsg,
            });

        default:
            return state;
    }
}