import appReducer from './app/reducer';
import authReducer from './auth/reducer';
import instituteReducer from './institute/reducer';
import userReducer from './user/reducer';
import classReducer from './class/reducer';
import {combineReducers} from "redux";

export default combineReducers({
    app: appReducer,
    auth: authReducer,
    institute: instituteReducer,
    user: userReducer,
    class: classReducer,
});