import ReactGA from 'react-ga';
import {getDeviceType, createHash, getBaseUrl} from '../helpers/helper';
import config from "../../src/config";

// export const analyticsTrackingId = 'UA-149122237-1'; // Acct: hamzakhan@clarybusinessmachines.com
 export const analyticsTrackingId = config.GOOGLE_ANALYTICS_ID;

export function initializeGA(){
    ReactGA.initialize(analyticsTrackingId);
    return;
}

export function registerEvent(category, action, label, value, domain, platform, institute, user, dim5, dim6, nextUserEmail){

    if (config.GOOGLE_ANALYTICS === 'ON'){
        let deviceType = getDeviceType();
        if (!label){
            label = '-';
        }
        if (!domain) {
            domain = '-';
        }
        if (!institute) {
            institute = '-';
        }
        if (!dim5) {
            dim5 = '-';
        }
        if (!dim6) {
            dim6 = '-';
        }

        let userEmailHash = localStorage.getItem('userEmailHash');
        if (!userEmailHash) {
            userEmailHash = createHash(user);
            if (!userEmailHash) {
                userEmailHash = '-';
            }
        }

        nextUserEmail = createHash(nextUserEmail);
        if (!nextUserEmail) {
            nextUserEmail = '-';
        }

        ReactGA.event({
            category: category,
            action: action,
            label: label,
            value: value,
            'dimension1': domain,
            'dimension2': deviceType,
            'dimension3': institute,
            'dimension4': userEmailHash,
            'dimension5': dim5,
            'dimension6': dim6,
            'dimension7': userEmailHash,
            'dimension8': nextUserEmail
        });
    }
    return;
}

export function SendUserActiveEvent(){
    if (config.GOOGLE_ANALYTICS === 'ON'){
        let deviceType = getDeviceType();
        let userEmailHash = localStorage.getItem('userEmailHash');
        if (userEmailHash && userEmailHash.length > 0) {
            ReactGA.event({
                category: 'User',
                action: 'Active',
                label: '',
                value: 1,
                'dimension1': window.location.hostname,
                'dimension2': deviceType,
                'dimension3': '-',
                'dimension4': userEmailHash,
                'dimension5': '-',
                'dimension6': '-',
                'dimension7': userEmailHash,
                'dimension8': '-'
            });
        }
    }
    return;
}

export function sendPageView(){
    ReactGA.ga('send', 'pageview', getBaseUrl(window.location.pathname));
}

