import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, DropdownItem} from 'reactstrap';

class FormModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle(e) {
        e.preventDefault();

        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        const {isAnchor, color, classes, modalContent, isDropDown, block} = this.props;
        let callToAction = '';
        if(isAnchor) {
            callToAction = (
                <a href="/" className={classes} color={color} onClick={this.toggle}>{this.props.children}</a>
            );
        } else if(isDropDown) {
            callToAction = (
                <DropdownItem className={classes} onClick={this.toggle}>{this.props.children}</DropdownItem>
            );
        } else {
            callToAction = (
                <Button color={color} block={block} className={classes} onClick={this.toggle}>{this.props.children}</Button>
            );
        }
        return (
            <React.Fragment>
                {callToAction}
                <Modal autoFocus={false} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={`static`}>
                    <ModalHeader toggle={this.toggle}>&nbsp;</ModalHeader>
                    <ModalBody>
                        {modalContent}
                        <div className="hidden" id="modalClose" onClick={this.toggle}>

                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormModal;