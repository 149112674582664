export const ROUTE_CHANGED = 'app.ROUTE_CHANGED';

export const SUPPORT_REQUEST = 'app.SUPPORT_REQUEST';
export const SUPPORT_SUCCESS = 'app.SUPPORT_SUCCESS';
export const SUPPORT_FAILURE = 'app.SUPPORT_FAILURE';
export const SUPPORT_CLEAR = 'app.SUPPORT_CLEAR';

export const CONTACT_ADMIN_REQUEST = 'app.CONTACT_ADMIN_REQUEST';
export const CONTACT_ADMIN_SUCCESS = 'app.CONTACT_ADMIN_SUCCESS';
export const CONTACT_ADMIN_FAILURE = 'app.CONTACT_ADMIN_FAILURE';
export const CONTACT_ADMIN_CLEAR = 'app.CONTACT_ADMIN_CLEAR';