import React from 'react';
import {
    Row, Col, Form
} from 'reactstrap';
import {contactAdmin, clearContactAdmin} from "../../store/app/actions";
import {connect} from "react-redux";
import MyForm from "../Common/MyForm";
import {CommonInput} from "../Common/MyFields";
import {CancelButton, LoaderButton} from "../Common/MyButtons";
import {
    clickCloseButton, validateIsEmpty
} from "../../helpers/helper";
import language from "../../helpers/language";

class ContactAdmin extends MyForm {
    componentWillMount() {
        this.setInitialState();
        this.props.clearContactAdmin();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.contactAdminSuccessMsg !== '') {
            this.resetState();
            clickCloseButton();
        }
    }

    setInitialState() {
        this.setState({
            fields: {
                message: '',
            },
            validationErrors: {
                message: '',
            },
            notRequiredFields: []
        });
    }

    submitFormUsingProps() {
        const {fields} = this.state;
        this.props.contactAdmin(fields);
    }

    customValidation(fieldName, value) {
        const {validationErrors} = this.state;
        switch (fieldName) {
            default:
                if(!validateIsEmpty(value)) {
                    validationErrors[fieldName] = '';
                }
                break;
        }
    }

    render() {
        const {fields, isValidationRun, isFormValid, validationErrors, isFormChanged} = this.state;
        const {isContactAdminRequested, canBrowserShowSuggestions} = this.props;
        return (
            <React.Fragment>
                <Row>
                    <Col className="text-center" sm="12">
                        <h1 className="text-uppercase mb-4">{language.TITLE_CONTACT_ADMIN}</h1>
                        <br />
                    </Col>
                    <Col className="text-center" sm={{size: 10, offset: 1}}>
                        <Form className="text-center" onSubmit={this.handleSubmit}
                              autoComplete={canBrowserShowSuggestions ? 'on' : 'off'}>
                            <div style={{minHeight: "214px"}}>
                                <CommonInput type="textarea" name="message" title="Write your message for the admin?" value={fields.message}
                                             error={validationErrors.message} onChange={this.handleChange} maxLength="500" autoFocus/>
                                <div className="text-right">
                                    <small className="text-light-grey">{fields.message.length}/500</small>
                                </div>
                            </div>

                            <br/>

                            <LoaderButton btnLabel="Send" isRequest={isContactAdminRequested}
                                          isFormValid={isFormValid}
                                          isValidationRun={isValidationRun} isFormChanged={isFormChanged}/>
                            <CancelButton btnLabel="Cancel" onClick={clickCloseButton} isFormChanged={isFormChanged}/>
                        </Form>
                        <br />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    contactAdmin,
    clearContactAdmin
};

function mapStateToProps(state) {
    return {
        isContactAdminRequested: state.app.isContactAdminRequested,
        contactAdminSuccessMsg: state.app.contactAdminSuccessMsg,
        contactAdminErrorMsg: state.app.contactAdminErrorMsg,
    };
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ContactAdmin);