import React, {Component} from 'react';
import {
    Row, Col, Button
} from 'reactstrap';
import {CancelButton} from "../../../Common/MyButtons";
import {clickCloseButton} from "../../../../helpers/helper";
import language from "../../../../helpers/language";
import {ListingLoader} from "../../../Common/MyListingCommonFunc";

class DownloadModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false
        };

        this.beforeDownloadReport = this.beforeDownloadReport.bind(this);
    }

    beforeDownloadReport(e, format) {
        e.preventDefault();
        this.setState({showLoader: true});
        this.props.downloadReport(format);
    }

    render() {
        const {showLoader} = this.state;
        return (
            <React.Fragment>
                {
                    showLoader
                    &&
                    (<ListingLoader className="download-loader" />)
                }

                <h1 className="text-uppercase text-center">{language.TITLE_DOWNLOAD}</h1>
                <hr className="my-1 mt-3"/>
                <Row>
                    <Col sm={{size: 10, offset: 1}}>
                        {/*<Row className="text-left">*/}
                            {/*<Col>*/}
                                {/*<small className="text-muted mr-2">CLASS</small>*/}
                                {/*Math*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                                {/*<small className="text-muted mr-2">SESSION</small>*/}
                                {/*ABC*/}
                            {/*</Col>*/}
                            {/*<Col>*/}
                                {/*<small className="text-muted mr-2">Date</small>*/}
                                {/*ABC*/}
                            {/*</Col>*/}
                        {/*</Row>*/}
                        <p className="font-family-lato text-muted font-0-9rem my-1">Please select the report format.</p>
                    </Col>
                </Row>
                <hr className="my-1 mb-3"/>

                <Row className="font-family-lato">
                    <Col className="text-center" sm={{size: 8, offset: 2}}>
                        <Button color="link" className="p-0 clearfix text-light-grey" block onClick={(e) => { this.beforeDownloadReport(e, 'PDF') }}>
                            <span className="float-left mb-0 mr-3">Download PDF</span>
                            <i className=" float-right fa fa-download"/>
                        </Button>

                        <hr className="my-1"/>

                        <Button color="link" className="p-0 clearfix text-light-grey" block onClick={(e) => { this.beforeDownloadReport(e, 'EXCEL') }}>
                            <span className="float-left mb-0 mr-3">Download Excel</span>
                            <i className=" float-right fa fa-download"/>
                        </Button>

                        <hr className="my-1"/>

                        <Button color="link" className="p-0 clearfix text-light-grey" block onClick={(e) => { this.beforeDownloadReport(e, 'CSV') }}>
                            <span className="float-left mb-0 mr-3">Download CSV</span>
                            <i className=" float-right fa fa-download"/>
                        </Button>

                        <hr className="my-1"/>
                    </Col>
                </Row>

                <br/>
                <div className="text-center">
                    <CancelButton btnLabel="Cancel" onClick={clickCloseButton}/>
                </div>
            </React.Fragment>
        );
    }
}

export default DownloadModal;