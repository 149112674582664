import React, {Component} from 'react';
import './footer.css';
import Anchor from '../../Common/Anchor';
import {connect} from "react-redux";
import icon_selfatten from '../../../assets/images/icon-selfatten.png';
import logo_full from '../../../assets/images/OneScreenAttend.png';
import language from "../../../helpers/language";


class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    !this.props.isFrontEnd && false
                    &&
                    (
                        <footer className="sa-footer">
                            &copy; {new Date().getFullYear()} All Rights Reserved.&nbsp;
                            <a href="/terms-and-conditions">Terms and Conditions</a>
                            &nbsp;and&nbsp;
                            <a href="/privacy-policy">Privacy Policy</a>
                        </footer>
                    )
                }
                {
                    !this.props.isLoggedIn
                    &&
                    (
                        <footer className="footer-section navbar-dark bg-dark text-white">
                            <div className="container whatsnew">
                                <div className="row pt-4 pb-4">
                                    <div className="col-lg-4">
                                        <Anchor classes="navbar-brand" href="/">
                                            <img src={logo_full} alt={language.TITLE_SELF_ATTENDANCE}/>
                                        </Anchor>
                                    </div>
                                    <div className="col-lg-4 text-center">
                                        <p className="m-0 mt-2">&copy; {new Date().getFullYear()} All Rights Reserved<br/>
                                            <a href="/terms-and-conditions" className="text-white">Terms and Conditions</a>&nbsp;and&nbsp;
                                            <a href="/privacy-policy" className="text-white">Privacy Policy</a>
                                        </p>
                                    </div>
                                    <div className="col-lg-4 text-right mt-2 social">
                                        <a href="https://www.facebook.com/Clary-Icon-242840525839430/" target="_blank"><i className="fa fa-1x fa-facebook"/></a>
                                        <a href="https://twitter.com/claryicon" target="_blank"><i className="fa fa-1x fa-twitter"/></a>
                                        <a href="https://www.youtube.com/channel/UCjbyF9jBh7Bt83AQ12sKW_w" target="_blank"><i className="fa fa-1x fa-youtube"/></a>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    )
                }
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        isFrontEnd: state.app.isFrontEnd,
        isLoggedIn: state.auth.isLoggedIn,
    };
}

export default connect(
    mapStateToProps
)(Footer);