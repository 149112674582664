export const ADD_CLASS_REQUEST = 'class.ADD_CLASS_REQUEST';
export const ADD_CLASS_SUCCESS = 'class.ADD_CLASS_SUCCESS';
export const ADD_CLASS_FAILURE = 'class.ADD_CLASS_FAILURE';
export const ADD_CLASS_CLEAR = 'class.ADD_CLASS_CLEAR';

export const EDIT_CLASS_REQUEST = 'class.EDIT_CLASS_REQUEST';
export const EDIT_CLASS_SUCCESS = 'class.EDIT_CLASS_SUCCESS';
export const EDIT_CLASS_FAILURE = 'class.EDIT_CLASS_FAILURE';
export const EDIT_CLASS_CLEAR = 'class.EDIT_CLASS_CLEAR';

export const LIST_CLASS_REQUEST = 'class.LIST_CLASS_REQUEST';
export const LIST_CLASS_SUCCESS = 'class.LIST_CLASS_SUCCESS';
export const LIST_CLASS_FAILURE = 'class.LIST_CLASS_FAILURE';
export const UPDATE_CLASS_LISTING_PARAMS = 'class.UPDATE_CLASS_LISTING_PARAMS';

export const SINGLE_CLASS_REQUEST = 'class.SINGLE_CLASS_REQUEST';
export const SINGLE_CLASS_SUCCESS = 'class.SINGLE_CLASS_SUCCESS';
export const SINGLE_CLASS_FAILURE = 'class.SINGLE_CLASS_FAILURE';
export const UPDATE_SINGLE_CLASS_LISTING_PARAMS = 'class.UPDATE_SINGLE_CLASS_LISTING_PARAMS';

export const DELETE_CLASS_REQUEST = 'class.DELETE_CLASS_REQUEST';
export const DELETE_CLASS_SUCCESS = 'class.DELETE_CLASS_SUCCESS';
export const DELETE_CLASS_FAILURE = 'class.DELETE_CLASS_FAILURE';

export const COMPLETED_CLASS_REQUEST = 'class.COMPLETED_CLASS_REQUEST';
export const COMPLETED_CLASS_SUCCESS = 'class.COMPLETED_CLASS_SUCCESS';
export const COMPLETED_CLASS_FAILURE = 'class.COMPLETED_CLASS_FAILURE';

export const ACCEPT_INVITE_CLASS_REQUEST = 'class.ACCEPT_INVITE_CLASS_REQUEST';
export const ACCEPT_INVITE_CLASS_SUCCESS = 'class.ACCEPT_INVITE_CLASS_SUCCESS';
export const ACCEPT_INVITE_CLASS_FAILURE = 'class.ACCEPT_INVITE_CLASS_FAILURE';

export const REJECT_INVITE_CLASS_REQUEST = 'class.REJECT_INVITE_CLASS_REQUEST';
export const REJECT_INVITE_CLASS_SUCCESS = 'class.REJECT_INVITE_CLASS_SUCCESS';
export const REJECT_INVITE_CLASS_FAILURE = 'class.REJECT_INVITE_CLASS_FAILURE';

export const ACCEPT_ENROLL_INVITE_CLASS_REQUEST = 'class.ACCEPT_ENROLL_INVITE_CLASS_REQUEST';
export const ACCEPT_ENROLL_INVITE_CLASS_SUCCESS = 'class.ACCEPT_ENROLL_INVITE_CLASS_SUCCESS';
export const ACCEPT_ENROLL_INVITE_CLASS_FAILURE = 'class.ACCEPT_ENROLL_INVITE_CLASS_FAILURE';

export const REJECT_ENROLL_INVITE_CLASS_REQUEST = 'class.REJECT_ENROLL_INVITE_CLASS_REQUEST';
export const REJECT_ENROLL_INVITE_CLASS_SUCCESS = 'class.REJECT_ENROLL_INVITE_CLASS_SUCCESS';
export const REJECT_ENROLL_INVITE_CLASS_FAILURE = 'class.REJECT_ENROLL_INVITE_CLASS_FAILURE';

export const ACCEPT_DROP_CLASS_REQUEST = 'class.ACCEPT_DROP_CLASS_REQUEST';
export const ACCEPT_DROP_CLASS_SUCCESS = 'class.ACCEPT_DROP_CLASS_SUCCESS';
export const ACCEPT_DROP_CLASS_FAILURE = 'class.ACCEPT_DROP_CLASS_FAILURE';

export const REJECT_DROP_CLASS_REQUEST = 'class.REJECT_DROP_CLASS_REQUEST';
export const REJECT_DROP_CLASS_SUCCESS = 'class.REJECT_DROP_CLASS_SUCCESS';
export const REJECT_DROP_CLASS_FAILURE = 'class.REJECT_DROP_CLASS_FAILURE';

export const ACCEPT_ALL_DROP_CLASS_REQUEST = 'class.ACCEPT_ALL_DROP_CLASS_REQUEST';
export const ACCEPT_ALL_DROP_CLASS_SUCCESS = 'class.ACCEPT_ALL_DROP_CLASS_SUCCESS';
export const ACCEPT_ALL_DROP_CLASS_FAILURE = 'class.ACCEPT_ALL_DROP_CLASS_FAILURE';

export const REJECT_ALL_DROP_CLASS_REQUEST = 'class.REJECT_ALL_DROP_CLASS_REQUEST';
export const REJECT_ALL_DROP_CLASS_SUCCESS = 'class.REJECT_ALL_DROP_CLASS_SUCCESS';
export const REJECT_ALL_DROP_CLASS_FAILURE = 'class.REJECT_ALL_DROP_CLASS_FAILURE';

export const DELETE_INVITE_CLASS_REQUEST = 'class.DELETE_INVITE_CLASS_REQUEST';
export const DELETE_INVITE_CLASS_SUCCESS = 'class.DELETE_INVITE_CLASS_SUCCESS';
export const DELETE_INVITE_CLASS_FAILURE = 'class.DELETE_INVITE_CLASS_FAILURE';

export const RESEND_INVITE_CLASS_REQUEST = 'class.RESEND_INVITE_CLASS_REQUEST';
export const RESEND_INVITE_CLASS_SUCCESS = 'class.RESEND_INVITE_CLASS_SUCCESS';
export const RESEND_INVITE_CLASS_FAILURE = 'class.RESEND_INVITE_CLASS_FAILURE';

export const DROP_CLASS_REQUEST = 'class.DROP_CLASS_REQUEST';
export const DROP_CLASS_SUCCESS = 'class.DROP_CLASS_SUCCESS';
export const DROP_CLASS_FAILURE = 'class.DROP_CLASS_FAILURE';

export const RESEND_DROP_CLASS_REQUEST = 'class.RESEND_DROP_CLASS_REQUEST';
export const RESEND_DROP_CLASS_SUCCESS = 'class.RESEND_DROP_CLASS_SUCCESS';
export const RESEND_DROP_CLASS_FAILURE = 'class.RESEND_DROP_CLASS_FAILURE';

export const DELETE_DROP_CLASS_REQUEST = 'class.DELETE_DROP_CLASS_REQUEST';
export const DELETE_DROP_CLASS_SUCCESS = 'class.DELETE_DROP_CLASS_SUCCESS';
export const DELETE_DROP_CLASS_FAILURE = 'class.DELETE_DROP_CLASS_FAILURE';

export const DELETE_INVITE_SINGLE_CLASS_REQUEST = 'class.DELETE_INVITE_SINGLE_CLASS_REQUEST';
export const DELETE_INVITE_SINGLE_CLASS_SUCCESS = 'class.DELETE_INVITE_SINGLE_CLASS_SUCCESS';
export const DELETE_INVITE_SINGLE_CLASS_FAILURE = 'class.DELETE_INVITE_SINGLE_CLASS_FAILURE';

export const RESEND_INVITE_SINGLE_CLASS_REQUEST = 'class.RESEND_INVITE_SINGLE_CLASS_REQUEST';
export const RESEND_INVITE_SINGLE_CLASS_SUCCESS = 'class.RESEND_INVITE_SINGLE_CLASS_SUCCESS';
export const RESEND_INVITE_SINGLE_CLASS_FAILURE = 'class.RESEND_INVITE_SINGLE_CLASS_FAILURE';

export const IMPORT_STUDENTS_CLASS_REQUEST = 'class.IMPORT_STUDENTS_CLASS_REQUEST';
export const IMPORT_STUDENTS_CLASS_SUCCESS = 'class.IMPORT_STUDENTS_CLASS_SUCCESS';
export const IMPORT_STUDENTS_CLASS_FAILURE = 'class.IMPORT_STUDENTS_CLASS_FAILURE';
export const IMPORT_STUDENTS_CLASS_CLEAR = 'class.IMPORT_STUDENTS_CLASS_CLEAR';

export const IMPORT_USERS_CLASSES_REQUEST = 'class.IMPORT_USERS_CLASSES_REQUEST';
export const IMPORT_USERS_CLASSES_SUCCESS = 'class.IMPORT_USERS_CLASSES_SUCCESS';
export const IMPORT_USERS_CLASSES_FAILURE = 'class.IMPORT_USERS_CLASSES_FAILURE';
export const IMPORT_USERS_CLASSES_CLEAR = 'class.IMPORT_USERS_CLASSES_CLEAR';

export const ASSIGN_SINGLE_TEACHER_CLASS_REQUEST = 'class.ASSIGN_SINGLE_TEACHER_CLASS_REQUEST';
export const ASSIGN_SINGLE_TEACHER_CLASS_SUCCESS = 'class.ASSIGN_SINGLE_TEACHER_CLASS_SUCCESS';
export const ASSIGN_SINGLE_TEACHER_CLASS_FAILURE = 'class.ASSIGN_SINGLE_TEACHER_CLASS_FAILURE';
export const CLEAR_ASSIGN_SINGLE_TEACHER_CLASS = 'class.CLEAR_ASSIGN_SINGLE_TEACHER_CLASS';

export const ASSIGN_SINGLE_STUDENT_CLASS_REQUEST = 'class.ASSIGN_SINGLE_STUDENT_CLASS_REQUEST';
export const ASSIGN_SINGLE_STUDENT_CLASS_SUCCESS = 'class.ASSIGN_SINGLE_STUDENT_CLASS_SUCCESS';
export const ASSIGN_SINGLE_STUDENT_CLASS_FAILURE = 'class.ASSIGN_SINGLE_STUDENT_CLASS_FAILURE';
export const CLEAR_ASSIGN_SINGLE_STUDENT_CLASS = 'class.CLEAR_ASSIGN_SINGLE_STUDENT_CLASS';

export const LIST_TEACHERS_WRT_CLASSES_REQUEST = 'class.LIST_TEACHERS_WRT_CLASSES_REQUEST';
export const LIST_TEACHERS_WRT_CLASSES_SUCCESS = 'class.LIST_TEACHERS_WRT_CLASSES_SUCCESS';
export const LIST_TEACHERS_WRT_CLASSES_FAILURE = 'class.LIST_TEACHERS_WRT_CLASSES_FAILURE';

export const LIST_STUDENTS_WRT_CLASSES_REQUEST = 'class.LIST_STUDENTS_WRT_CLASSES_REQUEST';
export const LIST_STUDENTS_WRT_CLASSES_SUCCESS = 'class.LIST_STUDENTS_WRT_CLASSES_SUCCESS';
export const LIST_STUDENTS_WRT_CLASSES_FAILURE = 'class.LIST_STUDENTS_WRT_CLASSES_FAILURE';

export const START_CLASS_SESSION_REQUEST = 'class.START_CLASS_SESSION_REQUEST';
export const START_CLASS_SESSION_SUCCESS = 'class.START_CLASS_SESSION_SUCCESS';
export const START_CLASS_SESSION_FAILURE = 'class.START_CLASS_SESSION_FAILURE';
export const START_CLASS_SESSION_CLEAR = 'class.START_CLASS_SESSION_CLEAR';

export const REGENERATE_CLASS_SESSION_CODE_REQUEST = 'class.REGENERATE_CLASS_SESSION_CODE_REQUEST';
export const REGENERATE_CLASS_SESSION_CODE_SUCCESS = 'class.REGENERATE_CLASS_SESSION_CODE_SUCCESS';
export const REGENERATE_CLASS_SESSION_CODE_FAILURE = 'class.REGENERATE_CLASS_SESSION_CODE_FAILURE';

export const STOP_CLASS_SESSION_REQUEST = 'class.STOP_CLASS_SESSION_REQUEST';
export const STOP_CLASS_SESSION_SUCCESS = 'class.STOP_CLASS_SESSION_SUCCESS';
export const STOP_CLASS_SESSION_FAILURE = 'class.STOP_CLASS_SESSION_FAILURE';

export const DELETE_CLASS_SESSION_REQUEST = 'class.DELETE_CLASS_SESSION_REQUEST';
export const DELETE_CLASS_SESSION_SUCCESS = 'class.DELETE_CLASS_SESSION_SUCCESS';
export const DELETE_CLASS_SESSION_FAILURE = 'class.DELETE_CLASS_SESSION_FAILURE';

export const SINGLE_SESSION_REQUEST = 'class.SINGLE_SESSION_REQUEST';
export const SINGLE_SESSION_SUCCESS = 'class.SINGLE_SESSION_SUCCESS';
export const SINGLE_SESSION_FAILURE = 'class.SINGLE_SESSION_FAILURE';
export const UPDATE_SINGLE_SESSION_LISTING_PARAMS = 'class.UPDATE_SINGLE_SESSION_LISTING_PARAMS';

export const EDIT_ATTENDANCE_CLASS_REQUEST = 'class.EDIT_ATTENDANCE_CLASS_REQUEST';
export const EDIT_ATTENDANCE_CLASS_SUCCESS = 'class.EDIT_ATTENDANCE_CLASS_SUCCESS';
export const EDIT_ATTENDANCE_CLASS_FAILURE = 'class.EDIT_ATTENDANCE_CLASS_FAILURE';
export const EDIT_ATTENDANCE_CLASS_CLEAR = 'class.EDIT_ATTENDANCE_CLASS_CLEAR';

export const CORRECTION_CLASS_REQUEST = 'class.CORRECTION_CLASS_REQUEST';
export const CORRECTION_CLASS_SUCCESS = 'class.CORRECTION_CLASS_SUCCESS';
export const CORRECTION_CLASS_FAILURE = 'class.CORRECTION_CLASS_FAILURE';
export const CORRECTION_CLASS_CLEAR = 'class.CORRECTION_CLASS_CLEAR';

export const EDIT_CORRECTION_CLASS_REQUEST = 'class.EDIT_CORRECTION_CLASS_REQUEST';
export const EDIT_CORRECTION_CLASS_SUCCESS = 'class.EDIT_CORRECTION_CLASS_SUCCESS';
export const EDIT_CORRECTION_CLASS_FAILURE = 'class.EDIT_CORRECTION_CLASS_FAILURE';
export const EDIT_CORRECTION_CLASS_CLEAR = 'class.EDIT_CORRECTION_CLASS_CLEAR';

export const RESEND_CORRECTION_CLASS_REQUEST = 'class.RESEND_CORRECTION_CLASS_REQUEST';
export const RESEND_CORRECTION_CLASS_SUCCESS = 'class.RESEND_CORRECTION_CLASS_SUCCESS';
export const RESEND_CORRECTION_CLASS_FAILURE = 'class.RESEND_CORRECTION_CLASS_FAILURE';

export const DELETE_CORRECTION_CLASS_REQUEST = 'class.DELETE_CORRECTION_CLASS_REQUEST';
export const DELETE_CORRECTION_CLASS_SUCCESS = 'class.DELETE_CORRECTION_CLASS_SUCCESS';
export const DELETE_CORRECTION_CLASS_FAILURE = 'class.DELETE_CORRECTION_CLASS_FAILURE';

export const IMPORT_CLASSES_REQUEST = 'class.IMPORT_CLASSES_REQUEST';
export const IMPORT_CLASSES_SUCCESS = 'class.IMPORT_CLASSES_SUCCESS';
export const IMPORT_CLASSES_FAILURE = 'class.IMPORT_CLASSES_FAILURE';
export const IMPORT_CLASSES_CLEAR = 'class.IMPORT_CLASSES_CLEAR';