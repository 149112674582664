import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import config from "../../config";
import {checkIfUndefined} from "../../helpers/helper";
import enums from "../../helpers/enums";

const {status} = enums;

const initialState = Immutable({
    isAddingClass: false,
    addClassSuccessMsg: '',
    addClassErrorMsg: '',

    swapPublisherId: null,

    isEditingClass: false,
    editClassSuccessMsg: '',
    editClassErrorMsg: '',

    isRequestedClasses: false,
    classes: [],
    totalCountsClass: {},
    isSuccessClasses: false,
    isFailureClasses: false,
    isRefreshClasses: false,
    classSearchText: '',
    classFilterStatus: '',
    classSortByName: config.SORT_BY_NAME,
    classSortByOrder: config.SORT_BY_ORDER,
    classLimitNRows: config.LIMIT_N_ROWS,
    classActiveTab: '1',
    classTabs: [
        {title: "All", tab: "1", filter: "", countKey: "all"},
        {title: "Active", tab: "2", filter: status.ACTIVE, countKey: "active"},
        {title: "Completed", tab: "3", filter: status.COMPLETED, countKey: "completed"},
        {title: "Pending", tab: "4", filter: status.PENDING, countKey: "pending"},
    ],

    isSingleClassRequested: false,
    singleClass: {},
    isSuccessSingleClass: false,
    isFailureSingleClass: false,
    isRefreshSingleClass: false,
    searchTextStudents: '',
    sortByNameStudents: config.SORT_BY_NAME,
    sortByOrderStudents: config.SORT_BY_ORDER,
    limitNRowsStudents: config.LIMIT_N_ROWS,
    searchTextSessions: '',
    sortByNameSessions: config.SORT_BY_NAME,
    sortByOrderSessions: config.SORT_BY_ORDER,
    limitNRowsSessions: config.LIMIT_N_ROWS,
    singleClassActiveTab: '1',

    isTeachersWrtClassesRequested: false,
    teachersWrtClasses: [],
    isSuccessTeachersWrtClasses: false,
    isFailureTeachersWrtClasses: false,
    isRefreshTeachersWrtClasses: false,

    isStudentsWrtClassesRequested: false,
    studentsWrtClasses: [],
    isSuccessStudentsWrtClasses: false,
    isFailureStudentsWrtClasses: false,
    isRefreshStudentsWrtClasses: false,

    isDeleteClassRequested: false,
    deleteClassSuccessMsg: '',
    deleteClassErrorMsg: '',

    isCompletedClassRequested: false,
    completedClassSuccessMsg: '',
    completedClassErrorMsg: '',

    isDeleteInviteClassRequested: false,
    deleteInviteClassSuccessMsg: '',
    deleteInviteClassErrorMsg: '',

    isAcceptInviteClassRequested: false,
    acceptInviteClassSuccessMsg: '',
    acceptInviteClassErrorMsg: '',

    isRejectInviteClassRequested: false,
    rejectInviteClassSuccessMsg: '',
    rejectInviteClassErrorMsg: '',

    isReInviteClassRequested: false,
    reInviteClassSuccessMsg: '',
    reInviteClassErrorMsg: '',

    isAcceptEnrollInviteClassRequested: false,
    acceptEnrollInviteClassSuccessMsg: '',
    acceptEnrollInviteClassErrorMsg: '',

    isRejectEnrollInviteClassRequested: false,
    rejectEnrollInviteClassSuccessMsg: '',
    rejectEnrollInviteClassErrorMsg: '',

    isAcceptDropClassRequested: false,
    acceptDropClassSuccessMsg: '',
    acceptDropClassErrorMsg: '',

    isRejectDropClassRequested: false,
    rejectDropClassSuccessMsg: '',
    rejectDropClassErrorMsg: '',

    isAcceptAllDropClassRequested: false,
    acceptAllDropClassSuccessMsg: '',
    acceptAllDropClassErrorMsg: '',

    isRejectAllDropClassRequested: false,
    rejectAllDropClassSuccessMsg: '',
    rejectAllDropClassErrorMsg: '',

    isDropClassRequested: false,
    dropClassSuccessMsg: '',
    dropClassErrorMsg: '',

    isResendDropClassRequested: false,
    resendDropClassSuccessMsg: '',
    resendDropClassErrorMsg: '',

    isDeleteDropClassRequested: false,
    deleteDropClassSuccessMsg: '',
    deleteDropClassErrorMsg: '',

    isDeleteInviteSingleClassRequested: false,
    deleteInviteSingleClassSuccessMsg: '',
    deleteInviteSingleClassErrorMsg: '',

    isReInviteSingleClassRequested: false,
    reInviteSingleClassSuccessMsg: '',
    reInviteSingleClassErrorMsg: '',

    assignSingleTeacherClassRequested: false,
    assignSingleTeacherClassSuccessMsg: '',
    assignSingleTeacherClassErrorMsg: '',

    assignSingleStudentClassRequested: false,
    assignSingleStudentClassSuccessMsg: '',
    assignSingleStudentClassErrorMsg: '',

    importStudentsClassRequested: false,
    importStudentsClassSuccessMsg: '',
    importStudentsClassErrorMsg: '',

    importUsersClassesRequested: false,
    importUsersClassesSuccessMsg: '',
    importUsersClassesErrorMsg: '',

    isStartingClassSessionRequested: false,
    startClassSessionSuccessMsg: '',
    startClassSessionErrorMsg: '',

    isRegenerateClassSessionRequested: false,
    regenerateClassSessionSuccessMsg: '',
    regenerateClassSessionErrorMsg: '',

    isStopClassSessionRequested: false,
    stopClassSessionSuccessMsg: '',
    stopClassSessionErrorMsg: '',

    isDeleteClassSessionRequested: false,
    deleteClassSessionSuccessMsg: '',
    deleteClassSessionErrorMsg: '',

    isSingleSessionRequested: false,
    singleSession: {},
    isSuccessSingleSession: false,
    isFailureSingleSession: false,
    isRefreshSingleSession: false,
    searchTextSession: '',
    sortByNameSession: config.SORT_BY_NAME,
    sortByOrderSession: config.SORT_BY_ORDER,
    limitNRowsSession: config.LIMIT_N_ROWS,

    isEditingAttendanceClass: false,
    editAttendanceClassSuccessMsg: '',
    editAttendanceClassErrorMsg: '',

    isCorrectionClassRequested: false,
    correctionClassSuccessMsg: '',
    correctionClassErrorMsg: '',

    isEditCorrectionClassRequested: false,
    editCorrectionClassSuccessMsg: '',
    editCorrectionClassErrorMsg: '',

    isResendCorrectionClassRequested: false,
    resendCorrectionClassSuccessMsg: '',
    resendCorrectionClassErrorMsg: '',

    isDeleteCorrectionClassRequested: false,
    deleteCorrectionClassSuccessMsg: '',
    deleteCorrectionClassErrorMsg: '',

    importClassesRequested: false,
    importClassesSuccessMsg: '',
    importClassesErrorMsg: '',
});

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        // Add Class
        case types.ADD_CLASS_REQUEST:
            return state.merge({
                isAddingClass: true,
                addClassSuccessMsg: '',
                addClassErrorMsg: '',
            });
        case types.ADD_CLASS_SUCCESS:
            return state.merge({
                isAddingClass: false,
                addClassSuccessMsg: action.addClassSuccessMsg,
                addClassErrorMsg: '',
            });
        case types.ADD_CLASS_FAILURE:
            return state.merge({
                isAddingClass: false,
                addClassSuccessMsg: '',
                addClassErrorMsg: action.addClassErrorMsg,
            });
        case types.ADD_CLASS_CLEAR:
            return state.merge({
                isAddingClass: false,
                addClassSuccessMsg: '',
                addClassErrorMsg: '',
            });

        // Edit Class
        case types.EDIT_CLASS_REQUEST:
            return state.merge({
                isEditingClass: true,
                editClassSuccessMsg: '',
                editClassErrorMsg: '',
            });
        case types.EDIT_CLASS_SUCCESS:
            return state.merge({
                isEditingClass: false,
                editClassSuccessMsg: action.editClassSuccessMsg,
                editClassErrorMsg: '',
            });
        case types.EDIT_CLASS_FAILURE:
            return state.merge({
                isEditingClass: false,
                editClassSuccessMsg: '',
                editClassErrorMsg: action.editClassErrorMsg,
            });
        case types.EDIT_CLASS_CLEAR:
            return state.merge({
                isEditingClass: false,
                editClassSuccessMsg: '',
                editClassErrorMsg: '',
            });

        // List Classes
        case types.LIST_CLASS_REQUEST:
            return state.merge({
                isRequestedClasses: true,
                isSuccessClasses: false,
                isFailureClasses: false,
                isRefreshClasses: false,
            });

        case types.LIST_CLASS_SUCCESS:
            return state.merge({
                isRequestedClasses: false,
                classes: action.classes,
                totalCountsClass: action.totalCountsClass,
                isSuccessClasses: true,
                isFailureClasses: false
            });

        case types.LIST_CLASS_FAILURE:
            return state.merge({
                isRequestedClasses: false,
                isSuccessClasses: false,
                isFailureClasses: true
            });

        case types.UPDATE_CLASS_LISTING_PARAMS:
            return state.merge({
                classSearchText: checkIfUndefined(action.classSearchText) ? state.classSearchText : action.classSearchText,
                classActiveTab: checkIfUndefined(action.classActiveTab) ? state.classActiveTab : action.classActiveTab,
                classFilterStatus: checkIfUndefined(action.classFilterStatus) ? state.classFilterStatus : action.classFilterStatus,
                classSortByName: checkIfUndefined(action.classSortByName) ? state.classSortByName : action.classSortByName,
                classSortByOrder: checkIfUndefined(action.classSortByOrder) ? state.classSortByOrder : action.classSortByOrder,
                classLimitNRows: checkIfUndefined(action.classLimitNRows) ? state.classLimitNRows : action.classLimitNRows,
            });

        // Get Single Class
        case types.SINGLE_CLASS_REQUEST:
            return state.merge({
                isSingleClassRequested: true,
                // singleClass: {},
                isSuccessSingleClass: false,
                isFailureSingleClass: false,
                isRefreshSingleClass: false,
            });

        case types.SINGLE_CLASS_SUCCESS:
            return state.merge({
                isSingleClassRequested: false,
                singleClass: action.singleClass,
                isSuccessSingleClass: true,
                isFailureSingleClass: false
            });

        case types.SINGLE_CLASS_FAILURE:
            return state.merge({
                isSingleClassRequested: false,
                isSuccessSingleClass: false,
                isFailureSingleClass: true
            });

        case types.UPDATE_SINGLE_CLASS_LISTING_PARAMS:
            return state.merge({
                searchTextStudents: checkIfUndefined(action.searchTextStudents) ? state.searchTextStudents : action.searchTextStudents,
                sortByNameStudents: checkIfUndefined(action.sortByNameStudents) ? state.sortByNameStudents : action.sortByNameStudents,
                sortByOrderStudents: checkIfUndefined(action.sortByOrderStudents) ? state.sortByOrderStudents : action.sortByOrderStudents,
                limitNRowsStudents: checkIfUndefined(action.limitNRowsStudents) ? state.limitNRowsStudents : action.limitNRowsStudents,
                searchTextSessions: checkIfUndefined(action.searchTextSessions) ? state.searchTextSessions : action.searchTextSessions,
                sortByNameSessions: checkIfUndefined(action.sortByNameSessions) ? state.sortByNameSessions : action.sortByNameSessions,
                sortByOrderSessions: checkIfUndefined(action.sortByOrderSessions) ? state.sortByOrderSessions : action.sortByOrderSessions,
                limitNRowsSessions: checkIfUndefined(action.limitNRowsSessions) ? state.limitNRowsSessions : action.limitNRowsSessions,
                singleClassActiveTab: checkIfUndefined(action.singleClassActiveTab) ? state.singleClassActiveTab : action.singleClassActiveTab,
            });

        // delete Class
        case types.DELETE_CLASS_REQUEST:
            return state.merge({
                isDeleteClassRequested: true,
                deleteClassSuccessMsg: '',
                deleteClassErrorMsg: '',
            });
        case types.DELETE_CLASS_SUCCESS:
            return state.merge({
                isDeleteClassRequested: false,
                deleteClassSuccessMsg: action.deleteClassSuccessMsg,
                deleteClassErrorMsg: '',
                isRefreshClasses: true,
            });
        case types.DELETE_CLASS_FAILURE:
            return state.merge({
                isDeleteClassRequested: false,
                deleteClassSuccessMsg: '',
                deleteClassErrorMsg: action.deleteClassErrorMsg,
            });

        // Completed Class
        case types.COMPLETED_CLASS_REQUEST:
            return state.merge({
                isCompletedClassRequested: true,
                completedClassSuccessMsg: '',
                completedClassErrorMsg: '',
            });
        case types.COMPLETED_CLASS_SUCCESS:
            return state.merge({
                isCompletedClassRequested: false,
                completedClassSuccessMsg: action.completedClassSuccessMsg,
                completedClassErrorMsg: '',
                isRefreshClasses: true,
            });
        case types.COMPLETED_CLASS_FAILURE:
            return state.merge({
                isCompletedClassRequested: false,
                completedClassSuccessMsg: '',
                completedClassErrorMsg: action.completedClassErrorMsg,
            });

        // accept invite
        case types.ACCEPT_INVITE_CLASS_REQUEST:
            return state.merge({
                isAcceptInviteClassRequested: true,
                acceptInviteClassSuccessMsg: '',
                acceptInviteClassErrorMsg: '',
            });
        case types.ACCEPT_INVITE_CLASS_SUCCESS:
            return state.merge({
                isAcceptInviteClassRequested: false,
                acceptInviteClassSuccessMsg: action.acceptInviteClassSuccessMsg,
                acceptInviteClassErrorMsg: '',
                isRefreshClasses: true,
            });
        case types.ACCEPT_INVITE_CLASS_FAILURE:
            return state.merge({
                isAcceptInviteClassRequested: false,
                acceptInviteClassSuccessMsg: '',
                acceptInviteClassErrorMsg: action.acceptInviteClassErrorMsg,
            });

        // reject invite
        case types.REJECT_INVITE_CLASS_REQUEST:
            return state.merge({
                isRejectInviteClassRequested: true,
                rejectInviteClassSuccessMsg: '',
                rejectInviteClassErrorMsg: '',
            });
        case types.REJECT_INVITE_CLASS_SUCCESS:
            return state.merge({
                isRejectInviteClassRequested: false,
                rejectInviteClassSuccessMsg: action.rejectInviteClassSuccessMsg,
                rejectInviteClassErrorMsg: '',
                isRefreshClasses: true,
            });
        case types.REJECT_INVITE_CLASS_FAILURE:
            return state.merge({
                isRejectInviteClassRequested: false,
                rejectInviteClassSuccessMsg: '',
                rejectInviteClassErrorMsg: action.rejectInviteClassErrorMsg,
            });

        // delete invite
        case types.DELETE_INVITE_CLASS_REQUEST:
            return state.merge({
                isDeleteInviteClassRequested: true,
                deleteInviteClassSuccessMsg: '',
                deleteInviteClassErrorMsg: '',
            });
        case types.DELETE_INVITE_CLASS_SUCCESS:
            return state.merge({
                isDeleteInviteClassRequested: false,
                deleteInviteClassSuccessMsg: action.deleteInviteClassSuccessMsg,
                deleteInviteClassErrorMsg: '',
                isRefreshClasses: true,
            });
        case types.DELETE_INVITE_CLASS_FAILURE:
            return state.merge({
                isDeleteInviteClassRequested: false,
                deleteInviteClassSuccessMsg: '',
                deleteInviteClassErrorMsg: action.deleteInviteClassErrorMsg,
            });

        // resend invite
        case types.RESEND_INVITE_CLASS_REQUEST:
            return state.merge({
                isReInviteClassRequested: true,
                reInviteClassSuccessMsg: '',
                reInviteClassErrorMsg: '',
            });
        case types.RESEND_INVITE_CLASS_SUCCESS:
            return state.merge({
                isReInviteClassRequested: false,
                reInviteClassSuccessMsg: action.reInviteClassSuccessMsg,
                reInviteClassErrorMsg: '',
                isRefreshClasses: true,
            });
        case types.RESEND_INVITE_CLASS_FAILURE:
            return state.merge({
                isReInviteClassRequested: false,
                reInviteClassSuccessMsg: '',
                reInviteClassErrorMsg: action.reInviteClassErrorMsg,
            });

        // accept enroll invite
        case types.ACCEPT_ENROLL_INVITE_CLASS_REQUEST:
            return state.merge({
                isAcceptEnrollInviteClassRequested: true,
                acceptEnrollInviteClassSuccessMsg: '',
                acceptEnrollInviteClassErrorMsg: '',
            });
        case types.ACCEPT_ENROLL_INVITE_CLASS_SUCCESS:
            return state.merge({
                isAcceptEnrollInviteClassRequested: false,
                acceptEnrollInviteClassSuccessMsg: action.acceptEnrollInviteClassSuccessMsg,
                acceptEnrollInviteClassErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.ACCEPT_ENROLL_INVITE_CLASS_FAILURE:
            return state.merge({
                isAcceptEnrollInviteClassRequested: false,
                acceptEnrollInviteClassSuccessMsg: '',
                acceptEnrollInviteClassErrorMsg: action.acceptEnrollInviteClassErrorMsg,
            });

        // reject enroll invite
        case types.REJECT_ENROLL_INVITE_CLASS_REQUEST:
            return state.merge({
                isRejectEnrollInviteClassRequested: true,
                rejectEnrollInviteClassSuccessMsg: '',
                rejectEnrollInviteClassErrorMsg: '',
            });
        case types.REJECT_ENROLL_INVITE_CLASS_SUCCESS:
            return state.merge({
                isRejectEnrollInviteClassRequested: false,
                rejectEnrollInviteClassSuccessMsg: action.rejectEnrollInviteClassSuccessMsg,
                rejectEnrollInviteClassErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.REJECT_ENROLL_INVITE_CLASS_FAILURE:
            return state.merge({
                isRejectEnrollInviteClassRequested: false,
                rejectEnrollInviteClassSuccessMsg: '',
                rejectEnrollInviteClassErrorMsg: action.rejectEnrollInviteClassErrorMsg,
            });

        // accept drop class
        case types.ACCEPT_DROP_CLASS_REQUEST:
            return state.merge({
                isAcceptDropClassRequested: true,
                acceptDropClassSuccessMsg: '',
                acceptDropClassErrorMsg: '',
            });
        case types.ACCEPT_DROP_CLASS_SUCCESS:
            return state.merge({
                isAcceptDropClassRequested: false,
                acceptDropClassSuccessMsg: action.acceptDropClassSuccessMsg,
                acceptDropClassErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.ACCEPT_DROP_CLASS_FAILURE:
            return state.merge({
                isAcceptDropClassRequested: false,
                acceptDropClassSuccessMsg: '',
                acceptDropClassErrorMsg: action.acceptDropClassErrorMsg,
            });

        // reject drop class
        case types.REJECT_DROP_CLASS_REQUEST:
            return state.merge({
                isRejectDropClassRequested: true,
                rejectDropClassSuccessMsg: '',
                rejectDropClassErrorMsg: '',
            });
        case types.REJECT_DROP_CLASS_SUCCESS:
            return state.merge({
                isRejectDropClassRequested: false,
                rejectDropClassSuccessMsg: action.rejectDropClassSuccessMsg,
                rejectDropClassErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.REJECT_DROP_CLASS_FAILURE:
            return state.merge({
                isRejectDropClassRequested: false,
                rejectDropClassSuccessMsg: '',
                rejectDropClassErrorMsg: action.rejectDropClassErrorMsg,
            });

        // accept all drop class
        case types.ACCEPT_ALL_DROP_CLASS_REQUEST:
            return state.merge({
                isAcceptAllDropClassRequested: true,
                acceptAllDropClassSuccessMsg: '',
                acceptAllDropClassErrorMsg: '',
            });
        case types.ACCEPT_ALL_DROP_CLASS_SUCCESS:
            return state.merge({
                isAcceptAllDropClassRequested: false,
                acceptAllDropClassSuccessMsg: action.acceptAllDropClassSuccessMsg,
                acceptAllDropClassErrorMsg: '',
                isRefreshClasses: true,
            });
        case types.ACCEPT_ALL_DROP_CLASS_FAILURE:
            return state.merge({
                isAcceptAllDropClassRequested: false,
                acceptAllDropClassSuccessMsg: '',
                acceptAllDropClassErrorMsg: action.acceptAllDropClassErrorMsg,
            });

        // reject all drop class
        case types.REJECT_ALL_DROP_CLASS_REQUEST:
            return state.merge({
                isRejectAllDropClassRequested: true,
                rejectAllDropClassSuccessMsg: '',
                rejectAllDropClassErrorMsg: '',
            });
        case types.REJECT_ALL_DROP_CLASS_SUCCESS:
            return state.merge({
                isRejectAllDropClassRequested: false,
                rejectAllDropClassSuccessMsg: action.rejectAllDropClassSuccessMsg,
                rejectAllDropClassErrorMsg: '',
                isRefreshClasses: true,
            });
        case types.REJECT_ALL_DROP_CLASS_FAILURE:
            return state.merge({
                isRejectAllDropClassRequested: false,
                rejectAllDropClassSuccessMsg: '',
                rejectAllDropClassErrorMsg: action.rejectAllDropClassErrorMsg,
            });

        // drop class
        case types.DROP_CLASS_REQUEST:
            return state.merge({
                isDropClassRequested: true,
                dropClassSuccessMsg: '',
                dropClassErrorMsg: '',
            });
        case types.DROP_CLASS_SUCCESS:
            return state.merge({
                isDropClassRequested: false,
                dropClassSuccessMsg: action.dropClassSuccessMsg,
                dropClassErrorMsg: '',
                isRefreshClasses: true,
            });
        case types.DROP_CLASS_FAILURE:
            return state.merge({
                isDropClassRequested: false,
                dropClassSuccessMsg: '',
                dropClassErrorMsg: action.dropClassErrorMsg,
            });

        // resend drop class
        case types.RESEND_DROP_CLASS_REQUEST:
            return state.merge({
                isResendDropClassRequested: true,
                resendDropClassSuccessMsg: '',
                resendDropClassErrorMsg: '',
            });
        case types.RESEND_DROP_CLASS_SUCCESS:
            return state.merge({
                isResendDropClassRequested: false,
                resendDropClassSuccessMsg: action.resendDropClassSuccessMsg,
                resendDropClassErrorMsg: '',
                isRefreshClasses: true,
            });
        case types.RESEND_DROP_CLASS_FAILURE:
            return state.merge({
                isResendDropClassRequested: false,
                resendDropClassSuccessMsg: '',
                resendDropClassErrorMsg: action.resendDropClassErrorMsg,
            });

        // delete drop class
        case types.DELETE_DROP_CLASS_REQUEST:
            return state.merge({
                isDeleteDropClassRequested: true,
                deleteDropClassSuccessMsg: '',
                deleteDropClassErrorMsg: '',
            });
        case types.DELETE_DROP_CLASS_SUCCESS:
            return state.merge({
                isDeleteDropClassRequested: false,
                deleteDropClassSuccessMsg: action.deleteDropClassSuccessMsg,
                deleteDropClassErrorMsg: '',
                isRefreshClasses: true,
            });
        case types.DELETE_DROP_CLASS_FAILURE:
            return state.merge({
                isDeleteDropClassRequested: false,
                deleteDropClassSuccessMsg: '',
                deleteDropClassErrorMsg: action.deleteDropClassErrorMsg,
            });

        // delete single class invite
        case types.DELETE_INVITE_SINGLE_CLASS_REQUEST:
            return state.merge({
                isDeleteInviteSingleClassRequested: true,
                deleteInviteSingleClassSuccessMsg: '',
                deleteInviteSingleClassErrorMsg: '',
            });
        case types.DELETE_INVITE_SINGLE_CLASS_SUCCESS:
            return state.merge({
                isDeleteInviteSingleClassRequested: false,
                deleteInviteSingleClassSuccessMsg: action.deleteInviteSingleClassSuccessMsg,
                deleteInviteSingleClassErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.DELETE_INVITE_SINGLE_CLASS_FAILURE:
            return state.merge({
                isDeleteInviteSingleClassRequested: false,
                deleteInviteSingleClassSuccessMsg: '',
                deleteInviteSingleClassErrorMsg: action.deleteInviteSingleClassErrorMsg,
            });

        // resend single class invite
        case types.RESEND_INVITE_SINGLE_CLASS_REQUEST:
            return state.merge({
                isReInviteSingleClassRequested: true,
                reInviteSingleClassSuccessMsg: '',
                reInviteSingleClassErrorMsg: '',
            });
        case types.RESEND_INVITE_SINGLE_CLASS_SUCCESS:
            return state.merge({
                isReInviteSingleClassRequested: false,
                reInviteSingleClassSuccessMsg: action.reInviteSingleClassSuccessMsg,
                reInviteSingleClassErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.RESEND_INVITE_SINGLE_CLASS_FAILURE:
            return state.merge({
                isReInviteSingleClassRequested: false,
                reInviteSingleClassSuccessMsg: '',
                reInviteSingleClassErrorMsg: action.reInviteSingleClassErrorMsg,
            });

        // import students
        case types.IMPORT_STUDENTS_CLASS_REQUEST:
            return state.merge({
                importStudentsClassRequested: true,
                importStudentsClassSuccessMsg: '',
                importStudentsClassErrorMsg: '',
            });
        case types.IMPORT_USERS_CLASSES_REQUEST:
            return state.merge({
                importUsersClassesRequested: true,
                importUsersClassesSuccessMsg: '',
                importUsersClassesErrorMsg: '',
            });
        case types.IMPORT_STUDENTS_CLASS_SUCCESS:
            return state.merge({
                importStudentsClassRequested: false,
                importStudentsClassSuccessMsg: action.importStudentsClassSuccessMsg,
                importStudentsClassErrorMsg: '',
            });
        case types.IMPORT_USERS_CLASSES_SUCCESS:
            return state.merge({
                importUsersClassesRequested: false,
                importUsersClassesSuccessMsg: action.importUsersClassesSuccessMsg,
                importUsersClassesErrorMsg: '',
            });
        case types.IMPORT_STUDENTS_CLASS_FAILURE:
            return state.merge({
                importStudentsClassRequested: false,
                importStudentsClassSuccessMsg: '',
                importStudentsClassErrorMsg: action.importStudentsClassErrorMsg,
            });
            case types.IMPORT_USERS_CLASSES_FAILURE:
            return state.merge({
                importUsersClassesRequested: false,
                importUsersClassesSuccessMsg: '',
                importUsersClassesErrorMsg: action.importUsersClassesErrorMsg,
            });

        // List Students Wrt Classes
        case types.LIST_STUDENTS_WRT_CLASSES_REQUEST:
            return state.merge({
                isStudentsWrtClassesRequested: true,
                studentsWrtClasses: [],
                isSuccessStudentsWrtClasses: false,
                isFailureStudentsWrtClasses: false,
                isRefreshStudentsWrtClasses: false,
            });

        case types.LIST_STUDENTS_WRT_CLASSES_SUCCESS:
            return state.merge({
                isStudentsWrtClassesRequested: false,
                studentsWrtClasses: action.studentsWrtClasses,
                isSuccessStudentsWrtClasses: true,
                isFailureStudentsWrtClasses: false
            });

        case types.LIST_STUDENTS_WRT_CLASSES_FAILURE:
            return state.merge({
                isStudentsWrtClassesRequested: false,
                isSuccessStudentsWrtClasses: false,
                isFailureStudentsWrtClasses: true
            });

        // Assign Student Class
        case types.ASSIGN_SINGLE_STUDENT_CLASS_REQUEST:
            return state.merge({
                assignSingleStudentClassRequested: true,
                assignSingleStudentClassSuccessMsg: '',
                assignSingleStudentClassErrorMsg: '',
            });
        case types.ASSIGN_SINGLE_STUDENT_CLASS_SUCCESS:
            return state.merge({
                assignSingleStudentClassRequested: false,
                assignSingleStudentClassSuccessMsg: action.assignSingleStudentClassSuccessMsg,
                assignSingleStudentClassErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.ASSIGN_SINGLE_STUDENT_CLASS_FAILURE:
            return state.merge({
                assignSingleStudentClassRequested: false,
                assignSingleStudentClassSuccessMsg: '',
                assignSingleStudentClassErrorMsg: action.assignSingleStudentClassErrorMsg,
            });
        case types.CLEAR_ASSIGN_SINGLE_STUDENT_CLASS:
            return state.merge({
                assignSingleStudentClassRequested: false,
                assignSingleStudentClassSuccessMsg: '',
                assignSingleStudentClassErrorMsg: '',
            });

        // List Teachers Wrt Classes
        case types.LIST_TEACHERS_WRT_CLASSES_REQUEST:
            return state.merge({
                isTeachersWrtClassesRequested: true,
                teachersWrtClasses: [],
                isSuccessTeachersWrtClasses: false,
                isFailureTeachersWrtClasses: false,
                isRefreshTeachersWrtClasses: false,
            });

        case types.LIST_TEACHERS_WRT_CLASSES_SUCCESS:
            return state.merge({
                isTeachersWrtClassesRequested: false,
                teachersWrtClasses: action.teachersWrtClasses,
                isSuccessTeachersWrtClasses: true,
                isFailureTeachersWrtClasses: false
            });

        case types.LIST_TEACHERS_WRT_CLASSES_FAILURE:
            return state.merge({
                isTeachersWrtClassesRequested: false,
                isSuccessTeachersWrtClasses: false,
                isFailureTeachersWrtClasses: true
            });

        // Assign Teacher Class
        case types.ASSIGN_SINGLE_TEACHER_CLASS_REQUEST:
            return state.merge({
                assignSingleTeacherClassRequested: true,
                assignSingleTeacherClassSuccessMsg: '',
                assignSingleTeacherClassErrorMsg: '',
            });
        case types.ASSIGN_SINGLE_TEACHER_CLASS_SUCCESS:
            return state.merge({
                assignSingleTeacherClassRequested: false,
                assignSingleTeacherClassSuccessMsg: action.assignSingleTeacherClassSuccessMsg,
                assignSingleTeacherClassErrorMsg: '',
                isRefreshSingleClass: true,
                isRefreshClasses: true,
            });
        case types.ASSIGN_SINGLE_TEACHER_CLASS_FAILURE:
            return state.merge({
                assignSingleTeacherClassRequested: false,
                assignSingleTeacherClassSuccessMsg: '',
                assignSingleTeacherClassErrorMsg: action.assignSingleTeacherClassErrorMsg,
            });
        case types.CLEAR_ASSIGN_SINGLE_TEACHER_CLASS:
            return state.merge({
                assignSingleTeacherClassRequested: false,
                assignSingleTeacherClassSuccessMsg: '',
                assignSingleTeacherClassErrorMsg: '',
            });

        // Start Class Session
        case types.START_CLASS_SESSION_REQUEST:
            return state.merge({
                isStartingClassSessionRequested: true,
                startClassSessionSuccessMsg: '',
                startClassSessionErrorMsg: '',
            });
        case types.START_CLASS_SESSION_SUCCESS:
            return state.merge({
                isStartingClassSessionRequested: false,
                startClassSessionSuccessMsg: action.startClassSessionSuccessMsg,
                startClassSessionErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.START_CLASS_SESSION_FAILURE:
            return state.merge({
                isStartingClassSessionRequested: false,
                startClassSessionSuccessMsg: '',
                startClassSessionErrorMsg: action.startClassSessionErrorMsg,
            });
        case types.START_CLASS_SESSION_CLEAR:
            return state.merge({
                isStartingClassSessionRequested: false,
                startClassSessionSuccessMsg: '',
                startClassSessionErrorMsg: '',
            });

        // Regenerate Class Session
        case types.REGENERATE_CLASS_SESSION_CODE_REQUEST:
            return state.merge({
                isRegenerateClassSessionRequested: true,
                regenerateClassSessionSuccessMsg: '',
                regenerateClassSessionErrorMsg: '',
            });
        case types.REGENERATE_CLASS_SESSION_CODE_SUCCESS:
            return state.merge({
                isRegenerateClassSessionRequested: false,
                regenerateClassSessionSuccessMsg: action.regenerateClassSessionSuccessMsg,
                regenerateClassSessionErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.REGENERATE_CLASS_SESSION_CODE_FAILURE:
            return state.merge({
                isRegenerateClassSessionRequested: false,
                regenerateClassSessionSuccessMsg: '',
                regenerateClassSessionErrorMsg: action.regenerateClassSessionErrorMsg,
            });

        // Stop Class Session
        case types.STOP_CLASS_SESSION_REQUEST:
            return state.merge({
                isStopClassSessionRequested: true,
                stopClassSessionSuccessMsg: '',
                stopClassSessionErrorMsg: '',
            });
        case types.STOP_CLASS_SESSION_SUCCESS:
            return state.merge({
                isStopClassSessionRequested: false,
                stopClassSessionSuccessMsg: action.stopClassSessionSuccessMsg,
                stopClassSessionErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.STOP_CLASS_SESSION_FAILURE:
            return state.merge({
                isStopClassSessionRequested: false,
                stopClassSessionSuccessMsg: '',
                stopClassSessionErrorMsg: action.stopClassSessionErrorMsg,
            });

        // Delete Class Session
        case types.DELETE_CLASS_SESSION_REQUEST:
            return state.merge({
                isDeleteClassSessionRequested: true,
                deleteClassSessionSuccessMsg: '',
                deleteClassSessionErrorMsg: '',
            });
        case types.DELETE_CLASS_SESSION_SUCCESS:
            return state.merge({
                isDeleteClassSessionRequested: false,
                deleteClassSessionSuccessMsg: action.deleteClassSessionSuccessMsg,
                deleteClassSessionErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.DELETE_CLASS_SESSION_FAILURE:
            return state.merge({
                isDeleteClassSessionRequested: false,
                deleteClassSessionSuccessMsg: '',
                deleteClassSessionErrorMsg: action.deleteClassSessionErrorMsg,
            });

        // Get Single Session
        case types.SINGLE_SESSION_REQUEST:
            return state.merge({
                isSingleSessionRequested: true,
                isSuccessSingleSession: false,
                isFailureSingleSession: false,
                isRefreshSingleSession: false,
            });

        case types.SINGLE_SESSION_SUCCESS:
            return state.merge({
                isSingleSessionRequested: false,
                singleSession: action.singleSession,
                isSuccessSingleSession: true,
                isFailureSingleSession: false
            });

        case types.SINGLE_SESSION_FAILURE:
            return state.merge({
                isSingleSessionRequested: false,
                isSuccessSingleSession: false,
                isFailureSingleSession: true
            });

        case types.UPDATE_SINGLE_SESSION_LISTING_PARAMS:
            return state.merge({
                searchTextSession: checkIfUndefined(action.searchTextSession) ? state.searchTextSession : action.searchTextSession,
                sortByNameSession: checkIfUndefined(action.sortByNameSession) ? state.sortByNameSession : action.sortByNameSession,
                sortByOrderSession: checkIfUndefined(action.sortByOrderSession) ? state.sortByOrderSession : action.sortByOrderSession,
                limitNRowsSession: checkIfUndefined(action.limitNRowsSession) ? state.limitNRowsSession : action.limitNRowsSession,
            });

        // Edit Attendance
        case types.EDIT_ATTENDANCE_CLASS_REQUEST:
            return state.merge({
                isEditingAttendanceClass: true,
                editAttendanceClassSuccessMsg: '',
                editAttendanceClassErrorMsg: '',
            });
        case types.EDIT_ATTENDANCE_CLASS_SUCCESS:
            return state.merge({
                isEditingAttendanceClass: false,
                editAttendanceClassSuccessMsg: action.editAttendanceClassSuccessMsg,
                editAttendanceClassErrorMsg: '',
            });
        case types.EDIT_ATTENDANCE_CLASS_FAILURE:
            return state.merge({
                isEditingAttendanceClass: false,
                editAttendanceClassSuccessMsg: '',
                editAttendanceClassErrorMsg: action.editAttendanceClassErrorMsg,
            });
        case types.EDIT_ATTENDANCE_CLASS_CLEAR:
            return state.merge({
                isEditingAttendanceClass: false,
                editAttendanceClassSuccessMsg: '',
                editAttendanceClassErrorMsg: '',
            });

        // correction attendance class
        case types.CORRECTION_CLASS_REQUEST:
            return state.merge({
                isCorrectionClassRequested: true,
                correctionClassSuccessMsg: '',
                correctionClassErrorMsg: '',
            });
        case types.CORRECTION_CLASS_SUCCESS:
            return state.merge({
                isCorrectionClassRequested: false,
                correctionClassSuccessMsg: action.correctionClassSuccessMsg,
                correctionClassErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.CORRECTION_CLASS_FAILURE:
            return state.merge({
                isCorrectionClassRequested: false,
                correctionClassSuccessMsg: '',
                correctionClassErrorMsg: action.correctionClassErrorMsg,
            });
        case types.CORRECTION_CLASS_CLEAR:
            return state.merge({
                isCorrectionClassRequested: false,
                correctionClassSuccessMsg: '',
                correctionClassErrorMsg: '',
            });

        // edit correction attendance class
        case types.EDIT_CORRECTION_CLASS_REQUEST:
            return state.merge({
                isEditCorrectionClassRequested: true,
                editCorrectionClassSuccessMsg: '',
                editCorrectionClassErrorMsg: '',
            });
        case types.EDIT_CORRECTION_CLASS_SUCCESS:
            return state.merge({
                isEditCorrectionClassRequested: false,
                editCorrectionClassSuccessMsg: action.editCorrectionClassSuccessMsg,
                editCorrectionClassErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.EDIT_CORRECTION_CLASS_FAILURE:
            return state.merge({
                isEditCorrectionClassRequested: false,
                editCorrectionClassSuccessMsg: '',
                editCorrectionClassErrorMsg: action.editCorrectionClassErrorMsg,
            });
        case types.EDIT_CORRECTION_CLASS_CLEAR:
            return state.merge({
                isCorrectionClassRequested: false,
                correctionClassSuccessMsg: '',
                correctionClassErrorMsg: '',
            });

        // resend correction attendance class
        case types.RESEND_CORRECTION_CLASS_REQUEST:
            return state.merge({
                isResendCorrectionClassRequested: true,
                resendCorrectionClassSuccessMsg: '',
                resendCorrectionClassErrorMsg: '',
            });
        case types.RESEND_CORRECTION_CLASS_SUCCESS:
            return state.merge({
                isResendCorrectionClassRequested: false,
                resendCorrectionClassSuccessMsg: action.resendCorrectionClassSuccessMsg,
                resendCorrectionClassErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.RESEND_CORRECTION_CLASS_FAILURE:
            return state.merge({
                isResendCorrectionClassRequested: false,
                resendCorrectionClassSuccessMsg: '',
                resendCorrectionClassErrorMsg: action.resendCorrectionClassErrorMsg,
            });

        // delete correction attendance class
        case types.DELETE_CORRECTION_CLASS_REQUEST:
            return state.merge({
                isDeleteCorrectionClassRequested: true,
                deleteCorrectionClassSuccessMsg: '',
                deleteCorrectionClassErrorMsg: '',
            });
        case types.DELETE_CORRECTION_CLASS_SUCCESS:
            return state.merge({
                isDeleteCorrectionClassRequested: false,
                deleteCorrectionClassSuccessMsg: action.deleteCorrectionClassSuccessMsg,
                deleteCorrectionClassErrorMsg: '',
                isRefreshSingleClass: true,
            });
        case types.DELETE_CORRECTION_CLASS_FAILURE:
            return state.merge({
                isDeleteCorrectionClassRequested: false,
                deleteCorrectionClassSuccessMsg: '',
                deleteCorrectionClassErrorMsg: action.deleteCorrectionClassErrorMsg,
            });

        // import classes
        case types.IMPORT_CLASSES_REQUEST:
            return state.merge({
                importClassesRequested: true,
                importClassesSuccessMsg: '',
                importClassesErrorMsg: '',
            });
        case types.IMPORT_CLASSES_SUCCESS:
            return state.merge({
                importClassesRequested: false,
                importClassesSuccessMsg: action.importClassesSuccessMsg,
                importClassesErrorMsg: '',
            });
        case types.IMPORT_CLASSES_FAILURE:
            return state.merge({
                importClassesRequested: false,
                importClassesSuccessMsg: '',
                importClassesErrorMsg: action.importClassesErrorMsg,
            });

        default:
            return state;
    }
}