import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, DropdownItem} from 'reactstrap';
import FormModal from "./FormModal";

class FormModalChild extends FormModal {
    render() {
        const {isAnchor, color, classes, modalContent, isDropDown, block} = this.props;
        let callToAction = '';
        if(isAnchor) {
            callToAction = (
                <a href="/" className={classes} color={color} onClick={this.toggle}>{this.props.children}</a>
            );
        } else if(isDropDown) {
            callToAction = (
                <DropdownItem className={classes} onClick={this.toggle}>{this.props.children}</DropdownItem>
            );
        } else {
            callToAction = (
                <Button color={color} block={block} className={classes} onClick={this.toggle}>{this.props.children}</Button>
            );
        }
        return (
            <React.Fragment>
                {callToAction}
                <Modal autoFocus={false} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={`static`}>
                    <ModalHeader toggle={this.toggle}>&nbsp;</ModalHeader>
                    <ModalBody>
                        {modalContent}
                        <div className="hidden" id="childModalClose" onClick={this.toggle}>

                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default FormModalChild;