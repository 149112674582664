import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {Route} from 'react-router-dom';
import language from "../../helpers/language";
import {getRoleIdFromUser} from "../../helpers/helper";

class MyRoute extends Component {
    componentDidMount() {
        MyRoute.updateTitle(this.props.title);

    }
    componentDidUpdate() {
        MyRoute.updateTitle(this.props.title);
    }

    static updateTitle(title) {
        if(title !== undefined) {
            document.title = title;
        } else {
            document.title = language.TITLE_SELF_ATTENDANCE;
        }
    }

    render() {
        let {isLoggedIn, isLoggingIn, isTokenValidating} = this.props;
        let {path, component, authorization, allowed, user} = this.props;
        if(authorization) {
            return (
                <React.Fragment>
                    {
                        (isLoggedIn && !allowed.includes(getRoleIdFromUser(user)))
                        &&
                        (
                            <Redirect to={{
                                pathname: '/'
                            }} />
                        )
                    }
                    {
                        (isLoggedIn && allowed.includes(getRoleIdFromUser(user)))
                        &&
                        (
                            <Route path={path} component={component}/>
                        )
                    }
                    {
                        (isTokenValidating && isLoggedIn && !allowed.includes(getRoleIdFromUser(user)))
                        &&
                        (
                            <Redirect to={{
                                pathname: '*'
                            }} />
                        )
                    }
                    {
                        (!isLoggedIn && !isLoggingIn && !isTokenValidating)
                        &&
                        (
                            <Redirect to={{
                                pathname: '/'
                            }} />
                        )
                    }
                </React.Fragment>
            );
        } else {
            return (
                <Route path={path} component={component}/>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        isLoggedIn: state.auth.isLoggedIn,
        user: state.auth.user,
        otherAccounts: state.auth.otherAccounts,

        isTokenValidating: state.auth.isTokenValidating,
    };
}

export default connect(
    mapStateToProps
)(MyRoute);
