// Created Store to hold the app state
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

let composer;
if(process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging') {
    composer = compose(
        applyMiddleware(thunk)
    );
} else {
    composer = compose(
        applyMiddleware(thunk),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
}

const store = createStore(
    rootReducer,
    {}, // For Debugging comment this line
    composer
);

export default store;