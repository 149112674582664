import React, {Component} from 'react';
import {
    checkIsFormValid,
    getPersistentLocalStorageKeys,
    toCapitalizedWords,
    validateIsEmpty
} from "../../helpers/helper";
import validator from "validator";
import enums from "../../helpers/enums";

class MyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            isFormValid: false,
            isValidationRun: false,
            isFormChanged: false,
            fields: {},
            plan:{},
            notRequiredFields: [], // Should be an array
            validationErrors: {},
            extraModal: false,
            extraModalContent: ''
        };

        this.setInitialState = this.setInitialState.bind(this);
        this.resetState = this.resetState.bind(this);
        this.customValidation = this.customValidation.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeReactSelect = this.handleChangeReactSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateAllFields = this.validateAllFields.bind(this);

        this.toggleModal = this.toggleModal.bind(this);
        this.setModalContentAndShow = this.setModalContentAndShow.bind(this);
    }

    // Interfaces
    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
    }

    setInitialState() {
    }

    submitFormUsingProps() {
    }

    customValidation(fieldName, value) {
    }

    resetState() {
        this.setState({
            submitted: false,
            isFormValid: false,
            isValidationRun: false,
            isFormChanged: false,
            fields: {},
            validationErrors: {},
            notRequiredFields: [],
        });

        this.setInitialState();
    }

    handleChange(e, isBlur = false, fromCode = false, callback = false) {

        let {name, value, placeholder} = e.target;
        const {fields, validationErrors} = this.state;
        let {isFormChanged} = this.state;
        let validationMessageEntered = false;

        switch (name) {
            case 'country_code':
                name = 'country';
                if (!(validator.isNumeric(value) || value === "+" || value === "")) {
                    value = fields[name];
                }
                break;
            case 'area_code':
                name = 'area_code';
                if (!(validator.isNumeric(value) || value === "")) {
                    value = fields[name];
                }
                break;
            case 'phone_code':
                name = 'phone_number';
                if (!(validator.isNumeric(value) || value === "")) {
                    value = fields[name];
                }
                break;
            case 'code': // Teacher session code
                if (value !== undefined && value !== null) {
                    value = value.toUpperCase();
                }
                break;
            case 'amountPerPaymentCategory':
                if (!(validator.isNumeric(value) || value === "")) {
                    value = fields[name];
                }
                break;
            case 'paymentCategoryQuota':
                if (!(validator.isNumeric(value) || value === "")) {
                    value = fields[name];
                }
                break;
            case 'tillDate':
                let fromDate = fields.hasOwnProperty('fromDate') ? fields['fromDate'] : false;
                if (fromDate) {
                    if (value.diff(fromDate) < 0) {
                        validationErrors[name] = 'End Date should be greater than Start Date';
                        validationMessageEntered = true;
                    }
                }
                break;
            default:
                // Do Nothing
                break;
        }

        // Setting State that indicating form has been changed
        if (fromCode === false && value !== fields[name] && fields[name] !== undefined) {
            isFormChanged = true;
        }

        let trimmedValue = typeof value === 'string' ? value.trim() : value;
        fields[name] = trimmedValue != null && isBlur ? trimmedValue : value;
        if (isBlur && trimmedValue != null && trimmedValue.length > 0) {
            setTimeout(() => {
                this.validateField(name, trimmedValue, placeholder);
            }, 200);
        } else {
            if (!validationMessageEntered)
                validationErrors[name] = '';
        }

        this.setState({fields, validationErrors, isFormValid: true, isFormChanged}, () => {
            if (callback)
                callback(true);
        });
    }

    handleChangeDate(name, date) {
        let event = {
            target: {
                name: name,
                value: date,
            }
        };
        this.handleChange(event);
    }

    handleChangeReactSelect(name, value) {
        let event = {
            target: {
                name: name,
                value: null,
            }
        };

        if (value !== null) {
            event.target.value = [value];
        }

        this.handleChange(event);
    }

    handleSubmit(e) {
        e.preventDefault();
        // Getting the fields
        const {validationErrors} = this.state;
        this.validateAllFields((callback) => {
            if (callback) {
                checkIsFormValid(validationErrors, (response) => {
                    if (response) {
                        // Set state Submitted TRUE
                        this.setState({submitted: true});
                        // Submit Form to API
                        this.submitFormUsingProps();
                    }
                });
            }
        }, e);
    }

    validateAllFields(callback, e) {
        const {fields} = this.state;

        for (let fieldName in fields) {
            if (fields.hasOwnProperty(fieldName)) {
                this.validateField(fieldName, fields[fieldName], '', e);
            }
        }
        callback(true);
    }

    validateField(fieldName, value, placeholder, e) {
        const {fields, validationErrors, notRequiredFields} = this.state;

        if (!(notRequiredFields.indexOf(fieldName) >= 0)) {
            if (validateIsEmpty(value)) {
                switch (fieldName) {
                    case 'paymentCategoryQuota':
                        if (fields['paymentCategoryId'] === enums.paymentCategories.PERSTUDENT.toString())
                            validationErrors[fieldName] = 'Max Students is required.';
                        else if (fields['paymentCategoryId'] === enums.paymentCategories.PERTEACHER.toString())
                            validationErrors[fieldName] = 'Max Teachers is required.';
                        else if (fields['paymentCategoryId'] === enums.paymentCategories.PERCLASS.toString())
                            validationErrors[fieldName] = 'Max Classes is required.';
                        break;
                    case 'amountPerPaymentCategory':
                        validationErrors[fieldName] = 'Amount is required.';
                        break;
                    case 'country':
                        validationErrors[fieldName] = toCapitalizedWords(fieldName) + ' is required.';
                        break;
                    case 'area_code':
                        validationErrors[fieldName] = toCapitalizedWords(fieldName) + ' is required.';
                        break;
                    case 'phone_number':
                        validationErrors[fieldName] = toCapitalizedWords(fieldName) + ' is required.';
                        break;
                    case 'parentCountry':
                        validationErrors[fieldName] = toCapitalizedWords(fieldName) + ' is required.';
                        break;
                    case 'parent_area_code':
                        validationErrors[fieldName] = toCapitalizedWords(fieldName) + ' is required.';
                        break;
                    case 'parent_phone_number':
                        validationErrors[fieldName] = toCapitalizedWords(fieldName) + ' is required.';
                        break;
                    case 'userId':
                        if (e.target.dataset.roleId === enums.role.TEACHER.toString())
                            validationErrors[fieldName] = 'Teacher is required.';
                        else if (e.target.dataset.roleId === enums.role.STUDENT.toString())
                            validationErrors[fieldName] = 'Student is required.';
                        else
                            validationErrors[fieldName] = (placeholder ? placeholder : toCapitalizedWords(fieldName)) + ' is required.';
                        break;
                    default:
                        validationErrors[fieldName] = (placeholder ? placeholder : toCapitalizedWords(fieldName)) + ' is required.';
                        break;
                }
            } else {
                this.customValidation(fieldName, value);
            }
        } else if (notRequiredFields.indexOf(fieldName) >= 0 && !validateIsEmpty(value) && typeof value === "string") {
            this.customValidation(fieldName, value);
        }

        checkIsFormValid(validationErrors, (response) => {
            this.setState({
                isFormValid: response,
                isValidationRun: true,
                validationErrors: validationErrors
            });
        });
    }

    toggleModal() {
        this.setState({
            extraModal: !this.state.extraModal
        });
    }

    setModalContentAndShow(modalContent) {
        this.setState({
            extraModalContent: modalContent,
            extraModal: !this.state.extraModal
        });
    }

    render() {
        return (
            <div>&nbsp;</div>
        );
    }
}

export default MyForm;
