export const CREATE_INSTITUTE_REQUEST = 'institute.CREATE_INSTITUTE_REQUEST';
export const CREATE_INSTITUTE_SUCCESS = 'institute.CREATE_INSTITUTE_SUCCESS';
export const CREATE_INSTITUTE_FAILURE = 'institute.CREATE_INSTITUTE_FAILURE';
export const CREATE_INSTITUTE_CLEAR = 'institute.CREATE_INSTITUTE_CLEAR';

export const ADD_INSTITUTE_REQUEST = 'institute.ADD_INSTITUTE_REQUEST';
export const ADD_INSTITUTE_SUCCESS = 'institute.ADD_INSTITUTE_SUCCESS';
export const ADD_INSTITUTE_FAILURE = 'institute.ADD_INSTITUTE_FAILURE';
export const ADD_INSTITUTE_CLEAR = 'institute.ADD_INSTITUTE_CLEAR';

export const EDIT_INSTITUTE_REQUEST = 'institute.EDIT_INSTITUTE_REQUEST';
export const EDIT_INSTITUTE_SUCCESS = 'institute.EDIT_INSTITUTE_SUCCESS';
export const EDIT_INSTITUTE_FAILURE = 'institute.EDIT_INSTITUTE_FAILURE';
export const EDIT_INSTITUTE_CLEAR = 'institute.EDIT_INSTITUTE_CLEAR';

export const JOIN_INSTITUTE_REQUEST = 'institute.JOIN_INSTITUTE_REQUEST';
export const JOIN_INSTITUTE_SUCCESS = 'institute.JOIN_INSTITUTE_SUCCESS';
export const JOIN_INSTITUTE_FAILURE = 'institute.JOIN_INSTITUTE_FAILURE';
export const JOIN_INSTITUTE_CLEAR = 'institute.JOIN_INSTITUTE_CLEAR';

export const INVITE_INSTITUTE_REQUEST = 'institute.INVITE_INSTITUTE_REQUEST';
export const INVITE_INSTITUTE_SUCCESS = 'institute.INVITE_INSTITUTE_SUCCESS';
export const INVITE_INSTITUTE_FAILURE = 'institute.INVITE_INSTITUTE_FAILURE';
export const INVITE_INSTITUTE_CLEAR = 'institute.INVITE_INSTITUTE_CLEAR';

export const LIST_INSTITUTES_REQUEST = 'institute.LIST_INSTITUTES_REQUEST';
export const LIST_INSTITUTES_SUCCESS = 'institute.LIST_INSTITUTES_SUCCESS';
export const LIST_INSTITUTES_FAILURE = 'institute.LIST_INSTITUTES_FAILURE';
export const UPDATE_INSTITUTE_LISTING_PARAMS = 'institute.UPDATE_INSTITUTE_LISTING_PARAMS';

export const SINGLE_INSTITUTE_REQUEST = 'institute.SINGLE_INSTITUTE_REQUEST';
export const SINGLE_INSTITUTE_SUCCESS = 'institute.SINGLE_INSTITUTE_SUCCESS';
export const SINGLE_INSTITUTE_FAILURE = 'institute.SINGLE_INSTITUTE_FAILURE';

export const DELETE_INSTITUTE_REQUEST = 'institute.DELETE_INSTITUTE_REQUEST';
export const DELETE_INSTITUTE_SUCCESS = 'institute.DELETE_INSTITUTE_SUCCESS';
export const DELETE_INSTITUTE_FAILURE = 'institute.DELETE_INSTITUTE_FAILURE';

export const BLOCK_INSTITUTE_REQUEST = 'institute.BLOCK_INSTITUTE_REQUEST';
export const BLOCK_INSTITUTE_SUCCESS = 'institute.BLOCK_INSTITUTE_SUCCESS';
export const BLOCK_INSTITUTE_FAILURE = 'institute.BLOCK_INSTITUTE_FAILURE';

export const UNBLOCK_INSTITUTE_REQUEST = 'institute.UNBLOCK_INSTITUTE_REQUEST';
export const UNBLOCK_INSTITUTE_SUCCESS = 'institute.UNBLOCK_INSTITUTE_SUCCESS';
export const UNBLOCK_INSTITUTE_FAILURE = 'institute.UNBLOCK_INSTITUTE_FAILURE';

export const ACCEPT_INVITE_REQUEST = 'institute.ACCEPT_INVITE_REQUEST';
export const ACCEPT_INVITE_SUCCESS = 'institute.ACCEPT_INVITE_SUCCESS';
export const ACCEPT_INVITE_FAILURE = 'institute.ACCEPT_INVITE_FAILURE';

export const REJECT_INVITE_REQUEST = 'institute.REJECT_INVITE_REQUEST';
export const REJECT_INVITE_SUCCESS = 'institute.REJECT_INVITE_SUCCESS';
export const REJECT_INVITE_FAILURE = 'institute.REJECT_INVITE_FAILURE';

export const DELETE_INVITE_REQUEST = 'institute.DELETE_INVITE_REQUEST';
export const DELETE_INVITE_SUCCESS = 'institute.DELETE_INVITE_SUCCESS';
export const DELETE_INVITE_FAILURE = 'institute.DELETE_INVITE_FAILURE';

export const RESEND_INVITE_REQUEST = 'institute.RESEND_INVITE_REQUEST';
export const RESEND_INVITE_SUCCESS = 'institute.RESEND_INVITE_SUCCESS';
export const RESEND_INVITE_FAILURE = 'institute.RESEND_INVITE_FAILURE';

export const REPORT_OVERALL_SUMMARY_REQUEST = 'institute.REPORT_OVERALL_SUMMARY_REQUEST';
export const REPORT_OVERALL_SUMMARY_SUCCESS = 'institute.REPORT_OVERALL_SUMMARY_SUCCESS';
export const REPORT_OVERALL_SUMMARY_FAILURE = 'institute.REPORT_OVERALL_SUMMARY_FAILURE';
export const REPORT_OVERALL_SUMMARY_CLEAR = 'institute.REPORT_OVERALL_SUMMARY_CLEAR';

export const REPORT_SINGLE_CLASS_REQUEST = 'institute.REPORT_SINGLE_CLASS_REQUEST';
export const REPORT_SINGLE_CLASS_SUCCESS = 'institute.REPORT_SINGLE_CLASS_SUCCESS';
export const REPORT_SINGLE_CLASS_FAILURE = 'institute.REPORT_SINGLE_CLASS_FAILURE';
export const REPORT_SINGLE_CLASS_CLEAR = 'institute.REPORT_SINGLE_CLASS_CLEAR';

export const REPORT_TEACHER_CLASS_REQUEST = 'institute.REPORT_TEACHER_CLASS_REQUEST';
export const REPORT_TEACHER_CLASS_SUCCESS = 'institute.REPORT_TEACHER_CLASS_SUCCESS';
export const REPORT_TEACHER_CLASS_FAILURE = 'institute.REPORT_TEACHER_CLASS_FAILURE';
export const REPORT_TEACHER_CLASS_CLEAR = 'institute.REPORT_TEACHER_CLASS_CLEAR';

export const REPORT_STUDENT_CLASS_REQUEST = 'institute.REPORT_STUDENT_CLASS_REQUEST';
export const REPORT_STUDENT_CLASS_SUCCESS = 'institute.REPORT_STUDENT_CLASS_SUCCESS';
export const REPORT_STUDENT_CLASS_FAILURE = 'institute.REPORT_STUDENT_CLASS_FAILURE';
export const REPORT_STUDENT_CLASS_CLEAR = 'institute.REPORT_STUDENT_CLASS_CLEAR';

export const SETTING_INSTITUTE_REQUEST = 'institute.SETTING_INSTITUTE_REQUEST';
export const SETTING_INSTITUTE_SUCCESS = 'institute.SETTING_INSTITUTE_SUCCESS';
export const SETTING_INSTITUTE_FAILURE = 'institute.SETTING_INSTITUTE_FAILURE';
export const SETTING_INSTITUTE_CLEAR = 'institute.SETTING_INSTITUTE_CLEAR';

export const SINGLE_CLASS_REPORT_REQUEST = 'institute.SINGLE_CLASS_REPORT_REQUEST';
export const SINGLE_CLASS_REPORT_SUCCESS = 'institute.SINGLE_CLASS_REPORT_SUCCESS';
export const SINGLE_CLASS_REPORT_FAILURE = 'institute.SINGLE_CLASS_REPORT_FAILURE';
export const SINGLE_CLASS_REPORT_CLEAR = 'institute.SINGLE_CLASS_REPORT_CLEAR';
export const UPDATE_SINGLE_CLASS_REPORT_LISTING_PARAMS = 'institute.UPDATE_SINGLE_CLASS_REPORT_LISTING_PARAMS';

export const SINGLE_SESSION_REPORT_REQUEST = 'institute.SINGLE_SESSION_REPORT_REQUEST';
export const SINGLE_SESSION_REPORT_SUCCESS = 'institute.SINGLE_SESSION_REPORT_SUCCESS';
export const SINGLE_SESSION_REPORT_FAILURE = 'institute.SINGLE_SESSION_REPORT_FAILURE';
export const SINGLE_SESSION_REPORT_CLEAR = 'institute.SINGLE_SESSION_REPORT_CLEAR';
export const UPDATE_SINGLE_SESSION_REPORT_LISTING_PARAMS = 'institute.UPDATE_SINGLE_SESSION_REPORT_LISTING_PARAMS';

export const SINGLE_STUDENT_CLASS_REPORT_REQUEST = 'institute.SINGLE_STUDENT_CLASS_REPORT_REQUEST';
export const SINGLE_STUDENT_CLASS_REPORT_SUCCESS = 'institute.SINGLE_STUDENT_CLASS_REPORT_SUCCESS';
export const SINGLE_STUDENT_CLASS_REPORT_FAILURE = 'institute.SINGLE_STUDENT_CLASS_REPORT_FAILURE';
export const SINGLE_STUDENT_CLASS_REPORT_CLEAR = 'institute.SINGLE_STUDENT_CLASS_REPORT_CLEAR';
export const UPDATE_SINGLE_STUDENT_CLASS_REPORT_LISTING_PARAMS = 'institute.UPDATE_SINGLE_STUDENT_CLASS_REPORT_LISTING_PARAMS';