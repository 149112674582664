import * as types from './actionTypes';
import {frontendRoutes, hiddenMenuRoutes, showAlertMessage} from '../../helpers/helper';
import {contact_admin, support_submit} from "../../services/User";
import language from "../../helpers/language";

export function routeChanged(_location) {
    let isFrontEnd = false;
    let isHideMenu = false;
    if(frontendRoutes.indexOf(_location.pathname) >= 0) {
        isFrontEnd = true;
    }
    if(hiddenMenuRoutes.indexOf(_location.pathname) >= 0) {
        isHideMenu = true;
    }
    return({ type: types.ROUTE_CHANGED, isFrontEnd, isHideMenu });
}

export function support(data) {
    return (dispatch) => {
        dispatch({type: types.SUPPORT_REQUEST});
        const alertOption = {
            heading: language.TITLE_CONTACT_US,
            type: "error"
        };

        support_submit(data, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({type: types.SUPPORT_SUCCESS, supportSuccessMsg: response.message});
            } else {
                dispatch({type: types.SUPPORT_FAILURE, supportErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearSupport() {
    return (dispatch) => {
        dispatch({type: types.SUPPORT_CLEAR});
    };
}

export function contactAdmin(data) {
    return (dispatch) => {
        dispatch({type: types.CONTACT_ADMIN_REQUEST});
        const alertOption = {
            heading: language.TITLE_CONTACT_ADMIN,
            type: "error"
        };

        contact_admin(data, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({type: types.CONTACT_ADMIN_SUCCESS, contactAdminSuccessMsg: response.message});
            } else {
                dispatch({type: types.CONTACT_ADMIN_FAILURE, contactAdminErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearContactAdmin() {
    return (dispatch) => {
        dispatch({type: types.CONTACT_ADMIN_CLEAR});
    };
}