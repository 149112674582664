import React, { Component } from 'react';
import {DropdownItem} from 'reactstrap';
import {switch_account} from "../../services/User";
import {getRoleString} from "../../helpers/helper";
import {connect} from "react-redux";

class OtherAccounts extends Component {
    render() {
        const {otherAccounts, isDropDown} = this.props;
        let navItems;
        if (otherAccounts && otherAccounts.length > 0) {
            navItems = otherAccounts.map((item, index) => {
                if(isDropDown === true) {
                    return (
                        <DropdownItem key={index} onClick={() => {
                            switch_account(item.instituteId, item.userId, item.roleId, () => {/* empty function */})
                        }}>
                            <span className="switch-role-dropdown-item-navbar">{getRoleString(item.roleId)}</span><br/>
                            <span className="switch-institute-dropdown-item-navbar">{item.instituteName}</span>
                        </DropdownItem>
                    );
                } else {
                    return (
                        <a href="#" className="list-group-item list-group-item-action list-group-item-light" key={index} onClick={() => {
                            switch_account(item.instituteId, item.userId, item.roleId, () => {/* empty function */})
                        }}>
                            <span className="switch-role-dropdown-item-navbar">{getRoleString(item.roleId)}</span>
                            <span className="mx-2">|</span>
                            <span className="switch-institute-dropdown-item-navbar">{item.instituteName}</span>
                        </a>
                    );
                }
            });
            return (
                <React.Fragment>
                    {navItems}
                </React.Fragment>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        otherAccounts: state.auth.otherAccounts
    };
}

export default connect(
    mapStateToProps
)(OtherAccounts);