import React from 'react';
import {
    Row, Col
} from 'reactstrap';
import MyForm from "../Common/MyForm";
import language from "../../helpers/language";
import {getEmailValidationMessage} from "../../helpers/validationHelpers";
import SignUpTeacherForOtherInstitute from "./SignUpTeacherForOtherInstitute";
import FormModalChild from "../Common/FormModalChild";
import SignUpStudentForOtherInstitute from "./SignUpStudentForOtherInstitute";

class SignUpModal extends MyForm {
    render() {
        const {user} = this.props;
        let doesUserHaveValidEmail = getEmailValidationMessage(user.email).length === 0;
        return (
            <React.Fragment>
                <Row>
                    <Col className="text-center" sm={{size: 10, offset: 1}}>
                        <h1 className="text-uppercase">{language.TITLE_JOIN_METHOD}</h1>
                        <br/>

                        {
                            (doesUserHaveValidEmail)
                            &&
                            (
                                <React.Fragment>
                                    <FormModalChild
                                        modalContent={<SignUpTeacherForOtherInstitute user={this.props.user}/>}
                                        classes="btn-block" color="account">
                                        Join as a Teacher
                                    </FormModalChild>

                                    <div className="my-3">OR</div>
                                </React.Fragment>
                            )
                        }

                        <FormModalChild modalContent={<SignUpStudentForOtherInstitute user={this.props.user}/>}
                                        classes="btn-block" color="account" className="modal-lg">
                            Join as a Student
                        </FormModalChild>
                        <br/>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default SignUpModal;