import {
    convertMomentDateToMySql,
    getContactNumberUsingAreaAndPhone,
    getFromAndTillDateFromDateRangeText,
    serialize,
    requestApiToServer, validateApiResponse, requestReportApiToServer, requestApiToServerWithAbort
} from '../helpers/helper';

////////////////////////// GET REQUESTS //////////////////////////
export function get_institutes(data, callback) {
    // Creating data according to Api
    let params = 'searchText=' + encodeURIComponent(data.searchText) + '&filterStatus=' + data.filterStatus +
        '&sortByName=' + data.sortByName + '&sortByOrder=' + data.sortByOrder +
        '&limitNRows=' + data.limitNRows;

    // Request Api using final data
    requestApiToServerWithAbort(`institute/get?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_institute(id, callback) {
    // Request Api using final data
    requestApiToServer(`institute/get/${id}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_institutes_offline(data, callback) {
    let params = 'searchText=' + encodeURIComponent(data.searchText) + '&limitNRows=' + data.limitNRows;

    // Request Api using final data
    requestApiToServerWithAbort(`institute/getAllForSignup?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function _callback_get_institute(id, callback) {
    // Request Api using final data
    requestApiToServer(`institute/get/${id}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function _callback_get_dashboard(callback) {
    // Request Api using final data
    requestApiToServer('institute/getDashboard', 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function _callback_validate_join_institute(code, callback) {
    // Request Api using final data
    requestApiToServer(`institute/validateInviteInstituteCode?code=${code}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function report_overall_summary(data, callback) {
    // Creating data according to Api
    let dataDates = getFromAndTillDateFromDateRangeText(data['dateRangeText']);
    data['fromDate'] = dataDates['fromDate'];
    data['tillDate'] = dataDates['tillDate'];
    let params = `fromDate=${data['fromDate']}&tillDate=${data['tillDate']}`;

    // Request Api using final data
    requestApiToServer(`institute/getOverallSummary?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function report_single_class(data, callback) {
    // Creating data according to Api
    data['fromDate'] = convertMomentDateToMySql(data['fromDate']);
    data['tillDate'] = convertMomentDateToMySql(data['tillDate']);
    let params = `classId=${data['classId']}&fromDate=${data['fromDate']}&tillDate=${data['tillDate']}`;

    // Request Api using final data
    requestApiToServer(`institute/getClassReport?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function report_single_class_with_data(data, callback) {
    // Creating data according to Api
    let params = serialize(data);

    // Request Api using final data
    requestApiToServer(`institute/getClassReport?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function report_teacher_class(data, callback) {
    // Creating data according to Api
    data['fromDate'] = convertMomentDateToMySql(data['fromDate']);
    data['tillDate'] = convertMomentDateToMySql(data['tillDate']);
    let params = `classId=${data['classId']}&userId=${data['userId']}&fromDate=${data['fromDate']}&tillDate=${data['tillDate']}`;

    // Request Api using final data
    requestApiToServer(`institute/getTeacherReport?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function report_student_class(data, callback) {
    // Creating data according to Api
    data['fromDate'] = convertMomentDateToMySql(data['fromDate']);
    data['tillDate'] = convertMomentDateToMySql(data['tillDate']);
    let params = `classId=${data['classId']}&userId=${data['userId']}&fromDate=${data['fromDate']}&tillDate=${data['tillDate']}`;

    // Request Api using final data
    requestApiToServer(`institute/getStudentReport?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function report_student_class_with_data(data, callback) {
    // Creating data according to Api
    let params = serialize(data);

    // Request Api using final data
    requestApiToServer(`institute/getStudentReport?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function report_single_session(data, callback) {
    // Creating data according to Api
    data['fromDate'] = convertMomentDateToMySql(data['fromDate']);
    data['tillDate'] = convertMomentDateToMySql(data['tillDate']);
    let params = `classId=${data['classId']}&sessionId=${data['sessionId']}&fromDate=${data['fromDate']}&tillDate=${data['tillDate']}`;

    // Request Api using final data
    requestApiToServer(`institute/getSessionReport?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function report_single_session_with_data(data, callback) {
    // Creating data according to Api
    let params = serialize(data);

    // Request Api using final data
    requestApiToServer(`institute/getSessionReport?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function download_overall_summary(data, callback) {
    // Creating data according to Api
    let dataDates = getFromAndTillDateFromDateRangeText(data['dateRangeText']);
    data['fromDate'] = dataDates['fromDate'];
    data['tillDate'] = dataDates['tillDate'];
    let params = `fromDate=${data['fromDate']}&tillDate=${data['tillDate']}&downloadAs=${data['downloadAs']}`;

    // Request Api using final data
    requestReportApiToServer(`institute/getOverallSummary?${params}`, 'overall-summary-report', data, (response) => {
        callback(response);
    });
}


export function download_single_class(data, callback) {
    // Creating data according to Api
    data['fromDate'] = convertMomentDateToMySql(data['fromDate']);
    data['tillDate'] = convertMomentDateToMySql(data['tillDate']);
    let params = `classId=${data['classId']}&fromDate=${data['fromDate']}&tillDate=${data['tillDate']}&downloadAs=${data['downloadAs']}`;

    // Request Api using final data
    requestReportApiToServer(`institute/getClassReport?${params}`, 'class-report', data, (response) => {
        callback(response);
    });
}


export function download_teacher_class(data, callback) {
    // Creating data according to Api
    data['fromDate'] = convertMomentDateToMySql(data['fromDate']);
    data['tillDate'] = convertMomentDateToMySql(data['tillDate']);
    let params = `classId=${data['classId']}&userId=${data['userId']}&fromDate=${data['fromDate']}&tillDate=${data['tillDate']}&downloadAs=${data['downloadAs']}`;

    // Request Api using final data
    requestReportApiToServer(`institute/getTeacherReport?${params}`, 'teacher-report', data, (response) => {
        callback(response);
    });
}


export function download_student_class(data, callback) {
    // Creating data according to Api
    data['fromDate'] = convertMomentDateToMySql(data['fromDate']);
    data['tillDate'] = convertMomentDateToMySql(data['tillDate']);
    let params = `classId=${data['classId']}&userId=${data['userId']}&fromDate=${data['fromDate']}&tillDate=${data['tillDate']}&downloadAs=${data['downloadAs']}`;

    // Request Api using final data
    requestReportApiToServer(`institute/getStudentReport?${params}`, 'student-report', data, (response) => {
        callback(response);
    });
}


export function download_single_session(data, callback) {
    // Creating data according to Api
    data['fromDate'] = convertMomentDateToMySql(data['fromDate']);
    data['tillDate'] = convertMomentDateToMySql(data['tillDate']);
    let params = `classId=${data['classId']}&sessionId=${data['sessionId']}&fromDate=${data['fromDate']}&tillDate=${data['tillDate']}&downloadAs=${data['downloadAs']}`;

    // Request Api using final data
    requestReportApiToServer(`institute/getSessionReport?${params}`, 'session-report', data, (response) => {
        callback(response);
    });
}
////////////////////////// END GET REQUESTS //////////////////////////

////////////////////////// POST REQUESTS //////////////////////////
// joinInstitute will be used for sign-up purpose
export function signup_institute(data, callback) {
    // Creating data according to Api
    data['contactNumber'] = getContactNumberUsingAreaAndPhone(data);

    // Request Api using final data
    requestApiToServer('institute/signupInstitute', 'POST', data, (response) => {
        callback(validateApiResponse(response));
    });
}

// joinInstitute will be used for sign-up purpose
export function add_institute(data, callback) {
    // Creating data according to Api
    data['contactNumber'] = getContactNumberUsingAreaAndPhone(data);

    // Request Api using final data
    requestApiToServer('institute/addInstitute', 'POST', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function invite_institute(email, callback) {
    // Creating data according to Api
    let data = {adminEmail: email};

    // Request Api using final data
    requestApiToServer('institute/inviteInstitute', 'POST', data, (response) => {
        callback(validateApiResponse(response));
    });
}
////////////////////////// END POST REQUESTS //////////////////////////

////////////////////////// PUT REQUESTS //////////////////////////
// joinInstitute will be used for sign-up purpose
export function edit_institute(data, callback) {
    // Creating data according to Api
    data['contactNumber'] = getContactNumberUsingAreaAndPhone(data);

    // Request Api using final data
    requestApiToServer(`institute/updateInstitute`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function update_settings_institute(data, callback) {
    // Creating data according to Api
    data['contactNumber'] = getContactNumberUsingAreaAndPhone(data);

    // Request Api using final data
    requestApiToServer(`institute/updateInstituteSettings`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function join_institute(data, callback) {
    // Creating data according to Api
    data['contactNumber'] = getContactNumberUsingAreaAndPhone(data);

    // Request Api using final data
    requestApiToServer(`institute/joinInstitute`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function block_institute(id, callback) {
    // Request Api using final data
    requestApiToServer(`institute/blockInstitute/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function unblock_institute(id, callback) {
    // Request Api using final data
    requestApiToServer(`institute/unblockInstitute/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function accept_invite(id, callback) {
    // Request Api using final data
    requestApiToServer(`institute/acceptInvite/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function reject_invite(id, callback) {
    // Request Api using final data
    requestApiToServer(`institute/rejectInvite/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function resend_invite(id, callback) {
    // Request Api using final data
    requestApiToServer(`institute/reinviteInstitute/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function accept_institute_invite(code, callback) {
    // Creating data according to Api
    let data = {code: code};

    // Request Api using final data
    requestApiToServer(`institute/acceptSuperAdminInvite`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function reject_institute_invite(code, callback) {
    // Creating data according to Api
    let data = {code: code};

    // Request Api using final data
    requestApiToServer(`institute/rejectSuperAdminInvite`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}
////////////////////////// END PUT REQUESTS //////////////////////////

////////////////////////// DELETE REQUESTS //////////////////////////
export function delete_institute(id, callback) {
    // Request Api using final data
    requestApiToServer(`institute/deleteInstitute/${id}`, 'DELETE', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function delete_invite(id, callback) {
    // Request Api using final data
    requestApiToServer(`institute/deleteInvite/${id}`, 'DELETE', [], (response) => {
        callback(validateApiResponse(response));
    });
}
////////////////////////// END DELETE REQUESTS //////////////////////////