import React from 'react';
import {
    Row, Col, Form
} from 'reactstrap';
import validator from "validator";
import {changePassword, clearChangePassword} from "../../store/user/actions";
import {connect} from "react-redux";
import MyForm from "../Common/MyForm";
import {CommonInput} from "../Common/MyFields";
import {CancelButton, LoaderButton} from "../Common/MyButtons";
import {clickCloseButton} from "../../helpers/helper";
import language from "../../helpers/language";
import {getPasswordValidationMessage} from "../../helpers/validationHelpers";

class ChangePassword extends MyForm {
    componentDidMount() {
        this.props.clearChangePassword();
        this.setInitialState();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.changePasswordSuccessMsg !== '') {
            this.resetState();
            clickCloseButton();
        }
    }

    setInitialState() {
        this.setState({
            fields: {
                old_password: '',
                new_password: '',
            },
            validationErrors: {
                old_password: '',
                new_password: '',
            },
            notRequiredFields: []
        });
    }

    submitFormUsingProps() {
        const {old_password, new_password} = this.state.fields;
        this.props.changePassword(old_password, new_password);
    }

    customValidation(fieldName, value) {
        const {validationErrors} = this.state;
        switch (fieldName) {
            case 'new_password':
                validationErrors.new_password = getPasswordValidationMessage(value);
                break;
            default:
                if (!validator.isEmpty(value)) {
                    validationErrors[fieldName] = '';
                }
                break;
        }
    }

    render() {
        const {old_password, new_password} = this.state.fields;
        const {isValidationRun, isFormValid, validationErrors, isFormChanged} = this.state;
        const {isChangePasswordRequested, canBrowserShowSuggestions} = this.props;
        return (
            <React.Fragment>
                <Row>
                    <Col className="text-center" sm={{size: 10, offset: 1}}>
                        <h1 className="text-uppercase">{language.TITLE_CHANGE_PASSWORD}</h1>
                        <br/>

                        <Form className="text-center" onSubmit={this.handleSubmit}
                              autoComplete={canBrowserShowSuggestions ? 'on' : 'off'}>
                            <CommonInput type="password" name="old_password" title="Current Password"
                                         value={old_password}
                                         error={validationErrors.old_password} onChange={this.handleChange} autoFocus/>
                            <CommonInput type="password" name="new_password" title="New Password" value={new_password}
                                         error={validationErrors.new_password} onChange={this.handleChange}/>

                            <br/>

                            <LoaderButton btnLabel="Update" isRequest={isChangePasswordRequested}
                                          isFormValid={isFormValid}
                                          isValidationRun={isValidationRun} isFormChanged={isFormChanged}/>
                            <CancelButton btnLabel="Cancel" onClick={clickCloseButton} isFormChanged={isFormChanged}/>
                        </Form>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    changePassword,
    clearChangePassword
};

function mapStateToProps(state) {
    return {
        isChangePasswordRequested: state.user.isChangePasswordRequested,
        changePasswordSuccessMsg: state.user.changePasswordSuccessMsg,
        changePasswordErrorMsg: state.user.changePasswordErrorMsg,
    };
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ChangePassword);