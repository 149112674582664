import {
    convertMomentDateToMySql,
    getTimeFromHoursMinutesAndMeridiem,
    requestApiToServer,
    validateApiResponse,
    requestFileApiToServer, requestApiToServerWithAbort
} from '../helpers/helper';
import enums from "../helpers/enums";

////////////////////////// GET REQUESTS //////////////////////////
export function get_classes(data, callback) {
    // Creating data according to Api
    let apiToCall;
    if (data.roleId === enums.role.TEACHER || data.roleId === enums.role.STUDENT) {
        apiToCall = `class/getAllByUser?`;
    } else {
        apiToCall = `class/getAll?`;
    }

    let params = `searchText=${encodeURIComponent(data.searchText)}&filterStatus=${data.filterStatus}&sortByName=${data.sortByName}&sortByOrder=${data.sortByOrder}&limitNRows=${data.limitNRows}`;

    // Request Api using final data
    requestApiToServerWithAbort(apiToCall + params, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_class(id, callback) {
    // Request Api using final data
    requestApiToServer(`class/getOneClass/${id}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function check_is_publishing(swapUserId, callback) {
    // Request Api using final data
    let differentServerUrl = 'http://127.0.0.1:64532/';
    requestApiToServer(`publishingStatus`, 'POST', {
        differentServerUrl: differentServerUrl,
        userId: swapUserId
    }, (response) => {
        callback(validateApiResponse(response));
    });
}

export function start_publishing(communityId, callback) {
    // Request Api using final data
    let differentServerUrl = 'http://127.0.0.1:64532/';
    requestApiToServer(`publish`, 'POST', {
        differentServerUrl: differentServerUrl,
        communityId: communityId
    }, (response) => {
        callback(validateApiResponse(response));
    });
}

export function change_access_control(swapCommunityId, swapUserId, canControl, callback) {
    // Request Api using final data
    let differentServerUrl = 'http://127.0.0.1:64532/';
    requestApiToServer(`setCanControl`, 'POST', {
        differentServerUrl: differentServerUrl,
        communityId: swapCommunityId,
        userId: swapUserId,
        canControl: canControl
    }, (response) => {
        callback(validateApiResponse(response));
    });
}

export function stop_publishing(callback) {
    // Request Api using final data
    let differentServerUrl = 'http://127.0.0.1:64532/';
    requestApiToServer(`unpublish`, 'POST', {
        differentServerUrl: differentServerUrl
    }, (response) => {
        callback(validateApiResponse(response));
    });
}

export function shutdown_swap_application(callback) {
    // Request Api using final data
    let differentServerUrl = 'http://127.0.0.1:64532/';
    requestApiToServer(`shutdown`, 'POST', {
        differentServerUrl: differentServerUrl
    }, (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_class_with_data(id, data, callback) {
    // Creating data according to Api
    let params = '?searchTextStudents=' + encodeURIComponent(data.searchTextStudents) + '&sortByNameStudents=' + data.sortByNameStudents +
        '&sortByOrderStudents=' + data.sortByOrderStudents + '&limitNRowsStudents=' + data.limitNRowsStudents +
        '&searchTextSessions=' + encodeURIComponent(data.searchTextSessions) + '&sortByNameSessions=' + data.sortByNameSessions +
        '&sortByOrderSessions=' + data.sortByOrderSessions + '&limitNRowsSessions=' + data.limitNRowsSessions;

    // Request Api using final data
    requestApiToServerWithAbort(`class/getOneClass/${id}${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function _callback_get_class(id, callback) {
    // Request Api using final data
    requestApiToServer(`class/getOneClass/${id}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_teachers_wrt_class(data, callback) {
    // Creating data according to Api
    let params = 'classId=' + data.singleId + '&searchText=' + encodeURIComponent(data.searchText) + '&filterStatus=' + data.filterStatus +
        '&limitNRows=' + data.limitNRows;

    // Request Api using final data
    requestApiToServerWithAbort(`class/getAllTeachersWrtClass?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_students_wrt_class(data, callback) {
    // Creating data according to Api
    let params = 'classId=' + data.singleId + '&searchText=' + encodeURIComponent(data.searchText) + '&filterStatus=' + data.filterStatus +
        '&limitNRows=' + data.limitNRows;

    // Request Api using final data
    requestApiToServerWithAbort(`class/getAllStudentsWrtClass?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function generate_session_code(callback) {
    // Request Api using final data
    requestApiToServer('class/generateSessionCode', 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_active_sessions(data, callback) {
    // Request Api using final data
    requestApiToServer('class/getAllActiveClassSessionsFor', 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_session_with_data(id, data, callback) {
    // Creating data according to Api
    let params = '?searchText=' + encodeURIComponent(data.searchTextSession) + '&sortByName=' + data.sortByNameSession +
        '&sortByOrder=' + data.sortByOrderSession + '&limitNRows=' + data.limitNRowsSession;

    // Request Api using final data
    requestApiToServerWithAbort(`class/getOneSession/${id}${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

////////////////////////// END GET REQUESTS //////////////////////////

////////////////////////// POST REQUESTS //////////////////////////
export function add_class(data, callback) {
    // Creating data according to Api
    data['completesOn'] = convertMomentDateToMySql(data['completesOn']);

    // Request Api using final data
    requestApiToServer('class/addClass', 'POST', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function import_students_csv(data, callback) {
    // Creating data according to Api
    const formData = new FormData();
    formData.append('csv', data['csv']);
    formData.append('classId', data['classId']);

    // Request Api using final data
    requestFileApiToServer('user/importStudents', 'POST', formData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function import_users_to_classes_csv(data, callback) {
    // Creating data according to Api
    const formData = new FormData();
    formData.append('csv', data['csv']);

    // Request Api using final data
    requestFileApiToServer('class/importUsersToClasses', 'POST', formData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function assigned_teacher_class(data, callback) {
    // Creating data according to Api
    let newData = {
        "userId": data['assignId'],
        "classId": data['singleId']
    };

    // Request Api using final data
    requestApiToServer('user/assignTeacherToClass', 'POST', newData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function assigned_students_class(data, callback) {
    // Creating data according to Api
    let newData = {
        "assignUserIds": data['assignIds'],
        "unassignUserIds": data['unassignIds'],
        "classId": data['singleId']
    };

    // Request Api using final data
    requestApiToServer('user/assignClassToStudents', 'POST', newData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function start_class_session(data, callback) {
    // Creating data according to Api
    let newData = data;
    if (data['acceptAttendanceBy'].indexOf(enums.acceptAttendanceBy.QRCODE.toString()) >= 0 &&
        data['acceptAttendanceBy'].indexOf(enums.acceptAttendanceBy.SESSIONCODE.toString()) >= 0) {
        newData['acceptAttendanceBy'] = enums.acceptAttendanceBy.BOTH.toString();
    }

    newData['code'] = newData['sessionCode'];
    delete newData['sessionCode'];

    // Request Api using final data
    requestApiToServer('class/startSession', 'POST', newData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function import_classes_to_institute(data, callback) {
    // Creating data according to Api
    const formData = new FormData();
    formData.append('csv', data['csv']);
    formData.append('instituteId', data['instituteId']);

    // Request Api using final data
    requestFileApiToServer('class/importClassesToInstitute', 'POST', formData, (response) => {
        callback(validateApiResponse(response));
    });
}

////////////////////////// END POST REQUESTS //////////////////////////

////////////////////////// PUT REQUESTS //////////////////////////
export function edit_class(data, callback) {
    // Creating data according to Api
    data['completesOn'] = convertMomentDateToMySql(data['completesOn']);

    // Request Api using final data
    requestApiToServer('class/editClass', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function completed_class(id, callback) {
    // Request Api
    requestApiToServer(`class/completeClass/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function accept_enroll_invite_class(userId, classId, callback) {
    // Creating data according to Api
    let data = {classId: classId, userId: userId};

    // Request Api using final data
    requestApiToServer('class/acceptEnrollInvite', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function reject_enroll_invite_class(userId, classId, callback) {
    // Creating data according to Api
    let data = {classId: classId, userId: userId};

    // Request Api using final data
    requestApiToServer('class/rejectEnrollInvite', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function reject_drop_request_class(userId, classId, callback) {
    // Creating data according to Api
    let data = {classId: classId, userId: userId};

    // Request Api using final data
    requestApiToServer('class/rejectDropRequest', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function reject_all_drop_request_class(id, callback) {
    // Creating data according to Api
    let data = {classId: id};

    // Request Api using final data
    requestApiToServer('class/rejectAllDropRequestsInClass', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function accept_invite_class(id, callback) {
    // Request Api using final data
    requestApiToServer(`class/acceptInvite/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function reject_invite_class(id, callback) {
    // Request Api using final data
    requestApiToServer(`class/rejectInvite/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function resend_invite_class(id, callback) {
    // Request Api using final data
    requestApiToServer(`class/reinviteAdminToClass/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function accept_class_invite(code, callback) {
    // Creating data according to Api
    let data = {code: code};

    // Request Api using final data
    requestApiToServer('class/acceptAdminInvite', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function reject_class_invite(code, callback) {
    // Creating data according to Api
    let data = {code: code};

    // Request Api using final data
    requestApiToServer('class/rejectAdminInvite', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function drop_class(id, callback) {
    // Creating data according to Api
    let data = {classId: id};

    // Request Api using final data
    requestApiToServer('class/dropClass', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function resend_drop_class_request(id, callback) {
    // Creating data according to Api
    let data = {classId: id};

    // Request Api using final data
    requestApiToServer('class/resendDropClassRequest', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function delete_drop_class_request(id, callback) {
    // Creating data according to Api
    let data = {classId: id};

    // Request Api using final data
    requestApiToServer('class/deleteDropRequest', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function regenerate_session_code(id, callback) {
    // Creating data according to Api
    let data = {classId: id};

    // Request Api using final data
    requestApiToServer('class/regenerateSessionCode', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function stop_class_session(id, callback) {
    // Request Api using final data
    requestApiToServer(`class/stopSession/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function mark_attendance(data, callback) {
    let dataToSend = {};
    // Creating data according to Api
    Object.assign(dataToSend, data);
    dataToSend['code'] = data['sessionCode'];
    delete dataToSend['sessionCode'];

    let Address = localStorage.getItem('Address');
    if (Address) {
        dataToSend['macAddress'] = Address;
    }

    // Request Api using final data
    requestApiToServer('class/markAttendance', 'PUT', dataToSend, (response) => {
        callback(validateApiResponse(response));
    });
}

export function edit_mark_attendance(data, callback) {
    // Creating data according to Api
    data['attendanceMarkedAt'] = getTimeFromHoursMinutesAndMeridiem(data);

    // Request Api using final data
    requestApiToServer('class/editAttendance', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function request_correction_class(data, callback) {
    // Request Api using final data
    requestApiToServer('class/requestCorrection', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function edit_request_correction_class(data, callback) {
    // Request Api using final data
    requestApiToServer('class/editCorrectionRequest', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function resend_request_correction_class(id, callback) {
    // Creating data according to Api
    let data = {sessionId: id};

    // Request Api using final data
    requestApiToServer('class/resendCorrectionRequest', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function delete_request_correction_class(id, callback) {
    // Creating data according to Api
    let data = {sessionId: id};

    // Request Api using final data
    requestApiToServer('class/deleteCorrectionRequest', 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function reinvite_single_class(userId, classId, callback) {
    // Creating data according to Api
    let data = {userId: userId};

    // Request Api using final data
    requestApiToServer(`class/reinviteUserToClass/${classId}`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

////////////////////////// END PUT REQUESTS //////////////////////////

////////////////////////// DELETE REQUESTS //////////////////////////
export function delete_class(id, callback) {
    // Request Api using final data
    requestApiToServer(`class/deleteClass/${id}`, 'DELETE', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function accept_drop_request_class(userId, classId, callback) {
    // Creating data according to Api
    let data = {classId: classId, userId: userId};

    // Request Api using final data
    requestApiToServer('class/acceptDropRequest', 'DELETE', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function accept_all_drop_request_class(id, callback) {
    // Creating data according to Api
    let data = {classId: id};

    // Request Api using final data
    requestApiToServer('class/acceptAllDropRequestsInClass', 'DELETE', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function delete_invite_class(id, callback) {
    // Request Api using final data
    requestApiToServer(`class/deleteInvite/${id}`, 'DELETE', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function delete_class_session(id, callback) {
    // Request Api using final data
    requestApiToServer(`class/deleteSession/${id}`, 'DELETE', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function delete_invite_single_class(userId, classId, callback) {
    // Creating data according to Api
    let data = {userId: userId};

    // Request Api using final data
    requestApiToServer(`class/deleteUserInvite/${classId}`, 'DELETE', data, (response) => {
        callback(validateApiResponse(response));
    });
}

////////////////////////// END DELETE REQUESTS //////////////////////////