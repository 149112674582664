import React, {Component} from 'react';
import {
    Row, Col
} from 'reactstrap';
import QrReader from 'react-qr-reader';
import {showAlertMessage} from "../../helpers/helper";
import language from "../../helpers/language";

class ReadQRCodeUsingCamera extends Component {
    constructor(props) {
        super(props);

        this.state = {
            delay: 500,
            result: 'No result',
        };

        /* For QR Code */
        this.handleScan = this.handleScan.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    /* For QR Code */
    handleScan(result){
        if (result) {
            this.props.QRCodeAction(result);
        }
    }

    handleError(err){
        showAlertMessage("warning", "Camera Access Failed", "Please check your camera permissions/settings and try again.");
        console.log(err);
        this.props.closeQRCodeModal();
    }

    render() {
        const previewStyle = {
            height: "auto",
            width: "100%",
        };
        return (
            <React.Fragment>
                <Row>
                    <Col className="text-center" sm="12">
                        <h1 className="text-uppercase mb-4">{language.TITLE_SCAN_QR_CODE}</h1>
                        <QrReader
                            delay={this.state.delay}
                            style={previewStyle}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            className="mb-5"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default ReadQRCodeUsingCamera;