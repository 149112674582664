let enums = {};

enums.role = {
    SUPER_ADMIN: 1,
    INSTITUTE_ADMIN: 2,
    TEACHER: 3,
    STUDENT: 4,
    PARENT: 5
};

enums.status = {
    BLOCKED: 0,
    ACTIVE: 1,
    PENDING: 2,
    INVITE_SENT: 3,
    INVITE_RECEIVED: 4,
    COMPLETED: 5,
    INVITE_REJECTED: 6,
    INVITE_DELETED: 7,
    INACTIVE: 8,
    PRESENT: 9,
    ABSENT: 10,
    DROP_CLASS_ACCEPTED: 11,
};

enums.paymentCategories = {
    PERSTUDENT: 1,
    PERTEACHER: 2,
    PERCLASS: 3,
    LUMPSUM: 4
};

enums.paymentTerms = {
    MONTHLY: 1,
    QUARTERLY: 2,
    HALFYEARLY: 3,
    YEARLY: 4
};

enums.deviceType = {
    WEB: 1,
    DROID: 2,
    IOS: 3,
};

enums.acceptAttendanceBy = {
    QRCODE: 0,
    SESSIONCODE: 1,
    BOTH: 2
};

enums.responseCodes = {
    SUCCESS: 0,
    INCORRECTEMAILORPASSWORD: 1,
    INCORRECTREQUESTPARAMS: 2,
    EMAILORUSERNAMEDOESNTEXIST: 3,
    PASSWORDDOESNTMATCH: 4,
    USERORINSTITUTEDOESNTEXIST: 5,
    USERFORINSTITUTEALREADYEXISTS: 6,
    INSTITUTEADMINALREADYINVITED: 7,
    INVALIDCODE: 8,
    CANNOTINVITETHISEMAIL: 9,
    FORGOTPASSWORDCODEDOESNOTEXIST: 10,
    NOSUCHPLANEXISTS: 11,
    CLASSALREADYASSIGNED: 12,
    NOTFOUND: 13,
    INSTITUTEADMINNOTINVITED: 14,
    ALREADYEXISTS: 15,
    USERALREADYINVITED: 16,
    UNAUTHORIZED: 17,
    BLOCKED: 18,
    INSUFFICIENTQUOTA: 19,
    INTERNALSERVERERROR: 500
};

enums.shareAccess = {
    VIEW: 0,
    CONTROL: 1,
    NO_ACCESS: 2
};

export default enums;
