import React, {Component} from 'react';
import {
    ModalBody, Modal
} from 'reactstrap';
import language from "../../helpers/language";

class FullScreenNoInternet extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal isOpen={true} toggle={false} className="modal-large-blocked">
                <ModalBody>
                    <div className="text-center mt-4 mb-5 font-family-lato">
                        <h1 className="mb-4">Internet Connection</h1>
                        <i className="fa fa-exclamation info-icon-modal"/>
                        <div className="mt-4 font-family-lato px-4">
                            <p className="mb-0">{ language.MSG_LOST_CONNECTION }</p>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default FullScreenNoInternet;