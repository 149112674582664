export const LOGIN_REQUEST = 'auth.LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth.LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth.LOGIN_FAILURE';

export const TOKEN_VALIDATING = 'auth.TOKEN_VALIDATING';
export const TOKEN_VALIDATE = 'auth.TOKEN_VALIDATE';

export const RECURRING_TOKEN_VALIDATING = 'auth.RECURRING_TOKEN_VALIDATING';
export const RECURRING_TOKEN_VALIDATE = 'auth.RECURRING_TOKEN_VALIDATE';

export const LOGOUT_REQUEST = 'auth.LOGOUT_REQUEST';