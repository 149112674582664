import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Router, Route, Switch} from 'react-router-dom';
import {routeChanged} from '../../store/app/actions';
import {validateToken, recurring_validate_token} from '../../store/auth/actions';
import '../../assets/fonts/self-icons.css';
import './App.css';
import Header from '../Common/Header/Header';
import Footer from '../Common/Footer/Footer';
import MyRoute from '../Common/MyRoute';
import history from '../../helpers/history';
import * as pages from "../Common/LoadableComponents";
import {
    detectIE,
    getDashboardLinkByRole,
    getRoleIdFromUser,
    isInternetConnectionAvailable,
    isMobile
} from "../../helpers/helper";
import {initializeGA, SendUserActiveEvent} from '../../helpers/googleAnalytics';
import {Alert} from "reactstrap";
import language from "../../helpers/language";
import enums from "../../helpers/enums";
import FullScreenInstituteBlocked from "../Modals/FullScreenInstituteBlocked";
import FullScreenNoInternet from "../Modals/FullScreenNoInternet";

class App extends Component {
    constructor(props) {
        super(props);
        this.props.validateToken();
        initializeGA();
        this.state = {isInternetConnection: true, pathName: ''};

        this.checkInternetConnection = this.checkInternetConnection.bind(this);
    }

    componentDidMount() {
        // Call these Starting Point of the app
        this.props.routeChanged(window.location);
        this.scrollToDiv(window.location);
        this.setState({pathName: window.location.pathname});

        // listening to this event when route changed
        this.unlisten = history.listen((location) => {
            this.setState({pathName: location.pathname});

            if (location.hash === '') {
                if(isMobile()) {
                    setTimeout(()=>{
                        window.scrollTo(0, 0);
                    }, 100);
                }
            } else {
                this.scrollToDiv(location);
            }

            // Calling Middleware that route is changed create UI for this Route
            this.props.routeChanged(location);

            // Detecting If IE browser then don't make a call for the Validate Token
            if(detectIE() === false) {
                this.props.validateToken();
            } else {
                if(location.pathname !== "/institutes" && location.pathname !== "/dashboard") {
                    this.props.validateToken();
                } else {
                    window.location.reload();
                }
            }
        });

        this.timerID = setInterval(
            () => this.checkInternetConnection(),
            500
        );

        this.intervalRecurringValidateToken = setInterval(
            () => this.props.recurring_validate_token(),
            2000
        );

        setInterval(
            () => SendUserActiveEvent(),
            3 * 60 * 1000
        );

    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.isLoggedIn) {
            const {pathName} = this.state;
            if(pathName === "" || pathName === "/") {
                history.push(getDashboardLinkByRole(getRoleIdFromUser(nextProps.user)));
            }
        }
    }

    componentWillUnmount() {
        this.unlisten();
        clearInterval(this.timerID);
        clearInterval(this.intervalRecurringValidateToken);
    }

    scrollToDiv(location) {
        setTimeout(() => {
            if (location.hash.substring(1)) {
                const hash = location.hash.substring(1);
                if(hash === "home") {
                    history.push("/");
                    window.scrollTo(0, 0);
                } else {
                    let el = document.getElementById(hash);
                    if(el !== undefined && el !== null) {
                        // el.scrollIntoView({behavior: 'smooth', block: 'start'});
                        window.scrollTo(0, el.offsetTop - 72);
                    }
                }
            }
        }, 500);
    }

    checkInternetConnection() {
        this.setState({
            isInternetConnection: isInternetConnectionAvailable()
        });
    }

    render() {
        const {isInternetConnection} = this.state;
        return (
            <Router history={history}>
                <div className="sa-content">
                    <Header/>
                    <div className="sa-content">
                        <Switch>
                            <MyRoute title={language.TITLE_SELF_ATTENDANCE} exact path="/" component={pages.Home}/>
                            <MyRoute title={language.TITLE_TERMS_AND_CONDITIONS} path="/terms-and-conditions"
                                     component={pages.TermsOfUse}/>
                            <MyRoute title={language.TITLE_PRIVACY_POLICY} path="/privacy-policy" component={pages.PrivacyPolicy}/>
                            <MyRoute title={language.TITLE_SUPPORT} path="/support" component={pages.Support}/>
                            <MyRoute title={language.TITLE_CONTACT_US} path="/contact-us" component={pages.ContactUs}/>
                            <MyRoute title={language.TITLE_LOGIN} path="/login" component={pages.Login}/>
                            <MyRoute title={language.TITLE_SIGN_UP_INSTITUTE} path="/register" component={pages.SignupInstitute}/>
                            <MyRoute title={language.TITLE_SIGN_UP_INSTITUTE} path="/register/:id" component={pages.SignupInstitute}/>
                            <MyRoute title={language.TITLE_FORGOT_PASSWORD} path="/forgot-password" component={pages.ForgotPassword}/>
                            <MyRoute title={language.TITLE_RESET_PASSWORD} path="/reset-password" component={pages.ResetPassword}/>
                            <MyRoute title={language.TITLE_JOIN_INSTITUTE} path="/join-us" component={pages.JoinInstitute}/>
                            <MyRoute title="Accept Institute Invite" path="/accept-institute-invite"
                                     component={pages.AcceptInstituteInvite}/>
                            <MyRoute title="Reject Institute Invite" path="/reject-institute-invite"
                                     component={pages.RejectInstituteInvite}/>
                            <MyRoute title={language.TITLE_INSTITUTE_INVITATION} path="/invite-expired"
                                     component={pages.ExpiredInstituteInvite}/>

                            <MyRoute authorization={true} allowed={[enums.role.SUPER_ADMIN]} title={language.TITLE_INSTITUTES} path="/institutes"
                                     component={pages.Institutes}/>
                            <MyRoute authorization={true} allowed={[enums.role.SUPER_ADMIN]} title={language.TITLE_ADD_INSTITUTE} path="/add-institute"
                                     component={pages.AddInstitute}/>
                            <MyRoute authorization={true} allowed={[enums.role.SUPER_ADMIN]} title="Institute Detail" path="/institute/:id"
                                     component={pages.InstituteDetail}/>
                            <MyRoute authorization={true} allowed={[enums.role.SUPER_ADMIN]} title={language.TITLE_EDIT_INSTITUTE} path="/edit-institute/:id"
                                     component={pages.EditInstitute}/>
                            <MyRoute authorization={true} allowed={[enums.role.SUPER_ADMIN]} title={language.TITLE_INVITE_INSTITUTE} path="/invite-institute"
                                     component={pages.InviteInstitute}/>

                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN, enums.role.TEACHER, enums.role.STUDENT]}
                                     title="Dashboard" path="/dashboard"
                                     component={pages.Dashboard}/>
                            <MyRoute authorization={true} allowed={[enums.role.INSTITUTE_ADMIN]} title="Institute Settings" path="/settings"
                                     component={pages.InstituteSettingsDetail}/>
                            <MyRoute authorization={true} allowed={[enums.role.INSTITUTE_ADMIN]} title={language.TITLE_EDIT_SETTINGS} path="/edit-settings"
                                     component={pages.InstituteSettings}/>
                            <MyRoute authorization={true} allowed={[enums.role.INSTITUTE_ADMIN]} title={language.TITLE_TEACHERS} path="/teachers" component={pages.Teachers}/>
                            <MyRoute authorization={true} allowed={[enums.role.INSTITUTE_ADMIN]} title={language.TITLE_ADD_TEACHER} path="/add-teacher"
                                     component={pages.AddTeacher}/>
                            <MyRoute authorization={true} allowed={[enums.role.INSTITUTE_ADMIN]} title={language.TITLE_EDIT_TEACHER} path="/edit-teacher/:id"
                                     component={pages.EditTeacher}/>
                            <MyRoute authorization={true} allowed={[enums.role.INSTITUTE_ADMIN]} title="Teacher Detail" path="/teacher/:id"
                                     component={pages.TeacherDetail}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN]} title={language.TITLE_STUDENTS}
                                     path="/students" component={pages.Students}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN]} title={language.TITLE_ADD_STUDENT}
                                     path="/add-student"
                                     component={pages.AddStudent}/>
                            <MyRoute authorization={true} allowed={[enums.role.INSTITUTE_ADMIN]} title={language.TITLE_EDIT_STUDENT} path="/edit-student/:id"
                                     component={pages.EditStudent}/>
                            <MyRoute authorization={true} allowed={[enums.role.INSTITUTE_ADMIN]} title="Student Detail" path="/student/:id"
                                     component={pages.StudentDetail}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN, enums.role.TEACHER, enums.role.STUDENT]}
                                     title={language.TITLE_CLASSES}
                                     path="/classes" component={pages.Classes}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN, enums.role.TEACHER]}
                                     title={language.TITLE_ADD_CLASS}
                                     path="/add-class"
                                     component={pages.AddClass}/>
                            <MyRoute authorization={true} allowed={[enums.role.INSTITUTE_ADMIN, enums.role.TEACHER]}
                                     title={language.TITLE_EDIT_CLASS} path="/edit-class/:id"
                                     component={pages.EditClass}/>
                            <MyRoute authorization={true} allowed={[enums.role.INSTITUTE_ADMIN, enums.role.TEACHER, enums.role.STUDENT]}
                                     title="Class Detail" path="/class/:id"
                                     component={pages.ClassDetail}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN, enums.role.TEACHER, enums.role.STUDENT]}
                                     title="Session Detail" path="/session/:id"
                                     component={pages.SessionDetail}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.TEACHER, enums.role.STUDENT]}
                                     title="Profile" path="/profile"
                                     component={pages.Profile}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.TEACHER, enums.role.STUDENT]}
                                     title={language.TITLE_EDIT_PROFILE} path="/edit-profile"
                                     component={pages.EditProfile}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN, enums.role.TEACHER, enums.role.STUDENT]}
                                     title="Reports" path="/reports"
                                     component={pages.ReportsNavigator}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN]}
                                     title={language.TITLE_REPORT_OVERALL_SUMMARY} path="/report/overall-summary"
                                     component={pages.OverallSummaryDetail}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN, enums.role.TEACHER, enums.role.STUDENT]}
                                     title={language.TITLE_REPORT_CLASS} path="/report/class"
                                     component={pages.ClassReportDetail}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN, enums.role.TEACHER, enums.role.STUDENT]}
                                     title="Session Report" path="/report/session"
                                     component={pages.SessionReportDetail}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN, enums.role.TEACHER]}
                                     title={language.TITLE_REPORT_TEACHER} path="/report/teacher"
                                     component={pages.TeacherReportDetail}/>
                            <MyRoute authorization={true}
                                     allowed={[enums.role.INSTITUTE_ADMIN, enums.role.TEACHER]}
                                     title={language.TITLE_REPORT_STUDENT} path="/report/student"
                                     component={pages.StudentReportDetail}/>
                            <MyRoute title="Accept User Invite" path="/accept-user-invite"
                                     component={pages.AcceptUserInvite}/>
                            <MyRoute title="Reject User Invite" path="/reject-user-invite"
                                     component={pages.RejectUserInvite}/>
                            <MyRoute title="Accept User Invite" path="/accept-class-invite"
                                     component={pages.AcceptClassInvite}/>
                            <MyRoute title="Reject User Invite" path="/reject-class-invite"
                                     component={pages.RejectClassInvite}/>
                            <MyRoute title="Sign Up Teacher" path="/signup-teacher"
                                     component={pages.SignUpTeacher}/>
                            <MyRoute title="Sign Up Student" path="/signup-student"
                                     component={pages.SignUpStudent}/>
                            <MyRoute title="Accept Sign Up Invite" path="/accept-signup-invite"
                                     component={pages.AcceptSignUpInvite}/>
                            <MyRoute title="Reject Sign Up Invite" path="/reject-signup-invite"
                                     component={pages.RejectSignUpInvite}/>

                            <Route path="*" component={pages.NotFound}/>
                        </Switch>
                    </div>
                    <Footer/>
                    {
                        (this.props.isInstituteBlocked && !this.props.isFrontEnd)
                        &&
                        (
                            <FullScreenInstituteBlocked />
                        )
                    }
                    {
                        !isInternetConnection
                        &&
                        (
                            <FullScreenNoInternet />
                        )
                    }
                </div>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        isFrontEnd: state.app.isFrontEnd,
        user: state.auth.user,
        isInstituteBlocked: state.auth.isInstituteBlocked,
    };
}

const mapDispatchToProps = {
    routeChanged,
    validateToken,
    recurring_validate_token,
};

export default connect(
    mapStateToProps, mapDispatchToProps
)(App);
