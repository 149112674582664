import React, {Component} from 'react';
import logo from '../../../assets/images/logo.png';
import logo_full from '../../../assets/images/OneScreenAttend.png';
import logo_full_dark from '../../../assets/images/OneScreenAttend_dark.png';
import {
    Collapse, Navbar, NavbarToggler,
    NavbarBrand, Nav, NavItem,
    NavLink, UncontrolledDropdown, DropdownToggle,
    DropdownItem, DropdownMenu
} from 'reactstrap';
import './header.css';
import {connect} from "react-redux";
import history from '../../../helpers/history';
import FormModal from "../../Common/FormModal";
import ChangePassword from "../../Modals/ChangePassword";
import {logout} from '../../../store/auth/actions';
import {
    checkLinkForActive,
    getConfirmAlertMessage,
    getDashboardLinkByRole,
    getFirstNameFromFullName, getRoleIdFromUser, getFullnameFromFirstAndLastNames,
    getRoleString, hideExtraContent, isDroidMobileApp, isIOSMobileApp, isMobile, isMobileByWidth
} from "../../../helpers/helper";
import enums from "../../../helpers/enums";
import confirm from "../../../thirdparty/reactstrap-confirm";
import {switch_account, update_presence} from "../../../services/User";
import MarkAttendance from "./MarkAttendance";
import classnames from "classnames";
import language from "../../../helpers/language";
import OtherAccounts from "../OtherAccounts";
import SignUpModal from "../../Modals/SignUpModal";
import ScrollspyNav from "../../Common/ScrollspyNav";

class Header extends Component {
    constructor(props) {
        super(props);

        let navbarColor = "sa-navbar-light";
        if (this.props.isFrontEnd === false) {
            navbarColor = "sa-navbar-dark";
        }

        this.state = {
            isOpen: false,
            navbarColor: navbarColor,
            fixedTop: 'fixed-top',
            presencePollingInterval: 3000,
            isPollingPresence: false,
        };
        this.toggle = this.toggle.bind(this);
        this.logout = this.logout.bind(this);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.isFrontEnd) {
            this.setState({navbarColor: "sa-navbar-dark", isOpen: false,});
        } else if (nextProps.isFrontEnd) {
            this.setState({navbarColor: "sa-navbar-light", isOpen: false,});
        }

        if (nextProps.isLoggedIn) {
            this.startPresencePolling();
        } else {
            this.stopPresencePolling();
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                isOpen: false
            });
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    async logout() {
        let result = await confirm(
            getConfirmAlertMessage('Confirm', language.CONFIRM_LOGOUT)
        );
        if (result) {
            this.props.logout();
        }
    }

    navbarCollapse() {
        let navbarCollapse = '';
        let navBarContent = this.navBarContent();

        if (!this.props.isLoggedIn && !this.props.isFrontEnd) {
            navbarCollapse = '';
        } else {
            navbarCollapse = (
                <React.Fragment>
                    {
                        (!this.props.isFrontEnd && getRoleIdFromUser(this.props.user) === enums.role.SUPER_ADMIN)
                        &&
                        (
                            <span className="sa-header-user">
                                <span className="text-muted align-middle">Welcome </span>
                                <div className="d-md-none" style={{height: 0}}><br/></div><span
                                className="overflow-name">{getFullnameFromFirstAndLastNames(this.props.user.firstName, this.props.user.lastName)}</span>
                            </span>
                        )
                    }
                    {
                        (getRoleIdFromUser(this.props.user) === enums.role.STUDENT && !this.props.isFrontEnd)
                        &&
                        (
                            <MarkAttendance/>
                        )
                    }
                    {
                        !this.props.isHideMenu && <NavbarToggler onClick={this.toggle}/>
                    }
                    <Collapse className="ml-auto" isOpen={this.state.isOpen} navbar>
                        {navBarContent}
                    </Collapse>
                </React.Fragment>
            );
        }

        return navbarCollapse;
    }

    navBarContent() {
        let navbarContent = '';
        if (!this.props.isHideMenu) {
            if (!this.props.isLoggedIn) {
                navbarContent = (
                    <React.Fragment>

                        <ScrollspyNav
                            scrollTargetIds={['login', 'why-us', 'how-it-works', 'whats-new', 'pricing', 'our-app']}
                            activeNavClass="activeLink"
                            scrollDuration="100"
                        >

                            <NavItem>
                                <NavLink href="#login" className="activeLink"
                                         onClick={() => history.push('/#home')}>
                                    Home
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink href="#why-us"
                                         onClick={() => history.push('/#why-us')}>
                                    Why Us
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink href="#how-it-works"
                                         onClick={() => history.push('/#how-it-works')}>How it Works</NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink href="#whats-new"
                                         onClick={() => history.push('/#whats-new')}>What's New</NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink href="#pricing"
                                         onClick={() => history.push('/#pricing')}>Pricing</NavLink>
                            </NavItem>
                            {
                                (!isDroidMobileApp() && !isIOSMobileApp())
                                &&
                                (
                                    <NavItem>
                                        <NavLink href="#our-app"
                                                 onClick={() => history.push('/#our-app')}>Get Our App</NavLink>
                                    </NavItem>
                                )
                            }
                            {
                                isMobileByWidth()
                                &&
                                (
                                    <NavItem>
                                        <NavLink href="#login"
                                                 onClick={() => history.push('/#login')}>Login</NavLink>
                                    </NavItem>
                                )
                            }

                        </ScrollspyNav>

                    </React.Fragment>
                );
            } else {
                let otherAccountsContent = this.otherAccountsContent();
                navbarContent = (
                    <React.Fragment>
                        <Nav className="ml-auto" navbar>
                            {
                                (this.props.user)
                                &&
                                (getRoleIdFromUser(this.props.user) === enums.role.SUPER_ADMIN)
                                &&
                                (
                                    <React.Fragment>
                                        <NavItem>
                                            <FormModal classes={`nav-link`} isAnchor={`true`}
                                                       modalContent={<ChangePassword/>}>
                                                Change Password
                                            </FormModal>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#" onClick={this.logout}>Sign out</NavLink>
                                        </NavItem>
                                    </React.Fragment>
                                )
                            }
                            {
                                this.props.user
                                &&
                                (
                                    <React.Fragment>
                                        {
                                            (getRoleIdFromUser(this.props.user) === enums.role.INSTITUTE_ADMIN ||
                                                getRoleIdFromUser(this.props.user) === enums.role.TEACHER ||
                                                getRoleIdFromUser(this.props.user) === enums.role.STUDENT)
                                            &&
                                            (
                                                <NavItem>
                                                    <NavLink href="#"
                                                             className={classnames({active: checkLinkForActive(['dashboard'])})}
                                                             onClick={() => history.push('/dashboard')}>Dashboard</NavLink>
                                                </NavItem>
                                            )
                                        }
                                        {
                                            (getRoleIdFromUser(this.props.user) === enums.role.INSTITUTE_ADMIN)
                                            &&
                                            (
                                                <React.Fragment>
                                                    <NavItem>
                                                        <NavLink href="#"
                                                                 className={classnames({active: checkLinkForActive(['teachers'])})}
                                                                 onClick={() => history.push('/teachers')}>Teachers</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="#"
                                                                 className={classnames({active: checkLinkForActive(['students'])})}
                                                                 onClick={() => history.push('/students')}>Students</NavLink>
                                                    </NavItem>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            (getRoleIdFromUser(this.props.user) === enums.role.INSTITUTE_ADMIN ||
                                                getRoleIdFromUser(this.props.user) === enums.role.TEACHER ||
                                                getRoleIdFromUser(this.props.user) === enums.role.STUDENT)
                                            &&
                                            (
                                                <React.Fragment>
                                                    <NavItem>
                                                        <NavLink href="#"
                                                                 className={classnames({active: checkLinkForActive(['classes'])})}
                                                                 onClick={() => history.push('/classes')}>Classes</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="#"
                                                                 className={classnames({active: checkLinkForActive(['reports'])})}
                                                                 onClick={() => history.push('/reports')}>Attendance</NavLink>
                                                    </NavItem>
                                                    <NavItem className="divider"/>
                                                    <UncontrolledDropdown nav inNavbar>
                                                        <DropdownToggle
                                                            className={classnames({active: checkLinkForActive(['settings', 'profile'])})}
                                                            nav caret>
                                                            {getFirstNameFromFullName(getFullnameFromFirstAndLastNames(this.props.user.firstName, this.props.user.lastName))}
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <DropdownItem header className="header-full-name-dropdown">
                                                                {hideExtraContent(getFullnameFromFirstAndLastNames(this.props.user.firstName, this.props.user.lastName))}
                                                            </DropdownItem>
                                                            <DropdownItem header className="header-user-info-dropdown">
                                                                {getRoleString(getRoleIdFromUser(this.props.user))}
                                                                <br/>
                                                                {hideExtraContent(this.props.user.email)}
                                                            </DropdownItem>
                                                            {
                                                                getRoleIdFromUser(this.props.user) === enums.role.INSTITUTE_ADMIN
                                                                &&
                                                                (
                                                                    <DropdownItem
                                                                        className={classnames({active: checkLinkForActive(['settings'])})}
                                                                        onClick={() => history.push("/settings")}>
                                                                        <i className="fa fa-cogs mr-3"/>Settings
                                                                    </DropdownItem>
                                                                )
                                                            }
                                                            {
                                                                (getRoleIdFromUser(this.props.user) === enums.role.TEACHER ||
                                                                    getRoleIdFromUser(this.props.user) === enums.role.STUDENT)
                                                                &&
                                                                (
                                                                    <DropdownItem
                                                                        className={classnames({active: checkLinkForActive(['profile'])})}
                                                                        onClick={() => history.push("/profile")}>
                                                                        <i className="fa fa-user mr-3"/>Profile
                                                                    </DropdownItem>
                                                                )
                                                            }
                                                            <FormModal isDropDown={`true`}
                                                                       modalContent={<ChangePassword/>}>
                                                                <i className="fa fa-unlock-alt mr-3"/>Change Password
                                                            </FormModal>
                                                            <DropdownItem onClick={this.logout}>
                                                                <i className="fa fa-power-off mr-3"/>Sign out
                                                            </DropdownItem>
                                                            <FormModal isDropDown={`true`} modalContent={<SignUpModal
                                                                user={this.props.user}/>}>
                                                                <i className="fa fa-user-plus mr-3"/>Join other
                                                                Institutes
                                                            </FormModal>
                                                            {otherAccountsContent}
                                                            <DropdownItem header className="version-info-dropdown">
                                                                OneScreen Attend v 1.0.0
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </React.Fragment>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            }
                        </Nav>
                    </React.Fragment>
                );
            }
        }

        return navbarContent;
    }

    otherAccountsContent() {
        const {otherAccounts} = this.props;
        let navItems;
        if (otherAccounts && otherAccounts.length > 0) {
            navItems = otherAccounts.map((item, index) => {
                return (
                    <DropdownItem key={index} onClick={() => {
                        switch_account(item.instituteId, item.userId, item.roleId, () => {/* empty function */
                        })
                    }}>
                        <span className="switch-role-dropdown-item-navbar">{getRoleString(item.roleId)}</span><br/>
                        <span className="switch-institute-dropdown-item-navbar">{item.instituteName}</span>
                    </DropdownItem>
                );

            });
            return (
                <React.Fragment>
                    <DropdownItem divider/>
                    <DropdownItem header className="header-switch-dropdown">Switch Account</DropdownItem>
                    <OtherAccounts isDropDown={true}/>
                </React.Fragment>
            );
        }
    }

    render() {
        let {navbarColor, fixedTop} = this.state;
        let navbarCollapse = this.navbarCollapse();
        let linkHome;
        if (this.props.user) {
            linkHome = getDashboardLinkByRole(getRoleIdFromUser(this.props.user));
        } else {
            linkHome = getDashboardLinkByRole('');
        }

        let navbarClasses = navbarColor + " " + fixedTop;

        return (
            <header className="sa-header" ref={this.setWrapperRef}>
                <Navbar className={navbarClasses} color={navbarColor} dark expand="lg">
                    <NavbarBrand href="#" onClick={() => history.push(linkHome)}>
                        {
                            (isMobile() || isMobileByWidth())
                            &&
                            (
                                <img src={logo} alt={language.TITLE_SELF_ATTENDANCE}/>
                            )
                        }
                        {
                            (!(isMobile() || isMobileByWidth()) && this.props.isFrontEnd)
                            &&
                            (
                                <img src={logo_full_dark} alt={language.TITLE_SELF_ATTENDANCE} height="50"/>
                            )
                        }
                        {
                            (!(isMobile() || isMobileByWidth()) && !this.props.isFrontEnd)
                            &&
                            (
                                <img src={logo_full} alt={language.TITLE_SELF_ATTENDANCE} height="50"/>
                            )
                        }
                    </NavbarBrand>
                    {navbarCollapse}
                </Navbar>
            </header>
        );
    }

    startPresencePolling() {
        if (!this.state.isPollingPresence) {
            let pollingTimer = setInterval(() => {
                update_presence(localStorage.getItem('swapPublisherId'), (response) => {

                });
            }, this.state.presencePollingInterval);

            this.setState({
                presencePollingTimer: pollingTimer,
                isPollingPresence: true
            });
        }
    }

    stopPresencePolling() {
        if (this.state.isPollingPresence) {
            clearInterval(this.state.presencePollingTimer);
            this.setState({
                isPollingPresence: false
            });
        }
    }
}

function mapStateToProps(state) {
    return {
        isFrontEnd: state.app.isFrontEnd,
        isHideMenu: state.app.isHideMenu,
        isLoggedIn: state.auth.isLoggedIn,
        user: state.auth.user,
        otherAccounts: state.auth.otherAccounts,
    };
}

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);