const development = {
    API_ENDPOINT_V1: 'https://192.168.2.62:1337/api/v1/',
    FRONTEND_URL: 'https://192.168.2.62:3006',
    PORT: '3000',
    LIMIT_N_ROWS: '10',
    SORT_BY_NAME: 'date',
    SORT_BY_ORDER: 'DESC',
    LIMIT_N_ROWS_DROPDOWN: '50',
    POLLING_INTERVAL_GENERAL: '5000',
    POLLING_INTERVAL_ATTENDANCE: '4000',
    SWAP_PUBLISHER_DOWNLOAD_LINK: '',
    GOOGLE_ANALYTICS: 'ON',
    GOOGLE_ANALYTICS_ID: 'UA-150714754-1'
};
const staging = {
    API_ENDPOINT_V1: 'https://staging3.onescreensolutions.com:1337/api/v1/',
    FRONTEND_URL: 'https://staging3.onescreensolutions.com:3006',
    PORT: '3006',
    LIMIT_N_ROWS: '10',
    SORT_BY_NAME: 'date',
    SORT_BY_ORDER: 'DESC',
    LIMIT_N_ROWS_DROPDOWN: '50',
    POLLING_INTERVAL_GENERAL: '5000',
    POLLING_INTERVAL_ATTENDANCE: '4000',
    SWAP_PUBLISHER_DOWNLOAD_LINK: '',
    GOOGLE_ANALYTICS: 'OFF',
    GOOGLE_ANALYTICS_ID: 'UA-150714754-1'
};
const production = {
    API_ENDPOINT_V1: 'https://attend.onescreensolutions.com:1337/api/v1/',
    FRONTEND_URL: 'https://attend.onescreensolutions.com',
    PORT: '443',
    LIMIT_N_ROWS: '10',
    SORT_BY_NAME: 'date',
    SORT_BY_ORDER: 'DESC',
    LIMIT_N_ROWS_DROPDOWN: '50',
    POLLING_INTERVAL_GENERAL: '5000',
    POLLING_INTERVAL_ATTENDANCE: '4000',
    SWAP_PUBLISHER_DOWNLOAD_LINK: '',
    GOOGLE_ANALYTICS: 'ON',
    GOOGLE_ANALYTICS_ID: 'UA-150714754-1'
};

let config;
if(process.env.REACT_APP_ENV === 'production') {
    config = production
} else if(process.env.REACT_APP_ENV === 'staging') {
    config = staging
} else {
    config = development
}

export default {
    ...config
};
