import React from 'react';
import {
    Row, Col, Form, Modal, ModalHeader, ModalBody, Button, Input, Alert
} from 'reactstrap';
import {markAttendanceStudent, clearMarkAttendanceStudent} from "../../store/user/actions";
import {connect} from "react-redux";
import MyForm from "../Common/MyForm";
import {CommonInput} from "../Common/MyFields";
import {CancelButton, LoaderButton} from "../Common/MyButtons";
import {
    calculateDifferenceInMinutes,
    clickCloseButton,
    convertMySqlToHumanReadable, isDroidMobileApp, isIOSMobileApp,
    isMobile, openDroidNativeCamera, openIOSNativeCamera,
    validateIsEmpty
} from "../../helpers/helper";
import {SmallTextWithoutIcon} from "../Common/MyDetailPageFunc";
import ReadQRCodeUsingCamera from "./ReadQRCodeUsingCamera";
import enums from "../../helpers/enums";
import {geolocated} from "react-geolocated";
import language from "../../helpers/language";

class MarkAttendanceModal extends MyForm {
    constructor(props) {
        super(props);

        this.state = {
            QRCodeModal: false,
            remainingTime: ''
        };

        this.tick = this.tick.bind(this);
        this.toggleQRCodeModal = this.toggleQRCodeModal.bind(this);
        this.openQRCodeModal = this.openQRCodeModal.bind(this);
        this.closeQRCodeModal = this.closeQRCodeModal.bind(this);
        this.QRCodeAction = this.QRCodeAction.bind(this);
    }

    componentWillMount() {
        this.props.clearMarkAttendanceStudent();
        this.setInitialState();

        // Calling native function
        if (window.native !== undefined && window.native.getDeviceInfoForMarkingAttendance !== undefined) {
            console.log("Calling Native Refresh Device Info");
            window.native.getDeviceInfoForMarkingAttendance();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.markAttendanceSuccessMsg !== '') {
            this.resetState();
            clickCloseButton();
        }

        if (this.props.coords !== null && this.props.coords !== undefined) {
            const {fields} = this.state;
            fields['Longitude'] = this.props.coords.longitude.toString();
            fields['Latitude'] = this.props.coords.latitude.toString();
            this.setState({fields: fields});
        }
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        const {data} = this.props;
        if (data.endsAt !== undefined) {
            this.setState({
                remainingTime: calculateDifferenceInMinutes(data.endsAt)
            });
        }
    }

    setInitialState() {
        const {data} = this.props;
        this.setState({
            fields: {
                sessionCode: '',
                Latitude: '',
                Longitude: '',
                classId: data.class ? data.class.id.toString() : '',
            },
            validationErrors: {
                sessionCode: '',
            },
            notRequiredFields: ["Latitude", "Longitude"]
        });
    }

    submitFormUsingProps() {
        let {fields} = this.state;
        console.log(fields);
        this.props.markAttendanceStudent(fields);
    }

    customValidation(fieldName, value) {
        const {validationErrors} = this.state;
        switch (fieldName) {
            default:
                if (!validateIsEmpty(value)) {
                    validationErrors[fieldName] = '';
                }
                break;
        }
    }

    openQRCodeModal() {
        this.setState({
            QRCodeModal: true
        });
    }

    closeQRCodeModal() {
        this.setState({
            QRCodeModal: false
        });
    }

    toggleQRCodeModal() {
        this.setState({
            QRCodeModal: !this.state.QRCodeModal
        });
    }

    QRCodeAction(code) {
        this.closeQRCodeModal();
        const {isMarkAttendanceRequested} = this.props;
        const {fields} = this.state;
        fields['sessionCode'] = code;
        if (!isMarkAttendanceRequested) {
            this.setState({fields: fields}, () => {
                this.props.markAttendanceStudent(fields);
            });
        }
    }

    render() {
        const {remainingTime, fields, isValidationRun, isFormValid, validationErrors} = this.state;
        const {isMarkAttendanceRequested, data, canBrowserShowSuggestions} = this.props;
        return (
            <React.Fragment>
                <Row>
                    <Col className="text-center" sm="12">
                        <h1 className="text-uppercase mb-4">{language.TITLE_MARK_ATTENDANCE}</h1>
                        <hr className="my-2 mt-3"/>
                        <Row className="text-left font-0-9rem font-family-lato">
                            <Col xs="6">
                                <SmallTextWithoutIcon heading="CLASS" isLarge={true}>
                                    {data.class ? data.class.name : "---"}
                                </SmallTextWithoutIcon>
                            </Col>
                            <Col xs="6">
                                <SmallTextWithoutIcon heading="Date" isLarge={true}>
                                    {data.startedAt ? convertMySqlToHumanReadable(data.startedAt) : "---"}
                                </SmallTextWithoutIcon>
                            </Col>
                            <Col xs="6">
                                <SmallTextWithoutIcon heading="Teacher" isLarge={true}>
                                    {(data.class.teacher !== undefined && data.class.teacher.firstName? data.class.teacher.firstName : "---") +
                                    (data.class.teacher !== undefined && data.class.teacher.lastName? data.class.teacher.lastName : "---")}
                                </SmallTextWithoutIcon>
                            </Col>
                            <Col xs="6">
                                <SmallTextWithoutIcon heading="Remaining Time" isLarge={true}>
                                    {remainingTime}
                                </SmallTextWithoutIcon>
                            </Col>
                        </Row>
                        <hr className="my-2 mb-3"/>
                    </Col>
                    <Col className="text-center" sm={{size: 10, offset: 1}}>
                        {
                            (!this.props.isGeolocationAvailable && !(!isMobile() && (data.acceptAttendanceBy === enums.acceptAttendanceBy.QRCODE)))
                            &&
                            (
                                <Alert color="warning">
                                    <strong>Warning:</strong> Your browser does not support Geolocation.
                                </Alert>
                            )
                        }
                        {
                            (!this.props.isGeolocationEnabled && !(!isMobile() && (data.acceptAttendanceBy === enums.acceptAttendanceBy.QRCODE)))
                            &&
                            (
                                <Alert color="warning">
                                    <strong>Warning:</strong> Geolocation is not enabled in your device.
                                </Alert>
                            )
                        }
                        <Form className="text-center" onSubmit={this.handleSubmit}
                              autoComplete={canBrowserShowSuggestions ? 'on' : 'off'}>
                            <Input type="hidden" name="Longitude" value={fields.Longitude}/>
                            <Input type="hidden" name="Latitude" value={fields.Latitude}/>

                            {
                                (isMobile() &&
                                    (data.acceptAttendanceBy === enums.acceptAttendanceBy.BOTH ||
                                        data.acceptAttendanceBy === enums.acceptAttendanceBy.QRCODE))
                                &&
                                (
                                    <React.Fragment>
                                        {
                                            isIOSMobileApp()
                                            &&
                                            (
                                                <Button type="button" className="btn-block mb-2" color="blue"
                                                        onClick={openIOSNativeCamera}>
                                                    Mark Using QRCode
                                                </Button>
                                            )
                                        }
                                        {
                                            isDroidMobileApp()
                                            &&
                                            (
                                                <Button type="button" className="btn-block mb-2" color="blue"
                                                        onClick={openDroidNativeCamera}>
                                                    Mark Using QRCode
                                                </Button>
                                            )
                                        }
                                        {
                                            (!isIOSMobileApp() && !isDroidMobileApp())
                                            &&
                                            (
                                                <Button type="button" className="btn-block mb-2" color="blue"
                                                        onClick={this.openQRCodeModal}>
                                                    Mark Using QRCode
                                                </Button>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            }
                            {
                                (isMobile() && (data.acceptAttendanceBy === enums.acceptAttendanceBy.BOTH))
                                &&
                                (
                                    <div className="text-center my-2">
                                        OR
                                        <br/>
                                    </div>
                                )
                            }
                            {
                                (data.acceptAttendanceBy === enums.acceptAttendanceBy.BOTH || data.acceptAttendanceBy === enums.acceptAttendanceBy.SESSIONCODE)
                                &&
                                (
                                    <React.Fragment>
                                        <CommonInput type="text" name="sessionCode" title="Session Code"
                                                     value={fields.sessionCode}
                                                     error={validationErrors.sessionCode} onChange={this.handleChange}/>
                                        <LoaderButton btnLabel="Mark" isRequest={isMarkAttendanceRequested}
                                                      isFormValid={isFormValid}
                                                      isValidationRun={isValidationRun}/>
                                    </React.Fragment>
                                )
                            }
                            {
                                (!isMobile() && (data.acceptAttendanceBy === enums.acceptAttendanceBy.QRCODE))
                                &&
                                (
                                    <Alert color="warning" className="mb-4 mb-5 text-center">
                                        Please use mobile app to mark attendance. Your teacher has blocked the
                                        attendance
                                        through WEB.
                                    </Alert>
                                )
                            }
                            <CancelButton btnLabel="Cancel" onClick={clickCloseButton}/>
                        </Form>
                        <br/>
                    </Col>
                </Row>
                <Modal className="modal-large" isOpen={this.state.QRCodeModal} toggle={this.toggleQRCodeModal}
                       backdrop={`static`}>
                    <ModalHeader toggle={this.toggleQRCodeModal}>&nbsp;</ModalHeader>
                    <ModalBody>
                        <ReadQRCodeUsingCamera QRCodeAction={this.QRCodeAction}
                                               closeQRCodeModal={this.closeQRCodeModal}/>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    markAttendanceStudent,
    clearMarkAttendanceStudent
};

function mapStateToProps(state) {
    return {
        isMarkAttendanceRequested: state.user.isMarkAttendanceRequested,
        markAttendanceSuccessMsg: state.user.markAttendanceSuccessMsg,
        markAttendanceErrorMsg: state.user.markAttendanceErrorMsg,
    };
}

const MarkAttendanceModalWithRedux = connect(mapStateToProps, mapDispatchToProps, null, {withRef: true})(MarkAttendanceModal);

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
})(MarkAttendanceModalWithRedux);