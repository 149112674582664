import React from 'react';
import {
    Row, Col, Form, FormFeedback, FormGroup
} from 'reactstrap';
import {clearSignUpStudent, signUpStudent} from "../../store/user/actions";
import {connect} from "react-redux";
import MyForm from "../Common/MyForm";
import {CancelButton, LoaderButton} from "../Common/MyButtons";
import {
    checkIfNull,
    clickCloseChildButton, getAreaAndPhoneFromContact,
    getInstituteOptionsForTeacherAndStudents,
    validateIsEmpty
} from "../../helpers/helper";
import AsyncSelect from 'react-select/lib/Async';
import language from "../../helpers/language";
import {
    getAreaCodeValidationMessage,
    getCountryCodeValidationMessage, getEmailValidationMessage,
    getPhoneNumberValidationMessage
} from "../../helpers/validationHelpers";
import '../Pages/Reports/reports.css';
import {CommonInput, PhoneNumberInputParent} from "../Common/MyFields";

class SignUpStudentForOtherInstitute extends MyForm {
    constructor(props) {
        super(props);

        this.isParentInfoAlreadyCompleted = this.isParentInfoAlreadyCompleted.bind(this);
    }

    componentDidMount() {
        this.props.clearSignUpStudent();
        this.setInitialState();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.signUpStudentSuccessMsg !== '') {
            this.resetState();
            clickCloseChildButton();
        }
    }

    setInitialState() {
        let notRequiredFields = [];
        const {user} = this.props;
        let isParentInfoRequired = user.isParentInfoRequired !== undefined && user.isParentInfoRequired === 1;
        if (!isParentInfoRequired) {
            notRequiredFields.push("parentFirstName");
            notRequiredFields.push("parentLastName");
            notRequiredFields.push("parentEmail");
            notRequiredFields.push("parentCountry");
            notRequiredFields.push("parent_area_code");
            notRequiredFields.push("parent_phone_number");
        }
        let parent = user.parent !== undefined ? user.parent : {
            firstName: '',
            lastName: '',
            email: '',
            country: '',
            contactNumber: '',
        };
        let contactNumber = getAreaAndPhoneFromContact(user.contactNumber);
        let contactNumberParent = getAreaAndPhoneFromContact(parent.contactNumber);
        this.setState({
            fields: {
                instituteId: '',
                firstName: checkIfNull(user.firstName),
                lastName: checkIfNull(user.lastName),
                email: checkIfNull(user.email),
                country: checkIfNull(user.country),
                area_code: contactNumber['area_code'],
                phone_number: contactNumber['phone_number'],
                parentFirstName: checkIfNull(parent.firstName),
                parentLastName: checkIfNull(parent.lastName),
                parentEmail: checkIfNull(parent.email),
                parentCountry: checkIfNull(parent.country),
                parent_area_code: contactNumberParent['area_code'],
                parent_phone_number: contactNumberParent['phone_number'],
            },
            validationErrors: {
                instituteId: '',
                parentFirstName: '',
                parentLastName: '',
                parentEmail: '',
                parentCountry: '',
                parent_area_code: '',
                parent_phone_number: '',
            },
            notRequiredFields: ["parentFirstName", "parentLastName", "parentEmail", "parentCountry", "parent_area_code", "parent_phone_number", "country", "area_code", "phone_number"]
        }, () => {
            this.setState({
                isParentInfoInitiallySet: this.isParentInfoAlreadyCompleted()
            });
        });
    }

    submitFormUsingProps() {
        const {fields} = this.state;
        const joinOther = 'Join Other Institutes';
        this.props.signUpStudent(fields, joinOther);
    }

    customValidation(fieldName, value) {
        const {validationErrors} = this.state;
        switch (fieldName) {
            case 'parentEmail':
                validationErrors.parentEmail = getEmailValidationMessage(value);
                break;
            case 'parentCountry':
                validationErrors.parentCountry = getCountryCodeValidationMessage(value);
                break;
            case 'parent_area_code':
                validationErrors.parent_area_code = getAreaCodeValidationMessage(value);
                break;
            case 'parent_phone_number':
                validationErrors.parent_phone_number = getPhoneNumberValidationMessage(value);
                break;
            default:
                if (!validateIsEmpty(value)) {
                    validationErrors[fieldName] = '';
                }
                break;
        }
    }

    handleChangeReactSelect(name, value) {
        let notRequiredFields = [];
        let {isParentInfoRequired} = value;
        if (!isParentInfoRequired) {
            notRequiredFields.push("parentFirstName");
            notRequiredFields.push("parentLastName");
            notRequiredFields.push("parentEmail");
            notRequiredFields.push("parentCountry");
            notRequiredFields.push("parent_area_code");
            notRequiredFields.push("parent_phone_number");
        }
        notRequiredFields.push("country");
        notRequiredFields.push("area_code");
        notRequiredFields.push("phone_number");
        this.setState({
            notRequiredFields: notRequiredFields
        }, () => {
            this.setState({
                validationErrors: {
                    instituteId: '',
                    parentFirstName: '',
                    parentLastName: '',
                    parentEmail: '',
                    parentCountry: '',
                    parent_area_code: '',
                    parent_phone_number: '',
                },
            });
        });

        super.handleChangeReactSelect(name, value);
    }

    isParentInfoAlreadyCompleted() {
        const {fields} = this.state;
        if (
            fields.parentFirstName !== "" &&
            fields.parentLastName !== "" &&
            fields.parentEmail !== "" &&
            fields.parentCountry !== "" &&
            fields.parent_area_code !== "" &&
            fields.parent_phone_number !== ""
        ) {
            return true;
        }
    }

    render() {
        console.log(this.state);
        const {fields, validationErrors, isFormValid, isValidationRun, isFormChanged, notRequiredFields} = this.state;
        const {isSigningUpTeacher, canBrowserShowSuggestions} = this.props;
        return (
            <React.Fragment>
                <Row>
                    <Col className="text-center" sm={{size: 10, offset: 1}}>
                        <h1 className="text-uppercase">{language.TITLE_JOIN_AS_STUDENT}</h1>
                        <br/>

                        <Form className="text-center" onSubmit={this.handleSubmit}
                              autoComplete={canBrowserShowSuggestions ? 'on' : 'off'}>
                            <div className="form-title">
                                INSTITUTE INFO
                            </div>
                            <FormGroup className="attendance_body white">
                                <AsyncSelect cacheOptions defaultOptions className="text-left"
                                             name="instituteId"
                                             clearValue={(value) => this.handleChangeReactSelect('instituteId', value)}
                                             onChange={(value) => this.handleChangeReactSelect('instituteId', value)}
                                             value={fields.instituteId}
                                             loadOptions={getInstituteOptionsForTeacherAndStudents}
                                             noOptionsMessage={(inputValue) => {
                                                 return 'No Institutes Available'
                                             }}
                                             blurInputOnSelect={true}
                                             placeholder="Select Institute"
                                             classNamePrefix="react-select"
                                             instanceId="12345678"
                                />
                                <FormFeedback className="d-block">{validationErrors.instituteId}</FormFeedback>
                            </FormGroup>
                            {
                                (!this.state.isParentInfoInitiallySet && notRequiredFields.indexOf("parentFirstName") === -1 && notRequiredFields.indexOf("parentLastName") === -1)
                                &&
                                (
                                    <React.Fragment>
                                        <div className="form-title">
                                            PARENT INFO
                                            <span className="mr-1 text-danger font-weight-bold">*</span>
                                        </div>

                                        <Row>
                                            <Col md="6">
                                                <CommonInput type="text" name="parentFirstName" title="First Name"
                                                             value={fields.parentFirstName}
                                                             error={validationErrors.parentFirstName}
                                                             onChange={this.handleChange}/>
                                            </Col>
                                            <Col md="6">
                                                <CommonInput type="text" name="parentLastName" title="Last Name"
                                                             value={fields.parentLastName}
                                                             error={validationErrors.parentLastName}
                                                             onChange={this.handleChange}/>
                                            </Col>
                                            <Col md="6">
                                                <CommonInput type="email" name="parentEmail" title="Email"
                                                             value={fields.parentEmail}
                                                             error={validationErrors.parentEmail}
                                                             onChange={this.handleChange}/>
                                            </Col>
                                            <Col md="12">
                                                <PhoneNumberInputParent fields={fields} errors={validationErrors}
                                                                        onChange={this.handleChange}/>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )
                            }

                            <br/>

                            <LoaderButton btnLabel="Join" isRequest={isSigningUpTeacher} isFormValid={isFormValid}
                                          isValidationRun={isValidationRun} isFormChanged={isFormChanged}/>
                            <CancelButton btnLabel="Cancel" onClick={clickCloseChildButton}
                                          isFormChanged={isFormChanged}/>
                            <br/>
                            <br/>
                        </Form>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    signUpStudent,
    clearSignUpStudent
};

function mapStateToProps(state) {
    return {
        isSigningUpStudent: state.user.isSigningUpStudent,
        signUpStudentSuccessMsg: state.user.signUpStudentSuccessMsg,
        signUpStudentErrorMsg: state.user.signUpStudentErrorMsg,

        user: state.auth.user,
    };
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(SignUpStudentForOtherInstitute);
