import * as types from './actionTypes';
import {
    forgot_password,
    reset_password,
    change_password,
    get_teachers,
    add_teacher,
    add_student,
    edit_teacher,
    edit_student,
    get_students,
    get_teacher,
    get_student,
    delete_user,
    block_user,
    unblock_user,
    accept_drop_request_user,
    reject_drop_request_user,
    delete_invite_user,
    resend_invite_user,
    reset_password_users,
    get_classes_wrt_teachers,
    assigned_classes_teacher,
    get_classes_wrt_students,
    assigned_classes_student,
    unassigned_classes_student,
    unassigned_classes_teacher,
    get_teacher_with_data,
    get_student_with_data,
    edit_profile_user,
    import_students_to_institute,
    import_teachers_to_institute,
    signup_teacher,
    signup_student,
    accept_user_invite,
    reject_user_invite,
    change_password_users, do_login
} from "../../services/User";
import language from "../../helpers/language";
import enums from "../../helpers/enums";
import {
    getDashboardLinkByRole,
    getRoleIdFromUser,
    showAlertMessage,
    showConnectionErrorAlert
} from "../../helpers/helper";
import {registerEvent} from '../../helpers/googleAnalytics';
import store from "../store";
import {get_active_sessions, mark_attendance} from "../../services/Class";
import history from "../../helpers/history";

export function forgotPassword(email) {
    return (dispatch) => {
        dispatch({type: types.FORGOT_PASSWORD_REQUEST});
        const alertOption = {
            heading: language.TITLE_FORGOT_PASSWORD,
            type: "error"
        };

        forgot_password(email, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";

                let role = '';
                let roleId = response.data.roleId;
                if (roleId === enums.role.INSTITUTE_ADMIN){
                    role = 'Institute Admin';
                } else if (roleId === enums.role.TEACHER){
                    role = 'Teacher';
                } else if (roleId === enums.role.STUDENT){
                    role = 'Student';
                }
                // Fire Analytics Event
                registerEvent(
                    'Authentication',
                    'Forgot Password',
                    role,
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    email,
                    '',
                    ''
                );

                dispatch({type: types.FORGOT_PASSWORD_SUCCESS, forgotPasswordSuccessMsg: response.message});
            } else {
                dispatch({type: types.FORGOT_PASSWORD_FAILURE, forgotPasswordErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearForgotPassword() {
    return (dispatch) => {
        dispatch({type: types.FORGOT_PASSWORD_CLEAR});
    };
}

export function resetPassword(password, code) {
    return (dispatch) => {
        dispatch({type: types.RESET_PASSWORD_REQUEST});
        const alertOption = {
            heading: language.TITLE_RESET_PASSWORD,
            type: "error"
        };

        reset_password(password, code, (response) => {

            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({type: types.RESET_PASSWORD_SUCCESS, resetPasswordSuccessMsg: response.message});
            } else {
                dispatch({type: types.RESET_PASSWORD_FAILURE, resetPasswordErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearResetPassword() {
    return (dispatch) => {
        dispatch({type: types.RESET_PASSWORD_CLEAR});
    };
}

export function forgotPasswordNotMatched() {
    return (dispatch) => {
        dispatch({
            type: types.FORGOT_PASSWORD_NOT_MATCHED,
            forgotPasswordNotMatchedErrorMsg: language.MSG_FORGOT_PASSWORD_NOT_MATCHED
        });
    };
}

export function changePassword(oldPassword, newPassword) {
    return (dispatch) => {
        dispatch({type: types.CHANGE_PASSWORD_REQUEST});
        const alertOption = {
            heading: language.TITLE_CHANGE_PASSWORD,
            type: "error"
        };

        change_password(oldPassword, newPassword, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({type: types.CHANGE_PASSWORD_SUCCESS, changePasswordSuccessMsg: response.message});
            } else {
                dispatch({type: types.CHANGE_PASSWORD_FAILURE, changePasswordErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearChangePassword() {
    return (dispatch) => {
        dispatch({type: types.CHANGE_PASSWORD_CLEAR});
    };
}

export function addTeacher(fieldsData) {
    return (dispatch) => {
        dispatch({type: types.ADD_TEACHER_REQUEST});
        const alertOption = {
            heading: language.TITLE_ADD_TEACHER,
            type: "error"
        };

        add_teacher(fieldsData, (response) => {
            console.log(response);
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Teacher',
                    'Invited',
                    '',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.ADD_TEACHER_SUCCESS,
                    addTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({type: types.ADD_TEACHER_FAILURE, addTeacherErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearAddTeacher() {
    return (dispatch) => {
        dispatch({type: types.ADD_TEACHER_CLEAR});
    };
}

export function editTeacher(fieldsData) {
    return (dispatch) => {
        dispatch({type: types.EDIT_TEACHER_REQUEST});
        const alertOption = {
            heading: language.TITLE_EDIT_TEACHER,
            type: "error"
        };

        edit_teacher(fieldsData, (response) => {

            if (response.code === 0) {
                // Fire Analytics Event
                registerEvent(
                    'Teacher',
                    'Edit Teacher',
                    'Teacher',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                alertOption.type = "success";
                dispatch({
                    type: types.EDIT_TEACHER_SUCCESS,
                    editTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({type: types.EDIT_TEACHER_FAILURE, editTeacherErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearEditTeacher() {
    return (dispatch) => {
        dispatch({type: types.EDIT_TEACHER_CLEAR});
    };
}

export function getTeachers(data) {
    return (dispatch) => {
        dispatch({type: types.LIST_TEACHER_REQUEST});

        get_teachers(data, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.LIST_TEACHER_SUCCESS,
                    isSuccessTeachers: true,
                    teachers: response.teachers,
                    totalCountsTeacher: response.totalCounts
                });
            } else {
                dispatch({
                    type: types.LIST_TEACHER_FAILURE,
                    isFailureTeachers: true
                });
            }
        });
    };
}

export function getTeachersUsingGlobalStates() {
    return (dispatch) => {
        let teacher = store.getState().user;
        let teachersData = {
            searchText: teacher.teacherSearchText,
            filterStatus: teacher.teacherFilterStatus,
            sortByName: teacher.teacherSortByName,
            sortByOrder: teacher.teacherSortByOrder,
            limitNRows: teacher.teacherLimitNRows
        };
        dispatch({type: types.LIST_TEACHER_REQUEST});

        get_teachers(teachersData, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.LIST_TEACHER_SUCCESS,
                    isSuccessTeachers: true,
                    teachers: response.teachers,
                    totalCountsTeacher: response.totalCounts
                });
            } else {
                dispatch({
                    type: types.LIST_TEACHER_FAILURE,
                    isFailureTeachers: true
                });
                showConnectionErrorAlert(response);
            }
        });
    };
}

export function getTeachersContinuously() {
    return (dispatch) => {
        let teacher = store.getState().user;
        let teachersData = {
            searchText: teacher.teacherSearchText,
            filterStatus: teacher.teacherFilterStatus,
            sortByName: teacher.teacherSortByName,
            sortByOrder: teacher.teacherSortByOrder,
            limitNRows: teacher.teacherLimitNRows
        };

        get_teachers(teachersData, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.LIST_TEACHER_SUCCESS,
                    isSuccessTeachers: true,
                    teachers: response.teachers,
                    totalCountsTeacher: response.totalCounts
                });
            }
        });
    };
}

export function updateTeacherListingParams(type, value, secondValue = undefined, callback = undefined) {
    return (dispatch) => {
        switch (type) {
            case "SEARCH":
                dispatch({
                    type: types.UPDATE_TEACHER_LISTING_PARAMS,
                    teacherSearchText: value
                });
                break;
            case "TAB":
                dispatch({
                    type: types.UPDATE_TEACHER_LISTING_PARAMS,
                    teacherActiveTab: value,
                    teacherFilterStatus: secondValue
                });
                break;
            case "SORT":
                dispatch({
                    type: types.UPDATE_TEACHER_LISTING_PARAMS,
                    teacherSortByName: value,
                    teacherSortByOrder: secondValue,
                });
                break;
            case "LOAD_MORE":
                dispatch({
                    type: types.UPDATE_TEACHER_LISTING_PARAMS,
                    teacherLimitNRows: value,
                });
                break;
            default:
            // Do Nothing
        }

        dispatch(getTeachersUsingGlobalStates());
    };
}

export function getSingleTeacher(id) {
    return (dispatch) => {
        dispatch({type: types.SINGLE_TEACHER_REQUEST});

        get_teacher(id, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.SINGLE_TEACHER_SUCCESS,
                    isSuccessSingleTeacher: true,
                    singleTeacher: response
                });
            } else {
                dispatch({
                    type: types.SINGLE_TEACHER_FAILURE,
                    isFailureSingleTeacher: true
                });
            }
        });
    };
}

export function getSingleTeacherUsingGlobalStates(id) {
    return (dispatch) => {
        let user_ = store.getState().user;
        let userData = {
            searchTextClasses: user_.searchTextClassesTeacher,
            sortByNameClasses: user_.sortByNameClassesTeacher,
            sortByOrderClasses: user_.sortByOrderClassesTeacher,
            limitNRowsClasses: user_.limitNRowsClassesTeacher
        };
        dispatch({type: types.SINGLE_TEACHER_REQUEST});

        get_teacher_with_data(id, userData, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.SINGLE_TEACHER_SUCCESS,
                    isSuccessSingleTeacher: true,
                    singleTeacher: response
                });
            } else {
                dispatch({
                    type: types.SINGLE_TEACHER_FAILURE,
                    isFailureSingleTeacher: true
                });
                showConnectionErrorAlert(response);
            }
        });
    };
}

export function getSingleTeacherContinuously(id) {
    return (dispatch) => {
        let user_ = store.getState().user;
        let userData = {
            searchTextClasses: user_.searchTextClassesTeacher,
            sortByNameClasses: user_.sortByNameClassesTeacher,
            sortByOrderClasses: user_.sortByOrderClassesTeacher,
            limitNRowsClasses: user_.limitNRowsClassesTeacher
        };

        get_teacher_with_data(id, userData, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.SINGLE_TEACHER_SUCCESS,
                    isSuccessSingleTeacher: true,
                    singleTeacher: response
                });
            }
        });
    };
}

export function updateSingleTeacherListingParams(type, id, value, secondValue = undefined, callback = undefined) {
    return (dispatch) => {
        switch (type) {
            case "SEARCH":
                dispatch({
                    type: types.UPDATE_SINGLE_TEACHER_LISTING_PARAMS,
                    searchTextClassesTeacher: value
                });
                break;
            case "SORT":
                dispatch({
                    type: types.UPDATE_SINGLE_TEACHER_LISTING_PARAMS,
                    sortByNameClassesTeacher: value,
                    sortByOrderClassesTeacher: secondValue,
                });
                break;
            case "LOAD_MORE":
                dispatch({
                    type: types.UPDATE_SINGLE_TEACHER_LISTING_PARAMS,
                    limitNRowsClassesTeacher: value,
                });
                break;
            default:
            // Do Nothing
        }

        dispatch(getSingleTeacherUsingGlobalStates(id));
    };
}

export function deleteTeacher(id) {
    return (dispatch) => {
        dispatch({type: types.DELETE_TEACHER_REQUEST});
        const alertOption = {
            heading: "Teacher Delete",
            type: "error"
        };

        delete_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Teacher',
                    'Delete Institute',
                    'Teacher',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.DELETE_TEACHER_SUCCESS,
                    deleteTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({type: types.DELETE_TEACHER_FAILURE, deleteTeacherErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function blockTeacher(id) {
    return (dispatch) => {
        dispatch({type: types.BLOCK_TEACHER_REQUEST});
        const alertOption = {
            heading: "Block Teacher",
            type: "error"
        };

        block_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Teacher',
                    'Block Teacher',
                    'Teacher',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({type: types.BLOCK_TEACHER_SUCCESS, blockTeacherSuccessMsg: response.message});
            } else {
                dispatch({type: types.BLOCK_TEACHER_FAILURE, blockTeacherErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function unblockTeacher(id) {
    return (dispatch) => {
        dispatch({type: types.UNBLOCK_TEACHER_REQUEST});
        const alertOption = {
            heading: "Unblock Teacher",
            type: "error"
        };

        unblock_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Teacher',
                    'Unblock Teacher',
                    'Teacher',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.UNBLOCK_TEACHER_SUCCESS,
                    unblockTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.UNBLOCK_TEACHER_FAILURE,
                    unblockTeacherErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function acceptDropRequestTeacher(id) {
    return (dispatch) => {
        dispatch({type: types.ACCEPT_DROP_REQUEST_TEACHER_REQUEST});
        const alertOption = {
            heading: "Accept Drop Request",
            type: "error"
        };

        accept_drop_request_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({
                    type: types.ACCEPT_DROP_REQUEST_TEACHER_SUCCESS,
                    acceptDropRequestTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.ACCEPT_DROP_REQUEST_TEACHER_FAILURE,
                    acceptDropRequestTeacherErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function rejectDropRequestTeacher(id) {
    return (dispatch) => {
        dispatch({type: types.REJECT_DROP_REQUEST_TEACHER_REQUEST});
        const alertOption = {
            heading: "Reject Drop Request",
            type: "error"
        };

        reject_drop_request_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({
                    type: types.REJECT_DROP_REQUEST_TEACHER_SUCCESS,
                    rejectDropRequestSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.REJECT_DROP_REQUEST_TEACHER_FAILURE,
                    rejectDropRequestErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function deleteInviteTeacher(id) {
    return (dispatch) => {
        dispatch({type: types.DELETE_INVITE_TEACHER_REQUEST});
        const alertOption = {
            heading: "Delete Invite",
            type: "error"
        };

        delete_invite_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Teacher',
                    'Delete Teacher Invite',
                    'Teacher',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.DELETE_INVITE_TEACHER_SUCCESS,
                    deleteInviteTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.DELETE_INVITE_TEACHER_FAILURE,
                    deleteInviteTeacherErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function resendInviteTeacher(id) {
    return (dispatch) => {
        dispatch({type: types.RESEND_INVITE_TEACHER_REQUEST});
        const alertOption = {
            heading: "Reinvite",
            type: "error"
        };

        resend_invite_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Teacher',
                    'Reinvite Teacher',
                    'Teacher',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.RESEND_INVITE_TEACHER_SUCCESS,
                    reInviteTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.RESEND_INVITE_TEACHER_FAILURE,
                    reInviteTeacherErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function resetPasswordTeacher(data) {
    return (dispatch) => {
        dispatch({type: types.RESET_PASSWORD_TEACHER_REQUEST});
        const alertOption = {
            heading: language.TITLE_RESET_PASSWORD,
            type: "error"
        };

        reset_password_users(data, (response) => {
            if (response.code === 0) {
                alertOption.type = "success"
                // Fire Analytics Event
                registerEvent(
                    'Authentication',
                    'Reset Password',
                    'Teacher',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );

                dispatch({
                    type: types.RESET_PASSWORD_TEACHER_SUCCESS,
                    resetPasswordTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.RESET_PASSWORD_TEACHER_FAILURE,
                    resetPasswordTeacherErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearResetPasswordTeacherSuccess() {
    return (dispatch) => {
        dispatch({type: types.RESET_PASSWORD_TEACHER_CLEAR_SUCCESS});
    };
}

export function unassignClassTeacher(classes, userId) {
    return (dispatch) => {
        dispatch({type: types.UNASSIGN_SINGLE_CLASS_TEACHER_REQUEST});
        const alertOption = {
            heading: "Unassign Class",
            type: "error"
        };

        unassigned_classes_teacher(classes, userId, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Teacher',
                    'Unassign Class',
                    'Institute Admin',
                    response.data.totalUnassignedClasses,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.UNASSIGN_SINGLE_CLASS_TEACHER_SUCCESS,
                    unassignSingleClassTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.UNASSIGN_SINGLE_CLASS_TEACHER_FAILURE,
                    unassignSingleClassTeacherErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function assignClassTeacher(data) {
    return (dispatch) => {
        dispatch({type: types.ASSIGN_SINGLE_CLASS_TEACHER_REQUEST});
        const alertOption = {
            heading: "Assign Class",
            type: "error"
        };

        assigned_classes_teacher(data, (response) => {
            console.log(response);
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Teacher',
                    'Request Assign Class',
                    'Institute Admin',
                    response.data.totalAssignedClasses,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.ASSIGN_SINGLE_CLASS_TEACHER_SUCCESS,
                    assignSingleClassTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.ASSIGN_SINGLE_CLASS_TEACHER_FAILURE,
                    assignSingleClassTeacherErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearAssignClassTeacher() {
    return (dispatch) => {
        dispatch({type: types.CLEAR_ASSIGN_SINGLE_CLASS_TEACHER});
    };
}

export function getClassesWrtTeachers(data) {
    return (dispatch) => {
        dispatch({type: types.LIST_CLASSES_WRT_TEACHER_REQUEST});

        get_classes_wrt_teachers(data, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.LIST_CLASSES_WRT_TEACHER_SUCCESS,
                    isSuccessClassesWrtTeachers: true,
                    classesWrtTeachers: response,
                });
            } else {
                dispatch({
                    type: types.LIST_CLASSES_WRT_TEACHER_FAILURE,
                    isFailureClassesWrtTeachers: true
                });
                showConnectionErrorAlert(response);
            }
        });
    };
}

export function editProfileTeacher(fieldsData) {
    return (dispatch) => {
        dispatch({type: types.EDIT_PROFILE_TEACHER_REQUEST});
        const alertOption = {
            heading: language.TITLE_EDIT_PROFILE,
            type: "error"
        };

        edit_profile_user(fieldsData, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({
                    type: types.EDIT_PROFILE_TEACHER_SUCCESS,
                    editProfileTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({type: types.EDIT_PROFILE_TEACHER_FAILURE, editProfileTeacherErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearEditProfileTeacher() {
    return (dispatch) => {
        dispatch({type: types.EDIT_PROFILE_TEACHER_CLEAR});
    };
}

export function importTeachers(data) {
    return (dispatch) => {
        dispatch({type: types.IMPORT_TEACHERS_REQUEST});
        const alertOption = {
            heading: language.TITLE_IMPORT_TEACHERS,
            type: "error"
        };
        import_teachers_to_institute(data, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Import',
                    'Teacher',
                    'Institute Admin',
                    response.data.totalCreatedCount,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    ''
                );
                dispatch({
                    type: types.IMPORT_TEACHERS_SUCCESS,
                    importTeachersSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.IMPORT_TEACHERS_FAILURE,
                    importTeachersErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearImportTeachers() {
    return (dispatch) => {
        dispatch({type: types.IMPORT_TEACHERS_CLEAR});
    };
}

export function signUpTeacher(fieldsData, joinOther = null) {
    return (dispatch) => {
        dispatch({type: types.SIGNUP_TEACHER_REQUEST});
        const alertOption = {
            heading: language.TITLE_SIGNUP_TEACHER,
            type: "error"
        };

        signup_teacher(fieldsData, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                let eventCategory = '';
                let eventAction = '';
                if (joinOther != null) {
                    eventCategory = joinOther;
                    eventAction = 'As Teacher';
                } else {
                    eventCategory = 'Authentication';
                    eventAction = 'Signup';
                }
                // Google Analytics
                registerEvent(
                    eventCategory,
                    eventAction,
                    'Teacher',
                    1,
                    window.location.hostname,
                    'Web',
                    response.data.instituteId,
                    response.data.userId,
                    '',
                    '');

                // Logging-in to this own account
                do_login(fieldsData['email'], fieldsData['password'], (response) => {
                    if(response.code === 0) {
                        history.replace('/');
                    }
                });

                dispatch({
                    type: types.SIGNUP_TEACHER_SUCCESS,
                    signUpTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({type: types.SIGNUP_TEACHER_FAILURE, signUpTeacherErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearSignUpTeacher() {
    return (dispatch) => {
        dispatch({type: types.SIGNUP_TEACHER_CLEAR});
    };
}

export function acceptSignUpInviteTeacher(id) {
    return (dispatch) => {
        dispatch({type: types.ACCEPT_SIGNUP_INVITE_TEACHER_REQUEST});
        const alertOption = {
            heading: "Accept Sign Up Invite",
            type: "error"
        };

        accept_user_invite(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({
                    type: types.ACCEPT_SIGNUP_INVITE_TEACHER_SUCCESS,
                    acceptSignUpInviteTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.ACCEPT_SIGNUP_INVITE_TEACHER_FAILURE,
                    acceptSignUpInviteTeacherErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function rejectSignUpInviteTeacher(id) {
    return (dispatch) => {
        dispatch({type: types.REJECT_SIGNUP_INVITE_TEACHER_REQUEST});
        const alertOption = {
            heading: "Reject Sign Up Invite",
            type: "error"
        };

        reject_user_invite(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({
                    type: types.REJECT_SIGNUP_INVITE_TEACHER_SUCCESS,
                    rejectSignUpInviteTeacherSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.REJECT_SIGNUP_INVITE_TEACHER_FAILURE,
                    rejectSignUpInviteTeacherErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}


/////////////////////////////////////////////////////////////////////////

export function addStudent(fieldsData) {
    return (dispatch) => {
        dispatch({type: types.ADD_STUDENT_REQUEST});
        const alertOption = {
            heading: language.TITLE_ADD_STUDENT,
            type: "error"
        };

        add_student(fieldsData, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Student',
                    'Invited',
                    '',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.ADD_STUDENT_SUCCESS,
                    addStudentSuccessMsg: response.message
                });
            } else {
                dispatch({type: types.ADD_STUDENT_FAILURE, addStudentErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearAddStudent() {
    return (dispatch) => {
        dispatch({type: types.ADD_STUDENT_CLEAR});
    };
}

export function editStudent(fieldsData) {
    return (dispatch) => {
        dispatch({type: types.EDIT_STUDENT_REQUEST});
        const alertOption = {
            heading: language.TITLE_EDIT_STUDENT,
            type: "error"
        };

        edit_student(fieldsData, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                let role = '';
                let roleId = response.data.roleId;
                if (roleId === enums.role.INSTITUTE_ADMIN){
                    role = 'Institute Admin';
                } else if (roleId === enums.role.STUDENT){
                    role = 'Student';
                }
                // Fire Analytics Event
                registerEvent(
                    'Student',
                    'Edit Student',
                    role,
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.EDIT_STUDENT_SUCCESS,
                    editStudentSuccessMsg: response.message
                });
            } else {
                dispatch({type: types.EDIT_STUDENT_FAILURE, editStudentErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearEditStudent() {
    return (dispatch) => {
        dispatch({type: types.EDIT_STUDENT_CLEAR});
    };
}

export function getStudents(data) {
    return (dispatch) => {
        dispatch({type: types.LIST_STUDENT_REQUEST});

        get_students(data, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.LIST_STUDENT_SUCCESS,
                    isSuccessStudents: true,
                    students: response.students,
                    totalCountsStudent: response.totalCounts
                });
            } else {
                dispatch({
                    type: types.LIST_STUDENT_FAILURE,
                    isFailureStudents: true
                });
            }
        });
    };
}

export function getStudentsUsingGlobalStates() {
    return (dispatch) => {
        let student = store.getState().user;
        let studentsData = {
            searchText: student.studentSearchText,
            filterStatus: student.studentFilterStatus,
            sortByName: student.studentSortByName,
            sortByOrder: student.studentSortByOrder,
            limitNRows: student.studentLimitNRows
        };
        dispatch({type: types.LIST_STUDENT_REQUEST});

        get_students(studentsData, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.LIST_STUDENT_SUCCESS,
                    isSuccessStudents: true,
                    students: response.students,
                    totalCountsStudent: response.totalCounts
                });
            } else {
                dispatch({
                    type: types.LIST_STUDENT_FAILURE,
                    isFailureStudents: true
                });
                showConnectionErrorAlert(response);
            }
        });
    };
}

export function getStudentsContinuously() {
    return (dispatch) => {
        let student = store.getState().user;
        let studentsData = {
            searchText: student.studentSearchText,
            filterStatus: student.studentFilterStatus,
            sortByName: student.studentSortByName,
            sortByOrder: student.studentSortByOrder,
            limitNRows: student.studentLimitNRows
        };

        get_students(studentsData, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.LIST_STUDENT_SUCCESS,
                    isSuccessStudents: true,
                    students: response.students,
                    totalCountsStudent: response.totalCounts
                });
            }
        });
    };
}

export function updateStudentListingParams(type, value, secondValue = undefined, callback = undefined) {
    return (dispatch) => {
        switch (type) {
            case "SEARCH":
                dispatch({
                    type: types.UPDATE_STUDENT_LISTING_PARAMS,
                    studentSearchText: value
                });
                break;
            case "TAB":
                dispatch({
                    type: types.UPDATE_STUDENT_LISTING_PARAMS,
                    studentActiveTab: value,
                    studentFilterStatus: secondValue
                });
                break;
            case "SORT":
                dispatch({
                    type: types.UPDATE_STUDENT_LISTING_PARAMS,
                    studentSortByName: value,
                    studentSortByOrder: secondValue,
                });
                break;
            case "LOAD_MORE":
                dispatch({
                    type: types.UPDATE_STUDENT_LISTING_PARAMS,
                    studentLimitNRows: value,
                });
                break;
            default:
            // Do Nothing
        }

        dispatch(getStudentsUsingGlobalStates());
    };
}

export function getSingleStudent(id) {
    return (dispatch) => {
        dispatch({type: types.SINGLE_STUDENT_REQUEST});

        get_student(id, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.SINGLE_STUDENT_SUCCESS,
                    isSuccessSingleStudent: true,
                    singleStudent: response
                });
            } else {
                dispatch({
                    type: types.SINGLE_STUDENT_FAILURE,
                    isFailureSingleStudent: true
                });
            }
        });
    };
}

export function getSingleStudentUsingGlobalStates(id) {
    return (dispatch) => {
        let user_ = store.getState().user;
        let userData = {
            searchTextClasses: user_.searchTextClassesStudent,
            sortByNameClasses: user_.sortByNameClassesStudent,
            sortByOrderClasses: user_.sortByOrderClassesStudent,
            limitNRowsClasses: user_.limitNRowsClassesStudent
        };
        dispatch({type: types.SINGLE_STUDENT_REQUEST});

        get_student_with_data(id, userData, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.SINGLE_STUDENT_SUCCESS,
                    isSuccessSingleStudent: true,
                    singleStudent: response
                });
            } else {
                dispatch({
                    type: types.SINGLE_STUDENT_FAILURE,
                    isFailureSingleStudent: true
                });
                showConnectionErrorAlert(response);
            }
        });
    };
}

export function getSingleStudentContinuously(id) {
    return (dispatch) => {
        let user_ = store.getState().user;
        let userData = {
            searchTextClasses: user_.searchTextClassesStudent,
            sortByNameClasses: user_.sortByNameClassesStudent,
            sortByOrderClasses: user_.sortByOrderClassesStudent,
            limitNRowsClasses: user_.limitNRowsClassesStudent
        };

        get_student_with_data(id, userData, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.SINGLE_STUDENT_SUCCESS,
                    isSuccessSingleStudent: true,
                    singleStudent: response
                });
            }
        });
    };
}

export function updateSingleStudentListingParams(type, id, value, secondValue = undefined, callback = undefined) {
    return (dispatch) => {
        switch (type) {
            case "SEARCH":
                dispatch({
                    type: types.UPDATE_SINGLE_STUDENT_LISTING_PARAMS,
                    searchTextClassesStudent: value
                });
                break;
            case "SORT":
                dispatch({
                    type: types.UPDATE_SINGLE_STUDENT_LISTING_PARAMS,
                    sortByNameClassesStudent: value,
                    sortByOrderClassesStudent: secondValue,
                });
                break;
            case "LOAD_MORE":
                dispatch({
                    type: types.UPDATE_SINGLE_STUDENT_LISTING_PARAMS,
                    limitNRowsClassesStudent: value,
                });
                break;
            default:
            // Do Nothing
        }

        dispatch(getSingleStudentUsingGlobalStates(id));
    };
}

export function deleteStudent(id) {
    return (dispatch) => {
        dispatch({type: types.DELETE_STUDENT_REQUEST});
        const alertOption = {
            heading: "Student Delete",
            type: "error"
        };

        delete_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Student',
                    'Delete Student',
                    'Student',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.DELETE_STUDENT_SUCCESS,
                    deleteStudentSuccessMsg: response.message
                });
            } else {
                dispatch({type: types.DELETE_STUDENT_FAILURE, deleteStudentErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function blockStudent(id) {
    return (dispatch) => {
        dispatch({type: types.BLOCK_STUDENT_REQUEST});
        const alertOption = {
            heading: "Block Student",
            type: "error"
        };

        block_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Student',
                    'Block Student',
                    'Student',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({type: types.BLOCK_STUDENT_SUCCESS, blockStudentSuccessMsg: response.message});
            } else {
                dispatch({type: types.BLOCK_STUDENT_FAILURE, blockStudentErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function unblockStudent(id) {
    return (dispatch) => {
        dispatch({type: types.UNBLOCK_STUDENT_REQUEST});
        const alertOption = {
            heading: "Unblock Student",
            type: "error"
        };

        unblock_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Student',
                    'Unblock Student',
                    'Student',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.UNBLOCK_STUDENT_SUCCESS,
                    unblockStudentSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.UNBLOCK_STUDENT_FAILURE,
                    unblockStudentErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function acceptDropRequestStudent(id) {
    return (dispatch) => {
        dispatch({type: types.ACCEPT_DROP_REQUEST_STUDENT_REQUEST});
        const alertOption = {
            heading: "Accept Drop Request",
            type: "error"
        };

        accept_drop_request_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({
                    type: types.ACCEPT_DROP_REQUEST_STUDENT_SUCCESS,
                    acceptDropRequestStudentSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.ACCEPT_DROP_REQUEST_STUDENT_FAILURE,
                    acceptDropRequestStudentErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function rejectDropRequestStudent(id) {
    return (dispatch) => {
        dispatch({type: types.REJECT_DROP_REQUEST_STUDENT_REQUEST});
        const alertOption = {
            heading: "Reject Drop Request",
            type: "error"
        };

        reject_drop_request_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({
                    type: types.REJECT_DROP_REQUEST_STUDENT_SUCCESS,
                    rejectDropRequestSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.REJECT_DROP_REQUEST_STUDENT_FAILURE,
                    rejectDropRequestErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function deleteInviteStudent(id) {
    return (dispatch) => {
        dispatch({type: types.DELETE_INVITE_STUDENT_REQUEST});
        const alertOption = {
            heading: "Delete Invite",
            type: "error"
        };

        delete_invite_user(id, (response) => {
            console.log(response);
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Student',
                    'Delete Student Invite',
                    'Student',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.DELETE_INVITE_STUDENT_SUCCESS,
                    deleteInviteStudentSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.DELETE_INVITE_STUDENT_FAILURE,
                    deleteInviteStudentErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function resendInviteStudent(id) {
    return (dispatch) => {
        dispatch({type: types.RESEND_INVITE_STUDENT_REQUEST});
        const alertOption = {
            heading: "Reinvite",
            type: "error"
        };

        resend_invite_user(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Student',
                    'Reinvite Student',
                    'Student',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.RESEND_INVITE_STUDENT_SUCCESS,
                    reInviteStudentSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.RESEND_INVITE_STUDENT_FAILURE,
                    reInviteStudentErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function resetPasswordStudent(data) {
    return (dispatch) => {
        dispatch({type: types.RESET_PASSWORD_STUDENT_REQUEST});
        const alertOption = {
            heading: language.TITLE_RESET_PASSWORD,
            type: "error"
        };

        reset_password_users(data, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Authentication',
                    'Reset Password',
                    'Student',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );

                dispatch({
                    type: types.RESET_PASSWORD_STUDENT_SUCCESS,
                    resetPasswordStudentSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.RESET_PASSWORD_STUDENT_FAILURE,
                    resetPasswordStudentErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearResetPasswordStudentSuccess() {
    return (dispatch) => {
        dispatch({type: types.RESET_PASSWORD_STUDENT_CLEAR_SUCCESS});
    };
}

export function changePasswordStudent(data) {
    return (dispatch) => {
        dispatch({type: types.CHANGE_PASSWORD_STUDENT_REQUEST});
        const alertOption = {
            heading: language.TITLE_CHANGE_PASSWORD,
            type: "error"
        };

        change_password_users(data, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({
                    type: types.CHANGE_PASSWORD_STUDENT_SUCCESS,
                    changePasswordStudentSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.CHANGE_PASSWORD_STUDENT_FAILURE,
                    changePasswordStudentErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearChangePasswordStudentSuccess() {
    return (dispatch) => {
        dispatch({type: types.CHANGE_PASSWORD_STUDENT_CLEAR_SUCCESS});
    };
}

export function unassignClassStudent(classes, userId) {
    return (dispatch) => {
        dispatch({type: types.UNASSIGN_SINGLE_CLASS_STUDENT_REQUEST});
        const alertOption = {
            heading: "Unassign Class",
            type: "error"
        };

        unassigned_classes_student(classes, userId, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Student',
                    'Unassign Student',
                    'Student',
                    response.data.totalUnassignedClasses,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.UNASSIGN_SINGLE_CLASS_STUDENT_SUCCESS,
                    unassignSingleClassStudentSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.UNASSIGN_SINGLE_CLASS_STUDENT_FAILURE,
                    unassignSingleClassStudentErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function assignClassStudent(data) {
    return (dispatch) => {
        dispatch({type: types.ASSIGN_SINGLE_CLASS_STUDENT_REQUEST});
        const alertOption = {
            heading: "Assign Class",
            type: "error"
        };

        assigned_classes_student(data, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Student',
                    'Assign Student',
                    'Student',
                    response.data.totalAssignedClasses,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    '',
                    response.data.userEmail
                );
                dispatch({
                    type: types.ASSIGN_SINGLE_CLASS_STUDENT_SUCCESS,
                    assignSingleClassStudentSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.ASSIGN_SINGLE_CLASS_STUDENT_FAILURE,
                    assignSingleClassStudentErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearAssignClassStudent() {
    return (dispatch) => {
        dispatch({type: types.CLEAR_ASSIGN_SINGLE_CLASS_STUDENT});
    };
}

export function getClassesWrtStudents(data) {
    return (dispatch) => {
        dispatch({type: types.LIST_CLASSES_WRT_STUDENT_REQUEST});

        get_classes_wrt_students(data, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.LIST_CLASSES_WRT_STUDENT_SUCCESS,
                    isSuccessClassesWrtStudents: true,
                    classesWrtStudents: response,
                });
            } else {
                dispatch({
                    type: types.LIST_CLASSES_WRT_STUDENT_FAILURE,
                    isFailureClassesWrtStudents: true
                });
                showConnectionErrorAlert(response);
            }
        });
    };
}

export function getActiveSessions(data) {
    return (dispatch) => {
        dispatch({type: types.ACTIVE_SESSIONS_REQUEST});

        get_active_sessions(data, (response) => {
            if (response.code === 0) {
                response = response.data;
                dispatch({
                    type: types.ACTIVE_SESSIONS_SUCCESS,
                    isSuccessActiveSessions: true,
                    activeSessions: response,
                });
            } else {
                dispatch({
                    type: types.ACTIVE_SESSIONS_FAILURE,
                    isFailureActiveSessions: true
                });
            }
        });
    };
}

export function markAttendanceStudent(data) {
    return (dispatch) => {
        dispatch({type: types.MARK_ATTENDANCE_REQUEST});
        const alertOption = {
            heading: language.TITLE_MARK_ATTENDANCE,
            type: "error"
        };

        mark_attendance(data, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({
                    type: types.MARK_ATTENDANCE_SUCCESS,
                    markAttendanceSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.MARK_ATTENDANCE_FAILURE,
                    markAttendanceErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearMarkAttendanceStudent() {
    return (dispatch) => {
        dispatch({type: types.MARK_ATTENDANCE_CLEAR});
    };
}

export function editProfileStudent(fieldsData) {
    return (dispatch) => {
        dispatch({type: types.EDIT_PROFILE_STUDENT_REQUEST});
        const alertOption = {
            heading: language.TITLE_EDIT_PROFILE,
            type: "error"
        };

        edit_profile_user(fieldsData, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                dispatch({
                    type: types.EDIT_PROFILE_STUDENT_SUCCESS,
                    editProfileStudentSuccessMsg: response.message
                });
            } else {
                dispatch({type: types.EDIT_PROFILE_STUDENT_FAILURE, editProfileStudentErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearEditProfileStudent() {
    return (dispatch) => {
        dispatch({type: types.EDIT_PROFILE_STUDENT_CLEAR});
    };
}

export function importStudents(data) {
    return (dispatch) => {
        dispatch({type: types.IMPORT_STUDENTS_REQUEST});
        const alertOption = {
            heading: language.TITLE_IMPORT_STUDENTS,
            type: "error"
        };

        import_students_to_institute(data, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Import',
                    'Student',
                    'Institute Admin',
                    response.data.totalCreatedCount,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    '',
                    '',
                    ''
                );
                dispatch({
                    type: types.IMPORT_STUDENTS_SUCCESS,
                    importStudentsSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.IMPORT_STUDENTS_FAILURE,
                    importStudentsErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearImportStudents() {
    return (dispatch) => {
        dispatch({type: types.IMPORT_STUDENTS_CLEAR});
    };
}

export function signUpStudent(fieldsData, joinOther = null) {
    return (dispatch) => {
        dispatch({type: types.SIGNUP_STUDENT_REQUEST});
        const alertOption = {
            heading: language.TITLE_SIGNUP_STUDENT,
            type: "error"
        };

        signup_student(fieldsData, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                let eventCategory = '';
                let eventAction = '';
                if (joinOther != null) {
                    eventCategory = joinOther;
                    eventAction = 'As Student';
                } else {
                    eventCategory = 'Authentication';
                    eventAction = 'Signup';
                }
                // Google Analytics
                registerEvent(
                    eventCategory,
                    eventAction,
                    'Student',
                    1,
                    window.location.hostname,
                    '',
                    '',
                    response.data.userEmail,
                    '',
                    ''
                );

                // Logging-in to this own account
                do_login(fieldsData['email'], fieldsData['password'], (response) => {
                    if(response.code === 0) {
                        history.replace('/');
                    }
                });

                dispatch({
                    type: types.SIGNUP_STUDENT_SUCCESS,
                    signUpStudentSuccessMsg: response.message
                });
            } else {
                dispatch({type: types.SIGNUP_STUDENT_FAILURE, signUpStudentErrorMsg: response.message});
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function clearSignUpStudent() {
    return (dispatch) => {
        dispatch({type: types.SIGNUP_STUDENT_CLEAR});
    };
}

export function acceptSignUpInviteStudent(id) {
    return (dispatch) => {
        dispatch({type: types.ACCEPT_SIGNUP_INVITE_TEACHER_REQUEST});
        const alertOption = {
            heading: "Accept Sign Up Invite",
            type: "error"
        };

        accept_user_invite(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Authentication',
                    'Accept Signup Invite',
                    'Student',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    response.data.userEmail,
                    '',
                    ''
                );
                dispatch({
                    type: types.ACCEPT_SIGNUP_INVITE_STUDENT_SUCCESS,
                    acceptSignUpInviteStudentSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.ACCEPT_SIGNUP_INVITE_STUDENT_FAILURE,
                    acceptSignUpInviteStudentErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}

export function rejectSignUpInviteStudent(id) {
    return (dispatch) => {
        dispatch({type: types.REJECT_SIGNUP_INVITE_STUDENT_REQUEST});
        const alertOption = {
            heading: "Reject Sign Up Invite",
            type: "error"
        };

        reject_user_invite(id, (response) => {
            if (response.code === 0) {
                alertOption.type = "success";
                // Fire Analytics Event
                registerEvent(
                    'Authentication',
                    'Reject Signup Invite',
                    'Student',
                    1,
                    window.location.hostname,
                    '',
                    response.data.instituteId,
                    response.data.userEmail,
                    '',
                    ''
                );
                dispatch({
                    type: types.REJECT_SIGNUP_INVITE_STUDENT_SUCCESS,
                    rejectSignUpInviteStudentSuccessMsg: response.message
                });
            } else {
                dispatch({
                    type: types.REJECT_SIGNUP_INVITE_STUDENT_FAILURE,
                    rejectSignUpInviteStudentErrorMsg: response.message
                });
            }

            // Showing Alert to User
            showAlertMessage(alertOption.type, alertOption.heading, response.message);
        });
    };
}
