import {
    getDashboardLinkByRole,
    getContactNumberUsingAreaAndPhone,
    getRoleIdFromUser,
    showAlertMessage,
    getParentContactNumberUsingAreaAndPhone,
    requestApiToServer, validateApiResponse, requestFileApiToServer, requestApiToServerWithAbort, createHash
} from '../helpers/helper';
import enums from "../helpers/enums";
import {registerEvent} from "../helpers/googleAnalytics";

////////////////////////// GET REQUESTS //////////////////////////

export function validate_forgot_password(code, callback) {
    // Request Api using final data
    requestApiToServer(`user/validateForgotPassword?code=${code}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function validate_token(callback) {
    // Request Api using final data
    requestApiToServer(`user/validateAuthToken`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}


export function do_logout(callback) {
    // Request Api using final data
    requestApiToServer(`user/logout`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_teachers(data, callback) {
    // Creating data according to Api
    let params = 'searchText=' + encodeURIComponent(data.searchText) + '&filterStatus=' + data.filterStatus + '&sortByName=' +
        data.sortByName + '&sortByOrder=' + data.sortByOrder + '&limitNRows=' + data.limitNRows;

    // Request Api using final data
    requestApiToServerWithAbort(`user/getAllTeachers?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_teacher(id, callback) {
    // Request Api using final data
    requestApiToServer(`user/getOneTeacher/${id}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_teacher_with_data(id, data, callback) {
    // Creating data according to Api
    let params = '?searchTextClasses=' + encodeURIComponent(data.searchTextClasses) + '&sortByNameClasses=' + data.sortByNameClasses +
        '&sortByOrderClasses=' + data.sortByOrderClasses + '&limitNRowsClasses=' + data.limitNRowsClasses;

    // Request Api using final data
    requestApiToServerWithAbort(`user/getOneTeacher/${id}${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function _callback_get_teacher(id, callback) {
    // Request Api using final data
    requestApiToServer(`user/getOneTeacher/${id}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_students(data, callback) {
    // Creating data according to Api
    let params = 'searchText=' + encodeURIComponent(data.searchText) + '&filterStatus=' + data.filterStatus + '&sortByName=' + data.sortByName + '&sortByOrder=' + data.sortByOrder + '&limitNRows=' + data.limitNRows;

    // Request Api using final data
    requestApiToServerWithAbort(`user/getAllStudents?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_student(id, callback) {
    // Request Api using final data
    requestApiToServer(`user/getOneStudent/${id}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_student_with_data(id, data, callback) {
    // Creating data according to Api
    let params = '?searchTextClasses=' + encodeURIComponent(data.searchTextClasses) + '&sortByNameClasses=' + data.sortByNameClasses +
        '&sortByOrderClasses=' + data.sortByOrderClasses + '&limitNRowsClasses=' + data.limitNRowsClasses;

    // Request Api using final data
    requestApiToServerWithAbort(`user/getOneStudent/${id}${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function _callback_get_student(id, callback) {
    // Request Api using final data
    requestApiToServer(`user/getOneStudent/${id}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_classes_wrt_students(data, callback) {
    // Creating data according to Api
    let params = 'userId=' + data.singleId + '&searchText=' + encodeURIComponent(data.searchText) + '&filterStatus=' + data.filterStatus + '&limitNRows=' + data.limitNRows;

    // Request Api using final data
    requestApiToServerWithAbort(`user/getAllClassesWrtStudent?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function get_classes_wrt_teachers(data, callback) {
    // Creating data according to Api
    let params = 'userId=' + data.singleId + '&searchText=' + encodeURIComponent(data.searchText) + '&filterStatus=' + data.filterStatus + '&limitNRows=' + data.limitNRows;

    // Request Api using final data
    requestApiToServerWithAbort(`user/getAllClassesWrtTeacher?${params}`, 'GET', [], (response) => {
        callback(validateApiResponse(response));
    });
}

////////////////////////// END GET REQUESTS //////////////////////////

////////////////////////// POST REQUESTS //////////////////////////
export function support_submit(data, callback) {
    // Request Api using final data
    requestApiToServer('support/sendFeedback', 'POST', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function contact_admin(data, callback) {
    // Request Api using final data
    requestApiToServer('support/contactAdmin', 'POST', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function do_login(email, password, callback) {
    // Creating data according to Api
    let data = {email, password};

    // Request Api using final data
    requestApiToServer(`user/login`, 'POST', data, (response) => {
        let validatedApiResponse = validateApiResponse(response);
        if (validatedApiResponse.code === 0) {
            if (validatedApiResponse.data.token !== undefined) {
                localStorage.setItem('token', validatedApiResponse.data.token);
                if (validatedApiResponse.data.userData !== undefined) {
                    localStorage.setItem('isFirstTimeLogin', validatedApiResponse.data.userData.isFirstTimeLogin);
                    let emailHash = createHash(validatedApiResponse.data.userData.email);
                    localStorage.setItem('userEmailHash', emailHash);

                }
                subscribe_push_notifications();
            }
        }
        callback(validatedApiResponse);
    });
}

export function subscribe_push_notifications(callback) {
    // Creating data according to Api
    let fcmToken = localStorage.getItem('FCMToken');
    let deviceId = localStorage.getItem('DeviceId');
    let deviceType = localStorage.getItem('DeviceType');

    if (fcmToken && deviceId && deviceType) {
        let data = {token: fcmToken, deviceId: deviceId, deviceType: deviceType};
        // Request Api using final data
        requestApiToServer('user/subscribePushNotifications', 'POST', data, (response) => {
            let validatedApiResponse = validateApiResponse(response);
            if (validatedApiResponse.code === 0) {
                // TODO: do something after subscription
            }
            callback(validatedApiResponse);
        });
    }
}

export function forgot_password(email, callback) {
    // Creating data according to Api
    let data = {email: email};

    // Request Api using final data
    requestApiToServer('user/forgotPassword', 'POST', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function add_teacher(data, callback) {
    // Creating data according to Api
    data['contactNumber'] = getContactNumberUsingAreaAndPhone(data);

    // Request Api using final data
    requestApiToServer('user/addTeacher', 'POST', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function unassigned_classes_teacher(classes, userId, callback) {
    // Creating data according to Api
    let newData = {"unassignClassIds": classes, "userId": userId};

    // Request Api using final data
    requestApiToServer('user/assignClassesToTeacher', 'POST', newData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function assigned_classes_teacher(data, callback) {
    // Creating data according to Api
    let newData = {
        "assignClassIds": data['assignIds'],
        "unassignClassIds": data['unassignIds'],
        "userId": data['singleId']
    };

    // Request Api using final data
    requestApiToServer('user/assignClassesToTeacher', 'POST', newData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function add_student(data, callback) {
    // Creating data according to Api
    data['contactNumber'] = getContactNumberUsingAreaAndPhone(data);
    data['parentContactNumber'] = getParentContactNumberUsingAreaAndPhone(data);

    // Request Api using final data
    requestApiToServer('user/addStudent', 'POST', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function assigned_classes_student(data, callback) {
    // Creating data according to Api
    let newData = {
        "assignClassIds": data['assignIds'],
        "unassignClassIds": data['unassignIds'],
        "userId": data['singleId']
    };

    // Request Api using final data
    requestApiToServer('user/assignClassesToStudent', 'POST', newData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function unassigned_classes_student(classes, userId, callback) {
    // Creating data according to Api
    let newData = {"unassignClassIds": classes, "userId": userId};

    // Request Api using final data
    requestApiToServer('user/assignClassesToStudent', 'POST', newData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function switch_account(instituteId, userId, roleId, callback) {
    // Creating data according to Api
    let newData = {instituteId: instituteId, userId: userId, roleId: roleId};

    // Request Api using final data
    requestApiToServer('user/switchAccount', 'POST', newData, (response) => {
        let validatedApiResponse = validateApiResponse(response);
        if (validatedApiResponse.code === 0) {
            if (validatedApiResponse.data.token !== undefined) {
                let role = '';
                if (response.data.userData.roleId === enums.role.INSTITUTE_ADMIN){
                    role = 'Institute Admin';
                } else if (response.data.userData.roleId === enums.role.TEACHER){
                    role = 'Teacher';
                } else if (response.data.userData.roleId === enums.role.STUDENT){
                    role = 'Student';
                }
                // Fire Analytics Event
                registerEvent(
                    'Authentication',
                    'Switch Account',
                    role,
                    1,
                    window.location.hostname,
                    '',
                    response.data.userData.instituteId,
                    '',
                    '',
                    ''
                );
                localStorage.setItem('token', validatedApiResponse.data.token);
                subscribe_push_notifications();
                window.location.href = getDashboardLinkByRole(getRoleIdFromUser(validatedApiResponse.data.userData));
            } else {
                showAlertMessage('error', 'Switch Account', validatedApiResponse.message);
            }
        } else {
            showAlertMessage('error', 'Switch Account', validatedApiResponse.message);
        }
        callback(validatedApiResponse);
    });
}

export function import_students_to_institute(data, callback) {
    // Creating data according to Api
    const formData = new FormData();
    formData.append('csv', data['csv']);
    formData.append('instituteId', data['instituteId']);

    // Request Api using final data
    requestFileApiToServer('user/importStudentsToInstitute', 'POST', formData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function import_teachers_to_institute(data, callback) {
    // Creating data according to Api
    const formData = new FormData();
    formData.append('csv', data['csv']);
    formData.append('instituteId', data['instituteId']);

    // Request Api using final data
    requestFileApiToServer('user/importTeachersToInstitute', 'POST', formData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function signup_teacher(data, callback) {
    let dataToSend = {};
    // Creating data according to Api
    Object.assign(dataToSend, data);
    dataToSend['instituteId'] = (typeof data['instituteId'] === "object" && data['instituteId'].hasOwnProperty(0)) ? data['instituteId'][0].value : '';
    dataToSend['contactNumber'] = getContactNumberUsingAreaAndPhone(data);

    // Request Api using final data
    requestApiToServer('user/signupTeacher', 'POST', dataToSend, (response) => {
        callback(validateApiResponse(response));
    });
}

export function signup_student(data, callback) {
    let dataToSend = {};
    // Creating data according to Api
    Object.assign(dataToSend, data);
    dataToSend['instituteId'] = (typeof data['instituteId'] === "object" && data['instituteId'].hasOwnProperty(0)) ? data['instituteId'][0].value : '';
    dataToSend['contactNumber'] = getContactNumberUsingAreaAndPhone(data);
    dataToSend['parentContactNumber'] = getParentContactNumberUsingAreaAndPhone(data);

    // Request Api using final data
    requestApiToServer('user/signupStudent', 'POST', dataToSend, (response) => {
        callback(validateApiResponse(response));
    });
}

////////////////////////// END POST REQUESTS //////////////////////////

////////////////////////// PUT REQUESTS //////////////////////////

export function update_presence(swapPublisherId, callback) {
    let data = {};
    if (swapPublisherId)
        data.swapPublisherId = swapPublisherId;
    requestApiToServer(`user/updatePresence`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function reset_password(password, code, callback) {
    // Creating data according to Api
    let data = {new_password: password, code: code};

    // Request Api using final data
    requestApiToServer(`user/resetPassword`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function change_password(oldPassword, newPassword, callback) {
    // Creating data according to Api
    let data = {old_password: oldPassword, new_password: newPassword};

    // Request Api using final data
    requestApiToServer(`user/changePassword`, 'PUT', data, (response) => {
        let validatedApiResponse = validateApiResponse(response);
        if (validatedApiResponse.code === 0) {
            if (validatedApiResponse.data.token !== undefined) {
                localStorage.setItem('token', validatedApiResponse.data.token);
            }
        }
        setTimeout(() => { // Adding Delay for unresponsive modal behaviour of change password due to excessive enter keys.
            callback(validatedApiResponse);
        }, 1000);
    });
}

export function edit_profile_user(data, callback) {
    // Creating data according to Api
    data['contactNumber'] = getContactNumberUsingAreaAndPhone(data);
    const formData = new FormData();
    formData.append('firstName', data['firstName']);
    formData.append('lastName', data['lastName']);
    formData.append('country', data['country']);
    formData.append('contactNumber', data['contactNumber']);
    formData.append('profilePicture', data['profilePicture']);
    formData.append('removeProfilePicture', data['removeProfilePicture']);

    // Request Api using final data
    requestFileApiToServer(`user/editProfile`, 'PUT', formData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function edit_teacher(data, callback) {
    // Creating data according to Api
    data['contactNumber'] = getContactNumberUsingAreaAndPhone(data);

    // Request Api using final data
    requestApiToServer(`user/editTeacher`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function block_user(id, callback) {
    // Request Api using final data
    requestApiToServer(`user/blockUser/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function unblock_user(id, callback) {
    // Request Api using final data
    requestApiToServer(`user/unblockUser/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function accept_drop_request_user(id, callback) {
    // Request Api using final data
    requestApiToServer(`user/acceptDropRequest/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function reject_drop_request_user(id, callback) {
    // Request Api using final data
    requestApiToServer(`user/rejectDropRequest/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function resend_invite_user(id, callback) {
    // Request Api using final data
    requestApiToServer(`user/reinviteUser/${id}`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function reset_password_users(data, callback) {
    // Creating data according to Api
    let newData = {"userEmailList": data};

    // Request Api using final data
    requestApiToServer(`user/resetPasswordFor`, 'PUT', newData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function change_password_users(password, callback) {
    // Creating data according to Api
    let newData = {"password": password};

    // Request Api using final data
    requestApiToServer(`user/resetPasswordFor`, 'PUT', newData, (response) => {
        callback(validateApiResponse(response));
    });
}

export function edit_student(data, callback) {
    // Creating data according to Api
    data['contactNumber'] = getContactNumberUsingAreaAndPhone(data);
    data['parentContactNumber'] = getParentContactNumberUsingAreaAndPhone(data);

    // Request Api using final data
    requestApiToServer(`user/editStudent`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function reject_admin_invite(code, callback) {
    // Creating data according to Api
    let data = {code: code};

    // Request Api using final data
    requestApiToServer(`user/rejectAdminInvite`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function accept_admin_invite(code, callback) {
    // Creating data according to Api
    let data = {code: code};

    // Request Api using final data
    requestApiToServer(`user/acceptAdminInvite`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function remove_profile_user(callback) {
    // Request Api using final data
    requestApiToServer(`user/removeProfilePicture`, 'PUT', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function accept_signup_invite(code, callback) {
    // Creating data according to Api
    let data = {code: code};

    // Request Api using final data
    requestApiToServer(`user/acceptSignupInvite`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function reject_signup_invite(code, callback) {
    // Creating data according to Api
    let data = {code: code};

    // Request Api using final data
    requestApiToServer(`user/rejectSignupInvite`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function accept_user_invite(userId, callback) {
    // Creating data according to Api
    let data = {"userId": userId};

    // Request Api using final data
    requestApiToServer(`user/acceptUserInvite`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

export function reject_user_invite(userId, callback) {
    // Creating data according to Api
    let data = {"userId": userId};

    // Request Api using final data
    requestApiToServer(`user/rejectUserInvite`, 'PUT', data, (response) => {
        callback(validateApiResponse(response));
    });
}

////////////////////////// END PUT REQUESTS //////////////////////////

////////////////////////// DELETE REQUESTS //////////////////////////
export function delete_user(id, callback) {
    // Request Api using final data
    requestApiToServer(`user/deleteUser/${id}`, 'DELETE', [], (response) => {
        callback(validateApiResponse(response));
    });
}

export function delete_invite_user(id, callback) {
    // Request Api using final data
    requestApiToServer(`user/deleteInvite/${id}`, 'DELETE', [], (response) => {
        callback(validateApiResponse(response));
    });
}

////////////////////////// END DELETE REQUESTS //////////////////////////
