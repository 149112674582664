import React from 'react';
import {
    FormGroup, Label, Input,
    FormFeedback, CustomInput
} from 'reactstrap';
import moment from "moment/moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {getMomentDateFromMysql} from "../../helpers/helper";

export function CommonInput(props) {
    const {type, name, title, value, error, onChange, disabled, className, maxLength, autoFocus, description} = props;
    if (disabled) {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label for="email" hidden>{title}</Label>
                    <Input type={type} name={name} id={name} value={value}
                           placeholder={title} className={className} disabled/>
                    {
                        description
                        &&
                        (
                            <p className="ml-4 text-lato text-left text-muted">
                                <small>
                                    {description}
                                </small>
                            </p>
                        )
                    }
                </FormGroup>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label for={name} hidden>{title}</Label>
                    <Input type={type} name={name} id={name} value={value}
                           onChange={onChange} onFocus={onChange} onBlur={(e) => onChange(e, true)} placeholder={title}
                           className={className} maxLength={maxLength ? maxLength : "255"} invalid={(error !== "")}
                           autoFocus={autoFocus}/>
                    <FormFeedback>{error}</FormFeedback>
                    {
                        description
                        &&
                        (
                            <p className="ml-4 text-lato text-left text-muted">
                                <small>
                                    {description}
                                </small>
                            </p>
                        )
                    }
                </FormGroup>
            </React.Fragment>
        )
    }
}

export function DateInput(props) {
    let {name, title, value, error, onChange, totalCost, className, prevDateEnable, dateFormat, showLabel, maxDateToday, fromDate, disabled} = props;
    className += (error !== "") ? " border-red" : '';
    if (value === 'NaN'){
        value = new Date();
    }
    let dateValue = value ? getMomentDateFromMysql(value) : undefined;

    if (dateValue !== undefined && fromDate !== undefined && dateValue.isBefore(fromDate) && name === 'tillDate') {
        dateValue = fromDate;
    }

    return (
        <React.Fragment>
            <FormGroup className="text-left" id="expiresOn_date">
                {
                    showLabel
                    &&
                    (
                        <Label for={name}
                               style={{paddingLeft: "20px", fontWeight: "400", fontSize: "12px"}}>{title}</Label>
                    )
                }
                {
                    !showLabel
                    &&
                    (
                        <Label for={name} hidden>{title}</Label>
                    )
                }
                <DatePicker name={name} onChange={onChange} className={className}
                            selected={dateValue} placeholderText={title}
                            minDate={fromDate ? fromDate : (prevDateEnable ? moment().add(-100, 'year') : moment().add(1, 'day'))}
                            maxDate={maxDateToday ? moment() : null}
                            dateFormat={dateFormat ? dateFormat : "LL"} autoComplete="off"
                            onChangeRaw={(e) => e.preventDefault()}
                            showMonthDropdown
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            useShortMonthInDropdown
                />
                <FormFeedback className={(error !== "") ? "d-block" : ""}>{error}</FormFeedback>
                {
                    totalCost
                    &&
                    (
                        <p className="text-left mt-1 ml-2 text-lato text-muted">
                            <small>
                                {totalCost}
                            </small>
                        </p>
                    )
                }
            </FormGroup>
        </React.Fragment>
    )
}

export function DateInputSimple(props) {
    let {name, title, value, error, onChange, className, minDate, dropdownPlacement} = props;
    className += (error !== "") ? " border-red" : '';
    return (
        <React.Fragment>
            <FormGroup>
                <Label for={name} hidden>{title}</Label>
                <DatePicker name={name} onChange={onChange} className={className}
                            popperPlacement={dropdownPlacement ? dropdownPlacement : null}
                            popperModifiers={{
                                flip: {
                                    behavior: dropdownPlacement ? [dropdownPlacement] : [] // don't allow it to flip to be above
                                },
                                preventOverflow: {
                                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                },
                                hide: {
                                    enabled: false // turn off since needs preventOverflow to be enabled
                                }
                            }}
                            selected={value ? getMomentDateFromMysql(value) : undefined} placeholderText={title}
                            minDate={minDate ? minDate : moment().add(1, 'day')} dateFormat="LL" autoComplete="off"
                            onChangeRaw={(e) => e.preventDefault()}
                            showMonthDropdown
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            useShortMonthInDropdown/>
                <FormFeedback className={(error !== "") ? "d-block" : ""}>{error}</FormFeedback>
            </FormGroup>
        </React.Fragment>
    )
}

export function DateTimeInput(props) {
    let {name, title, value, error, onChange, className} = props;
    className += (error !== "") ? " border-red" : '';
    return (
        <React.Fragment>
            <FormGroup>
                <Label for={name} hidden>{title}</Label>
                <DatePicker name={name} onChange={onChange} className={className}
                            selected={value ? getMomentDateFromMysql(value) : undefined} placeholderText={title}
                            minDate={moment().add(1, 'day')} dateFormat="LLL" autoComplete="off" timeFormat="HH:mm"
                            showTimeSelect timeIntervals={15} preventOpenOnFocus={true}
                            onChangeRaw={(e) => e.preventDefault()}
                            showMonthDropdown
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            useShortMonthInDropdown/>
                <FormFeedback className={(error !== "") ? "d-block" : ""}>{error}</FormFeedback>
            </FormGroup>
        </React.Fragment>
    )
}

export function PhoneNumberInput(props) {
    const {fields, errors, onChange} = props;
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="country" hidden>Country Code</Label>
                <Input className="country-code" type="tel" name="country_code" id="country_code"
                       onChange={onChange} onFocus={onChange} onBlur={(e) => onChange(e, true)}
                       value={fields.country} placeholder="+000" maxLength="4"
                       invalid={(errors.country !== "")}
                       onKeyUp={(e) => moveFieldForwardAndBackward(e, "country_code")}
                       onKeyDown={(e) => moveFieldForwardAndBackward(e, "country_code")}/>
                <Label for="area_code" hidden>Area Code</Label>
                <Input className="area-code" type="tel" name="area_code" id="area_code"
                       onChange={onChange} onFocus={onChange} onBlur={(e) => onChange(e, true)}
                       value={fields.area_code} placeholder="000" maxLength="3"
                       invalid={(errors.area_code !== "")}
                       onKeyUp={(e) => moveFieldForwardAndBackward(e, "area_code")}
                       onKeyDown={(e) => moveFieldForwardAndBackward(e, "area_code")}/>
                <Label for="phone_number" hidden>Phone Number</Label>
                <Input className="phone-number" type="tel" name="phone_code" id="phone_code"
                       onChange={onChange} onFocus={onChange} onBlur={(e) => onChange(e, true)}
                       value={fields.phone_number} placeholder="0000 0000" maxLength="8"
                       invalid={(errors.phone_number !== "")}
                       onKeyUp={(e) => moveFieldForwardAndBackward(e, "phone_code")}
                       onKeyDown={(e) => moveFieldForwardAndBackward(e, "phone_code")}/>
                <FormFeedback>{errors.phone_number || errors.area_code || errors.country}</FormFeedback>
                <p className="text-left ml-2 text-lato text-muted">Country Code - Area Code - Phone Number</p>
            </FormGroup>
        </React.Fragment>
    )
}

function moveFieldForwardAndBackward(event, fieldName) {
    const {value} = event.target;
    switch (fieldName) {
        case 'country_code' || 'parentCountry':
            if (value.length === 4 && event.keyCode !== 8) {
                document.getElementById('area_code').focus();
            }
            break;
        case 'area_code' || 'parent_area_code':
            if (value.length === 3 && event.keyCode !== 8) {
                document.getElementById('phone_code').focus();
            } else if (value.length === 0 && event.keyCode === 8) {
                if (event.target.getAttribute('data-backward') === null) {
                    event.target.setAttribute("data-backward", "1");
                } else {
                    document.getElementById('country_code').focus();
                    event.target.removeAttribute("data-backward");
                }
            }
            break;
        case 'phone_code' || 'parent_phone_number':
            if (value.length === 0 && event.keyCode === 8) {
                if (event.target.getAttribute('data-backward') === null) {
                    event.target.setAttribute("data-backward", "1");
                } else {
                    document.getElementById('area_code').focus();
                    event.target.removeAttribute("data-backward");
                }
            }
            break;
        default:
            // Do Nothing
            break;
    }
}

export function TimeInput(props) {
    const {fields, errors, onChange} = props;
    return (
        <React.Fragment>
            <FormGroup>
                <Input className="hour-field" type="number" name="hours" id="hours" value={fields.hours}
                       onChange={onChange} onFocus={onChange} onBlur={(e) => onChange(e, true)}
                       min="1" max="12" maxLength="2"
                       invalid={(errors.hours !== "")}/>
                <Input className="min-field" type="number" name="minutes" id="minutes" value={fields.minutes}
                       onChange={onChange} onFocus={onChange} onBlur={(e) => onChange(e, true)}
                       min="1" max="59" maxLength="2"
                       invalid={(errors.minutes !== "")}/>
                <CustomInput className="meridiem-field" type="select" name="meridiem" id="meridiem"
                             value={fields.meridiem}
                             onChange={onChange} onFocus={onChange} onBlur={(e) => onChange(e, true)}
                             invalid={(errors.meridiem !== "")}>
                    <option value="am">AM</option>
                    <option value="pm">PM</option>
                </CustomInput>
                {/*<FormFeedback>{errors.hours || errors.minutes || errors.meridiem}</FormFeedback>*/}
            </FormGroup>
        </React.Fragment>
    )
}

export function PhoneNumberInputParent(props) {
    const {fields, errors, onChange} = props;
    return (
        <React.Fragment>
            <FormGroup>
                <Label for="parentCountry" hidden>Country Code</Label>
                <Input className="country-code" type="tel" name="parentCountry" id="parentCountry"
                       onChange={onChange} onFocus={onChange} onBlur={(e) => onChange(e, true)}
                       value={fields.parentCountry} placeholder="+000" maxLength="4"
                       invalid={(errors.parentCountry !== "")}
                       onKeyUp={(e) => moveFieldForwardAndBackwardParent(e, "parentCountry")}
                       onKeyDown={(e) => moveFieldForwardAndBackwardParent(e, "parentCountry")}/>
                <Label for="parent_area_code" hidden>Area Code</Label>
                <Input className="area-code" type="tel" name="parent_area_code" id="parent_area_code"
                       onChange={onChange} onFocus={onChange} onBlur={(e) => onChange(e, true)}
                       value={fields.parent_area_code} placeholder="000" maxLength="3"
                       invalid={(errors.parent_area_code !== "")}
                       onKeyUp={(e) => moveFieldForwardAndBackwardParent(e, "parent_area_code")}
                       onKeyDown={(e) => moveFieldForwardAndBackwardParent(e, "parent_area_code")}/>
                <Label for="parent_phone_number" hidden>Phone Number</Label>
                <Input className="phone-number" type="tel" name="parent_phone_number" id="parent_phone_number"
                       onChange={onChange} onFocus={onChange} onBlur={(e) => onChange(e, true)}
                       value={fields.parent_phone_number} placeholder="0000 0000" maxLength="8"
                       invalid={(errors.parent_phone_number !== "")}
                       onKeyUp={(e) => moveFieldForwardAndBackwardParent(e, "parent_phone_number")}
                       onKeyDown={(e) => moveFieldForwardAndBackwardParent(e, "parent_phone_number")}/>
                <FormFeedback>{errors.parent_phone_number || errors.parent_area_code || errors.parentCountry}</FormFeedback>
                <p className="text-left ml-2 text-lato text-muted">Country Code - Area Code - Phone Number</p>
            </FormGroup>
        </React.Fragment>
    )
}

function moveFieldForwardAndBackwardParent(event, fieldName) {
    const {value} = event.target;
    switch (fieldName) {
        case 'parentCountry':
            if (value.length === 4 && event.keyCode !== 8) {
                document.getElementById('parent_area_code').focus();
            }
            break;
        case 'parent_area_code':
            if (value.length === 3 && event.keyCode !== 8) {
                document.getElementById('parent_phone_number').focus();
            } else if (value.length === 0 && event.keyCode === 8) {
                if (event.target.getAttribute('data-backward') === null) {
                    event.target.setAttribute("data-backward", "1");
                } else {
                    document.getElementById('parentCountry').focus();
                    event.target.removeAttribute("data-backward");
                }
            }
            break;
        case 'parent_phone_number':
            if (value.length === 0 && event.keyCode === 8) {
                if (event.target.getAttribute('data-backward') === null) {
                    event.target.setAttribute("data-backward", "1");
                } else {
                    document.getElementById('parent_area_code').focus();
                    event.target.removeAttribute("data-backward");
                }
            }
            break;
        default:
            // Do Nothing
            break;
    }
}
