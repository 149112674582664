import React from 'react';
import {Button, Col, Label, Row} from "reactstrap";
import {checkIfNullProfilePicture, getTitleFromChildren, isMobile} from "../../helpers/helper";
import userPlaceholder from '../../assets/images/user-placeholder.jpg';
import DownloadModal from "../Pages/Reports/Common/DownloadModal";
import FormModal from "./FormModal";
import history from "../../helpers/history";

export function SmallTextWithIcon(props) {
    return (
        <div className="overflow-full-width text-muted">
            <small className="text-uppercase text-muted mr-2">
                <i className={props.className}/>
            </small>
            <span title={typeof(props.children) === 'object' ? '' : props.children}>{props.children}</span>
        </div>
    );
}

export function SmallTextWithoutIcon(props) {
    let firstColumnSize = props.isLarge ? '6' : '3';
    let secondColumnSize = props.isLarge ? '6' : '9';
    return (
        <Row>
            {
                props.heading
                &&
                (
                    <Col xs={firstColumnSize}>
                        <small className="text-uppercase text-muted">{props.heading}</small>
                    </Col>
                )
            }
            <Col xs={secondColumnSize}>
                <span title={getTitleFromChildren(props.children)} className={`${props.className} overflow-small-text-no-icon`}>{props.children}</span>
            </Col>
        </Row>
    );
}

export function BlockInfoHeading(props) {
    let className = "text-uppercase text-muted mb-2 overflow-full-width";
    className += isMobile() ? " mt-3" : "";
    return (
        <p className={className}>{props.children}</p>
    );
}

export function BlockInfoTitle(props) {
    let className = props.className + " overflow-full-width";
    return (
        <h4 className={className} title={props.children}>{props.children ? props.children : "---"}</h4>
    );
}

export function BlockInfoBigTitle(props) {
    return (
        <React.Fragment>
            <h2 className={props.className + ' font-weight-bold mb-0'}>
                {props.children}
            </h2>
            <small style={{fontSize: "0.7rem"}} className="text-uppercase text-muted mb-1">{props.subText}</small>
        </React.Fragment>

    );
}

export function BorderBottom(props) {
    return (
        <div className="border-bottom my-2 mb-4">&nbsp;</div>
    );
}

export function ProfileImageBlock(props) {
    return (
        <div className="profile-image-detail">
            {
                checkIfNullProfilePicture(props.imageSrc)
                &&
                (
                    <img src={checkIfNullProfilePicture(props.imageSrc)} alt="P"/>
                )
            }
            {
                !checkIfNullProfilePicture(props.imageSrc)
                &&
                (
                    <img src={userPlaceholder} alt="P"/>
                )
            }
        </div>
    );
}

export function FormTitle(props) {
    return (
        <div className="text-left">
            <Label className="ml-3 font-family-lato">{props.title}</Label>
            <hr className="attendance-body-hr"/>
        </div>
    );
}

export function ReportDownloadAndPrintBtns(props) {
    const {downloadReport} = props;
    return (
        <div className="float-right">
            <FormModal color="link" classes="btn-link-dark"
                       modalContent={<DownloadModal downloadReport={downloadReport}/>}>
                <span className="span-header-btn">DOWNLOAD</span> <i className="fa fa-download icon-circle"/>
            </FormModal>
            <Button color="link" className="btn-link-dark d-none" onClick={() => window.print()}>
                <span className="span-header-btn">PRINT</span> <i className="fa fa-print icon-circle"/>
            </Button>
        </div>
    );
}

export function BackButton(props) {
    let {isActualBack, isAbsolute, className, href} = props;
    className = className ? `${className} p-0` : 'p-0';
    className = isAbsolute ? `${className} back-absolute` : className;
    isActualBack = (!isActualBack && href) ? () => history.push(href) : () => history.goBack();
    return (
        <React.Fragment>
            <Button color="link" className={className} onClick={isActualBack}>
                <i className="fa fa-chevron-left mr-1"/>Back
            </Button>
            {
                !isAbsolute
                &&
                (
                    <React.Fragment>
                        <br/>
                        <br/>
                    </React.Fragment>
                )
            }

        </React.Fragment>
    );
}

export function TitleWithBackButton(props) {
    let {isActualBack, className, href, doReplace, title, noBr} = props;
    className = className ? `${className} p-0 back-absolute-main-title` : 'p-0 back-absolute-main-title';
    isActualBack = (!isActualBack && href) ? () => (doReplace !== null) ? history.replace(href) : history.push(href) : () => history.goBack();
    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Button color="link" className={className} onClick={isActualBack}>
                        <i className="fa fa-chevron-left mr-1"/>Back
                    </Button>
                    <h1 className="text-uppercase with-back-button">{title}</h1>
                    {
                        !noBr
                        &&
                        (
                            <br/>
                        )
                    }
                </Col>
            </Row>
        </React.Fragment>
    );
}

export function FullBackButton(props) {
    let {isActualBack, className, href, title} = props;
    className = className ? `${className} p-0` : 'p-0';
    isActualBack = (!isActualBack && href) ? () => history.push(href) : () => history.goBack();
    return (
        <React.Fragment>
            <Row>
                <Col md="12" className="mt-3 mb-2 font-family-lato">
                    <Button color="link" className={className} onClick={isActualBack}>
                        <i className="fa fa-chevron-left mr-1"/>Back
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    );
}
